import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { StatusCodes } from 'http-status-codes'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NgForm } from '@angular/forms'
import { Subscription } from 'rxjs'
import { CaregiverService } from '../../../services/caregivers.service'
import * as dayjs from 'dayjs'
import { CaregiverEmployeedModel } from '../../../models/caregiver/caregiver-employeed.model'
import { AuthService } from '../../../services/auth.service'
import { ConfirmationService } from 'primeng/api'

@Component({
  selector: 'app-add-caregiver-employeed-dialog',
  templateUrl: './add-caregiver-employeed-dialog.component.html',
})
export class AddCaregiverEmployeedDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null
  private isDirty = false

  public hasOpenEmployment = false

  public data: any = {}
  public values = {
    comment: '',
    employed_since_date: '',
    employed_until_date: '',
    employed_temporary: true,
    document_appendix: false,
    date_contract: '',
  }

  public submitted = false
  public submittedDelete = false

  constructor(
    public authService: AuthService,
    private ref: DynamicDialogRef,
    private confirmationService: ConfirmationService,
    private caregiverService: CaregiverService,
    private config: DynamicDialogConfig,
    private eventbus: EventBusService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.data = this.config.data

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })

    if (this.data.isEdit) {
      this.values.comment = this.data.employment.comment
      this.values.employed_temporary = this.data.employment.employed_temporary

      this.values.employed_since_date = dayjs(
        this.data.employment.employed_since_date
      ).format('DD.MM.YYYY')

      if (this.data.employment.employed_until_date) {
        this.values.employed_until_date = dayjs(
          this.data.employment.employed_until_date
        ).format('DD.MM.YYYY')
      }
    } else {
      // Bei Erstellung soll geprüft werden, ob vorhandene Anstellungen
      // noch kein "bis" Datum haben.
      this.hasOpenEmployment = this.data.caregiver.employments.find(
        (employment: CaregiverEmployeedModel) => {
          return !employment.employed_until_date
        }
      )
    }
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  public store(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    const subscription = this.data.isEdit
      ? this.caregiverService.updateEmployment(
          this.data.employment.id,
          this.values
        )
      : this.caregiverService.storeEmployment(
          this.data.caregiver.id,
          this.values
        )

    subscription.subscribe(
      () => {
        this.submitted = false
        this.eventbus.emit(GlobalEvent.CaregiverDetailReload)
        this.ref.close()
        this.toastService.success(
          'Anstellung gespeichert',
          'Die Anstellung wurde erfolgreich gespeichert'
        )
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }
        this.submitted = false
      }
    )
  }

  /**
   * Sobald das Häkchen bei "Befristet" entfernt wird, soll auch
   * das Datum bei "Angestellt bis" entfernt werden.
   */
  public employedTemporaryChanged(event: any): void {
    if (!this.values.employed_temporary) {
      this.values.employed_until_date = ''
    }
  }

  public untilDateChanged(event: any): void {
    if (this.values.employed_until_date) {
      this.values.employed_temporary = true
    }
  }

  public remove(event: any): void {
    this.confirmationService.confirm({
      target: event.target ?? undefined,
      message: 'Anstellung wirklich löschen?',
      accept: () => {
        this.submittedDelete = true

        this.caregiverService
          .deleteEmployment(this.data.employment.id)
          .subscribe(
            () => {
              this.submittedDelete = false
              this.eventbus.emit(GlobalEvent.CaregiverDetailReload)
              this.ref.close()
              this.toastService.success(
                'Anstellung gelöscht',
                'Die Anstellung wurde erfolgreich gelöscht'
              )
            },
            () => {
              this.submittedDelete = false
              this.toastService.error(
                'Löschen fehlgeschlagen',
                'Der Eintrag konnte nicht gelöscht werden'
              )
            }
          )
      },
      reject: () => {},
    })
  }
}
