<form #form="ngForm" class="dialog-form">
  <div class="dialog-form-body" style="padding-bottom: 0">
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <div>
          {{ data.description }}
        </div>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <div class="p-formgroup-inline">
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Ja"
              name="type"
              [(ngModel)]="values.selected_value"
              value="YES"
              [required]="true"
            ></p-radioButton>
          </div>
          <div class="p-field-radiobutton">
            <p-radioButton
              label="Nein"
              name="type"
              [(ngModel)]="values.selected_value"
              value="NO"
              [required]="true"
            ></p-radioButton>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="dialog-form-footer">
    <i></i>

    <button
      (click)="save()"
      pButton
      label="Bestätigen"
      type="button"
      [disabled]="submitted || !values.selected_value"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>
