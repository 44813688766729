import { CaregiverStatusModel } from './caregiver-status.model'
import { CaregiverJobDaysModel } from './caregiver-job-days.model'
import { CaregiverJobScopeModel } from './caregiver-job-scope.model'
import { CaregiverEmployeedModel } from './caregiver-employeed.model'
import { UserModel } from '../user/user.model'

export class CaregiverDetailModel {
  id: number | null = null
  user_id: number | null = null
  form_of_address = ''
  first_name = ''
  last_name = ''
  full_name = ''
  can_print = false
  phone_1 = ''
  phone_2 = ''
  phone_3 = ''
  birthday_date: Date | null = null
  birthday_date_string = ''
  first_last_name = ''
  last_first_name = ''
  notice = ''
  is_mentor = false
  persplan_id: number | null = null
  employed_temporary = false
  job_scope: string | null = null
  email = ''
  job_position = ''
  job_day = 6
  vacation_days = ''
  salary = ''
  employed_since_date: Date | null | string = null
  employed_until_date: Date | null | string = null
  employed_since_date_string: null | string = null
  employed_until_date_string: null | string = null
  employment_possible_at: Date | null | string = null
  employment_possible_at_string: string | null = null
  initial_documents_generated = true
  deleted_at: Date | null = null
  user: null | UserModel = null
  current_status: CaregiverStatusModel = new CaregiverStatusModel()
  current_job_days: CaregiverJobDaysModel = new CaregiverJobDaysModel()
  first_job_days: CaregiverJobDaysModel = new CaregiverJobDaysModel()
  current_job_scope: CaregiverJobScopeModel = new CaregiverJobScopeModel()
  first_job_scope: CaregiverJobScopeModel = new CaregiverJobScopeModel()
  current_employment: CaregiverEmployeedModel = new CaregiverEmployeedModel()
}
