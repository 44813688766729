import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { DialogService } from 'primeng/dynamicdialog'
import { Subscription } from 'rxjs'
import * as dayjs from 'dayjs'
import { CustomerDetailGeneralModel } from '../../../../models/customer-patient/customer-detail-general.model'
import { LatestChangeModel } from '../../../../models/history/latest-change.model'
import { OfferService } from '../../../../services/offer.service'
import { PatientService } from '../../../../services/patient.service'
import { UserMultiplierService } from '../../../../services/user-multiplier.service'
import { PhoneCallService } from '../../../../services/phone-call.service'
import {
  EventBusService,
  GlobalEvent,
} from '../../../../services/eventbus.service'
import { UserCustomerService } from '../../../../services/user-customer.service'

@Component({
  selector: 'app-user-customer-data-general',
  templateUrl: './user-customer-data-general.component.html',
})
export class UserCustomerDataGeneralComponent implements OnInit, OnDestroy {
  @ViewChild('op') overlayPanel: ElementRef | any = null
  @ViewChild('opNearPatients') overlayPanelNearPatients: ElementRef | any = null

  public loading = true
  public customer: CustomerDetailGeneralModel = new CustomerDetailGeneralModel()
  public area = 1
  public nearPatientsLoading = false
  public nearPatients = []
  public latestChanges = new LatestChangeModel()
  public openedContactPersons: { [index: number]: boolean } = {}
  private eventBusSubscription: Subscription = new Subscription()
  public onlyImportant = false

  private persplanDates: any = {}
  public persplanData = new LatestChangeModel()
  public selectedPatient: 'first_patient' | 'second_patient' = 'first_patient'

  public currentBudgetTable = ''

  constructor(
    private offerService: OfferService,
    private patientService: PatientService,
    private userMultiplierService: UserMultiplierService,
    private userCustomerService: UserCustomerService,
    private route: ActivatedRoute,
    public phoneCallService: PhoneCallService,
    private eventbus: EventBusService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    dayjs.locale('de')

    this.persplanDates = {
      last: {
        month: dayjs().subtract(1, 'month').format('MMMM'),
        year: dayjs().subtract(1, 'month').format('YYYY'),
      },
      current: {
        month: dayjs().format('MMMM'),
        year: dayjs().format('YYYY'),
      },
      next: {
        month: dayjs().add(1, 'month').format('MMMM'),
        year: dayjs().add(1, 'month').format('YYYY'),
      },
    }

    this.loadCustomer()
    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
  }

  public getPersplanBudget(
    patientId: number | null,
    type: 'current' | 'next' | 'last'
  ): void {
    if (this.persplanData[type + patientId]) {
      return
    }

    this.userMultiplierService
      .getPersplanMonth(
        patientId ? patientId.toString() : '',
        this.persplanDates[type].month,
        this.persplanDates[type].year,
        true
      )
      .subscribe((response: string) => {
        this.persplanData[type + patientId] = response
      })
  }

  public openBudgetOverlay(event: any, text: string): void {
    this.currentBudgetTable = text

    this.overlayPanel.toggle(event)
  }

  public changePatient(type: 'first_patient' | 'second_patient'): void {
    this.selectedPatient = type
  }

  public loadCustomer(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.userCustomerService.loadData().subscribe((data: any) => {
        this.customer = data

        if (withLoading) {
          this.loading = false
        }
      })
    })
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe((event) => {
      switch (event) {
        case GlobalEvent.ContactPersonChanged:
          this.loadCustomer(false)
          break
      }
    })
  }
}
