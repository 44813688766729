import Bugsnag from '@bugsnag/js'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'

import { BrowserModule } from '@angular/platform-browser'
import {
  APP_INITIALIZER,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
} from '@angular/core'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { FormsModule } from '@angular/forms'
import { registerLocaleData } from '@angular/common'
import localeDe from '@angular/common/locales/de'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { HeaderComponent } from './components/header/header.component'
import { NavComponent } from './components/nav/nav.component'
import { TableModule } from 'primeng/table'
import { InputTextModule } from 'primeng/inputtext'
import { AutoCompleteModule } from 'primeng/autocomplete'
import { PhoneCallsComponent } from './views/phone-calls/phone-calls.component'
import { TooltipModule } from 'primeng/tooltip'
import { MenuModule } from 'primeng/menu'
import { ButtonModule } from 'primeng/button'
import { CaregiversListActiveComponent } from './views/caregivers/list/active/caregivers-list-active.component'
import { CaregiversFormComponent } from './views/caregivers/form/caregivers-form.component'
import { DropdownModule } from 'primeng/dropdown'
import { InputTextareaModule } from 'primeng/inputtextarea'
import { CheckboxModule } from 'primeng/checkbox'
import { CalendarModule } from 'primeng/calendar'
import { RadioButtonModule } from 'primeng/radiobutton'
import { CaregiversDetailGeneralComponent } from './views/caregivers/detail/general/caregivers-detail-general.component'
import { DialogModule } from 'primeng/dialog'
import { DiffViewDialogComponent } from './components/dialogs/diff-view-dialog/diff-view-dialog.component'
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog'
import { BlockUIModule } from 'primeng/blockui'
import { LoaderComponent } from './components/loader/loader.component'
import { ToastModule } from 'primeng/toast'
import { PhoneCallComponent } from './components/header/phone-call/phone-call.component'
import { ConfirmationService, MessageService } from 'primeng/api'
import { OfferComponent } from './views/offer/offer.component'
import { LoginComponent } from './views/auth/login/login.component'
import { CaregiversListDeactivatedComponent } from './views/caregivers/list/deactivated/caregivers-list-deactivated.component'
import { CaregiversListComponent } from './views/caregivers/list/caregivers-list.component'
import { CaregiversDetailComponent } from './views/caregivers/detail/caregivers-detail.component'
import { CaregiversDetailHistoryComponent } from './views/caregivers/detail/history/caregivers-detail-history.component'
import { ConfirmPopupModule } from 'primeng/confirmpopup'
import { CaregiversDetailDocumentsComponent } from './views/caregivers/detail/documents/caregivers-detail-documents.component'
import { PhoneCallEditDialogComponent } from './components/dialogs/phone-call-edit-dialog/phone-call-edit-dialog.component'
import { EventBusService } from './services/eventbus.service'
import { HistoryManualDialogComponent } from './components/dialogs/history-manual-dialog/history-manual-dialog.component'
import { CarsListComponent } from './views/cars/list/cars-list.component'
import { CarsFormComponent } from './views/cars/form/cars-form.component'
import { CarsDetailComponent } from './views/cars/detail/cars-detail.component'
import { CarsDetailGeneralComponent } from './views/cars/detail/general/cars-detail-general.component'
import { CarsDetailHistoryComponent } from './views/cars/detail/history/cars-detail-history.component'
import { InputNumberModule } from 'primeng/inputnumber'
import { AddDocumentDialogComponent } from './components/dialogs/add-document-dialog/add-document-dialog.component'
import { TitleService } from './services/title.service'
import { DashboardComponent } from './views/dashboard/dashboard.component'
import { MultipliersListComponent } from './views/multipliers/list/multipliers-list.component'
import { NoticeDialogComponent } from './components/dialogs/notice-dialog/notice-dialog.component'
import { CurrencyPipe } from '@angular/common'
import { MultipliersFormComponent } from './views/multipliers/form/multipliers-form.component'
import { MultiSelectModule } from 'primeng/multiselect'
import { MultipliersDetailComponent } from './views/multipliers/detail/multipliers-detail.component'
import { MultipliersDetailGeneralComponent } from './views/multipliers/detail/general/multipliers-detail-general.component'
import { MultipliersDetailHistoryComponent } from './views/multipliers/detail/history/multipliers-detail-history.component'
import { PatientsQualificationOpenComponent } from './views/patients/qualification-open/patients-qualification-open.component'
import { ContactPersonDialogComponent } from './components/dialogs/contact-person-dialog/contact-person-dialog.component'
import { PatientsDetailComponent } from './views/patients/detail/patients-detail.component'
import { PatientsDetailGeneralComponent } from './views/patients/detail/general/patients-detail-general.component'
import { PatientsDetailDocumentsComponent } from './views/patients/detail/documents/patients-detail-documents.component'
import { PatientsDetailHistoryComponent } from './views/patients/detail/history/patients-detail-history.component'
import { PatientsInactiveComponent } from './views/patients/inactive/patients-inactive.component'
import { QualificationDialogComponent } from './components/dialogs/qualification-dialog/qualification-dialog.component'
import { PatientsNewComponent } from './views/patients/new/patients-new.component'
import { PatientsRegularComponent } from './views/patients/regular/patients-regular.component'
import { ConfirmBudgetDialogComponent } from './components/dialogs/confirm-budget-dialog/confirm-budget-dialog.component'
import { ChangeCustomerServiceDialogComponent } from './components/dialogs/change-customer-service-dialog/change-customer-service-dialog.component'
import { TodoFormDialogComponent } from './components/dialogs/todo-form-dialog/todo-form-dialog.component'
import { DragulaModule } from 'ng2-dragula'
import { AddVacationDialogComponent } from './components/dialogs/add-vacation-dialog/add-vacation-dialog.component'
import { AddIllDialogComponent } from './components/dialogs/add-ill-dialog/add-ill-dialog.component'
import { NoticeAccountingDialogComponent } from './components/dialogs/notice-accounting-dialog/notice-accounting-dialog.component'
import { AddFreeTimeRequestDialogComponent } from './components/dialogs/add-free-time-request-dialog/add-free-time-request-dialog.component'
import { CaregiversDetailVetoComponent } from './views/caregivers/detail/veto/caregivers-detail-veto.component'
import { AddVetoDialogComponent } from './components/dialogs/add-veto-dialog/add-veto-dialog.component'
import { PatientsDetailVetoComponent } from './views/patients/detail/veto/patients-detail-veto.component'
import { AddTrainingDialogComponent } from './components/dialogs/add-training-dialog/add-training-dialog.component'
import { ChangeBudgetDialogComponent } from './components/dialogs/change-budget-dialog/change-budget-dialog.component'
import { GlobalDocumentsComponent } from './views/global-documents/global-documents.component'
import { AddGlobalDocumentDialogComponent } from './components/dialogs/add-global-document-dialog/add-global-document-dialog.component'
import { AddCaregiverCarDialogComponent } from './components/dialogs/add-caregiver-car-dialog/add-caregiver-car-dialog.component'
import { CapacityComponent } from './views/capacity/capacity.component'
import { SendDlvDialogComponent } from './components/dialogs/send-dlv-dialog/send-dlv-dialog.component'
import { SendAppointmentsDialogComponent } from './components/dialogs/send-appointments-dialog/send-appointments-dialog.component'
import { InputSwitchModule } from 'primeng/inputswitch'
import { TodosComponent } from './views/todos/todos.component'
import { PrintAddressDialogComponent } from './components/dialogs/print-address-dialog/print-address-dialog.component'
import { PauseDialogComponent } from './components/dialogs/pause-dialog/pause-dialog.component'
import { PatientsPausedComponent } from './views/patients/paused/patients-paused.component'
import { SendLetterDialogComponent } from './components/dialogs/send-letter-dialog/send-letter-dialog.component'
import { OverlayPanelModule } from 'primeng/overlaypanel'
import { ChangeDriveTimeManuallyDialogComponent } from './components/dialogs/change-drive-time-manually-dialog/change-drive-time-manually-dialog.component'
import { ChangeCaregiverTimeCorrectionDialogComponent } from './components/dialogs/change-caregiver-time-correction-dialog/change-caregiver-time-correction-dialog.component'
import { AddCaregiverJobScopeDialogComponent } from './components/dialogs/add-caregiver-job-scope-dialog/add-caregiver-job-scope-dialog.component'
import { AddCaregiverJobDaysDialogComponent } from './components/dialogs/add-caregiver-job-days-dialog/add-caregiver-job-days-dialog.component'
import { ChangeCaregiverPersplanTimeCorrectionDialogComponent } from './components/dialogs/change-caregiver-persplan-time-correction-dialog/change-caregiver-persplan-time-correction-dialog.component'
import { DesiredDatesIncomingComponent } from './views/desired-dates/incoming/desired-dates-incoming.component'
import { AddDesiredDateDialogComponent } from './components/dialogs/add-desired-date-dialog/add-desired-date-dialog.component'
import { PatientsConsultingsComponent } from './views/patients/consultings/patients-consultings.component'
import { CancelledDatesLastComponent } from './views/cancelled-dates/last/cancelled-dates-last.component'
import { AddCancelledDateDialogComponent } from './components/dialogs/add-cancelled-date-dialog/add-cancelled-date-dialog.component'
import { DiffViewHistoryDialogComponent } from './components/dialogs/diff-view-history-dialog/diff-view-history-dialog.component'
import { CarsDetailDamageComponent } from './views/cars/detail/damage/cars-detail-damage.component'
import { AddDamageDialogComponent } from './components/dialogs/add-damage-dialog/add-damage-dialog.component'
import { AddCarMileageDialogComponent } from './components/dialogs/add-car-mileage-dialog/add-car-mileage-dialog.component'
import { ShowDamageDialogComponent } from './components/dialogs/show-damage-dialog/show-damage-dialog.component'
import { CarsDetailDocumentsComponent } from './views/cars/detail/documents/cars-detail-documents.component'
import { AddCarStatusDialogComponent } from './components/dialogs/add-car-status-dialog/add-car-status-dialog.component'
import { CaregiversDetailCalendarComponent } from './views/caregivers/detail/overview/calendar/caregivers-detail-calendar.component'
import { CaregiversDetailOverviewComponent } from './views/caregivers/detail/overview/caregivers-detail-overview.component'
import { AddCaregiverTimesDialogComponent } from './components/dialogs/add-caregiver-times-dialog/add-caregiver-times-dialog.component'
import { AdministrationComponent } from './views/administration/administration.component'
import { UsersListComponent } from './views/administration/users/list/users-list.component'
import { UsersFormComponent } from './views/administration/users/form/users-form.component'
import { UsersDetailComponent } from './views/administration/users/detail/users-detail.component'
import { UsersDetailGeneralComponent } from './views/administration/users/detail/general/users-detail-general.component'
import { UsersDetailHistoryComponent } from './views/administration/users/detail/history/users-detail-history.component'
import { RolesListComponent } from './views/administration/roles/list/roles-list.component'
import { RolesFormComponent } from './views/administration/roles/form/roles-form.component'
import { NoPermissionComponent } from './components/no-permission/no-permission.component'
import { HelperService } from './services/helper.service'
import { RolesDetailComponent } from './views/administration/roles/detail/roles-detail.component'
import { CaregiversDetailDatesComponent } from './views/caregivers/detail/overview/dates/caregivers-detail-dates.component'
import { AddCaregiverDateDialogComponent } from './components/dialogs/add-caregiver-date-dialog/add-caregiver-date-dialog.component'
import { CarsListActiveComponent } from './views/cars/list/active/cars-list-active.component'
import { CarsListDeactivatedComponent } from './views/cars/list/deactivated/cars-list-deactivated.component'
import { AddCaregiverEmployeedDialogComponent } from './components/dialogs/add-caregiver-employeed-dialog/add-caregiver-employeed-dialog.component'
import { CaregiversDetailAnalysesComponent } from './views/caregivers/detail/overview/analyses/caregivers-detail-analyses.component'
import { CaregiversDetailProofsComponent } from './views/caregivers/detail/overview/proofs/caregivers-detail-proofs.component'
import { AddCaregiverProofDialogComponent } from './components/dialogs/add-caregiver-proof-dialog/add-caregiver-proof-dialog.component'
import { SelectButtonModule } from 'primeng/selectbutton'
import { CaregiversDetailMasterDataComponent } from './views/caregivers/detail/general/master-data/caregivers-detail-master-data.component'
import { CaregiversDetailContractDataComponent } from './views/caregivers/detail/general/contract-data/caregivers-detail-contract-data.component'
import { CaregiversDetailQualificationsComponent } from './views/caregivers/detail/general/qualifications/caregivers-detail-qualifications.component'
import { ChangeStatusPatientDialogComponent } from './components/dialogs/change-status-patient-dialog/change-status-patient-dialog.component'
import { ChangeStatusCaregiverDialogComponent } from './components/dialogs/change-status-caregiver-dialog/change-status-caregiver-dialog.component'
import { OpenTodosComponent } from './views/todos/open-todos/open-todos.component'
import { FinishedTodosComponent } from './views/todos/finished-todos/finished-todos.component'
import { OwnTodosComponent } from './views/todos/own-todos/own-todos.component'
import { BadgeModule } from 'primeng/badge'
import { ConfirmImportantHistoriesDialogComponent } from './components/dialogs/confirm-important-histories-dialog/confirm-important-histories-dialog.component'
import { ShowTodoDialogComponent } from './components/dialogs/show-todo-dialog/show-todo-dialog.component'
import { PasswordResetComponent } from './views/auth/password-reset/password-reset.component'
import { LockPatientDialogComponent } from './components/dialogs/lock-patient-dialog/lock-patient-dialog.component'
import { ContextMenuModule } from 'primeng/contextmenu'
import { CaregiversDetailListComponent } from './views/caregivers/detail/overview/list/caregivers-detail-list.component'
import { PlanningsComponent } from './views/plannings/plannings.component'
import { VacationPlanningsComponent } from './views/plannings/vacation-plannings/vacation-plannings.component'
import { CarsDetailCostsComponent } from './views/cars/detail/costs/cars-detail-costs.component'
import { AddCarCostDialogComponent } from './components/dialogs/add-car-cost-dialog/add-car-cost-dialog.component'
import { GlobalAnalysesComponent } from './views/global-analyses/global-analyses.component'
import { AnalysesCaregiversComponent } from './views/global-analyses/caregivers/analyses-caregivers.component'
import { AnalysesPlanningsComponent } from './views/global-analyses/plannings/analyses-plannings.component'
import { RolesDetailHistoryComponent } from './views/administration/roles/detail/history/roles-detail-history.component'
import { RolesDetailGeneralComponent } from './views/administration/roles/detail/general/roles-detail-general.component'
import { UserSettingsComponent } from './views/user-settings/user-settings.component'
import { AccountingComponent } from './views/accounting/accounting.component'
import { AccountingReturnsComponent } from './views/accounting/returns/accounting-returns.component'
import { AccountingReturnsCreatedComponent } from './views/accounting/returns/created/accounting-returns-created.component'
import { AddAccountingReturnDialogComponent } from './components/dialogs/add-accounting-return-dialog/add-accounting-return-dialog.component'
import { AccountingReturnsEditedComponent } from './views/accounting/returns/edited/accounting-returns-edited.component'
import { AccountingReturnsFinishedComponent } from './views/accounting/returns/finished/accounting-returns-finished.component'
import { PatientsDetailAccountingComponent } from './views/patients/detail/accounting/patients-detail-accounting.component'
import { PatientsDetailAccountingReturnsComponent } from './views/patients/detail/accounting/returns/patients-detail-accounting-returns.component'
import { ChangePatientAppointmentTypesDialogComponent } from './components/dialogs/change-patient-appointment-types-dialog/change-patient-appointment-types-dialog.component'
import { AddCaregiverOverviewCheckCommentDialogComponent } from './components/dialogs/add-caregiver-overview-check-comment-dialog/add-caregiver-overview-check-comment-dialog.component'
import { SkeletonModule } from 'primeng/skeleton'
import { ChangeInitialPasswordComponent } from './views/auth/change-initial-password/change-initial-password.component'
import { PasswordModule } from 'primeng/password'
import { UserCaregiverPhoneCallsComponent } from './views/user-caregiver/phone-calls/user-caregiver-phone-calls.component'
import { UserCaregiverProofsComponent } from './views/user-caregiver/proofs/user-caregiver-proofs.component'
import { UserCaregiverAnalysesComponent } from './views/user-caregiver/analyses/user-caregiver-analyses.component'
import { UserCaregiverCalendarComponent } from './views/user-caregiver/calendar/user-caregiver-calendar.component'
import { UserCaregiverOverviewListComponent } from './views/user-caregiver/overview-list/user-caregiver-overview-list.component'
import { UserCaregiverDataComponent } from './views/user-caregiver/data/user-caregiver-data.component'
import { AverageBudgetHoursPatientDialogComponent } from './components/dialogs/average-budget-hours-patient-dialog/average-budget-hours-patient-dialog.component'
import { AppointmentFeedbackDialogComponent } from './components/dialogs/appointment-feedback-dialog/appointment-feedback-dialog.component'
import { AppointmentTripsDialogComponent } from './components/dialogs/appointment-trips-dialog/appointment-trips-dialog.component'
import { CaregiversListApplicantsComponent } from './views/caregivers/list-applicants/caregivers-list-applicants.component'
import { CaregiversListApplicantsApplicantsComponent } from './views/caregivers/list-applicants/applicants/caregivers-list-applicants-applicants.component'
import { UserCaregiverDataMasterDataComponent } from './views/user-caregiver/data/master-data/user-caregiver-data-master-data.component'
import { UserCaregiverDataContractDataComponent } from './views/user-caregiver/data/contract-data/user-caregiver-data-contract-data.component'
import { UserCaregiverDataQualificationsComponent } from './views/user-caregiver/data/qualifications/user-caregiver-data-qualifications.component'
import { CaregiversListApplicantsDeactivatedComponent } from './views/caregivers/list-applicants/deactivated/caregivers-list-applicants-deactivated.component'
import { ServiceWorkerModule, SwUpdate } from '@angular/service-worker'
import { environment } from '../environments/environment'
import { UserCaregiverDocumentsComponent } from './views/user-caregiver/documents/user-caregiver-documents.component'
import { CaregiverApproveOverviewDialogComponent } from './components/dialogs/caregiver-approve-overview/caregiver-approve-overview-dialog.component'
import { ShowCaregiverOverviewReleaseDialogComponent } from './components/dialogs/show-caregiver-release-overview-dialog/show-caregiver-overview-release-dialog.component'
import { ChangePatientInvoiceTypesDialogComponent } from './components/dialogs/change-patient-invoice-types-dialog/change-patient-invoice-types-dialog.component'
import { AppointmentChangeFeedbackDialogComponent } from './components/dialogs/appointment-change-feedback-dialog/appointment-change-feedback-dialog.component'
import { PlansComponent } from './views/plans/plans.component'
import { PlansApprovalsComponent } from './views/plans/approvals/plans-approvals.component'
import { PlansFeedbackComponent } from './views/plans/feedback/plans-feedback.component'
import { PlansFeedbackCreatedComponent } from './views/plans/feedback/created/plans-feedback-created.component'
import { PlansFeedbackDialogComponent } from './components/dialogs/plans-feedback-dialog/plans-feedback-dialog.component'
import { PlansFeedbackEditedComponent } from './views/plans/feedback/edited/plans-feedback-edited.component'
import { PlansFeedbackFinishedComponent } from './views/plans/feedback/finished/plans-feedback-finished.component'
import { SendSystemMessageDialogComponent } from './components/dialogs/send-system-message-dialog/send-system-message-dialog.component'
import { AnonymousAppointmentsDialogComponent } from './components/dialogs/anonymous-appointments-dialog/anonymous-appointments-dialog.component'
import { ThcDatesComponent } from './views/calendar/thc-dates/thc-dates.component'
import { FullCalendarModule } from '@fullcalendar/angular'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import { PatientsDetailPlanningComponent } from './views/patients/detail/planning/patients-detail-planning.component'
import { TreeSelectModule } from 'primeng/treeselect'
import { DragDropModule } from 'primeng/dragdrop'
import { AppointmentPlanningDialogComponent } from './components/dialogs/appointment-planning-dialog/appointment-planning-dialog.component'
import { SidebarModule } from 'primeng/sidebar'
import { ConfirmWithTextDialogComponent } from './components/dialogs/confirm-with-text-dialog/confirm-with-text-dialog.component'
import interactionPlugin from '@fullcalendar/interaction'
import { ThcDateDialogComponent } from './components/dialogs/thc-date-dialog/thc-date-dialog.component'
import { ChangeRequiredPlanningsComponent } from './views/plannings/change-required-plannings/change-required-plannings.component'
import { AnalysesBudgetsComponent } from './views/global-analyses/budgets/analyses-budgets.component'
import { SendMessageToCaregiverDialogComponent } from './components/dialogs/send-message-to-caregiver-dialog/send-message-to-caregiver-dialog.component'
import { InvoiceDialogComponent } from './components/dialogs/invoice-dialog/invoice-dialog.component'
import { SplitButtonModule } from 'primeng/splitbutton'
import { InvoicesComponent } from './views/invoices/invoices.component'
import { InvoicesKmComponent } from './views/invoices/km/invoices-km.component'
import { InvoicesKmDraftsComponent } from './views/invoices/km/drafts/invoices-km-drafts.component'
import { InvoicesKmFinishedComponent } from './views/invoices/km/finished/invoices-km-finished.component'
import { InvoicesKmOpenedComponent } from './views/invoices/km/opened/invoices-km-opened.component'
import { InvoiceKmDialogComponent } from './components/dialogs/invoice-dialog/invoice-km-dialog.component'
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox'
import { InvoicesKmReadyComponent } from './views/invoices/km/ready/invoices-km-ready.component'
import { PatientsDetailAccountingKmComponent } from './views/patients/detail/accounting/km/patients-detail-accounting-km.component'
import { InvoicesKmStornoComponent } from './views/invoices/km/storno/invoices-km-storno.component'
import { InvoicesKmCreditComponent } from './views/invoices/km/credit/invoices-km-credit.component'
import { CaregiverApprovePlanningDialogComponent } from './components/dialogs/caregiver-approve-planning-dialog/caregiver-approve-planning-dialog.component'
import { CalendarComponent } from './views/calendar/calendar.component'
import { ConsultingDatesComponent } from './views/calendar/consulting-dates/consulting-dates.component'
import { AccountMultiplierDialogComponent } from './components/dialogs/account-multiplier-dialog/account-multiplier-dialog.component'
import { AccountCaregiveDialogComponent } from './components/dialogs/account-caregiver-dialog/account-caregiver-dialog.component'
import { UserMultiplierPatientsComponent } from './views/user-multiplier/patients/user-multiplier-patients.component'
import { UserMultiplierPatientsDetailComponent } from './views/user-multiplier/patients/detail/user-multiplier-patients-detail.component'
import { UserMultiplierPatientsDetailGeneralComponent } from './views/user-multiplier/patients/detail/general/user-multiplier-patients-detail-general.component'
import { UserMultiplierPatientsDetailHistoryComponent } from './views/user-multiplier/patients/detail/history/user-multiplier-patients-detail-history.component'
import { UserMultiplierOfferComponent } from './views/user-multiplier/offer/user-multiplier-offer.component'
import { UserCaregiverOfferComponent } from './views/user-caregiver/offer/user-caregiver-offer.component'
import { CaregiversDetailRecommendationsComponent } from './views/caregivers/detail/overview/recommendations/caregivers-detail-recommendations.component'
import { InvoicesConsultingsComponent } from './views/invoices/consultings/invoices-consultings.component'
import { InvoicesConsultingsOpenedComponent } from './views/invoices/consultings/opened/invoices-consultings-opened.component'
import { InvoicesConsultingsFinishedComponent } from './views/invoices/consultings/finished/invoices-consultings-finished.component'
import { InvoicesConsultingsStornoComponent } from './views/invoices/consultings/storno/invoices-consultings-storno.component'
import { InvoicesConsultingsCreditComponent } from './views/invoices/consultings/credit/invoices-consultings-credit.component'
import { PatientsDetailConsultingComponent } from './views/patients/detail/consulting/patients-detail-consulting.component'
import { ConsultingConfirmDialogComponent } from './components/dialogs/consulting-confirm-dialog/consulting-confirm-dialog.component'
import { ConsultingDialogComponent } from './components/dialogs/consulting-dialog/consulting-dialog.component'
import { InvoicesConsultingsReadyComponent } from './views/invoices/consultings/ready/invoices-consultings-ready.component'
import { PatientsDetailAccountingConsultingsComponent } from './views/patients/detail/accounting/consultings/patients-detail-accounting-consultings.component'
import { ConsultingsListComponent } from './views/consultings-list/consultings-list.component'
import { ConsultingsListOpenComponent } from './views/consultings-list/open/consultings-list-open.component'
import { ConsultingsListFinishedComponent } from './views/consultings-list/finished/consultings-list-finished.component'
import { AnalysesProgressComponent } from './views/global-analyses/progress/analyses-progress.component'
import { AnalysesPhoneCallsComponent } from './views/global-analyses/phone-calls/analyses-phone-calls.component'
import { CaregiversDetailPlanningComponent } from './views/caregivers/detail/planning/caregivers-detail-planning.component'
import { CaregiverPlanningDialogComponent } from './components/dialogs/caregiver-planning-dialog/caregiver-planning-dialog.component'
import { CancelledDatesComponent } from './views/cancelled-dates/cancelled-dates.component'
import { CancelledDatesAllComponent } from './views/cancelled-dates/all/cancelled-dates-all.component'
import { DesiredDatesComponent } from './views/desired-dates/desired-dates.component'
import { DesiredDatesFinishedComponent } from './views/desired-dates/finished/desired-dates-finished.component'
import { HandoverComponent } from './views/handover/handover.component'
import { ProofsComponent } from './views/proofs/proofs.component'
import { GenerateProofCoverDialogComponent } from './components/dialogs/generate-proof-cover-dialog/generate-proof-cover-dialog.component'
import { UploadProofsDialogComponent } from './components/dialogs/upload-proofs-dialog/upload-proofs-dialog.component'
import { ProgressBarModule } from 'primeng/progressbar'
import { ProofCheckDialogComponent } from './components/dialogs/proof-check-dialog/proof-check-dialog.component'
import { SplitterModule } from 'primeng/splitter'
import { ReleaseAppointmentAdminDialogComponent } from './components/dialogs/release-appointment-admin-dialog/release-appointment-admin-dialog.component'
import { FinishProofReworkDialogComponent } from './components/dialogs/finish-proof-rework-dialog/finish-proof-rework-dialog.component'
import { InvoicesAppointmentsComponent } from './views/invoices/appointments/invoices-appointments.component'
import { InvoicesAppointmentsDraftsComponent } from './views/invoices/appointments/drafts/invoices-appointments-drafts.component'
import { UploadSingleProofDialogComponent } from './components/dialogs/upload-single-proof-dialog/upload-single-proof-dialog.component'
import { UploadProofsHistoryDialogComponent } from './components/dialogs/upload-proofs-history-dialog/upload-proofs-history-dialog.component'
import { ChangePatientBudgetProofDialogComponent } from './components/dialogs/change-patient-budget-proof-dialog/change-patient-budget-proof-dialog.component'
import { SelectPatientForProofDialogComponent } from './components/dialogs/select-patient-for-proof-dialog/select-patient-for-proof-dialog.component'
import { InvoicesAppointmentsOpenedComponent } from './views/invoices/appointments/opened/invoices-appointments-opened.component'
import { PatientsDetailAccountingAppointmentsComponent } from './views/patients/detail/accounting/appointments/patients-detail-accounting-appointments.component'
import { InvoicesAppointmentsFinishedComponent } from './views/invoices/appointments/finished/invoices-appointments-finished.component'
import { InvoicesAppointmentsCreditComponent } from './views/invoices/appointments/credit/invoices-appointments-credit.component'
import { InvoicesAppointmentsStornoComponent } from './views/invoices/appointments/storno/invoices-appointments-storno.component'
import { InvoiceViewDialogComponent } from './components/dialogs/invoice-view-dialog/invoice-view-dialog.component'
import { InvoiceNewDialogComponent } from './components/dialogs/invoice-new-dialog/invoice-new-dialog.component'
import { UserCaregiverRecommendationsComponent } from './views/user-caregiver/recommendations/user-caregiver-recommendations.component'
import { AnalysesRecommendationsComponent } from './views/global-analyses/recommendations/analyses-recommendations.component'
import { NgxJoditModule } from 'ngx-jodit'
import { UserCaregiverHospitationComponent } from './views/user-caregiver/hospitation/user-caregiver-hospitation.component'
import { HospitationDialogComponent } from './components/dialogs/hospitation-dialog/hospitation-dialog.component'
import { ShowHistoriesDialogComponent } from './components/dialogs/show-histories-dialog/show-histories-dialog.component'
import { HospitationFeedbackDialogComponent } from './components/dialogs/hospitation-feedback-dialog/hospitation-feedback-dialog.component'
import { SliderModule } from 'primeng/slider'
import { SendInvoiceReminderDialogComponent } from './components/dialogs/send-invoice-reminder-dialog/send-invoice-reminder-dialog.component'
import { AddUserCaregiverDateDialogComponent } from './components/dialogs/add-user-caregiver-date-dialog/add-user-caregiver-date-dialog.component'
import { AccountCustomerDialogComponent } from './components/dialogs/account-customer-dialog/account-customer-dialog.component'
import { UserCustomerDataComponent } from './views/user-customer/data/user-customer-data.component'
import { UserCustomerDocumentsComponent } from './views/user-customer/documents/user-customer-documents.component'
import { UserCustomerDataGeneralComponent } from './views/user-customer/data/general/user-customer-data-general.component'
import { UserCustomerOverviewComponent } from './views/user-customer/overview/user-customer-overview.component'
import { AddUserCustomerRequestDialogComponent } from './components/dialogs/add-user-customer-request-dialog/add-user-customer-request-dialog.component'
import { UserCustomerRequestsComponent } from './views/user-customer/requests/user-customer-requests.component'
import { CustomerRequestsComponent } from './views/customer-requests/customer-requests.component'
import { CustomerRequestsCreatedComponent } from './views/customer-requests/created/customer-requests-created.component'
import { SystemMessageInternDialogComponent } from './components/dialogs/system-message-intern-dialog/system-message-intern-dialog.component'
import { RequiredNotificationDialogComponent } from './components/dialogs/required-notification-dialog/required-notification-dialog.component'
import { ConsultingsListAppointmentsComponent } from './views/consultings-list/appointments/consultings-list-appointments.component'
import { ConsultingsListInvoicesComponent } from './views/consultings-list/invoices/consultings-list-invoices.component'
import { PatientsDetailHoursComponent } from './views/patients/detail/hours/patients-detail-hours.component'
import { PatientsDetailBudgetComponent } from './views/patients/detail/hours/budgets/patients-detail-budget.component'
import { PatientsDetailAppointmentsComponent } from './views/patients/detail/hours/appointments/patients-detail-appointments.component'

registerLocaleData(localeDe)

Bugsnag.start({
  apiKey: 'b17f5cece326626161f3ba08b2cfb319',
  enabledReleaseStages: ['production', 'staging', 'demo'],
})

export function errorHandlerFactory(): BugsnagErrorHandler {
  return new BugsnagErrorHandler()
}

FullCalendarModule.registerPlugins([
  dayGridPlugin,
  timeGridPlugin,
  interactionPlugin,
])

/**
 * Die Abhängigkeiten werden zuerst als APP_INITIALIZER geladen.
 * Stellt sicher, dass diese vorhanden ist, bevor die App aufgebaut wird.
 */
export function initDependencies(
  helperService: HelperService,
  update: SwUpdate
) {
  return () => {
    // Sobald Angular (Service Worker) erkennt, dass eine neue Version der Dateien
    // vorhanden ist, wird der Benutzer gefragt, ob er die Seite aktualisieren will.
    update.available.subscribe((event) => {
      if (confirm('Das Portal hat eine neue Version. Jetzt aktualisieren?')) {
        update.activateUpdate().then(() => {
          document.location.reload()
        })
      }
    })

    return helperService.init()
  }
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    NavComponent,
    PhoneCallsComponent,
    CaregiversListComponent,
    CaregiversListActiveComponent,
    CaregiversListDeactivatedComponent,
    CaregiversFormComponent,
    CaregiversDetailComponent,
    CaregiversDetailMasterDataComponent,
    CaregiversDetailDocumentsComponent,
    CaregiversDetailHistoryComponent,
    CaregiverApprovePlanningDialogComponent,
    CaregiversDetailContractDataComponent,
    CaregiversDetailGeneralComponent,
    CaregiversDetailQualificationsComponent,
    DiffViewDialogComponent,
    AddCaregiverProofDialogComponent,
    DiffViewHistoryDialogComponent,
    ChangePatientBudgetProofDialogComponent,
    ContactPersonDialogComponent,
    LoaderComponent,
    SelectPatientForProofDialogComponent,
    NoPermissionComponent,
    PhoneCallComponent,
    OfferComponent,
    LoginComponent,
    PasswordResetComponent,
    UsersListComponent,
    SendInvoiceReminderDialogComponent,
    PhoneCallEditDialogComponent,
    UsersFormComponent,
    UsersDetailComponent,
    UsersDetailGeneralComponent,
    UsersDetailHistoryComponent,
    HistoryManualDialogComponent,
    AddCarMileageDialogComponent,
    AddCarStatusDialogComponent,
    CarsListComponent,
    CarsListActiveComponent,
    CarsListDeactivatedComponent,
    CarsDetailComponent,
    CarsDetailGeneralComponent,
    CarsDetailDamageComponent,
    CarsDetailCostsComponent,
    AnalysesRecommendationsComponent,
    CarsFormComponent,
    ChangeInitialPasswordComponent,
    UploadProofsHistoryDialogComponent,
    AnalysesProgressComponent,
    GenerateProofCoverDialogComponent,
    PlanningsComponent,
    VacationPlanningsComponent,
    ChangeStatusPatientDialogComponent,
    ChangeStatusCaregiverDialogComponent,
    SendMessageToCaregiverDialogComponent,
    CarsDetailHistoryComponent,
    AnalysesBudgetsComponent,
    AddDocumentDialogComponent,
    DashboardComponent,
    MultipliersListComponent,
    CaregiverApproveOverviewDialogComponent,
    MultipliersFormComponent,
    ShowCaregiverOverviewReleaseDialogComponent,
    MultipliersDetailComponent,
    MultipliersDetailGeneralComponent,
    MultipliersDetailHistoryComponent,
    NoticeDialogComponent,
    QualificationDialogComponent,
    ConsultingConfirmDialogComponent,
    InvoicesConsultingsReadyComponent,
    PatientsDetailAccountingConsultingsComponent,
    PatientsQualificationOpenComponent,
    PatientsDetailComponent,
    PatientsDetailGeneralComponent,
    HandoverComponent,
    ProofsComponent,
    PatientsDetailDocumentsComponent,
    PatientsDetailHistoryComponent,
    PatientsInactiveComponent,
    PatientsRegularComponent,
    PatientsNewComponent,
    PatientsDetailHoursComponent,
    PatientsDetailBudgetComponent,
    PatientsDetailAppointmentsComponent,
    ConfirmBudgetDialogComponent,
    ChangeCustomerServiceDialogComponent,
    TodoFormDialogComponent,
    AddVacationDialogComponent,
    AddIllDialogComponent,
    AddDamageDialogComponent,
    ShowDamageDialogComponent,
    FinishProofReworkDialogComponent,
    InvoicesAppointmentsComponent,
    InvoicesAppointmentsOpenedComponent,
    InvoicesAppointmentsFinishedComponent,
    InvoicesAppointmentsCreditComponent,
    InvoicesAppointmentsStornoComponent,
    InvoicesAppointmentsDraftsComponent,
    UploadSingleProofDialogComponent,
    InvoiceViewDialogComponent,
    ShowTodoDialogComponent,
    UserCaregiverRecommendationsComponent,
    AnalysesPhoneCallsComponent,
    CaregiversDetailDatesComponent,
    NoticeAccountingDialogComponent,
    AddFreeTimeRequestDialogComponent,
    CaregiversDetailVetoComponent,
    CaregiversDetailPlanningComponent,
    AddVetoDialogComponent,
    PatientsDetailVetoComponent,
    UserMultiplierPatientsDetailGeneralComponent,
    UserMultiplierPatientsDetailHistoryComponent,
    UserMultiplierPatientsDetailComponent,
    AddTrainingDialogComponent,
    UserCaregiverPhoneCallsComponent,
    UserCaregiverDocumentsComponent,
    UserCaregiverProofsComponent,
    UserCaregiverAnalysesComponent,
    UserCaregiverCalendarComponent,
    UserCaregiverOverviewListComponent,
    ChangeBudgetDialogComponent,
    AddCaregiverDateDialogComponent,
    UserMultiplierOfferComponent,
    UserCaregiverOfferComponent,
    CaregiversDetailRecommendationsComponent,
    UserCaregiverDataComponent,
    UserCustomerDataComponent,
    UserCustomerDataGeneralComponent,
    UserCustomerDocumentsComponent,
    UserCaregiverDataMasterDataComponent,
    AddUserCustomerRequestDialogComponent,
    UserCustomerOverviewComponent,
    UserCustomerRequestsComponent,
    UserCaregiverDataContractDataComponent,
    GlobalDocumentsComponent,
    AddGlobalDocumentDialogComponent,
    UserCaregiverDataQualificationsComponent,
    AverageBudgetHoursPatientDialogComponent,
    AddCaregiverCarDialogComponent,
    CapacityComponent,
    SendDlvDialogComponent,
    SendAppointmentsDialogComponent,
    TodosComponent,
    OpenTodosComponent,
    FinishedTodosComponent,
    OwnTodosComponent,
    PrintAddressDialogComponent,
    PauseDialogComponent,
    PatientsPausedComponent,
    InvoicesConsultingsComponent,
    InvoicesConsultingsOpenedComponent,
    InvoicesConsultingsFinishedComponent,
    InvoicesConsultingsStornoComponent,
    InvoicesConsultingsCreditComponent,
    PatientsDetailConsultingComponent,
    SendLetterDialogComponent,
    CaregiversDetailAnalysesComponent,
    CaregiversDetailProofsComponent,
    ChangeDriveTimeManuallyDialogComponent,
    ConsultingsListComponent,
    ConsultingsListOpenComponent,
    ConsultingsListAppointmentsComponent,
    ConsultingsListFinishedComponent,
    ConsultingsListInvoicesComponent,
    UploadProofsDialogComponent,
    ChangeCaregiverTimeCorrectionDialogComponent,
    AddCaregiverJobScopeDialogComponent,
    AddCaregiverJobDaysDialogComponent,
    ConsultingDialogComponent,
    AddCaregiverEmployeedDialogComponent,
    ChangeCaregiverPersplanTimeCorrectionDialogComponent,
    DesiredDatesIncomingComponent,
    DesiredDatesFinishedComponent,
    DesiredDatesComponent,
    LockPatientDialogComponent,
    RolesDetailHistoryComponent,
    RolesDetailGeneralComponent,
    AddDesiredDateDialogComponent,
    AnalysesCaregiversComponent,
    GlobalAnalysesComponent,
    PatientsConsultingsComponent,
    CancelledDatesComponent,
    CancelledDatesLastComponent,
    CancelledDatesAllComponent,
    AddCancelledDateDialogComponent,
    AddCarCostDialogComponent,
    CarsDetailDocumentsComponent,
    CaregiversDetailCalendarComponent,
    AnalysesPlanningsComponent,
    CaregiversDetailListComponent,
    CaregiversDetailOverviewComponent,
    AddCaregiverTimesDialogComponent,
    AdministrationComponent,
    RolesListComponent,
    RolesFormComponent,
    RolesDetailComponent,
    CustomerRequestsComponent,
    CustomerRequestsCreatedComponent,
    ConfirmImportantHistoriesDialogComponent,
    UserSettingsComponent,
    AccountingReturnsComponent,
    AccountingComponent,
    AccountingReturnsCreatedComponent,
    AddAccountingReturnDialogComponent,
    AccountingReturnsEditedComponent,
    AccountingReturnsFinishedComponent,
    InvoiceDialogComponent,
    InvoiceKmDialogComponent,
    PatientsDetailAccountingKmComponent,
    PatientsDetailAccountingComponent,
    PatientsDetailAccountingReturnsComponent,
    AddUserCaregiverDateDialogComponent,
    PatientsDetailAccountingAppointmentsComponent,
    ChangePatientAppointmentTypesDialogComponent,
    ChangePatientInvoiceTypesDialogComponent,
    CaregiversListApplicantsComponent,
    CaregiversListApplicantsApplicantsComponent,
    CaregiversListApplicantsDeactivatedComponent,
    AddCaregiverOverviewCheckCommentDialogComponent,
    AccountCaregiveDialogComponent,
    AccountCustomerDialogComponent,
    InvoiceNewDialogComponent,
    AccountMultiplierDialogComponent,
    AppointmentFeedbackDialogComponent,
    ProofCheckDialogComponent,
    AppointmentChangeFeedbackDialogComponent,
    RequiredNotificationDialogComponent,
    AppointmentTripsDialogComponent,
    PlansComponent,
    PlansApprovalsComponent,
    PlansFeedbackComponent,
    PlansFeedbackCreatedComponent,
    PlansFeedbackEditedComponent,
    ReleaseAppointmentAdminDialogComponent,
    HospitationDialogComponent,
    ShowHistoriesDialogComponent,
    HospitationFeedbackDialogComponent,
    CalendarComponent,
    ConsultingDatesComponent,
    UserCaregiverHospitationComponent,
    PlansFeedbackDialogComponent,
    PlansFeedbackFinishedComponent,
    SendSystemMessageDialogComponent,
    AnonymousAppointmentsDialogComponent,
    ThcDatesComponent,
    PatientsDetailPlanningComponent,
    AppointmentPlanningDialogComponent,
    CaregiverPlanningDialogComponent,
    ConfirmWithTextDialogComponent,
    ThcDateDialogComponent,
    ChangeRequiredPlanningsComponent,
    InvoicesComponent,
    SystemMessageInternDialogComponent,
    InvoicesKmComponent,
    InvoicesKmDraftsComponent,
    InvoicesKmFinishedComponent,
    InvoicesKmReadyComponent,
    InvoicesKmStornoComponent,
    InvoicesKmCreditComponent,
    InvoicesKmOpenedComponent,

    UserMultiplierPatientsComponent,
  ],
  imports: [
    SplitButtonModule,
    SidebarModule,
    DragDropModule,
    TreeSelectModule,
    FullCalendarModule,
    TriStateCheckboxModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    TableModule,
    SkeletonModule,
    PasswordModule,
    InputTextModule,
    AutoCompleteModule,
    TooltipModule,
    MenuModule,
    ButtonModule,
    DropdownModule,
    InputTextareaModule,
    CheckboxModule,
    CalendarModule,
    RadioButtonModule,
    DialogModule,
    DynamicDialogModule,
    BlockUIModule,
    ProgressBarModule,
    ToastModule,
    ConfirmPopupModule,
    InputNumberModule,
    MultiSelectModule,
    DragulaModule.forRoot(),
    NgxJoditModule,
    InputSwitchModule,
    OverlayPanelModule,
    SelectButtonModule,
    BadgeModule,
    ContextMenuModule,
    SplitterModule,
    SliderModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      registrationStrategy: 'registerImmediately',
    }),
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'de',
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initDependencies,
      deps: [HelperService, SwUpdate],
      multi: true,
    },
    CurrencyPipe,
    MessageService,
    { provide: ErrorHandler, useFactory: errorHandlerFactory },
    ConfirmationService,
    DialogService,
    EventBusService,
    TitleService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
