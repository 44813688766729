import { AuthGuard } from '../guards/auth.guard'
import { UserCustomerDocumentsComponent } from '../views/user-customer/documents/user-customer-documents.component'
import { UserCustomerDataComponent } from '../views/user-customer/data/user-customer-data.component'
import { UserCustomerDataGeneralComponent } from '../views/user-customer/data/general/user-customer-data-general.component'
import { UserCustomerOverviewComponent } from '../views/user-customer/overview/user-customer-overview.component'
import { UserCustomerRequestsComponent } from '../views/user-customer/requests/user-customer-requests.component'

export const userCustomerRoutes = [
  {
    path: 'meine-übersicht',
    canActivate: [AuthGuard],
    component: UserCustomerOverviewComponent,
  },
  {
    path: 'meine-anfragen',
    canActivate: [AuthGuard],
    component: UserCustomerRequestsComponent,
  },
  {
    path: 'meine-dokumente',
    canActivate: [AuthGuard],
    component: UserCustomerDocumentsComponent,
  },
  {
    path: 'meine-daten',
    canActivate: [AuthGuard],
    component: UserCustomerDataComponent,
    children: [
      {
        path: '',
        component: UserCustomerDataGeneralComponent,
      },
    ],
  },
]
