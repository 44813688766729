import { Component, OnDestroy, OnInit } from '@angular/core'
import { CapacityService } from '../../../services/capacity.service'
import * as dayjs from 'dayjs'
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import * as utc from 'dayjs/plugin/utc'
import { LatestChangeModel } from '../../../models/history/latest-change.model'
import { GlobalAnalysesService } from '../../../services/global-analyses.service'
import { HelperService } from '../../../services/helper.service'
import { AuthService } from '../../../services/auth.service'
import { CaregiverAnalysesService } from '../../../services/caregiver-analyses.service'
import { Subject, Subscription } from 'rxjs'
import { switchMap } from 'rxjs/operators'

@Component({
  selector: 'app-analyses-caregivers',
  templateUrl: './analyses-caregivers.component.html',
  styleUrls: ['./analyses-caregivers.component.scss'],
})
export class AnalysesCaregiversComponent implements OnInit, OnDestroy {
  // TODO: model erstellen
  public data: any
  public currentHover = ''

  private loadTrigger$ = new Subject<void>()
  private subscription: Subscription = new Subscription()

  public loadingOverTime = true
  public loading = true
  public contentLoading = true

  private caregiverIds = []

  public persplanData = new LatestChangeModel()
  // private startWeek: Dayjs = dayjs()

  public lastUpdate = ''
  public searchQuery = ''
  public month = ''
  public year = ''

  public selectedYear = 0
  public selectedMonth = 0
  public isSorted = false

  public globalRelease = false

  public plannerUsers: any[] = []
  public selectedPlanner: any = null

  public monthOptions: any = []
  public yearOptions = [
    { label: '2019', value: '2019' },
    { label: '2020', value: '2020' },
    { label: '2021', value: '2021' },
    { label: '2022', value: '2022' },
    { label: '2023', value: '2023' },
    { label: '2024', value: '2024' },
    { label: '2025', value: '2025' },
  ]

  constructor(
    public globalAnalysesService: GlobalAnalysesService,
    public authService: AuthService,
    public capacityService: CapacityService,
    private helperService: HelperService,
    private caregiverAnalysesService: CaregiverAnalysesService
  ) {
    dayjs.locale('de')
    dayjs.extend(customParseFormat)
    dayjs.extend(utc)
  }

  ngOnInit(): void {
    const date = new Date()
    this.selectedYear = date.getFullYear()
    this.selectedMonth = date.getMonth()

    this.helperService.dependencies$.subscribe((data: any) => {
      this.monthOptions = data.months
      this.month = this.monthOptions[this.selectedMonth].label
      this.year = this.selectedYear.toString()

      for (const planner of data.planner_users) {
        this.plannerUsers.push({
          id: planner.id,
          full_name: `${planner.user_system.first_name} ${planner.user_system.last_name}`,
        })
      }
    })

    this.activateDataLoading()

    this.loadAnalyses()
    this.loadLastUpdate()
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  public releaseAccumulatedToUser(caregiver: any, id: any): void {
    this.caregiverAnalysesService
      .releaseAccumulatedToUser(id, this.selectedMonth + 1, this.selectedYear)
      .subscribe((response: any) => {
        caregiver.accumulated_single.released = true
        //this.loadAnalyses(false)
      })
  }

  public releaseAccumulatedForAll(): void {
    this.globalRelease = true

    this.caregiverAnalysesService
      .releaseAccumulatedForAll(
        this.caregiverIds,
        this.selectedMonth + 1,
        this.selectedYear
      )
      .subscribe((response: any) => {
        this.data.caregivers.forEach((caregiver: any) => {
          caregiver.accumulated_single.released = true
        })
      })
  }

  public unreleaseAccumulatedForAll(): void {
    this.globalRelease = false

    this.caregiverAnalysesService
      .unreleaseAccumulatedForAll(
        this.caregiverIds,
        this.selectedMonth + 1,
        this.selectedYear
      )
      .subscribe((response: any) => {
        this.data.caregivers.forEach((caregiver: any) => {
          caregiver.accumulated_single.released = false
        })
      })
  }

  public unreleaseAccumulatedFromUser(caregiver: any, id: any): void {
    this.caregiverAnalysesService
      .unreleaseAccumulatedFromUser(
        id,
        this.selectedMonth + 1,
        this.selectedYear
      )
      .subscribe((response: any) => {
        caregiver.accumulated_single.released = false
        //this.loadAnalyses(false)
      })
  }

  /**
   * Springt zum letzten Monat.
   */
  public goToPreviousMonth(): void {
    if (this.selectedMonth === 0) {
      const lastYear = this.selectedYear - 1

      const hasYearOption = this.yearOptions.find(
        (data: any) => data.value == lastYear
      )

      if (hasYearOption) {
        this.selectedYear = lastYear
        this.selectedMonth = 11
      }
    } else {
      this.selectedMonth--
    }

    this.year = this.selectedYear.toString()
    this.month = this.monthOptions[this.selectedMonth].label

    this.loadAnalyses(false)
  }

  /**
   * Springt zum nächsten Monat.
   */
  public goToNextMonth(): void {
    if (this.selectedMonth === 11) {
      const nextYear = this.selectedYear + 1

      const hasYearOption = this.yearOptions.find(
        (data: any) => data.value == nextYear
      )

      if (hasYearOption) {
        this.selectedYear = nextYear
        this.selectedMonth = 0
      }
    } else {
      this.selectedMonth++
    }

    this.year = this.selectedYear.toString()
    this.month = this.monthOptions[this.selectedMonth].label

    this.loadAnalyses(false)
  }

  public searchedForCaregiver(caregiverName: string): boolean {
    if (!this.searchQuery) {
      return true
    }

    return caregiverName.toLowerCase().includes(this.searchQuery.toLowerCase())
  }

  private loadLastUpdate(): void {
    this.capacityService.loadLastUpdate().subscribe((lastUpdate: string) => {
      this.lastUpdate = lastUpdate
    })
  }

  public setHover(type: string): void {
    this.currentHover = type
  }

  public loadAnalyses(withLoading: boolean = true): void {
    const foundMonth = this.monthOptions.findIndex((month: any) => {
      return month.label === this.month
    })

    if (foundMonth != null) {
      this.selectedMonth = foundMonth
    }

    this.selectedYear = +this.year

    this.contentLoading = true

    if (withLoading) {
      this.loading = true
    }

    this.loadTrigger$.next()
  }

  private activateDataLoading(): void {
    this.subscription.add(
      this.loadTrigger$
        .pipe(
          switchMap(() =>
            this.globalAnalysesService.caregivers(
              this.month,
              this.year,
              this.selectedPlanner,
              this.isSorted
            )
          )
        )
        .subscribe((data: any) => {
          this.data = data

          this.caregiverIds = this.data['caregivers'].map((data: any) => {
            return data['caregiver']['id']
          })

          this.contentLoading = false
          this.loading = false

          this.loadOverUnderTimeFromLastMonth()
        })
    )
  }

  public loadOverUnderTimeFromLastMonth(): void {
    this.loadingOverTime = true

    this.globalAnalysesService
      .loadOverUnderTimeFromLastMonth(this.caregiverIds, this.month, this.year)
      .subscribe((data: any) => {
        this.data.total.OVER_UNDER_TIME_LAST_MONTH = data.total

        this.data.caregivers.forEach((caregiver: any) => {
          const found = data.times.find((item: any) => {
            return item.caregiver_id == caregiver.caregiver.id
          })

          caregiver.times.OVER_UNDER_TIME_LAST_MONTH = found
            ? found.times
            : null
        })

        this.loadingOverTime = false
      })
  }
}
