import { AuthGuard } from '../guards/auth.guard'
import { PatientsQualificationOpenComponent } from '../views/patients/qualification-open/patients-qualification-open.component'
import { PatientsInactiveComponent } from '../views/patients/inactive/patients-inactive.component'
import { PatientsConsultingsComponent } from '../views/patients/consultings/patients-consultings.component'
import { PatientsPausedComponent } from '../views/patients/paused/patients-paused.component'
import { PatientsNewComponent } from '../views/patients/new/patients-new.component'
import { PatientsRegularComponent } from '../views/patients/regular/patients-regular.component'
import { PatientsDetailComponent } from '../views/patients/detail/patients-detail.component'
import { PatientsDetailGeneralComponent } from '../views/patients/detail/general/patients-detail-general.component'
import { PatientsDetailDocumentsComponent } from '../views/patients/detail/documents/patients-detail-documents.component'
import { PatientsDetailVetoComponent } from '../views/patients/detail/veto/patients-detail-veto.component'
import { PatientsDetailHistoryComponent } from '../views/patients/detail/history/patients-detail-history.component'
import { PatientsDetailAccountingComponent } from '../views/patients/detail/accounting/patients-detail-accounting.component'
import { PatientsDetailAccountingReturnsComponent } from '../views/patients/detail/accounting/returns/patients-detail-accounting-returns.component'
import { PatientsDetailPlanningComponent } from '../views/patients/detail/planning/patients-detail-planning.component'
import { PatientsDetailAccountingKmComponent } from '../views/patients/detail/accounting/km/patients-detail-accounting-km.component'
import { PatientsDetailConsultingComponent } from '../views/patients/detail/consulting/patients-detail-consulting.component'
import { PatientsDetailAccountingConsultingsComponent } from '../views/patients/detail/accounting/consultings/patients-detail-accounting-consultings.component'
import { PatientsDetailAccountingAppointmentsComponent } from '../views/patients/detail/accounting/appointments/patients-detail-accounting-appointments.component'
import { PatientsDetailHoursComponent } from '../views/patients/detail/hours/patients-detail-hours.component'
import { PatientsDetailBudgetComponent } from '../views/patients/detail/hours/budgets/patients-detail-budget.component'
import { PatientsDetailAppointmentsComponent } from '../views/patients/detail/hours/appointments/patients-detail-appointments.component'

export const patientsRoutes = [
  {
    path: 'patients',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: PatientsQualificationOpenComponent,
      },
      {
        path: 'inactive',
        component: PatientsInactiveComponent,
      },
      {
        path: 'consultings',
        component: PatientsConsultingsComponent,
      },
      {
        path: 'paused',
        component: PatientsPausedComponent,
      },
      {
        path: 'new',
        component: PatientsNewComponent,
      },
      {
        path: 'regular',
        component: PatientsRegularComponent,
      },
      {
        path: ':id',
        component: PatientsDetailComponent,
        children: [
          {
            path: '',
            component: PatientsDetailGeneralComponent,
          },
          {
            path: 'documents',
            component: PatientsDetailDocumentsComponent,
          },
          {
            path: 'budget',
            component: PatientsDetailHoursComponent,
            children: [
              {
                path: '',
                component: PatientsDetailBudgetComponent,
              },
              {
                path: 'appointments',
                component: PatientsDetailAppointmentsComponent,
              },
            ],
          },
          {
            path: 'planning',
            component: PatientsDetailPlanningComponent,
          },
          {
            path: 'veto',
            component: PatientsDetailVetoComponent,
          },
          {
            path: 'consulting',
            component: PatientsDetailConsultingComponent,
          },
          {
            path: 'history',
            component: PatientsDetailHistoryComponent,
          },
          {
            path: 'accounting',
            component: PatientsDetailAccountingComponent,
            children: [
              {
                path: '',
                component: PatientsDetailAccountingAppointmentsComponent,
              },
              {
                path: 'returns',
                component: PatientsDetailAccountingReturnsComponent,
              },
              {
                path: 'km',
                component: PatientsDetailAccountingKmComponent,
              },
              {
                path: 'consultings',
                component: PatientsDetailAccountingConsultingsComponent,
              },
            ],
          },
        ],
      },
    ],
  },
]
