import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'

import { caregiversRoutes } from './routes/caregivers.routes'
import { patientsRoutes } from './routes/patients.routes'
import { todosRoutes } from './routes/todos.routes'
import { offerRoutes } from './routes/offer.routes'
import { administrationRoutes } from './routes/administration.routes'
import { rolesRoutes } from './routes/roles.routes'
import { miscRoutes } from './routes/misc.routes'
import { multipliersRoutes } from './routes/multipliers.routes'
import { carsRoutes } from './routes/cars.routes'
import { planningsRoutes } from './routes/plannings.routes'
import { accountingRoutes } from './routes/accounting.routes'
import { userCaregiverRoutes } from './routes/user-caregiver.routes'
import { userCustomerRoutes } from './routes/user-customer.routes'
import { userMultiplierRoutes } from './routes/user-multiplier.routes'
import { plansRoutes } from './routes/plans.routes'
import { invoicesRoutes } from './routes/invoices.routes'

const routes = [
  ...miscRoutes,
  ...caregiversRoutes,
  ...userCaregiverRoutes,
  ...userCustomerRoutes,
  ...userMultiplierRoutes,
  ...planningsRoutes,
  ...plansRoutes,
  ...patientsRoutes,
  ...todosRoutes,
  ...offerRoutes,
  ...administrationRoutes,
  ...rolesRoutes,
  ...multipliersRoutes,
  ...accountingRoutes,
  ...invoicesRoutes,
  ...carsRoutes,
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
