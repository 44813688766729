import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { NgForm } from '@angular/forms'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { Subscription } from 'rxjs'
import * as dayjs from 'dayjs'
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import * as utc from 'dayjs/plugin/utc'
import { HelperService } from '../../../services/helper.service'
import { PlanningService } from '../../../services/planning.service'

@Component({
  selector: 'app-confirm-with-text-dialog',
  templateUrl: './confirm-with-text-dialog.component.html',
})
export class ConfirmWithTextDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null
  private isDirty = false

  public appointmentIsInCurrentMonth = false

  public isIll = false
  public isAppointment = false
  public isInvoice = false
  public isAppointmentInvoice = false
  public isRequired = false
  public data: any = {}
  public submitted = false
  public maxMinutes = 0

  public errors = {
    time: false,
    max_minutes: false,
    time_quarter: [] as any[],
  }

  public reasonOptions = []
  public lnwIsAvailable = false

  public values = {
    storno_reason_id: null,
    storno_comment: '',
    lnw: '',
    until_date: '',
    type: null as any,
    required_storno_options: false,
    consider_drive_time: false,
    consider_working_time: false,
    consider_working_time_minutes: 15,
    calculate_appointment: false,
    from_new: '',
    to_new: '',
    with_new_draft: true,
  }

  constructor(
    private helperService: HelperService,
    private ref: DynamicDialogRef,
    private planningService: PlanningService,
    private config: DynamicDialogConfig
  ) {}

  ngOnInit(): void {
    dayjs.locale('de')
    dayjs.extend(customParseFormat)
    dayjs.extend(utc)

    this.helperService.dependencies$.subscribe((data: any) => {
      this.reasonOptions = data.appointment_storno_reasons
    })

    this.data = this.config.data

    this.isRequired = this.data.isRequired
    const appointment = this.data.appointment
    const appointmentComplete = this.data.appointment_complete

    if (this.data.invoice) {
      this.isInvoice = true
      this.isAppointmentInvoice = this.data.invoice.type === 'APPOINTMENT'
    }

    if (appointment) {
      const currentDate = dayjs()
      const appointmentDate = dayjs(appointment.date, 'DD.MM.YYYY')

      this.appointmentIsInCurrentMonth =
        currentDate.isSame(appointmentDate, 'month') &&
        currentDate.isSame(appointmentDate, 'year')

      this.loadAllAppointmentsForMonth()

      this.values.from_new = appointment.from
      this.values.to_new = appointment.to
      this.isAppointment = true
      this.isIll = appointmentComplete.abgleich_status === 'krank'

      const maxDate = dayjs().add(72, 'hours')

      const appointmentDateFrom = dayjs(
        `${appointment.date} ${this.values.from_new}`,
        'DD.MM.YYYY HH:mm'
      )
      const appointmentDateTo = dayjs(
        `${appointment.date} ${this.values.to_new}`,
        'DD.MM.YYYY HH:mm'
      )

      // Berechnet, wie viel Minuten maximal hinterlegt werden dürfen.
      this.maxMinutes = appointmentDateTo.diff(appointmentDateFrom, 'minutes')

      // Wenn dieser Einsatz nach den 72h ist,
      // sind die Storno-Optionen optional.
      if (appointmentDateFrom.isAfter(maxDate)) {
        this.values.required_storno_options = false
      } else {
        this.values.required_storno_options = true
      }

      // Wir setzen die Storno-Optionen, falls wir den stornierten Termin nochmal bearbeiten.
      this.values.consider_drive_time = appointmentComplete.consider_drive_time
      this.values.consider_working_time =
        appointmentComplete.consider_working_time
      this.values.consider_working_time_minutes =
        appointmentComplete.consider_working_time_minutes || 15
      this.values.calculate_appointment =
        appointmentComplete.calculate_appointment
      this.values.from_new = appointmentComplete.from_SOLL_h
      this.values.to_new = appointmentComplete.to_SOLL_h

      this.values.storno_comment = appointmentComplete.storno_comment
      this.values.storno_reason_id = appointmentComplete.storno_reason_id
    }

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })
  }

  public typeChanged(): void {
    this.loadAllAppointmentsForMonth()
  }

  public checkForTimes(event: any, type: string): void {
    const value = event.target.value

    if (value.length === 1) {
      this.values[type] = `0${value}:00`
    } else if (value.length === 2) {
      this.values[type] = `${value}:00`
    } else if (value.length === 4) {
      this.values[type] = `${value[0]}${value[1]}:${value[2]}${value[3]}`
    }

    const time = this.values[type]

    if (time) {
      const minutes = time.split(':')[1]

      // Wir prüfen hier, ob die Minutenanzeige im 15-Minuten-Takt ist.
      if (!['00', '15', '30', '45'].includes(minutes)) {
        this.errors.time_quarter.push(type)
      } else {
        this.errors.time_quarter = this.errors.time_quarter.filter(
          (value: any) => value != type
        )
      }

      // Wir müssen noch prüfen, ob die neuen Minuten
      // mehr als die originalen Minuten sind.
      const appointmentDateFrom = dayjs(
        `01.01.2000 ${this.values.from_new}`,
        'DD.MM.YYYY HH:mm'
      )
      const appointmentDateTo = dayjs(
        `01.01.2000 ${this.values.to_new}`,
        'DD.MM.YYYY HH:mm'
      )

      const currentMinutes = appointmentDateTo.diff(
        appointmentDateFrom,
        'minutes'
      )

      this.errors.max_minutes = currentMinutes > this.maxMinutes
    }
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  public stornoOptionsChanged(): void {
    // Falls die Arbeitszeit angerechnet wird, muss
    // die Fahrzeit ebenfalls ausgewählt werden.
    if (this.values.consider_working_time) {
      this.values.consider_drive_time = true
    }

    if (this.values.consider_working_time_minutes > this.maxMinutes) {
      this.values.consider_working_time_minutes = this.maxMinutes
    }

    // Falls der Einsatz für den Patienten berechnet werden soll,
    // muss (wenn Interval ist) die Option "Nur dieser Einsatz"
    // ausgewählt werden, und die anderen Optionen deaktiviert werden.
    if (this.values.calculate_appointment) {
      if (this.data.wasWithInterval) {
        this.values.type = 1
      }
    }
  }

  public abort(): void {
    this.ref.close()
  }

  public save(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    // Bei Einsätzen werden zusätzliche Werte abgeprüft.
    if (this.isAppointment) {
      if (this.errors.time_quarter.length > 0) {
        return
      }

      if (this.errors.max_minutes) {
        return
      }

      this.errors.time = false

      // Die Uhrzeiten werden als integer umgewandelt, dadurch
      // lässt es sich leicht nach größer/kleiner überprüfen.
      const integerStart = this.values.from_new
        ? +this.values.from_new.replace(':', '')
        : 0
      const integerEnd = this.values.to_new
        ? +this.values.to_new.replace(':', '')
        : 0

      if (integerStart >= integerEnd) {
        this.errors.time = true
        return
      }
    }

    this.ref.close(this.values)
  }

  /**
   * Lädt alle Termine für den aktuellen Monat.
   * Wird benötigt um zu prüfen, ob ich gerade den letzten
   * Termin storniere. Falls ja, muss ein To-do erstellt werden.
   */
  private loadAllAppointmentsForMonth(): void {
    if (this.appointmentIsInCurrentMonth) {
      this.planningService
        .allAppointmentsForMonth(
          this.data.appointment.patient_id,
          this.data.appointment.date
        )
        .subscribe((response: any) => {
          const lastAppointment = response[response.length - 1]

          // Falls es sich um den letzten Termin handelt.
          if (this.data.appointment_complete.id == lastAppointment.id) {
            this.lnwIsAvailable = true
          } else if (this.values.type == 2 || this.values.type == 3) {
            // Falls der letzte Termin aus dieser Terminserie erstellt wurde,
            // und ich diese Serie ebenfalls löschen will.
            this.lnwIsAvailable =
              this.data.appointment_complete.from_appointment_id ==
              lastAppointment.from_appointment_id
          } else {
            this.lnwIsAvailable = false
          }
        })
    }
  }
}
