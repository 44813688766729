<form #form="ngForm" class="dialog-form">
  <div class="dialog-form-body">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12">
        <div class="p-formgroup-inline">
          <div class="p-field-checkbox">
            <p-checkbox
              label="Kann drucken"
              name="can_print"
              [binary]="true"
              [(ngModel)]="contactPerson.can_print"
            ></p-checkbox>
          </div>
          <div class="p-field-checkbox">
            <p-checkbox
              label="Sozialamt"
              name="is_social_office"
              [binary]="true"
              [(ngModel)]="contactPerson.is_social_office"
            ></p-checkbox>
          </div>
          <div class="p-field-checkbox">
            <p-checkbox
              label="AP vor Ort"
              name="is_at_home"
              [binary]="true"
              [(ngModel)]="contactPerson.is_at_home"
            ></p-checkbox>
          </div>
          <div class="p-field-checkbox">
            <p-checkbox
              label="für Termine"
              name="is_for_appointments"
              [binary]="true"
              [(ngModel)]="contactPerson.is_for_appointments"
            ></p-checkbox>
          </div>
          <div class="p-field-checkbox">
            <p-checkbox
              label="für Rechnungen"
              name="is_for_invoices"
              [binary]="true"
              [(ngModel)]="contactPerson.is_for_invoices"
            ></p-checkbox>
          </div>
          <div class="p-field-checkbox">
            <p-checkbox
              label="für Notfall"
              name="is_emergency"
              [binary]="true"
              [(ngModel)]="contactPerson.is_emergency"
            ></p-checkbox>
          </div>
          <div class="p-field-checkbox">
            <p-checkbox
              label="im gleichen Haushalt"
              name="is_same_house"
              [binary]="true"
              [(ngModel)]="contactPerson.is_same_house"
            ></p-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-4">
        <label for="form_of_address">Anrede *</label>
        <p-dropdown
          [(ngModel)]="contactPerson.form_of_address"
          [options]="formOfAddress"
          inputId="form_of_address"
          #form_of_address="ngModel"
          name="form-of-address"
          placeholder="Bitte auswählen"
          [required]="true"
        ></p-dropdown>
        <div
          *ngIf="
            form_of_address.invalid &&
            (form_of_address.dirty || form_of_address.touched)
          "
          class="p-invalid"
        >
          <div *ngIf="form_of_address.errors?.required">
            <small>Bitte ausfüllen</small>
          </div>
        </div>
      </div>

      <div class="p-field p-col-4">
        <label for="first_name">Vorname *</label>
        <input
          id="first_name"
          name="first_name"
          [(ngModel)]="contactPerson.first_name"
          type="text"
          pInputText
          #first_name="ngModel"
          required
          [class.p-invalid]="
            first_name.invalid && (first_name.dirty || first_name.touched)
          "
        />
        <div
          *ngIf="first_name.invalid && (first_name.dirty || first_name.touched)"
          class="p-invalid"
        >
          <div *ngIf="first_name.errors?.required">
            <small>Bitte ausfüllen</small>
          </div>
        </div>
      </div>

      <div class="p-field p-col-4">
        <label for="last_name">Nachname *</label>
        <input
          id="last_name"
          name="last_name"
          [(ngModel)]="contactPerson.last_name"
          type="text"
          pInputText
          #last_name="ngModel"
          required
          [class.p-invalid]="
            last_name.invalid && (last_name.dirty || last_name.touched)
          "
        />
        <div
          *ngIf="last_name.invalid && (last_name.dirty || last_name.touched)"
          class="p-invalid"
        >
          <div *ngIf="last_name.errors?.required">
            <small>Bitte ausfüllen</small>
          </div>
        </div>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid">
      <div
        class="p-field p-col-6"
        *ngIf="data.type === 'customer' || data.type === 'caregiver'"
      >
        <label for="relation-customer">Verhältnis *</label>
        <input
          id="relation-customer"
          name="relation-customer"
          [(ngModel)]="contactPerson.relation"
          type="text"
          pInputText
          #relation_customer="ngModel"
          required
          [class.p-invalid]="
            relation_customer.invalid &&
            (relation_customer.dirty || relation_customer.touched)
          "
        />
        <div
          *ngIf="
            relation_customer.invalid &&
            (relation_customer.dirty || relation_customer.touched)
          "
          class="p-invalid"
        >
          <div *ngIf="relation_customer.errors?.required">
            <small>Bitte ausfüllen</small>
          </div>
        </div>
      </div>

      <div class="p-field p-col-6" *ngIf="data.type === 'multiplier'">
        <label for="relation">Abteilung</label>
        <input
          id="relation"
          name="relation"
          [(ngModel)]="contactPerson.relation"
          type="text"
          pInputText
        />
      </div>

      <div class="p-field p-col-6">
        <label for="contact_person-birthday">Geburtsdatum</label>
        <p-calendar
          [firstDayOfWeek]="1"
          [showWeek]="true"
          [(ngModel)]="contactPerson.birthday_string"
          inputId="contact_person-birthday"
          name="contact_person-birthday"
          dateFormat="dd.mm.yy"
          [showButtonBar]="true"
          dataType="string"
        ></p-calendar>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-4">
        <label for="street_house_number">Straße / Hausnummer</label>
        <input
          id="street_house_number"
          name="street_house_number"
          [(ngModel)]="contactPerson.street_house_number"
          type="text"
          pInputText
        />
      </div>

      <div class="p-field p-col-4">
        <label for="zipcode">PLZ</label>
        <input
          (change)="loadCity()"
          id="zipcode"
          name="zipcode"
          [(ngModel)]="contactPerson.zipcode"
          type="text"
          pInputText
        />
      </div>

      <div class="p-field p-col-4">
        <label for="city">Ort</label>
        <span class="p-input-icon-right">
          <i *ngIf="cityLoading" class="pi pi-spin pi-spinner"></i>
          <input
            id="city"
            name="city"
            [(ngModel)]="contactPerson.city"
            type="text"
            pInputText
          />
        </span>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-4">
        <label for="city-part">Ortsteil</label>
        <span class="p-input-icon-right">
          <input
            id="city-part"
            name="city-part"
            [(ngModel)]="contactPerson.city_part"
            type="text"
            pInputText
          />
        </span>
      </div>

      <div class="p-field p-col-4">
        <label for="county">Landkreis</label>
        <span class="p-input-icon-right">
          <input
            id="county"
            name="county"
            [(ngModel)]="contactPerson.county"
            type="text"
            pInputText
          />
        </span>
      </div>

      <div class="p-field p-col-4">
        <label for="state">Bundesland</label>
        <span class="p-input-icon-right">
          <input
            id="state"
            name="state"
            [(ngModel)]="contactPerson.state"
            type="text"
            pInputText
          />
        </span>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12">
        <label for="local_specialties">Örtliche Besonderheiten</label>
        <textarea
          id="local_specialties"
          name="local_specialties"
          [(ngModel)]="contactPerson.local_specialties"
          pInputTextarea
        ></textarea>
      </div>
    </div>

    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-3">
        <div class="p-field">
          <label for="email_private">E-Mail Privat</label>
          <input
            id="email_private"
            name="email_private"
            [(ngModel)]="contactPerson.email_private"
            type="email"
            pInputText
            email
            #email_private="ngModel"
          />
        </div>
        <div class="p-field" style="margin-top: -10px; margin-bottom: 0">
          <input
            id="email_private_info"
            name="email_private_info"
            placeholder="Informationen zur E-Mail"
            [(ngModel)]="contactPerson.email_private_info"
            type="text"
            pInputText
          />
        </div>

        <div
          *ngIf="
            email_private.invalid &&
            (email_private.dirty || email_private.touched)
          "
          class="p-invalid"
        >
          <div *ngIf="email_private.errors?.email">
            <small>Keine richtige E-Mail</small>
          </div>
        </div>
      </div>

      <div class="p-field p-col-3">
        <div class="p-field">
          <label for="email_work">E-Mail Geschäftlich</label>
          <input
            id="email_work"
            name="email_work"
            [(ngModel)]="contactPerson.email_work"
            type="email"
            pInputText
            #email_work="ngModel"
            email
          />
        </div>
        <div class="p-field" style="margin-top: -10px; margin-bottom: 0">
          <input
            id="email_work_info"
            name="email_work_info"
            placeholder="Informationen zur E-Mail"
            [(ngModel)]="contactPerson.email_work_info"
            type="text"
            pInputText
          />
        </div>

        <div
          *ngIf="email_work.invalid && (email_work.dirty || email_work.touched)"
          class="p-invalid"
        >
          <div *ngIf="email_work.errors?.email">
            <small>Keine richtige E-Mail</small>
          </div>
        </div>
      </div>

      <div class="p-field p-col-3">
        <div class="p-field">
          <label for="phone-1">Telefon 1</label>
          <input
            id="phone-1"
            name="phone-1"
            [(ngModel)]="contactPerson.phone_1"
            type="text"
            pInputText
          />
        </div>
        <div class="p-field" style="margin-top: -10px; margin-bottom: 0">
          <input
            id="phone_1_info"
            name="phone_1_info"
            placeholder="Informationen zu Telefon 1"
            [(ngModel)]="contactPerson.phone_1_info"
            type="text"
            pInputText
          />
        </div>
      </div>

      <div class="p-field p-col-3">
        <div class="p-field">
          <label for="phone-2">Telefon 2</label>
          <input
            id="phone-2"
            name="phone-2"
            [(ngModel)]="contactPerson.phone_2"
            type="text"
            pInputText
          />
        </div>
        <div class="p-field" style="margin-top: -10px; margin-bottom: 0">
          <input
            id="phone_2_info"
            name="phone_2_info"
            placeholder="Informationen zu Telefon 2"
            [(ngModel)]="contactPerson.phone_2_info"
            type="text"
            pInputText
          />
        </div>
      </div>
    </div>
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12">
        <label for="comment">Kommentar</label>
        <textarea
          id="comment"
          name="comment"
          [(ngModel)]="contactPerson.comment"
          pInputTextarea
        ></textarea>
      </div>
    </div>
  </div>
  <div class="dialog-form-footer">
    <i *ngIf="!data.edit"></i>
    <i
      class="pi pi-trash"
      *ngIf="data.edit"
      [class.pi-trash]="!submittedDelete"
      [class.pi-spin]="submittedDelete"
      [class.pi-spinner]="submittedDelete"
      tooltipPosition="left"
      tooltipZIndex="20000"
      [pTooltip]="
        submittedDelete ? 'Wird gelöscht' : 'Ansprechpartner deaktivieren'
      "
      (click)="remove($event)"
    ></i>

    <button
      (click)="save()"
      pButton
      label="Speichern"
      type="button"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>

<p-confirmPopup></p-confirmPopup>
