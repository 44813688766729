<app-loader [loading]="loading"></app-loader>

<div class="content-actions">
  <h2>
    <i class="tio">document_text_outlined</i>
    Meine Dokumente
  </h2>
</div>

<div class="content new-content-container" *ngIf="!loading">
  <ng-container>
    <div class="content-body without-padding auto-height">
      <span
        class="content-no-message with-padding"
        *ngIf="documents.length === 0"
      >
        Keine Dokumente hinterlegt
      </span>

      <p-table
        [value]="documents"
        [rowHover]="true"
        responsiveLayout="scroll"
        *ngIf="documents.length > 0"
      >
        <ng-template pTemplate="header">
          <tr class="table-headline-highlight">
            <th pSortableColumn="name">
              Name <p-sortIcon field="name"></p-sortIcon>
            </th>
            <th style="width: 200px" pSortableColumn="document.created_at">
              Erstellt am <p-sortIcon field="document.created_at"></p-sortIcon>
            </th>
            <th style="width: 100px"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-document>
          <tr class="clickable">
            <td (click)="openDownloadTab(document.document)">
              {{ document.name }}
            </td>
            <td (click)="openDownloadTab(document.document)">
              {{ document.document.created_at | date: "dd.MM.y, H:mm" }} Uhr
            </td>
            <td class="table-action" style="width: 100px">
              <a
                [href]="
                  userCustomerService.getDownloadDocumentLink(
                    document.document.uuid
                  )
                "
                target="_blank"
              >
                <i
                  tooltipPosition="left"
                  pTooltip="Herunterladen"
                  class="pi pi-download"
                ></i>
              </a>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </ng-container>
</div>
