<form #form="ngForm" class="dialog-form">
  <div class="dialog-form-body">
    <div
      class="p-fluid p-formgrid p-grid without-border"
      *ngIf="authService.isSuperAdmin()"
    >
      <div class="p-field p-col-12">
        <label for="subject">Betreff</label>
        <input
          placeholder="Optional"
          id="subject"
          name="subject"
          type="text"
          [(ngModel)]="values.subject"
          pInputText
        />
      </div>

      <div class="p-field p-col-12">
        <label>Inhalt * </label>

        <ngx-jodit [(value)]="values.text" [options]="options"></ngx-jodit>
      </div>

      <div style="display: flex; margin-left: auto">
        <button
          pButton
          label="Speichern"
          type="submit"
          (click)="save()"
          [disabled]="submitted"
          [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
        ></button>
      </div>
    </div>

    <div *ngFor="let message of data" class="p-fluid p-formgrid p-grid">
      <div
        class="p-field p-col-12"
        style="display: flex; flex-direction: column"
      >
        <small class="color-gray">{{
          message.created_at | date: "dd.MM.y, H:mm"
        }}</small>
        <strong style="margin-bottom: 10px" *ngIf="message.subject">{{
          message.subject
        }}</strong>
        <div class="intern-message-container" [innerHTML]="message.text"></div>
      </div>
    </div>
  </div>
</form>
