import { Component, OnInit } from '@angular/core'
import * as dayjs from 'dayjs'
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import * as utc from 'dayjs/plugin/utc'
import { LatestChangeModel } from '../../../models/history/latest-change.model'
import { GlobalAnalysesService } from '../../../services/global-analyses.service'
import { HelperService } from '../../../services/helper.service'
import { AuthService } from '../../../services/auth.service'
import { NoticeDialogComponent } from '../../../components/dialogs/notice-dialog/notice-dialog.component'
import { AverageBudgetHoursPatientDialogComponent } from '../../../components/dialogs/average-budget-hours-patient-dialog/average-budget-hours-patient-dialog.component'
import { DialogService } from 'primeng/dynamicdialog'

@Component({
  selector: 'app-analyses-plannings',
  templateUrl: './analyses-plannings.component.html',
  styleUrls: ['./analyses-plannings.component.scss'],
})
export class AnalysesPlanningsComponent implements OnInit {
  // TODO: model erstellen
  public data: any
  public keys: any

  public currentHover = -1
  public currentHoverForHeader = -1

  public loading = true
  public contentLoading = true

  public persplanData = new LatestChangeModel()

  public searchQuery = ''
  public year = ''

  private selectedYear = 0

  public monthOptions: any = []
  public yearOptions = [
    { label: '2022', value: '2022' },
    { label: '2023', value: '2023' },
    { label: '2024', value: '2024' },
    { label: '2025', value: '2025' },
  ]

  constructor(
    public globalAnalysesService: GlobalAnalysesService,
    public authService: AuthService,
    private dialogService: DialogService,
    private helperService: HelperService
  ) {
    dayjs.locale('de')
    dayjs.extend(customParseFormat)
    dayjs.extend(utc)
  }

  ngOnInit(): void {
    const date = new Date()
    this.selectedYear = date.getFullYear()

    this.helperService.dependencies$.subscribe((data: any) => {
      this.year = this.selectedYear.toString()
    })

    this.loadAnalyses()
  }

  public searchedForCaregiver(caregiverName: string): boolean {
    if (!this.searchQuery) {
      return true
    }

    return caregiverName.toLowerCase().includes(this.searchQuery.toLowerCase())
  }

  public setHover(type: number = -1): void {
    this.currentHover = type
  }

  public setHoverForHeader(index: number = -1): void {
    this.currentHoverForHeader = index
  }

  public openAverageBudgetHoursPatientDialog(index: any): void {
    this.dialogService.open(AverageBudgetHoursPatientDialogComponent, {
      header: 'Ø Dauer pro Patient',
      width: '620px',
      styleClass: 'dialog-container',
      data: {
        monthNumber: index + 1,
        year: this.year,
      },
    })
  }

  public loadAnalyses(withLoading: boolean = true): void {
    this.contentLoading = true

    if (withLoading) {
      this.loading = true
    }

    this.globalAnalysesService.plannings(this.year).subscribe((data: any) => {
      this.data = data.data
      this.keys = Object.keys(data.data)

      this.contentLoading = false

      if (withLoading) {
        this.loading = false
      }
    })
  }
}
