<app-loader [loading]="loading"></app-loader>

<div class="content-actions"></div>

<div class="content new-content-container">
  <div class="content-body feedback-banner">
    <span>Teilen Sie uns<br />Ihr Feedback mit!</span>
    <div>
      <i class="tio">star</i>
      <i class="tio">star</i>
      <i class="tio">star</i>
      <i class="tio">star</i>
      <i class="tio">star</i>
    </div>
  </div>
</div>

<div class="content new-content-container" style="margin-bottom: 0">
  <header class="content-header">
    <h2>Nächste Termine</h2>
    <a routerLink="/"> alle ansehen <i class="pi pi-arrow-right"></i> </a>
  </header>
</div>

<!--<div class="content new-content-container">-->
<!--  <div class="content-body">-->
<!--    <div class="content-body-inner-wrap">-->
<!--      <i class="tio">appointment</i>-->
<!--      <span class="content-no-message">Keine Termine vorhanden</span>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

<div class="content new-content-container">
  <div class="content-body without-padding" style="flex-direction: row">
    <div class="content-new-inner-wrap">
      <div class="p-field p-col-4" style="margin: 0">
        <label>Datum</label>
        <strong class="bigger-label">18.10.2024</strong>
      </div>

      <div class="p-field p-col-4" style="margin: 0">
        <label>Uhrzeit</label>
        <strong class="bigger-label">12:00 - 14:30</strong>
      </div>

      <div class="p-field p-col-4" style="margin: 0">
        <label>Betreuungskraft</label>
        <strong class="bigger-label">Frau Sarah Bick </strong>
      </div>
    </div>
    <div
      class="content-new-inner-wrap with-action"
      pTooltip="Feedback geben"
      style="max-width: 60px"
    >
      <i class="tio">edit</i>
    </div>
  </div>
</div>

<div class="content new-content-container">
  <div class="content-body without-padding" style="flex-direction: row">
    <div class="content-new-inner-wrap">
      <div class="p-field p-col-4" style="margin: 0">
        <label>Datum</label>
        <strong class="bigger-label">24.10.2024</strong>
      </div>

      <div class="p-field p-col-4" style="margin: 0">
        <label>Uhrzeit</label>
        <strong class="bigger-label">09:30 - 11:30</strong>
      </div>

      <div class="p-field p-col-4" style="margin: 0">
        <label>Betreuungskraft</label>
        <strong class="bigger-label">Frau Jasmin Janz</strong>
      </div>
    </div>
    <div
      class="content-new-inner-wrap with-action"
      pTooltip="Feedback geben"
      style="max-width: 60px"
    >
      <i class="tio">edit</i>
    </div>
  </div>
</div>

<div class="content new-content-container">
  <div class="content-body without-padding" style="flex-direction: row">
    <div class="content-new-inner-wrap">
      <div class="p-field p-col-4" style="margin: 0">
        <label>Datum</label>
        <strong class="bigger-label">05.11.2024</strong>
      </div>

      <div class="p-field p-col-4" style="margin: 0">
        <label>Uhrzeit</label>
        <strong class="bigger-label">14:00 - 17:30</strong>
      </div>

      <div class="p-field p-col-4" style="margin: 0">
        <label>Betreuungskraft</label>
        <strong class="bigger-label">Frau Linda Scherer</strong>
      </div>
    </div>
    <div
      class="content-new-inner-wrap with-action"
      pTooltip="Feedback geben"
      style="max-width: 60px"
    >
      <i class="tio">edit</i>
    </div>
  </div>
</div>

<div class="content new-content-container">
  <header class="content-header">
    <h2>Letzte Termine</h2>
    <a routerLink="/"> alle ansehen <i class="pi pi-arrow-right"></i> </a>
  </header>

  <div class="content-body">
    <div class="content-body-inner-wrap">
      <span class="content-no-message">Keine vergangenen Termine</span>
    </div>
  </div>
</div>
