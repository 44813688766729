import { Component, OnDestroy, OnInit } from '@angular/core'
import { TitleService } from '../../../services/title.service'
import { UserCustomerService } from '../../../services/user-customer.service'
import {
  EventBusService,
  GlobalEvent,
} from '../../../services/eventbus.service'
import { Subscription } from 'rxjs'
import { AddUserCustomerRequestDialogComponent } from '../../../components/dialogs/add-user-customer-request-dialog/add-user-customer-request-dialog.component'
import { DialogService } from 'primeng/dynamicdialog'

@Component({
  selector: 'app-user-caregiver-requests',
  templateUrl: './user-customer-requests.component.html',
})
export class UserCustomerRequestsComponent implements OnInit, OnDestroy {
  public loading = true
  private eventBusSubscription: Subscription = new Subscription()

  public data: any[] = []

  constructor(
    public userCustomerService: UserCustomerService,
    private eventbus: EventBusService,
    private dialogService: DialogService,
    private titleService: TitleService
  ) {}

  ngOnInit(): void {
    document.body.classList.remove('mobile-nav-visible')

    this.titleService.setTitle(`Meine Anfragen`)

    this.loadData()
    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.CustomerDateChanged:
            this.loadData(false)
        }
      }
    )
  }

  public openUserCustomerRequestDialog(request: any): void {
    this.dialogService.open(AddUserCustomerRequestDialogComponent, {
      header: 'Anfrage bearbeiten',
      width: '450px',
      styleClass: 'dialog-container',
      data: {
        isEdit: true,
        request,
      },
    })
  }

  public loadData(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    this.userCustomerService.loadRequests().subscribe((data: any[]) => {
      this.data = data

      this.loading = false
    })
  }
}
