import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { Router } from '@angular/router'
import { PhoneCallService } from '../../../services/phone-call.service'
import { HelperService } from '../../../services/helper.service'
import { PlanningService } from '../../../services/planning.service'
import * as dayjs from 'dayjs'
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import * as utc from 'dayjs/plugin/utc'
import { AddCaregiverDateDialogComponent } from '../../../components/dialogs/add-caregiver-date-dialog/add-caregiver-date-dialog.component'
import { DialogService } from 'primeng/dynamicdialog'
import {
  EventBusService,
  GlobalEvent,
} from '../../../services/eventbus.service'
import { Subject, Subscription } from 'rxjs'
import { AddCaregiverOverviewCheckCommentDialogComponent } from '../../../components/dialogs/add-caregiver-overview-check-comment-dialog/add-caregiver-overview-check-comment-dialog.component'
import { switchMap } from 'rxjs/operators'
import { CapacityResponseModel } from '../../../models/capacity/capacity.model'

@Component({
  selector: 'app-vacation-plannings',
  templateUrl: './vacation-plannings.component.html',
  styleUrls: ['./vacation-plannings.component.scss'],
})
export class VacationPlanningsComponent implements OnInit, OnDestroy {
  @ViewChild('op') overlayPanel: ElementRef | any = null

  private loadTrigger$ = new Subject<void>()
  private subscription: Subscription = new Subscription()

  public loading = true
  public contentLoading = true
  public currentHover = ''
  public currentHoverForHeader = ''
  private eventBusSubscription: Subscription = new Subscription()

  public currentCheckOverlayData = {
    type: '',
    comment_type: '',
    caregiverId: null,
    data: [],
  }

  // TODO: Model erstellen
  public data: any
  // TODO: Model erstellen
  public overviewChecks: any = {}
  public checksLoaded = false
  // TODO: Model erstellen
  public markedCaregivers: any = []
  public caregiverOptions: any[] = []

  public filters = {
    caregivers: [],
    start: '',
    end: '',
    year: '',
    month: '',
  }

  private selectedYear = 0
  private selectedMonth = 0

  public monthOptions: any = []
  public yearOptions = [
    { label: '2021', value: '2021' },
    { label: '2022', value: '2022' },
    { label: '2023', value: '2023' },
    { label: '2024', value: '2024' },
    { label: '2025', value: '2025' },
  ]

  constructor(
    public phoneCallService: PhoneCallService,
    private helperService: HelperService,
    private planningService: PlanningService,
    private eventbus: EventBusService,
    private dialogService: DialogService,
    private router: Router
  ) {
    dayjs.locale('de')
    dayjs.extend(customParseFormat)
    dayjs.extend(utc)
  }

  ngOnInit(): void {
    const date = new Date()
    this.selectedYear = date.getFullYear()
    this.selectedMonth = date.getMonth()

    // TODO: Die BKs nicht in den Frontend dependencies speichern,
    //  sondern einen eigenen Request hier machen.
    this.helperService.dependencies$.subscribe((data: any) => {
      this.caregiverOptions = data['caregivers_for_capacity']

      this.filters.caregivers = this.caregiverOptions.map(
        (caregiver: any) => caregiver.id
      ) as []

      this.monthOptions = data.months
      this.filters.month = this.monthOptions[this.selectedMonth].label
      this.filters.year = this.selectedYear.toString()
    })

    this.activateDataLoading()

    this.setDates()
    this.initFilters()
    this.loadCaregivers()
    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
    this.eventBusSubscription.unsubscribe()
  }

  /**
   * Springt zum letzten Monat.
   */
  public goToPreviousMonth(): void {
    if (this.selectedMonth === 0) {
      const lastYear = this.selectedYear - 1

      const hasYearOption = this.yearOptions.find(
        (data: any) => data.value == lastYear
      )

      if (hasYearOption) {
        this.selectedYear = lastYear
        this.selectedMonth = 11
      }
    } else {
      this.selectedMonth--
    }

    this.filters.year = this.selectedYear.toString()
    this.filters.month = this.monthOptions[this.selectedMonth].label

    this.setDates()
    this.loadCaregivers(false)
  }

  /**
   * Springt zum nächsten Monat.
   */
  public goToNextMonth(): void {
    if (this.selectedMonth === 11) {
      const nextYear = this.selectedYear + 1

      const hasYearOption = this.yearOptions.find(
        (data: any) => data.value == nextYear
      )

      if (hasYearOption) {
        this.selectedYear = nextYear
        this.selectedMonth = 0
      }
    } else {
      this.selectedMonth++
    }

    this.filters.year = this.selectedYear.toString()
    this.filters.month = this.monthOptions[this.selectedMonth].label

    this.setDates()
    this.loadCaregivers(false)
  }

  public openCheckOverlay(
    event: any,
    caregiverId: any,
    data: any,
    commentType: string,
    type: string
  ): void {
    this.currentCheckOverlayData = {
      comment_type: commentType,
      type,
      caregiverId,
      data,
    }

    this.overlayPanel.toggle(event)
  }

  public toggleMarking(caregiver: any): void {
    caregiver.marked = !caregiver.marked

    if (caregiver.marked) {
      this.markedCaregivers.push(caregiver)
    } else {
      this.markedCaregivers = this.markedCaregivers.filter(
        (markedCaregiver: any) =>
          markedCaregiver.caregiver_id !== caregiver.caregiver_id
      )
    }

    const markedCaregiverIds = this.markedCaregivers.map(
      (caregiver: any) => caregiver.caregiver_id
    )

    localStorage.setItem(
      'planning-vacation-marked-caregiver-ids',
      JSON.stringify(markedCaregiverIds)
    )
  }

  public openCheckCommentDialog(
    data: any,
    nextRowData: any,
    comments: any
  ): void {
    this.dialogService.open(AddCaregiverOverviewCheckCommentDialogComponent, {
      header: 'Kommentar hinzufügen',
      width: '720px',
      styleClass: 'dialog-container',
      data: {
        caregiver_id: this.currentCheckOverlayData.caregiverId,
        row: data,
        next_row: nextRowData,
        comments,
        type: this.currentCheckOverlayData.type,
      },
    })
  }

  private initMarkedCaregivers(): void {
    if (
      localStorage.getItem('planning-vacation-marked-caregiver-ids') !== null
    ) {
      const markedCaregiverIds = JSON.parse(
        localStorage.getItem('planning-vacation-marked-caregiver-ids') || '[]'
      )

      // Laufen über die neuen Daten rüber, und setzen die markierung bei den
      // Betreuungskräften, die vorher schon markiert waren.
      for (const item of this.data.caregivers) {
        if (markedCaregiverIds.includes(item.caregiver_id)) {
          item.marked = true
        }
      }

      // Setzen die Daten mit den markierten Betreuungskräften nochmal neu.
      this.markedCaregivers = this.data.caregivers.filter(
        (caregiver: any) => caregiver.marked
      )
    }
  }

  public loadCaregivers(withLoading: boolean = true): void {
    localStorage.setItem(
      'planning-vacation-filters',
      JSON.stringify(this.filters)
    )

    this.contentLoading = true

    if (withLoading) {
      this.loading = true
    }

    this.loadTrigger$.next()
  }

  private activateDataLoading(): void {
    this.subscription.add(
      this.loadTrigger$
        .pipe(switchMap(() => this.planningService.vacations(this.filters)))
        .subscribe((data: CapacityResponseModel) => {
          this.loadOverviewChecks()

          this.data = data

          this.initMarkedCaregivers()

          this.contentLoading = false
          this.loading = false
        })
    )
  }

  public loadOverviewChecks(): void {
    this.checksLoaded = false
    this.overviewChecks = {}

    this.planningService.overviewChecks(this.filters).subscribe((data: any) => {
      this.checksLoaded = true
      this.overviewChecks = data
    })
  }

  public openDateDialog(
    startDate: string,
    caregiverId: string | number,
    caregiverName: string
  ): void {
    this.dialogService.open(AddCaregiverDateDialogComponent, {
      header: 'Urlaub hinzufügen',
      width: '450px',
      styleClass: 'dialog-container',
      data: {
        isEdit: false,
        single_type: 'VACATION',
        start_date: startDate,
        caregiver_id: caregiverId,
        name: caregiverName,
      },
    })
  }

  public setHover(type: string): void {
    this.currentHover = type
  }

  public setHoverForHeader(type: string): void {
    this.currentHoverForHeader = type
  }

  public monthOrYearChanged(): void {
    this.selectedYear = +this.filters.year
    this.selectedMonth = this.monthOptions.findIndex(
      (element: any) => element.value == this.filters.month
    )

    this.setDates()
    this.loadCaregivers(false)
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.CaregiverDateChanged:
          case GlobalEvent.CaregiverOverviewChanged:
            this.loadCaregivers(false)
        }
      }
    )
  }

  /**
   * Setzt bei der expliziten Auswahl vom Monat oder Jahr die richtigen Filterwerte.
   */
  private setDates(): void {
    // Monat muss + 1 gerechnet werden, da dieser bei 0 anfängt.
    const date = dayjs(`${this.selectedYear}-${this.selectedMonth + 1}-01`)

    this.filters.start = date.clone().format('DD.MM.YYYY')
    this.filters.end = date.clone().endOf('month').format('DD.MM.YYYY')
  }

  private initFilters(): void {
    // Falls noch nichts im Speicher vorhanden ist, setze den Default wert.
    if (localStorage.getItem('planning-vacation-filters') === null) {
      localStorage.setItem(
        'planning-vacation-filters',
        JSON.stringify(this.filters)
      )
    } else {
      // Falls aber was vorhanden ist, werden die Filter aus dem Speicher genommen.
      const savedFilters = JSON.parse(
        localStorage.getItem('planning-vacation-filters') || '{}'
      )

      // Es muss geprüft werden, ob die Menge der gespeicherten Daten übereinstimmt.
      // Falls nicht, wurde bei der Entwicklung ein neuer Filter hinzugefügt
      // und die Daten werden nun zurückgesetzt.
      if (
        Object.keys(savedFilters).length != Object.keys(this.filters).length
      ) {
        localStorage.setItem(
          'planning-vacation-filters',
          JSON.stringify(this.filters)
        )
      } else {
        this.filters = JSON.parse(
          localStorage.getItem('planning-vacation-filters') || '{}'
        )

        this.selectedYear = +this.filters.year
        this.selectedMonth = this.monthOptions.findIndex(
          (element: any) => element.value == this.filters.month
        )
      }
    }
  }
}
