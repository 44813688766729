import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { Subscription } from 'rxjs'
import { NgForm } from '@angular/forms'
import { PatientService } from '../../../services/patient.service'
import { SendAppointmentsService } from '../../../services/send-appointments.service'
import { DocumentService } from '../../../services/document.service'
import { HelperService } from '../../../services/helper.service'
import { PhoneCallService } from '../../../services/phone-call.service'
import { HandoverService } from '../../../services/handover.service'
import { HistoryListModel } from '../../../models/history/history-list.model'

@Component({
  selector: 'app-send-appointments-dialog',
  templateUrl: './send-appointments-dialog.component.html',
})
export class SendAppointmentsDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null
  private isDirty = false
  public histories: HistoryListModel[] = []

  public selectedPatientId = null
  public activeTab = 'MAIL_TEXT'
  public fromHandover = false
  public fromHandoverPatients: any = {}
  public fromHandoverPatientIds: any[] = []
  public downloadClicked: any[] = []

  public options = {
    buttons: ['bold', 'underline', 'italic'],
    toolbarAdaptive: false,
    toolbar: true,
    placeholder: 'Text eintragen...',
    statusbar: false,
    addNewLine: false,
    enter: 'br' as any,
    language: 'de',
    showBrowserColorPicker: false,
    allowResizeTags: [],
    table: {
      allowCellResize: false,
    },
  }

  public optionsText = {
    toolbar: false,
    placeholder: 'Termine eintragen...',
    statusbar: false,
    addNewLine: false,
    height: '100px',
    enter: 'br' as any,
    language: 'de',
    showBrowserColorPicker: false,
    allowResizeTags: [],
    table: {
      allowCellResize: false,
    },
  }

  public documentLink = ''
  public values = {
    type: 'email',
    receiver: '',

    text_start_appointment_anschreiben:
      'anbei finden Sie eine Übersicht, an welchen Tagen wir Sie in der Häuslichkeit unterstützen werden.',
    text_end_appointment_anschreiben:
      'Auf dieser Übersicht sehen Sie die geplante Erleichterung im Haushalt.<br/>Bitte prüfen Sie die Termine!<br/>Sollten wir von Ihnen <b><u>binnen drei Werktagen</u></b> nichts gegenteiliges hören, so gelten diese als bestätigt.<br/>Bitte haben Sie Verständnis dafür, dass die Organisation in Abhängigkeit von der Wetterlage, des Verkehrs und von Notfällen, variieren kann.<br/>Selbstverständlich werden wir Sie in solch einem Fall rechtzeitig informieren.',

    cc: [],
    patient_id: '',
    subject: '',
    text: '',
    month: '',
    year: '',
  }

  public ccOptions: any[] = []
  public monthOptions: any = []
  public yearOptions = [
    { label: '2019', value: '2019' },
    { label: '2020', value: '2020' },
    { label: '2021', value: '2021' },
    { label: '2022', value: '2022' },
    { label: '2023', value: '2023' },
    { label: '2024', value: '2024' },
    { label: '2025', value: '2025' },
  ]

  public clickedOnPreview = false
  public data: any = {}
  public receiverOptions: any[] = []
  public patientOptions: any[] = []

  public phoneReceivers: any[] = []

  submitted = false
  submittedDelete = false
  private channel: any = null
  public appointmentsHandoverViaPhone = false

  constructor(
    private documentService: DocumentService,
    private ref: DynamicDialogRef,
    private handoverService: HandoverService,
    private config: DynamicDialogConfig,
    private helperService: HelperService,
    private patientService: PatientService,
    public sendAppointmentsService: SendAppointmentsService,
    private eventbus: EventBusService,
    public phoneCallService: PhoneCallService,
    private toastService: ToastService
  ) {}

  public ngOnInit(): void {
    this.data = this.config.data

    const date = new Date()
    let nextMonth = date.getMonth() + 1
    let currentYear = date.getFullYear()

    // Wenn der aktuelle Monat einen index von 11 hatte (also bereits Dezember),
    // müssen wir den nächsten Monat auf Januar (also index 0) setzen und das Jahr erhöhen.
    if (nextMonth === 12) {
      nextMonth = 0
      currentYear++
    }

    // Wenn der Dialog aus der Terminübergabe geöffnet wird, müssen wir zuerst einige Daten laden.
    if (this.data.from_handover) {
      this.fromHandover = true

      this.values.year = this.data.year
      this.values.month = this.data.month
      this.fromHandoverPatients = this.data.caregiver_data.patients.all
      this.fromHandoverPatientIds = this.data.caregiver_data.patients.patient_ids

      // Wir setzen den ersten Patienten aus der Liste als Standard.
      this.loadReceivers(this.fromHandoverPatients[0].id)
      this.loadImportantHistories(this.fromHandoverPatients[0].id)
    } else {
      this.values.year = currentYear.toString()
      this.values.patient_id = this.data.patient_id

      this.setCorrectType()
      this.buildPhoneReceivers()

      this.helperService.dependencies$.subscribe((data: any) => {
        this.monthOptions = data.months
        this.values.month = this.monthOptions[nextMonth].label
      })
    }

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })

    if (!this.data.from_handover) {
      this.loadBudgetData()
      this.buildReceiverOptions()
      this.buildCCOptions()
      this.buildPatientOptions()
    }

    this.channel = new BroadcastChannel('appointment-sent-from-phone')
    this.channel.onmessage = (event: any) => {
      const data = event.data.data.data

      if (
        data.from_appointment &&
        data.from_appointment_patient_id == this.values.patient_id &&
        data.appointment_transfer !== 'NO'
      ) {
        this.appointmentsHandoverViaPhone = true
        this.loadPatients()
      }
    }
  }

  private setCorrectType(): void {
    const patient =
      this.data.first_patient.id === this.values.patient_id
        ? this.data.first_patient
        : this.data.second_patient

    const appointmentTypeNames = patient.appointment_types.map(
      (type: any) => type.type
    )

    if (appointmentTypeNames.includes('E-Mail')) {
      this.values.type = 'email'
    } else if (appointmentTypeNames.includes('Post')) {
      this.values.type = 'post'
    } else if (appointmentTypeNames.includes('Telefon')) {
      this.values.type = 'phone'
    }
  }

  public selectPatient(patientId: any): void {
    this.loadReceivers(patientId)
    this.loadImportantHistories(patientId)
  }

  private loadImportantHistories(patientId: any): void {
    this.patientService
      .historyImportant(patientId)
      .subscribe((histories: HistoryListModel[]) => {
        this.histories = histories
      })
  }

  private loadReceivers(patientId: any): void {
    this.clickedOnPreview = false
    this.selectedPatientId = patientId
    this.appointmentsHandoverViaPhone = false
    this.values.cc = []

    this.patientService.getReceivers(patientId).subscribe((response: any) => {
      this.data = response

      this.data.patient_id = patientId
      this.values.patient_id = patientId

      this.setCorrectType()
      this.loadBudgetData()
      this.buildReceiverOptions()
      this.buildCCOptions()
      this.buildPatientOptions()
      this.buildPhoneReceivers()
    })
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
    this.channel && this.channel.close()
  }

  private loadPatients(): void {
    this.handoverService
      .loadPatients(
        this.values.month,
        this.values.year,
        this.fromHandoverPatientIds
      )
      .subscribe((response: any) => {
        this.fromHandoverPatients = response
      })
  }

  public openMediaFromUuid(uuid: string): void {
    this.downloadClicked.push(uuid)

    window.open(this.documentService.getDocumentDownloadLink(uuid))
  }

  public save(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    this.sendAppointmentsService.send(this.values).subscribe(
      (response: any) => {
        this.submitted = false

        // Bei der großen Terminübergabe müssen wir die Patienten
        // neu laden und den Dialog nicht schließen.
        if (this.fromHandover) {
          this.eventbus.emit(GlobalEvent.HandoverSent)
          this.loadPatients()
        } else {
          // Wenn es per POST verschickt wird, bekommen wir die UUID vom Dokument.
          // Wir zeigen dem Benutzer einen Button an damit er das Dokument runterladen kann.
          if (response.uuid) {
            this.documentLink = this.documentService.getDocumentDownloadLink(
              response.uuid
            )
          } else {
            this.eventbus.emit(GlobalEvent.OfferSent)
            this.ref.close()
          }
        }

        if (this.values.type === 'email') {
          this.toastService.success(
            'Termine versendet',
            'Die Termine wurde erfolgreich versendet'
          )
        } else {
          this.toastService.success(
            'Termine erstellt',
            'Die Termine wurde erfolgreich erstellt'
          )
        }
      },
      () => {
        this.toastService.error(
          'Etwas ist schiefgelaufen...',
          'Bitte wenden Sie sich an den Support'
        )

        this.submitted = false
      }
    )
  }

  public createCalling(phone: any): void {
    this.phoneCallService.createCalling(phone.phone, {
      appointment: true,
      patient_id: this.values.patient_id,
      year: this.values.year,
      month: this.values.month,
    })
  }

  public loadBudgetData(): void {
    this.setSubject()

    this.patientService
      .getPersplanMonthForAppointmentSending(
        this.values.patient_id,
        this.values.month,
        this.values.year,
        false
      )
      .subscribe((budget: string) => {
        this.values.text = budget
      })
  }

  public buildCCOptions(): void {
    this.ccOptions = []

    this.ccOptions.push({
      value: this.data.first_patient.email,
      email: this.data.first_patient.email || 'Keine E-Mail',
      label: `${this.data.first_patient.full_name} - Patient`,
      disabled: !this.data.first_patient.email,
    })

    if (this.data.second_patient) {
      this.ccOptions.push({
        value: this.data.second_patient.email,
        email: this.data.second_patient.email || 'Keine E-Mail',
        label: `${this.data.second_patient.full_name} - Patient`,
        disabled: !this.data.second_patient.email,
      })
    }

    for (let contactPerson of this.data.customer.contact_persons) {
      if (contactPerson.email_private) {
        this.ccOptions.push({
          value: contactPerson.email_private,
          email: `Private E-Mail: ${contactPerson.email_private}`,
          label: `${contactPerson.full_name} - AP - Private E-Mail`,
          disabled: false,
        })
      }

      if (contactPerson.email_work) {
        this.ccOptions.push({
          value: contactPerson.email_work,
          email: `Geschäftliche E-Mail: ${contactPerson.email_work}`,
          label: `${contactPerson.full_name} - AP - Geschäftliche E-Mail`,
          disabled: false,
        })
      }

      // Wenn gar keine E-Mail hinterlegt ist.
      if (!contactPerson.email_private && !contactPerson.email_work) {
        this.ccOptions.push({
          value: null,
          email: 'Keine E-Mail',
          label: `${contactPerson.full_name} - AP - Keine E-Mail`,
          disabled: true,
        })
      }
    }
  }

  public changeTab(type: string): void {
    this.activeTab = type
  }

  /**
   * Prüft, ob der Terminversand für den ausgewählten Patienten gesperrt ist.
   */
  public appointmentIsLocked(): boolean {
    if (this.data.patient_id) {
      const patient =
        this.data.first_patient.id === this.values.patient_id
          ? this.data.first_patient
          : this.data.second_patient

      return patient.appointment_lock !== null
    }

    return false
  }

  public getAppointmentTypes(): any {
    if (this.data.patient_id) {
      const patient =
        this.data.first_patient.id === this.values.patient_id
          ? this.data.first_patient
          : this.data.second_patient

      return {
        types: patient.appointment_types_string || 'Keine Angabe',
        comment: patient.appointment_type_tooltip || '',
      }
    }
  }

  /**
   * Setzt einen Standardmäßigen Betreff für E-Mail oder Brief.
   */
  private setSubject(): void {
    if (this.values.type === 'post') {
      this.values.subject = 'Termine für persönliche Unterstützung'
    } else {
      const patient =
        this.data.first_patient.id === this.values.patient_id
          ? this.data.first_patient
          : this.data.second_patient

      this.values.subject = `Persönliche Termine für ${patient.id} ${patient.last_name}, ${patient.first_name}`
    }
  }

  private buildPatientOptions(): void {
    this.patientOptions.push({
      id: this.data.first_patient.id,
      full_name: this.data.first_patient.full_name,
    })

    if (this.data.second_patient) {
      this.patientOptions.push({
        id: this.data.second_patient.id,
        full_name: this.data.second_patient.full_name,
      })
    }
  }

  private buildPhoneReceivers(): void {
    this.phoneReceivers = []

    this.phoneReceivers.push({
      type: 'Patient',
      name: this.data.first_patient.full_name,
      phones: [
        {
          name: 'Telefon 1',
          phone: this.data.first_patient.phone_1,
          info: this.data.first_patient.phone_1_info,
        },
        {
          name: 'Telefon 2',
          phone: this.data.first_patient.phone_2,
          info: this.data.first_patient.phone_2_info,
        },
      ],
    })

    if (this.data.second_patient) {
      this.phoneReceivers.push({
        type: 'Patient',
        name: this.data.second_patient.full_name,
        phones: [
          {
            name: 'Telefon 1',
            phone: this.data.second_patient.phone_1,
            info: this.data.second_patient.phone_1_info,
          },
          {
            name: 'Telefon 2',
            phone: this.data.second_patient.phone_2,
            info: this.data.second_patient.phone_2_info,
          },
        ],
      })
    }

    for (let contactPerson of this.data.customer.contact_persons) {
      this.phoneReceivers.push({
        type: 'AP',
        name: contactPerson.full_name,
        phones: [
          {
            name: 'Telefon 1',
            phone: contactPerson.phone_1,
            info: contactPerson.phone_1_info,
          },
          {
            name: 'Telefon 2',
            phone: contactPerson.phone_2,
            info: contactPerson.phone_2_info,
          },
        ],
      })
    }
  }

  public buildReceiverOptions(): void {
    this.setSubject()

    this.receiverOptions = []
    this.values.receiver = ''

    this.receiverOptions.push({
      value: '',
      label: 'Bitte auswählen',
      email: '',
      disabled: true,
    })

    this.receiverOptions.push({
      value: `patient_${this.data.first_patient.id}_`,
      email: this.data.first_patient.email || 'Keine E-Mail',
      label: `${this.data.first_patient.full_name} - Patient`,
      disabled: this.values.type === 'email' && !this.data.first_patient.email,
    })

    if (this.data.second_patient) {
      this.receiverOptions.push({
        value: `patient_${this.data.second_patient.id}_`,
        email: this.data.second_patient.email || 'Keine E-Mail',
        label: `${this.data.second_patient.full_name} - Patient`,
        disabled:
          this.values.type === 'email' && !this.data.second_patient.email,
      })
    }

    for (let contactPerson of this.data.customer.contact_persons) {
      if (contactPerson.email_private) {
        this.receiverOptions.push({
          value: `ap_${contactPerson.id}_private`,
          email: `Private E-Mail: ${contactPerson.email_private}`,
          label: `${contactPerson.full_name} - AP`,
          disabled: false,
        })

        // Bei Postversand soll der AP soll nur einmal in der Liste angezeigt
        // werden, selbst wenn er noch eine Geschäftliche E-Mail Adresse hat.
        if (this.values.type === 'post') {
          continue
        }
      }

      if (contactPerson.email_work) {
        this.receiverOptions.push({
          value: `ap_${contactPerson.id}_work`,
          email: `Geschäftliche E-Mail: ${contactPerson.email_work}`,
          label: `${contactPerson.full_name} - AP`,
          disabled: false,
        })
      }

      // Wenn gar keine E-Mail hinterlegt ist.
      if (!contactPerson.email_private && !contactPerson.email_work) {
        this.receiverOptions.push({
          value: `ap_${contactPerson.id}_`,
          email: 'Keine E-Mail',
          label: `${contactPerson.full_name} - AP`,
          disabled: this.values.type === 'email',
        })
      }
    }
  }
}
