<div class="content-actions">
  <h2>
    <i class="tio">group_equal</i>
    Meine Daten
  </h2>
</div>

<!--<header class="content-header-tabs">-->
<!--  <a-->
<!--    routerLink="/meine-daten"-->
<!--    routerLinkActive="active"-->
<!--    [routerLinkActiveOptions]="{ exact: true }"-->
<!--    >Allgemeines</a-->
<!--  >-->
<!--  &lt;!&ndash;  <a&ndash;&gt;-->
<!--  &lt;!&ndash;    routerLink="/patienten/{{ customerDetail.first_patient.id }}/historie"&ndash;&gt;-->
<!--  &lt;!&ndash;    routerLinkActive="active"&ndash;&gt;-->
<!--  &lt;!&ndash;    [routerLinkActiveOptions]="{ exact: true }"&ndash;&gt;-->
<!--  &lt;!&ndash;    >Historie</a&ndash;&gt;-->
<!--  &lt;!&ndash;  >&ndash;&gt;-->
<!--</header>-->

<router-outlet></router-outlet>
