import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { AuthService } from '../../../../services/auth.service'
import { DocumentService } from '../../../../services/document.service'
import { DialogService } from 'primeng/dynamicdialog'
import {
  EventBusService,
  GlobalEvent,
} from '../../../../services/eventbus.service'
import { Subject, Subscription } from 'rxjs'
import { HelperService } from '../../../../services/helper.service'
import { PlanService } from '../../../../services/plan.service'
import { PlansFeedbackDialogComponent } from '../../../../components/dialogs/plans-feedback-dialog/plans-feedback-dialog.component'
import { switchMap } from 'rxjs/operators'

@Component({
  selector: 'app-accounting-returns-edited',
  templateUrl: './plans-feedback-edited.component.html',
})
export class PlansFeedbackEditedComponent implements OnInit, OnDestroy {
  @ViewChild('dt') table: ElementRef | any = null

  public firstPage = 0

  private loadTrigger$ = new Subject<void>()
  private subscription: Subscription = new Subscription()

  public plannerUsers: any[] = []
  public selectedPlanner: any = null

  // TODO: Model erstellen
  public data: any[] = []
  public loading = true
  public globalFilterValue = ''
  public contentLoading = true

  public correctionReasons: any = {}

  private eventBusSubscription: Subscription = new Subscription()

  constructor(
    private planService: PlanService,
    public authService: AuthService,
    private dialogService: DialogService,
    public documentService: DocumentService,
    private eventbus: EventBusService,
    private helperService: HelperService
  ) {}

  ngOnInit(): void {
    this.activateDataLoading()

    this.loadFeedbacks()
    this.listenForEventbus()

    this.helperService.dependencies$.subscribe((data: any) => {
      for (const planner of data.planner_users) {
        this.plannerUsers.push({
          id: planner.id,
          full_name: `${planner.user_system.first_name} ${planner.user_system.last_name}`,
        })
      }
    })
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
    this.subscription.unsubscribe()
  }

  public openPlansFeedbackDialog(item: any): void {
    let header = ''

    if (item.type === 'FEEDBACK') {
      header = 'Feedback'
    } else if (item.type === 'TIME_CHANGE') {
      header = 'Dauer'
    } else if (item.type === 'DRIVE_TIME_FEEDBACK') {
      header = 'Fahrzeit'
    } else {
      header = 'KM-Fahrt'
    }

    this.dialogService.open(PlansFeedbackDialogComponent, {
      header,
      width: '680px',
      dismissableMask: true,
      styleClass: 'dialog-container',
      data: {
        item,
      },
    })
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe((event) => {
      switch (event) {
        case GlobalEvent.PlanFeedbackChanged:
          this.loadFeedbacks(false)
          break
      }
    })
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }

  public loadFeedbacks(withLoading: boolean = true): void {
    this.contentLoading = true

    if (withLoading) {
      this.loading = true
    }

    this.loadTrigger$.next()
  }

  private activateDataLoading(): void {
    this.subscription.add(
      this.loadTrigger$
        .pipe(
          switchMap(() => {
            const filters = JSON.parse(
              localStorage.getItem('plans-feedback-filters') || '[]'
            )

            return this.planService.loadEdited(filters, this.selectedPlanner)
          })
        )
        .subscribe((data: any) => {
          this.data = data

          this.contentLoading = false
          this.loading = false

          setTimeout(() => {
            if (this.table) {
              const totalItems = this.table._totalRecords

              this.globalFilterValue = this.table.filters.global?.value ?? ''

              if (
                this.table._first > totalItems ||
                this.table._rows > totalItems
              ) {
                this.firstPage = 0
              }
            }
          })
        })
    )
  }
}
