import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, ParamMap } from '@angular/router'
import { CustomerDetailGeneralModel } from '../../../../models/customer-patient/customer-detail-general.model'
import { PatientService } from '../../../../services/patient.service'

@Component({
  selector: 'app-patients-detail-hours',
  templateUrl: './patients-detail-hours.component.html',
})
export class PatientsDetailHoursComponent implements OnInit, OnDestroy {
  public patientId: string | null = null
  private routeSubscription: any = null

  public loading = true
  public customer: CustomerDetailGeneralModel = new CustomerDetailGeneralModel()

  public patients: CustomerDetailGeneralModel = new CustomerDetailGeneralModel()
  public selectedPatient: 'first_patient' | 'second_patient' = 'first_patient'

  constructor(
    private route: ActivatedRoute,
    private patientService: PatientService
  ) {}

  ngOnInit(): void {
    if (this.route.parent) {
      this.routeSubscription = this.route.parent.paramMap.subscribe(
        (params: ParamMap) => {
          this.loading = true

          this.patientId = params.get('id')

          this.patientService
            .load(this.patientId)
            .subscribe((data: CustomerDetailGeneralModel) => {
              this.customer = data

              this.loading = false
            })
        }
      )
    }
  }

  ngOnDestroy(): void {
    this.routeSubscription && this.routeSubscription.unsubscribe()
  }
}
