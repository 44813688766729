import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { ActivatedRoute, ParamMap } from '@angular/router'
import {
  EventBusService,
  GlobalEvent,
} from '../../../../services/eventbus.service'
import { Subject, Subscription } from 'rxjs'
import { PlanningService } from '../../../../services/planning.service'
import { ToastService } from '../../../../services/toast.service'
import { AppointmentPlanningDialogComponent } from '../../../../components/dialogs/appointment-planning-dialog/appointment-planning-dialog.component'
import { DialogService } from 'primeng/dynamicdialog'
import { CustomerDetailGeneralModel } from '../../../../models/customer-patient/customer-detail-general.model'
import * as dayjs from 'dayjs'
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import * as utc from 'dayjs/plugin/utc'
import { HelperService } from '../../../../services/helper.service'
import { DiffViewDialogComponent } from '../../../../components/dialogs/diff-view-dialog/diff-view-dialog.component'
import { SearchResultModel } from '../../../../models/search/search-result.model'
import { SearchService } from '../../../../services/search.service'
import { switchMap } from 'rxjs/operators'

@Component({
  selector: 'app-patients-detail-planning',
  templateUrl: './patients-detail-planning.component.html',
  styleUrls: ['patients-details-planning.component.scss'],
  providers: [DialogService],
})
export class PatientsDetailPlanningComponent implements OnInit, OnDestroy {
  @ViewChild('budgetOverlay') budgetOverlay: ElementRef | any = null

  public loading = true
  public contentLoading = true
  public showHistory = false
  public searchedCaregivers: SearchResultModel[] = []
  public selectedCaregiver: any

  private loadTrigger$ = new Subject<void>()
  private subscription: Subscription = new Subscription()

  public patients: CustomerDetailGeneralModel = new CustomerDetailGeneralModel()
  public data: any
  public draggedData: any
  public draggedBudget: any
  public draggedType: any
  public currentHover = ''
  public currentHoverForHeader = ''
  public copyMode = false
  public appointmentToCopy: any = null

  private patientId: string | null = null
  public selectedPatient: 'first_patient' | 'second_patient' = 'first_patient'
  public currentDay = dayjs().format('DD.MM.YYYY')

  private eventBusSubscription: Subscription = new Subscription()

  private selectedYear = 0
  private selectedMonth = 0
  public month = ''
  public year = ''
  public monthOptions: any = []
  public yearOptions = [
    { label: '2019', value: '2019' },
    { label: '2020', value: '2020' },
    { label: '2021', value: '2021' },
    { label: '2022', value: '2022' },
    { label: '2023', value: '2023' },
    { label: '2024', value: '2024' },
    { label: '2025', value: '2025' },
  ]

  constructor(
    public planningService: PlanningService,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private searchService: SearchService,
    private eventbus: EventBusService,
    private helperService: HelperService,
    private dialogService: DialogService
  ) {
    dayjs.locale('de')
    dayjs.extend(customParseFormat)
    dayjs.extend(utc)
  }

  ngOnInit(): void {
    this.activateDataLoading()

    // document.body.classList.add('nav-hidden')

    const date = new Date()
    this.selectedYear = date.getFullYear()
    this.selectedMonth = date.getMonth()

    this.helperService.dependencies$.subscribe((data: any) => {
      this.monthOptions = data.months
      this.month = this.monthOptions[this.selectedMonth].label
      this.year = this.selectedYear.toString()
    })

    this.loadPlanning()
    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
    this.subscription.unsubscribe()

    document.body.classList.remove('fullscreen-mode')
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        // switch (event) {
        //   case GlobalEvent.VetoChanged:
        //     this.loadPlanning(false)
        // }
      }
    )
  }

  public removeFromBudgetList(budget: any): void {
    const confirm = window.confirm(
      `Budget ${budget.type_formatted} wirklich aus der Liste entfernen?`
    )

    if (!confirm) {
      return
    }

    this.planningService
      .removeFromBudgetList(this.patientId, {
        year: this.year,
        type_formatted: budget.type_formatted,
        type: budget.type,
      })
      .subscribe((data: any) => {
        this.loadPlanning(false)
      })
  }

  public changePatient(type: 'first_patient' | 'second_patient'): void {
    this.selectedPatient = type
  }

  public copyAppointmentToggle(appointment: any): void {
    this.copyMode = !this.copyMode
    this.appointmentToCopy = this.copyMode ? appointment : null
  }

  public appointmentClicked(
    date: any = null,
    appointment: any = null,
    budget: any = null
  ): void {
    let isCopy = false
    let type = 'appointment'

    if (this.copyMode) {
      isCopy = true
      appointment = this.appointmentToCopy
      type = 'copy'
    }

    this.openAppointmentPlanningDialog(date, type, appointment, budget, isCopy)
  }

  public loadPlanning(withLoading: boolean = true): void {
    const foundMonth = this.monthOptions.findIndex((month: any) => {
      return month.label === this.month
    })

    if (foundMonth != null) {
      this.selectedMonth = foundMonth
    }

    this.selectedYear = +this.year

    this.contentLoading = true

    if (withLoading) {
      this.loading = true
    }

    if (this.route.parent) {
      this.patientId = this.route.parent.snapshot.params.id

      this.loadTrigger$.next()
    }
  }

  private activateDataLoading(): void {
    this.subscription.add(
      this.loadTrigger$
        .pipe(
          switchMap(() =>
            this.planningService.loadPatientPlanning(
              this.patientId,
              this.month,
              this.year
            )
          )
        )
        .subscribe((data: any) => {
          this.data = data
          this.patients = data

          this.contentLoading = false
          this.loading = false
        })
    )
  }

  public searchedCaregiverSelected(event: any): void {
    // Wir prüfen, ob die ausgewählte Betreuungskraft
    // bereits in der Liste vorhanden ist.
    const exists = this.data.caregivers.find((caregiver: any) => {
      return caregiver.caregiver_id === event.id
    })

    // Falls die Betreuungskraft bereits in der Liste vorhanden
    // ist, resetten wir einfach direkt das Suchfeld.
    if (exists) {
      setTimeout(() => {
        this.selectedCaregiver = null
      }, 100)
    } else {
      document.body.classList.add('loading-cursor')

      // Ansonsten machen wir einen Request und holen uns die Planungsdaten.
      this.planningService
        .selectCaregiver(event.id, this.patientId, this.month, this.year)
        .subscribe(
          (response: any) => {
            this.data.caregivers.push({
              ...response,
              searched: true,
            })

            this.selectedCaregiver = null

            document.body.classList.remove('loading-cursor')
          },
          () => {
            this.toastService.error(
              'Etwas ist schiefgelaufen...',
              'Bitte wenden Sie sich an den Support'
            )

            document.body.classList.remove('loading-cursor')
          }
        )
    }
  }

  public removeSelectedCaregiver(caregiver: any): void {
    this.data.caregivers = this.data.caregivers.filter(
      (c: any) => c.caregiver_id !== caregiver.caregiver_id
    )
  }

  public searchCaregivers(event: any): void {
    this.searchService
      .findCaregiversOnlyActiveForPatient(this.patientId, event.query.trim())
      .subscribe((results: SearchResultModel[]) => {
        this.searchedCaregivers = results
      })
  }

  /**
   * Springt zum letzten Monat.
   */
  public goToPreviousMonth(): void {
    if (this.selectedMonth === 0) {
      const lastYear = this.selectedYear - 1

      const hasYearOption = this.yearOptions.find(
        (data: any) => data.value == lastYear
      )

      if (hasYearOption) {
        this.selectedYear = lastYear
        this.selectedMonth = 11
      }
    } else {
      this.selectedMonth--
    }

    this.year = this.selectedYear.toString()
    this.month = this.monthOptions[this.selectedMonth].label

    this.loadPlanning(false)
  }

  public openHistorySidebar(): void {
    this.showHistory = true
  }

  public toggleFullscreen(): void {
    document.body.classList.toggle('fullscreen-mode')
  }

  public openDiffView(id: number): void {
    this.dialogService.open(DiffViewDialogComponent, {
      data: {
        id,
      },
      header: 'Änderungen ansehen',
      styleClass: 'dialog-diff-view',
      dismissableMask: true,
    })
  }

  /**
   * Springt zum nächsten Monat.
   */
  public goToNextMonth(): void {
    if (this.selectedMonth === 11) {
      const nextYear = this.selectedYear + 1

      const hasYearOption = this.yearOptions.find(
        (data: any) => data.value == nextYear
      )

      if (hasYearOption) {
        this.selectedYear = nextYear
        this.selectedMonth = 0
      }
    } else {
      this.selectedMonth++
    }

    this.year = this.selectedYear.toString()
    this.month = this.monthOptions[this.selectedMonth].label

    this.loadPlanning(false)
  }

  public onDateDrop(event: any, date: any, budget: any): void {
    if (this.draggedData) {
      if (this.draggedType === 'caregiver') {
        this.openAppointmentPlanningDialog(
          date,
          this.draggedType,
          this.draggedData,
          budget
        )
      } else if (this.draggedType === 'appointment') {
        this.openAppointmentPlanningDialog(
          date,
          this.draggedType,
          this.draggedData,
          budget
        )
      }
    }
  }

  public openAppointmentPlanningDialog(
    date: any,
    type: string,
    data: any,
    budget: any,
    isCopy = false
  ): void {
    const ref = this.dialogService.open(AppointmentPlanningDialogComponent, {
      header:
        type === 'appointment' ? 'Einsatz verschieben' : 'Einsatz erstellen',
      width: '520px',
      styleClass: 'dialog-container',
      data: {
        isPast: this.data.is_past,
        budgets: this.data.patient_budgets,
        dates: this.data.dates,
        date,
        data,
        type,
        isCopy,
        budget_type: budget?.type,
        patient: this.data.first_patient,
        caregivers: this.data.caregivers,
      },
    })

    ref.onClose.subscribe((newAppointment: any) => {
      this.copyMode = false
      this.appointmentToCopy = null

      if (newAppointment) {
        this.loadPlanning(false)
      }
    })
  }

  public setHover(type: string): void {
    this.currentHover = type
  }

  public setHoverForHeader(type: string): void {
    this.currentHoverForHeader = type
  }

  public dragStart(data: any, type: 'appointment' | 'caregiver'): void {
    this.draggedData = data
    this.draggedType = type
  }

  public dragEnd(): void {
    this.draggedData = null
    this.draggedType = null
  }
}
