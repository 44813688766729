<form #form="ngForm" class="dialog-form">
  <div class="dialog-form-body">
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="detail-information-banner" *ngIf="!hasEmail">
        <span>Keine E-Mail-Adresse hinterlegt</span>
      </div>

      <div class="p-field p-col-12">
        <label for="subject">Betreff *</label>
        <div class="p-inputgroup inputgroup-auto-width">
          <input
            id="subject"
            name="subject"
            type="text"
            [(ngModel)]="values.subject"
            pInputText
            #subject="ngModel"
            required
          />
          <span class="p-inputgroup-addon"
            >| {{ data.caregiver.full_name.trim() }}</span
          >
        </div>
        <div
          *ngIf="subject.invalid && (subject.dirty || subject.touched)"
          class="p-invalid"
        >
          <div *ngIf="subject.errors?.required">
            <small>Bitte geben Sie einen Betreff ein</small>
          </div>
        </div>
      </div>
    </div>

    <div
      class="p-fluid p-formgrid p-grid without-border bigger-jodit-font-size"
    >
      <div class="p-field p-col-12">
        <label for="text">Inhalt *</label>

        <ngx-jodit [(value)]="values.text" [options]="options"></ngx-jodit>
      </div>
    </div>
  </div>
  <div class="dialog-form-footer">
    <a
      target="_blank"
      (click)="clickedOnPreview = true"
      [href]="caregiverService.getMailPreviewLinkForMessage(values)"
    >
      <button
        pButton
        label="Mail Vorschau"
        type="button"
        icon="pi pi-eye"
        iconPos="left"
        class="p-button p-button-info"
      ></button>
    </a>

    <button
      pButton
      label="Versenden"
      type="submit"
      (click)="save()"
      [disabled]="submitted || !clickedOnPreview || !hasEmail"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>
