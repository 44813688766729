import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { ActivatedRoute, ParamMap } from '@angular/router'
import { CaregiverOverviewService } from '../../../../../services/caregiver-overview.service'
import { LatestChangeModel } from '../../../../../models/history/latest-change.model'
import { CapacityService } from '../../../../../services/capacity.service'
import * as dayjs from 'dayjs'
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import * as utc from 'dayjs/plugin/utc'
import { HelperService } from '../../../../../services/helper.service'

@Component({
  selector: 'app-caregivers-detail-calendar',
  templateUrl: './caregivers-detail-calendar.component.html',
  styleUrls: ['caregivers-detail-calendar.component.scss'],
})
export class CaregiversDetailCalendarComponent implements OnInit, OnDestroy {
  @ViewChild('dt') table: ElementRef | any = null

  public loading = true
  public contentLoading = true
  public caregiverId: string | null = null
  public currentHover = ''
  public persplanData = new LatestChangeModel()
  public globalFilterValue = ''

  // TODO: Models erstellen
  public calendarData: any
  public calendarDays: string[] = []

  public year = ''
  private selectedYear = 0

  private routeSubscription: any = null

  public yearOptions = [
    { label: '2021', value: '2021' },
    { label: '2022', value: '2022' },
    { label: '2023', value: '2023' },
    { label: '2024', value: '2024' },
    { label: '2025', value: '2025' },
  ]

  constructor(
    private route: ActivatedRoute,
    private helperService: HelperService,
    private capacityService: CapacityService,
    private caregiverOverviewService: CaregiverOverviewService
  ) {
    dayjs.locale('de')
    dayjs.extend(customParseFormat)
    dayjs.extend(utc)
  }

  ngOnInit(): void {
    const date = new Date()
    this.selectedYear = date.getFullYear()
    this.year = this.selectedYear.toString()

    this.loadData()
  }

  ngOnDestroy(): void {
    this.routeSubscription && this.routeSubscription.unsubscribe()
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }

  /**
   * Springt zum letzten Jahr.
   */
  public goToPreviousYear(): void {
    const newYear = this.selectedYear - 1

    const hasYearOption = this.yearOptions.find(
      (data: any) => data.value == newYear
    )

    if (hasYearOption) {
      this.selectedYear--
      this.year = this.selectedYear.toString()

      this.loadData(false)
    }
  }

  /**
   * Springt zum nächsten Jahr.
   */
  public goToNextYear(): void {
    const newYear = this.selectedYear + 1

    const hasYearOption = this.yearOptions.find(
      (data: any) => data.value == newYear
    )

    if (hasYearOption) {
      this.selectedYear++
      this.year = this.selectedYear.toString()

      this.loadData(false)
    }
  }

  public loadData(withLoading: boolean = true): void {
    this.routeSubscription && this.routeSubscription.unsubscribe()

    this.contentLoading = true

    if (withLoading) {
      this.loading = true
    }

    if (this.route.parent?.parent) {
      this.routeSubscription = this.route.parent.parent.paramMap.subscribe(
        (params: ParamMap) => {
          this.caregiverId = params.get('id')

          this.caregiverOverviewService
            .calendar(this.caregiverId, this.year)
            .subscribe((calendar: any) => {
              this.calendarDays = Object.keys(calendar)
              this.calendarData = calendar

              this.contentLoading = false

              if (withLoading) {
                this.loading = false
              }
            })
        }
      )
    }
  }

  public getPersplanForCaregiver(type: string, date: string): void {
    // Wir können nur eine Request machen, wenn es sich um einen Termin handelt.
    if (
      this.persplanData[this.caregiverId + date] ||
      type !== 'TYPE_APPOINTMENT'
    ) {
      return
    }

    this.capacityService
      .getPersplan(this.caregiverId ? +this.caregiverId : 0, date)
      .subscribe((response: string) => {
        this.persplanData[this.caregiverId + date] = response
      })
  }

  public setHover(day: string = ''): void {
    this.currentHover = day
  }
}
