export class HouseholdModel {
  id: number | null = null
  customer_id: number | null = null
  type = ''
  condition = ''
  living_space = ''
  living_floor = ''
  living_floor_number = ''
  transfer_needed = ''
  smoking = ''
  key_case = ''
  cleaning_stuff = ''
  pets = ''
  pets_info_string = ''
  dogs = false
  cats = false
  can_print = false
  pets_info = ''
  specials = ''
  created_at: Date | null = null
  updated_at: Date | null = null
}
