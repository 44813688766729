import { Component, OnDestroy, OnInit } from '@angular/core'
import { CaregiverService } from '../../../../../services/caregivers.service'
import { ActivatedRoute, ParamMap } from '@angular/router'
import { DialogService } from 'primeng/dynamicdialog'
import {
  EventBusService,
  GlobalEvent,
} from '../../../../../services/eventbus.service'
import { Subscription } from 'rxjs'
import { CaregiverAnalysesService } from '../../../../../services/caregiver-analyses.service'
import { ChangeDriveTimeManuallyDialogComponent } from '../../../../../components/dialogs/change-drive-time-manually-dialog/change-drive-time-manually-dialog.component'
import { ChangeCaregiverTimeCorrectionDialogComponent } from '../../../../../components/dialogs/change-caregiver-time-correction-dialog/change-caregiver-time-correction-dialog.component'
import { CaregiverDetailGeneralDataModel } from '../../../../../models/caregiver/caregiver-detail-general.model'
import { ChangeCaregiverPersplanTimeCorrectionDialogComponent } from '../../../../../components/dialogs/change-caregiver-persplan-time-correction-dialog/change-caregiver-persplan-time-correction-dialog.component'

@Component({
  selector: 'app-caregivers-detail-analyses',
  templateUrl: './caregivers-detail-analyses.component.html',
  styleUrls: ['./caregivers-detail-analyses.component.scss'],
  providers: [DialogService],
})
export class CaregiversDetailAnalysesComponent implements OnInit, OnDestroy {
  public loading = true
  public contentLoading = true
  public currentHover = -1
  public currentHoverForHeader = -1
  public year = ''

  public selectedYear = 0
  public selectedMonth = 0

  public caregiver: CaregiverDetailGeneralDataModel = new CaregiverDetailGeneralDataModel()
  public times: any
  public jobScopes: any
  public employed: any
  public jobDays: any
  public illQuota: any
  public timeKeys: any
  public caregiverId: string | null = null

  private routeSubscription: any = null
  private eventBusSubscription: Subscription = new Subscription()

  public yearOptions = [
    { label: '2021', value: '2021' },
    { label: '2022', value: '2022' },
    { label: '2023', value: '2023' },
    { label: '2024', value: '2024' },
    { label: '2025', value: '2025' },
  ]

  constructor(
    public caregiverAnalysesService: CaregiverAnalysesService,
    public caregiverService: CaregiverService,
    private route: ActivatedRoute,
    private eventbus: EventBusService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    const date = new Date()
    this.selectedYear = date.getFullYear()
    this.selectedMonth = date.getMonth()
    this.year = this.selectedYear.toString()

    this.loadAnalyses()
    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
    this.routeSubscription && this.routeSubscription.unsubscribe()
  }

  public setHover(index: number = -1): void {
    this.currentHover = index
  }

  public setHoverForHeader(index: number = -1): void {
    this.currentHoverForHeader = index
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.CaregiverTimeChanged:
            this.loadAnalyses(false)
        }
      }
    )
  }

  public loadAnalyses(withLoading: boolean = true): void {
    this.contentLoading = true

    if (withLoading) {
      this.loading = true
    }

    if (this.route.parent?.parent) {
      this.routeSubscription = this.route.parent.parent.paramMap.subscribe(
        (params: ParamMap) => {
          this.caregiverId = params.get('id')

          this.caregiverAnalysesService
            .load(this.caregiverId, this.year)
            .subscribe((data: any) => {
              this.caregiver = data.caregiver
              this.times = data.times
              this.jobScopes = data.job_scopes
              this.employed = data.employed
              this.jobDays = data.job_days
              this.illQuota = data.ill_quota
              this.timeKeys = Object.keys(data.times)

              this.contentLoading = false

              if (withLoading) {
                this.loading = false
              }
            })
        }
      )
    }
  }

  // TODO: Model erstellen
  public openChangeDriveTimeManuallyDialog(data: any): void {
    this.dialogService.open(ChangeDriveTimeManuallyDialogComponent, {
      header: `Fahrtzeit für ${data.name} ${this.year} bearbeiten`,
      width: '450px',
      styleClass: 'dialog-container',
      data: {
        ...data,
      },
    })
  }

  // TODO: Model erstellen
  public openChangeCaregiverTimeCorrectionDialog(data: any): void {
    this.dialogService.open(ChangeCaregiverTimeCorrectionDialogComponent, {
      header: `Korrektur für ${data.name} ${this.year}`,
      width: '450px',
      styleClass: 'dialog-container',
      data: {
        ...data,
      },
    })
  }

  // TODO: Model erstellen
  public openChangeCaregiverPersplanTimeCorrectionDialog(
    data: any,
    month_index: number
  ): void {
    this.dialogService.open(
      ChangeCaregiverPersplanTimeCorrectionDialogComponent,
      {
        header: `Arbeitszeit für ${data.name} ${this.year}`,
        width: '450px',
        styleClass: 'dialog-container',
        data: {
          ...data,
          caregiver_id: this.caregiverId,
          month_index,
          selected_year: this.year,
        },
      }
    )
  }

  /**
   * Springt zum letzten Jahr.
   */
  public goToPreviousYear(): void {
    const newYear = this.selectedYear - 1

    const hasYearOption = this.yearOptions.find(
      (data: any) => data.value == newYear
    )

    if (hasYearOption) {
      this.selectedYear--
      this.year = this.selectedYear.toString()

      this.loadAnalyses(false)
    }
  }

  /**
   * Springt zum nächsten Jahr.
   */
  public goToNextYear(): void {
    const newYear = this.selectedYear + 1

    const hasYearOption = this.yearOptions.find(
      (data: any) => data.value == newYear
    )

    if (hasYearOption) {
      this.selectedYear++
      this.year = this.selectedYear.toString()

      this.loadAnalyses(false)
    }
  }
}
