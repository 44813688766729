import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { NgForm } from '@angular/forms'
import { Subscription } from 'rxjs'
import { DocumentService } from '../../../services/document.service'
import { SystemMessageInternService } from '../../../services/system-message-intern.service'
import { AuthService } from '../../../services/auth.service'
import { ToastService } from '../../../services/toast.service'

@Component({
  selector: 'app-send-system-message-dialog',
  templateUrl: './system-message-intern-dialog.component.html',
})
export class SystemMessageInternDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null

  submitted = false

  public data: any = []

  public values = {
    subject: '',
    text: '',
  }

  public options = {
    buttons: ['bold', 'underline', 'italic'],
    toolbarAdaptive: false,
    toolbar: true,
    enter: 'br' as any,
    height: 50,
    placeholder: '',
    statusbar: false,
    addNewLine: false,
    language: 'de',
    showBrowserColorPicker: false,
    allowResizeTags: [],
    table: {
      allowCellResize: false,
    },
  }

  constructor(
    private toastService: ToastService,
    public authService: AuthService,
    public systemMessageInternService: SystemMessageInternService,
    public documentService: DocumentService
  ) {}

  public ngOnInit(): void {
    this.loadAll()
    this.setAsRead()
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  public save(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    this.systemMessageInternService.store(this.values).subscribe(
      () => {
        window.location.reload()
      },
      () => {
        this.toastService.error(
          'Etwas ist schiefgelaufen...',
          'Bitte wenden Sie sich an den Support'
        )

        this.submitted = false
      }
    )
  }

  private loadAll(): void {
    this.systemMessageInternService.loadAll().subscribe((response: any) => {
      this.data = response
    })
  }

  private setAsRead(): void {
    this.systemMessageInternService.setAsRead().subscribe()
  }
}
