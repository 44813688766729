import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { StatusCodes } from 'http-status-codes'
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { DocumentService } from '../../../services/document.service'
import { NgForm } from '@angular/forms'
import { Subscription } from 'rxjs'
import { AccountingReturnService } from '../../../services/accounting-return.service'
import { SearchService } from '../../../services/search.service'
import { AuthModel } from '../../../models/user/auth.model'
import { AuthService } from '../../../services/auth.service'
import { PlanService } from '../../../services/plan.service'
import { TodoCommentModel } from '../../../models/todo/todo-comment.model'
import { TodoFormDialogComponent } from '../todo-form-dialog/todo-form-dialog.component'
import { ShowTodoDialogComponent } from '../show-todo-dialog/show-todo-dialog.component'
import { AddDocumentDialogComponent } from '../add-document-dialog/add-document-dialog.component'
import { MediaModel } from '../../../models/document/media.model'

@Component({
  selector: 'app-plans-feedback-dialog',
  templateUrl: './plans-feedback-dialog.component.html',
})
export class PlansFeedbackDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null
  private isDirty = false

  public editComment: any = null
  public comments: any[] = []

  public isDateRequest = false
  public todoCreated = false
  public isEdit = false
  public data: any = {}

  public values = {
    status: '',
    important: false,
    comment: '',
  }

  public finishedSelected = false
  public previousStatus = ''

  public currentUser: AuthModel | null = new AuthModel()

  public statusOptions = [
    { label: 'ERSTELLT', value: 'CREATED', backgroundColor: '#3296dc' },
    { label: 'IN BEARBEITUNG', value: 'EDITED', backgroundColor: '#dbaa25' },
    { label: 'ERLEDIGT', value: 'FINISHED', backgroundColor: '#84bc3c' },
  ]

  public submitted = false

  constructor(
    private ref: DynamicDialogRef,
    private searchService: SearchService,
    private dialogService: DialogService,
    private config: DynamicDialogConfig,
    public documentService: DocumentService,
    private accountingService: AccountingReturnService,
    private planService: PlanService,
    private eventbus: EventBusService,
    private toastService: ToastService,
    public authService: AuthService
  ) {}

  ngOnInit(): void {
    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })

    this.currentUser = this.authService.getUser()

    this.data = this.config.data

    this.isDateRequest = this.data.item.is_date_request
    this.comments = this.data.item.admin_comments
    this.values.status = this.data.item.process_status
    this.previousStatus = this.data.item.process_status
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  public changeStatus(event: any): void {
    // Wenn auf "Erledigt" geklickt wird, soll ein Feld aufgehen
    // zum eintragen von Infos und der Benutzer muss explizit speichern.
    if (event.value === 'FINISHED') {
      this.finishedSelected = true
    } else {
      this.finishedSelected = false
      this.previousStatus = event.value

      this.saveStatus()
    }
  }

  public saveStatus(withDialogClose = false): void {
    this.planService
      .changeStatus(
        this.data.item.type,
        this.data.item.type_id,
        this.values.status,
        this.data.item.data.caregiver_appointment_change,
        this.data.item.data
      )
      .subscribe(
        () => {
          if (withDialogClose) {
            this.ref.close()
          }

          this.eventbus.emit(GlobalEvent.PlanFeedbackChanged)
          this.toastService.success(
            'Status gespeichert',
            'Der Status wurde erfolgreich geändert'
          )
        },
        (error: HttpErrorResponse) => {
          if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
            this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
          } else if (error.status === StatusCodes.CONFLICT) {
            this.toastService.error(
              'Abwesenheit bereits vorhanden',
              'Es ist bereits eine AU oder Urlaub im angegebenen Zeitraum vorhanden'
            )
          } else {
            this.toastService.error(
              'Etwas ist schief gelaufen...',
              'Bitte wenden Sie sich an den Support'
            )
          }
        }
      )
  }

  public sendDriveTimeMailChanged(event: any): void {
    if (this.data.item.data.caregiver_appointment_change.send_drive_time_mail) {
      this.data.item.data.caregiver_appointment_change.credit_drive_time = true
    }
  }

  public creditDriveTimeChanged(event: any): void {
    if (!this.data.item.data.caregiver_appointment_change.credit_drive_time) {
      this.data.item.data.caregiver_appointment_change.send_drive_time_mail = false
    }
  }

  public saveWithHistory(): void {
    this.planService
      .saveWithHistory(
        this.data.item.type,
        this.data.item.type_id,
        this.values,
        this.data.item.data.caregiver_appointment_change,
        this.data.item.data
      )
      .subscribe(
        () => {
          this.ref.close()
          this.eventbus.emit(GlobalEvent.PlanFeedbackChanged)
          this.toastService.success(
            'Status gespeichert',
            'Der Status wurde erfolgreich geändert'
          )
        },
        (error: HttpErrorResponse) => {
          if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
            this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
          } else if (error.status === StatusCodes.CONFLICT) {
            this.toastService.error(
              'Abwesenheit bereits vorhanden',
              'Es ist bereits eine AU oder Urlaub im angegebenen Zeitraum vorhanden'
            )
          } else {
            this.toastService.error(
              'Etwas ist schief gelaufen...',
              'Bitte wenden Sie sich an den Support'
            )
          }
        }
      )
  }

  public save(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    this.planService
      .storeComment(
        this.data.item.type,
        this.data.item.type_id,
        this.values.comment
      )
      .subscribe(
        () => {
          // Falls ein Kommentar erstellt wird, und der Status auf "Erstellt" steht
          // soll der Status automatisch auf "In Bearbeitung" gestellt werden.
          if (this.values.status === 'CREATED') {
            this.values.status = 'EDITED'

            this.saveStatus()
          }

          this.loadComments()
          this.values.comment = ''
          this.submitted = false
          this.eventbus.emit(GlobalEvent.PlanFeedbackChanged)
          this.toastService.success(
            'Kommentar gespeichert',
            'Der Kommentar wurde erfolgreich gespeichert'
          )
        },
        (error: HttpErrorResponse) => {
          if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
            this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
          } else {
            this.toastService.error(
              'Etwas ist schief gelaufen...',
              'Bitte wenden Sie sich an den Support'
            )
          }
          this.submitted = false
        }
      )
  }

  public update(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    this.planService
      .updateComment(this.editComment?.id || '', this.values.comment)
      .subscribe(
        () => {
          this.loadComments()
          this.values.comment = ''
          this.editComment = null
          this.submitted = false
          this.eventbus.emit(GlobalEvent.PlanFeedbackChanged)
          this.toastService.success(
            'Kommentar bearbeitet',
            'Der Kommentar wurde erfolgreich bearbeitet'
          )
        },
        (error: HttpErrorResponse) => {
          if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
            this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
          } else {
            this.toastService.error(
              'Etwas ist schief gelaufen...',
              'Bitte wenden Sie sich an den Support'
            )
          }
          this.submitted = false
        }
      )
  }

  public openAddDocumentDialog(): void {
    const ref = this.dialogService.open(AddDocumentDialogComponent, {
      data: {
        type_id: this.data.item.type_id,
        type: this.data.item.type,
        default_document_type: 'misc',
      },
      header: 'Dokument hochladen',
      styleClass: 'dialog-container',
      dismissableMask: false,
      width: '450px',
    })

    ref.onClose.subscribe((document: any) => {
      if (document) {
        this.eventbus.emit(GlobalEvent.PlanFeedbackChanged)

        const model = new MediaModel()
        model.name = document.media.name
        model.uuid = document.media.uuid

        this.data.item.documents.push(model)
      }
    })
  }

  public openTodoDialog(): void {
    const item = this.data.item

    let comment = `Betreuungskraft: ${this.data.item.data.caregiver.full_name}\n`

    if (item.type === 'FEEDBACK') {
      comment += item.tooltip
    } else if (item.type === 'TIME_CHANGE') {
      const time = item.data.caregiver_appointment_change.time
      const plus = time > 0 ? '+' : ''

      comment += `${plus}${time.toString().replace('.', ',')} Std\n\n${
        item.data.caregiver_appointment_change.time_feedback || ''
      }`
    } else if (item.type === 'DRIVE_TIME_FEEDBACK') {
      const time = item.data.caregiver_appointment_change.drive_time
      const plus = time > 0 ? '+' : ''

      comment += `${plus}${time.toString().replace('.', ',')} min\n\n${
        item.data.caregiver_appointment_change.drive_time_feedback || ''
      }`
    } else {
      comment += `${item.data.caregiver_appointment_trip.mileage
        .toString()
        .replace('.', ',')} KM\n\n${item.tooltip}`
    }

    const ref = this.dialogService.open(TodoFormDialogComponent, {
      header: 'Neues Todo',
      width: '520px',
      styleClass: 'dialog-container',
      data: {
        isPreSelected: true,
        selectedType: 'PATIENT',
        user_type_name: this.data.item.data.patient.full_name,
        user_type_id: this.data.item.data.patient.id,
        plansFeedback: true,
        plansFeedbackType: this.data.item.type,
        plansFeedbackId: this.data.item.type_id,
        comment,
      },
    })

    ref.onClose.subscribe((hasCreated: boolean) => {
      if (hasCreated) {
        this.todoCreated = true
        this.eventbus.emit(GlobalEvent.PlanFeedbackChanged)
      }
    })
  }

  public openShowTodoDialog(): void {
    this.dialogService.open(ShowTodoDialogComponent, {
      header: 'Todo ansehen',
      width: '820px',
      styleClass: 'dialog-container',
      data: {
        todo: this.data.item.todo,
        todo_id: this.data.item.todo.id,
        user_type_name:
          this.data.item.todo.caregiver?.full_name ||
          this.data.item.todo.patient?.full_name,
      },
    })
  }

  public loadComments(): void {
    this.planService
      .loadComments(this.data.item.type, this.data.item.type_id)
      .subscribe((comments: any) => {
        this.comments = comments
      })
  }

  public checkForTimes(event: any, type: 'from_time' | 'to_time'): void {
    const value = event.target.value

    if (value.length === 1) {
      this.values[type] = `0${value}:00`
    } else if (value.length === 2) {
      this.values[type] = `${value}:00`
    } else if (value.length === 4) {
      this.values[type] = `${value[0]}${value[1]}:${value[2]}${value[3]}`
    }
  }

  public openEditMode(comment: TodoCommentModel): void {
    this.editComment = comment
    this.values.comment = comment.comment
  }

  public cancelEditMode(): void {
    this.editComment = null
    this.values.comment = ''
  }
}
