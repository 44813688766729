<app-loader [loading]="loading"></app-loader>

<div class="content" *ngIf="!loading">
  <header class="content-header">
    <span>
      <i (click)="goToPreviousMonth()" class="pi pi-chevron-left"></i>

      <p-dropdown
        inputId="month"
        name="month"
        scrollHeight="500px"
        (onChange)="loadAnalyses(false)"
        [options]="monthOptions"
        [(ngModel)]="month"
        [style]="{ width: '150px', zIndex: '10' }"
      ></p-dropdown>

      <p-dropdown
        inputId="year"
        name="year"
        (onChange)="loadAnalyses(false)"
        [options]="yearOptions"
        [(ngModel)]="year"
      ></p-dropdown>

      <i
        (click)="goToNextMonth()"
        class="pi pi-chevron-right"
        style="margin: 0 10px 0 10px"
      ></i>

      <p-treeSelect
        [filter]="true"
        [filterInputAutoFocus]="true"
        (onNodeSelect)="loadAnalyses(false)"
        (onNodeUnselect)="loadAnalyses(false)"
        (onClear)="loadAnalyses(false)"
        class="proofs-treeselect"
        [style]="{ margin: '0 0 0 -1px' }"
        selectionMode="checkbox"
        [showClear]="true"
        inputId="cities"
        name="cities"
        placeholder="Bezirke"
        [(ngModel)]="selectedCities"
        [options]="dependencies.counties"
        scrollHeight="500px"
      ></p-treeSelect>

      <p-checkbox
        label="nur Minusbudgets"
        [binary]="true"
        (onChange)="loadAnalyses(false)"
        [(ngModel)]="onlyMinusBudgets"
        name="only_minus_budgets"
      ></p-checkbox>
    </span>

    <div class="table-search-container">
      <div class="p-input-icon-left">
        <i class="pi pi-search"></i>
        <input
          pInputText
          (input)="filterTableGlobal($event)"
          placeholder="Suche..."
          [value]="globalFilterValue"
        />
      </div>
    </div>
  </header>
</div>

<div class="content" style="margin-bottom: 0" *ngIf="!loading">
  <div
    class="content-body content-body-full-height-with-tabs without-padding"
    [class.content-loading]="contentLoading"
  >
    <p-table
      #dt
      sortMode="multiple"
      [value]="data"
      [rowHover]="true"
      [globalFilterFields]="[
        'patient.full_name',
      ]"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{totalRecords} Patienten"
      [rows]="50"
      [paginator]="true"
      scrollHeight="100%"
      [scrollable]="true"
    >
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th style="min-width: 300px" pSortableColumn="patient.last_name">
            Patient <p-sortIcon field="patient.last_name"></p-sortIcon>
          </th>
          <th pSortableColumn="_37.budget_end_integer">
            §37
            <p-sortIcon field="_37.budget_end_integer"></p-sortIcon>
          </th>
          <th pSortableColumn="_45a.budget_end_integer">
            §45a <p-sortIcon field="_45a.budget_end_integer"></p-sortIcon>
          </th>
          <th pSortableColumn="_45b_OLD.budget_end_integer">
            §45b ALT
            <p-sortIcon field="_45b_OLD.budget_end_integer"></p-sortIcon>
          </th>
          <th pSortableColumn="_45b_CURRENT.budget_end_integer">
            §45b LAUFEND
            <p-sortIcon field="_45b_CURRENT.budget_end_integer"></p-sortIcon>
          </th>
          <th pSortableColumn="_39.budget_end_integer">
            §39 <p-sortIcon field="_39.budget_end_integer"></p-sortIcon>
          </th>
          <th pSortableColumn="_42.budget_end_integer">
            §42 <p-sortIcon field="_42.budget_end_integer"></p-sortIcon>
          </th>
          <th pSortableColumn="Selbstzahler.budget_end_integer">
            Selbstzahler
            <p-sortIcon field="Selbstzahler.budget_end_integer"></p-sortIcon>
          </th>
          <th pSortableColumn="SGB_V.budget_end_integer">
            SGB V <p-sortIcon field="SGB_V.budget_end_integer"></p-sortIcon>
          </th>
          <th pSortableColumn="Unfallkasse.budget_end_integer">
            Unfallkasse
            <p-sortIcon field="Unfallkasse.budget_end_integer"></p-sortIcon>
          </th>
          <th pSortableColumn="Kostenträger.budget_end_integer">
            Kostenträger
            <p-sortIcon field="Kostenträger.budget_end_integer"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr>
          <td style="min-width: 300px">
            <a
              routerLink="/patients/{{ item.patient.id }}/budget"
              target="_blank"
            >
              {{ item.patient.last_name }}, {{ item.patient.first_name }}
            </a>
          </td>
          <td>
            <span *ngIf="item['_37']">{{ item["_37"].budget_end }} €</span>
            <span *ngIf="!item['_37']">-</span>
          </td>
          <td>
            <span *ngIf="item['_45a']">{{ item["_45a"].budget_end }} €</span>
            <span *ngIf="!item['_45a']">-</span>
          </td>
          <td>
            <span *ngIf="item['_45b_OLD']"
              >{{ item["_45b_OLD"].budget_end }} €</span
            >
            <span *ngIf="!item['_45b_OLD']">-</span>
          </td>
          <td>
            <span *ngIf="item['_45b_CURRENT']"
              >{{ item["_45b_CURRENT"].budget_end }} €</span
            >
            <span *ngIf="!item['_45b_CURRENT']">-</span>
          </td>
          <td>
            <span *ngIf="item['_39']">{{ item["_39"].budget_end }} €</span>
            <span *ngIf="!item['_39']">-</span>
          </td>
          <td>
            <span *ngIf="item['_42']">{{ item["_42"].budget_end }} €</span>
            <span *ngIf="!item['_42']">-</span>
          </td>
          <td>
            <span *ngIf="item['Selbstzahler']"
              >{{ item["Selbstzahler"].budget_end }} €</span
            >
            <span *ngIf="!item['Selbstzahler']">-</span>
          </td>
          <td>
            <span *ngIf="item['SGB_V']">{{ item["SGB_V"].budget_end }} €</span>
            <span *ngIf="!item['SGB_V']">-</span>
          </td>
          <td>
            <span *ngIf="item['Unfallkasse']"
              >{{ item["Unfallkasse"].budget_end }} €</span
            >
            <span *ngIf="!item['Unfallkasse']">-</span>
          </td>
          <td>
            <span *ngIf="item['Kostenträger']"
              >{{ item["Kostenträger"].budget_end }} €</span
            >
            <span *ngIf="!item['Kostenträger']">-</span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
