<form #form="ngForm" class="dialog-form">
  <div class="dialog-form-body">
    <div class="p-fluid p-formgrid p-grid without-border">
      <div class="p-field p-col-12">
        <label for="car_id">Stellenumfang *</label>
        <p-dropdown
          name="job_scope"
          inputId="job_scope"
          placeholder="Bitte auswählen"
          [(ngModel)]="values.job_scope"
          [options]="dependencies.job_scopes"
          scrollHeight="300px"
          [required]="true"
        ></p-dropdown>
      </div>

      <div class="p-field p-col-12">
        <label for="salary">Gehalt *</label>
        <p-dropdown
          name="salary"
          inputId="salary"
          placeholder="Bitte auswählen"
          [(ngModel)]="values.salary"
          [options]="dependencies.salaries"
          scrollHeight="300px"
          #salary="ngModel"
          [required]="true"
          [class.p-invalid]="salary.invalid && (salary.dirty || salary.touched)"
        ></p-dropdown>
        <div
          *ngIf="salary.invalid && (salary.dirty || salary.touched)"
          class="p-invalid"
        >
          <div *ngIf="salary.errors?.required">
            <small>Bitte geben Sie ein Gehalt ein</small>
          </div>
        </div>
      </div>

      <div class="p-field p-col-6">
        <label for="month">Ab dem Monat *</label>
        <p-dropdown
          appendTo="body"
          scrollHeight="500px"
          inputId="month"
          name="month"
          [options]="monthOptions"
          [(ngModel)]="values.month"
        ></p-dropdown>
      </div>

      <div class="p-field p-col-6">
        <label for="year">Jahr *</label>
        <p-dropdown
          inputId="year"
          name="year"
          [options]="yearOptions"
          [(ngModel)]="values.year"
        ></p-dropdown>
      </div>
    </div>

    <div class="p-field">
      <label for="comment">Bemerkung *</label>
      <textarea
        id="comment"
        name="comment"
        [(ngModel)]="values.comment"
        [rows]="3"
        pInputTextarea
        [required]="true"
        #comment="ngModel"
        [class.p-invalid]="
          comment.invalid && (comment.dirty || comment.touched)
        "
      ></textarea>
    </div>

    <div class="p-field">
      <div class="p-field-checkbox">
        <p-checkbox
          label="Mit Anlage zum Arbeitsvertrag vom:"
          name="document_appendix"
          [binary]="true"
          [(ngModel)]="values.document_appendix"
        ></p-checkbox>

        <div
          style="display: flex; margin-left: auto"
          *ngIf="values.document_appendix"
        >
          <p-calendar
            [firstDayOfWeek]="1"
            [showWeek]="true"
            inputId="date_contract"
            name="date_contract"
            placeholder="Datum eintragen"
            dateFormat="dd.mm.yy"
            appendTo="body"
            [(ngModel)]="values.date_contract"
            dataType="string"
            [showButtonBar]="true"
            [required]="true"
            #date_contract="ngModel"
            [class.p-invalid]="
              date_contract.invalid &&
              (date_contract.dirty || date_contract.touched)
            "
          ></p-calendar>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-form-footer">
    <i></i>
    <!--    <i-->
    <!--      class="pi pi-trash"-->
    <!--      *ngIf="data.isEdit"-->
    <!--      [class.pi-trash]="!submittedDelete"-->
    <!--      [class.pi-spin]="submittedDelete"-->
    <!--      [class.pi-spinner]="submittedDelete"-->
    <!--      tooltipPosition="left"-->
    <!--      tooltipZIndex="20000"-->
    <!--      [pTooltip]="submittedDelete ? 'Wird gelöscht' : 'Verknüpfung löschen'"-->
    <!--      (click)="remove()"-->
    <!--    ></i>-->

    <button
      (click)="store()"
      pButton
      [label]="data.isEdit ? 'Speichern' : 'Hinzufügen'"
      type="button"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>
