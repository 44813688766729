import { Component, OnDestroy, OnInit } from '@angular/core'
import { TitleService } from '../../../services/title.service'

@Component({
  selector: 'app-user-customer-data',
  templateUrl: './user-customer-data.component.html',
  styleUrls: ['./user-customer-data.component.scss'],
})
export class UserCustomerDataComponent implements OnInit, OnDestroy {
  constructor(private titleService: TitleService) {}

  ngOnInit(): void {
    this.titleService.setTitle(`Meine Daten`)
  }

  ngOnDestroy(): void {}
}
