import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { StatusCodes } from 'http-status-codes'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NgForm } from '@angular/forms'
import { Subscription } from 'rxjs'
import { SearchService } from '../../../services/search.service'
import { PatientService } from '../../../services/patient.service'
import { ConfirmationService } from 'primeng/api'
import { HelperService } from '../../../services/helper.service'
import { ApprovePlanningService } from '../../../services/approve-planning.service'

@Component({
  selector: 'app-caregiver-approve-planning-dialog',
  templateUrl: './caregiver-approve-planning-dialog.component.html',
})
export class CaregiverApprovePlanningDialogComponent
  implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null
  private isDirty = false

  public patients: any = []

  public isEdit = false

  public data: any = {}
  public values = {
    id: null,
    comment: '',
    month: '',
    year: '',
  }
  public monthOptions: any = []
  public yearOptions = [
    { label: '2019', value: '2019' },
    { label: '2020', value: '2020' },
    { label: '2021', value: '2021' },
    { label: '2022', value: '2022' },
    { label: '2023', value: '2023' },
    { label: '2024', value: '2024' },
    { label: '2025', value: '2025' },
  ]

  public submitted = false
  public submittedDelete = false

  constructor(
    private ref: DynamicDialogRef,
    private searchService: SearchService,
    private config: DynamicDialogConfig,
    private approvePlanningService: ApprovePlanningService,
    private eventbus: EventBusService,
    private helperService: HelperService,
    private toastService: ToastService,
    private patientService: PatientService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.data = this.config.data

    const date = new Date()
    let currentYear = date.getFullYear()
    let nextMonth = date.getMonth() + 1

    // Wenn der aktuelle Monat einen index von 11 hatte (also bereits Dezember),
    // müssen wir den nächsten Monat auf Januar (also index 0) setzen und das Jahr erhöhen.
    if (nextMonth === 12) {
      nextMonth = 0
      currentYear++
    }

    this.helperService.dependencies$.subscribe((data: any) => {
      this.monthOptions = data.months
      this.values.month = this.monthOptions[nextMonth].label
    })

    this.values.year = currentYear.toString()

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })

    this.loadApproval()
  }

  public loadApproval(): void {
    this.isEdit = false
    this.values.id = null
    this.values.comment = ''

    this.approvePlanningService
      .load(this.data.caregiver.id, this.values)
      .subscribe((response: any) => {
        if (response) {
          this.values.comment = response.comment
          this.values.id = response.id

          this.isEdit = true
        }
      })
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  public store(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.submitted = true

    const subscription = this.isEdit
      ? this.approvePlanningService.update(this.values.id, this.values.comment)
      : this.approvePlanningService.store(this.data.caregiver.id, this.values)

    subscription.subscribe(
      () => {
        this.submitted = false
        this.eventbus.emit(GlobalEvent.CaregiverDetailReload)
        this.ref.close()
        this.toastService.success(
          'Planung gespeichert',
          'Die Planung wurde erfolgreich gespeichert'
        )
      },
      (error: HttpErrorResponse) => {
        if (error.status === StatusCodes.UNPROCESSABLE_ENTITY) {
          this.toastService.error('Bitte füllen Sie alle Pflichtfelder aus')
        } else {
          this.toastService.error(
            'Etwas ist schief gelaufen...',
            'Bitte wenden Sie sich an den Support'
          )
        }
        this.submitted = false
      }
    )
  }

  public remove(event: any): void {
    this.confirmationService.confirm({
      target: event.target ?? undefined,
      message: 'Freigabe wirklich zurückziehen?',
      accept: () => {
        this.submittedDelete = true

        this.approvePlanningService.delete(this.values.id).subscribe(
          () => {
            this.submittedDelete = false
            this.eventbus.emit(GlobalEvent.CaregiverDetailReload)
            this.ref.close()
            this.toastService.success(
              'Freigabe zurückgezogen',
              'Die Freigabe wurde erfolgreich zurückgezogen'
            )
          },
          () => {
            this.submittedDelete = false
            this.toastService.error(
              'Löschen fehlgeschlagen',
              'Der Eintrag konnte nicht gelöscht werden'
            )
          }
        )
      },
      reject: () => {},
    })
  }
}
