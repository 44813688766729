import { Component, OnDestroy, OnInit } from '@angular/core'
import { CaregiverService } from '../../../../services/caregivers.service'
import { ActivatedRoute, ParamMap } from '@angular/router'
import { DialogService } from 'primeng/dynamicdialog'
import { EventBusService } from '../../../../services/eventbus.service'
import { Subject, Subscription } from 'rxjs'
import { HelperService } from '../../../../services/helper.service'
import { CaregiverPlanningService } from '../../../../services/caregiver-planning.service'
import * as dayjs from 'dayjs'
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import * as utc from 'dayjs/plugin/utc'
import { CaregiverPlanningDialogComponent } from '../../../../components/dialogs/caregiver-planning-dialog/caregiver-planning-dialog.component'
import { switchMap } from 'rxjs/operators'
import { CapacityResponseModel } from '../../../../models/capacity/capacity.model'

@Component({
  selector: 'app-caregivers-detail-planning',
  templateUrl: './caregivers-detail-planning.component.html',
  styleUrls: ['./caregivers-detail-planning.component.scss'],
  providers: [DialogService],
})
export class CaregiversDetailPlanningComponent implements OnInit, OnDestroy {
  public loading = true
  public contentLoading = true

  public showTest = false

  private changedIds: any[] = []
  public hasCopiedAppointment = false

  public year = ''
  public month = ''
  public currentHover = ''
  public currentTimeHover = ''

  public driveTimeForDayIsLoading = ''
  public driveTimeLoadedTime = ''
  public currentHighlightDay = ''
  public currentHighlightTime = ''

  public selectedYear = 0
  public selectedMonth = 0

  public statistics: any = {}
  public data: any
  public caregiverId: string | null = null
  public globalFilterValue = ''

  private routeSubscription: any = null
  private eventBusSubscription: Subscription = new Subscription()

  private channel: any = null
  private contentTimeout: any = null
  private driveTimeTimeout: any = null

  private loadTrigger$ = new Subject<void>()
  private subscription: Subscription = new Subscription()

  public monthOptions: any = []
  public yearOptions = [
    { label: '2021', value: '2021' },
    { label: '2022', value: '2022' },
    { label: '2023', value: '2023' },
    { label: '2024', value: '2024' },
    { label: '2025', value: '2025' },
  ]

  constructor(
    public caregiverService: CaregiverService,
    private route: ActivatedRoute,
    private caregiverPlanningService: CaregiverPlanningService,
    private helperService: HelperService,
    private eventbus: EventBusService,
    private dialogService: DialogService
  ) {
    dayjs.locale('de')
    dayjs.extend(customParseFormat)
    dayjs.extend(utc)
  }

  ngOnInit(): void {
    this.activateDataLoading()

    const date = new Date()
    this.selectedYear = date.getFullYear()
    this.selectedMonth = date.getMonth()

    this.helperService.dependencies$.subscribe((data: any) => {
      this.monthOptions = data.months
      this.month = this.monthOptions[this.selectedMonth].label
      this.year = this.selectedYear.toString()
    })

    this.channel = new BroadcastChannel('caregiver-appointment-changed')
    this.channel.onmessage = (event: any) => {
      const data = event.data.data.data

      if (data.month != this.month || data.year != this.year) {
        this.loadAmountPatients()
      }
    }

    if (this.route.parent) {
      this.routeSubscription = this.route.parent.paramMap.subscribe(
        (params: ParamMap) => {
          this.caregiverId = params.get('id')

          this.loadData()
        }
      )
    }

    this.listenForEventbus()
  }

  dragStart(data: any, item: any) {
    const object = {
      id: data.data.id,
      time: data.data.real_from_h,
      day: item.day,
      date: data.data.real_from,
    }

    localStorage.setItem('drag-item', JSON.stringify(object))
  }

  dragEnter(event: any, date: string, time: string): void {
    this.driveTimeForDayIsLoading = ''
    this.driveTimeLoadedTime = ''

    clearTimeout(this.driveTimeTimeout)
    this.driveTimeTimeout = null

    this.driveTimeTimeout = setTimeout(() => {
      this.getDriveTime(date, time)
    }, 1500)

    this.currentHover = date
    this.currentTimeHover = time.substring(0, 2)
  }

  dragEnd(): void {
    clearTimeout(this.driveTimeTimeout)
    this.driveTimeTimeout = null

    this.driveTimeForDayIsLoading = ''
    this.driveTimeLoadedTime = ''
    this.currentHover = ''
    this.currentTimeHover = ''
  }

  onDrop(event: any, time: string, date: string): void {
    event.preventDefault()

    clearTimeout(this.driveTimeTimeout)
    this.driveTimeTimeout = null

    this.currentHover = ''
    this.currentTimeHover = ''

    this.currentHighlightDay = ''
    this.currentHighlightTime = ''

    const object = localStorage.getItem('drag-item')

    if (object) {
      const data = JSON.parse(object)

      const month = dayjs(data.date).month()
      const year = dayjs(data.date).year()

      let type = 'COPY'

      // Falls der Einsatz innerhalb des gleichen Monats verschoben wird,
      // soll er in den Bearbeitungsmodus, anstatt den Kopiermodus.
      if (month == this.selectedMonth && year == this.selectedYear) {
        type = 'EDIT'
      }

      this.openCaregiverPlanningDialog(data.id, type, time, date)
    }
  }

  contentLeaved(): void {
    this.driveTimeForDayIsLoading = ''
    this.driveTimeLoadedTime = ''

    clearTimeout(this.contentTimeout)
    this.contentTimeout = null

    this.contentTimeout = setTimeout(() => {
      this.currentHighlightDay = ''
      this.currentHighlightTime = ''
    }, 2000)
  }

  contentEntered(): void {
    const object = localStorage.getItem('drag-item')

    if (object) {
      const data = JSON.parse(object)

      const month = dayjs(data.date).month()
      const year = dayjs(data.date).year()

      // Die Platzhalter sollen für den aktuellen Monat nicht angezeigt werden.
      if (month == this.selectedMonth && year == this.selectedYear) {
        return
      }

      clearTimeout(this.contentTimeout)
      this.contentTimeout = null

      this.currentHighlightDay = data.day
      this.currentHighlightTime = data.time
    }
  }

  onDrag(event: any) {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
    this.eventBusSubscription.unsubscribe()
    this.channel && this.channel.close()
    this.routeSubscription && this.routeSubscription.unsubscribe()
  }

  private listenForEventbus(): void {
    // this.eventBusSubscription = this.eventbus.subject.subscribe(
    //   (event: GlobalEvent) => {
    //     switch (event) {
    //       case GlobalEvent.VetoChanged:
    //         this.loadVetos(false)
    //     }
    //   }
    // )
  }

  /**
   * Springt zum letzten Monat.
   */
  public goToPreviousMonth(): void {
    if (this.selectedMonth === 0) {
      const lastYear = this.selectedYear - 1

      const hasYearOption = this.yearOptions.find(
        (data: any) => data.value == lastYear
      )

      if (hasYearOption) {
        this.selectedYear = lastYear
        this.selectedMonth = 11
      }
    } else {
      this.selectedMonth--
    }

    this.year = this.selectedYear.toString()
    this.month = this.monthOptions[this.selectedMonth].label

    this.loadData(false)
  }

  /**
   * Springt zum nächsten Monat.
   */
  public goToNextMonth(): void {
    if (this.selectedMonth === 11) {
      const nextYear = this.selectedYear + 1

      const hasYearOption = this.yearOptions.find(
        (data: any) => data.value == nextYear
      )

      if (hasYearOption) {
        this.selectedYear = nextYear
        this.selectedMonth = 0
      }
    } else {
      this.selectedMonth++
    }

    this.year = this.selectedYear.toString()
    this.month = this.monthOptions[this.selectedMonth].label

    this.loadData(false)
  }

  public copyAppointment(id: any): void {
    this.changedIds = [id]

    localStorage.setItem('copied-appointment-id', JSON.stringify(id))

    this.playBounce()
  }

  public checkForCopiedAppointment(): void {
    this.hasCopiedAppointment =
      localStorage.getItem('copied-appointment-id') !== null
  }

  public openCopyDialog(date: string): void {
    const id = localStorage.getItem('copied-appointment-id')

    this.openCaregiverPlanningDialog(id, 'COPY', '', date)
  }

  public openCaregiverPlanningDialog(
    id: any,
    type: string,
    newTime = '',
    newDate = ''
  ): void {
    if (id && type !== 'COPY') {
      this.changedIds = [id]
    }

    let header = 'Neuer Einsatz'

    if (type === 'EDIT') {
      header = 'Einsatz bearbeiten'
    } else if (type === 'COPY') {
      header = 'Neuer Einsatz aus Kopie'
    }

    const ref = this.dialogService.open(CaregiverPlanningDialogComponent, {
      header,
      width: '520px',
      styleClass: 'dialog-container',
      data: {
        year: this.year,
        month: this.month,
        type,
        id,
        newTime,
        newDate,
        caregiverId: this.caregiverId,
      },
    })

    setTimeout(() => {
      this.driveTimeForDayIsLoading = ''
      this.driveTimeLoadedTime = ''
    }, 1000)

    ref.onClose.subscribe((response: any) => {
      if (response) {
        if (response.length) {
          this.changedIds = response
        }

        this.loadData(false, false)
      }
    })
  }

  private activateDataLoading(): void {
    this.subscription.add(
      this.loadTrigger$
        .pipe(
          switchMap(() =>
            this.caregiverPlanningService.load(
              this.caregiverId,
              this.month,
              this.year
            )
          )
        )
        .subscribe((response: any) => {
          this.data = response

          this.contentLoading = false
          this.loading = false

          // Nachladen von einigen Statistiken.
          this.caregiverPlanningService
            .loadAnalysesForMonth(this.caregiverId, this.month, this.year)
            .subscribe((data: any) => {
              this.statistics = data
            })

          this.playBounce()
        })
    )
  }

  public loadData(withLoading = true, withContentLoading = true): void {
    const foundMonth = this.monthOptions.findIndex((month: any) => {
      return month.label === this.month
    })

    if (foundMonth != null) {
      this.selectedMonth = foundMonth
    }

    this.selectedYear = +this.year

    if (withLoading) {
      this.loading = true
    }

    if (withContentLoading) {
      this.contentLoading = true
    }

    this.statistics = {}

    this.loadTrigger$.next()
  }

  private loadAmountPatients(): void {
    this.caregiverPlanningService
      .load(this.caregiverId, this.month, this.year)
      .subscribe((data: any) => {
        this.data.amount_patients = data.amount_patients
      })
  }

  private getDriveTime(date: any, time: any): void {
    this.driveTimeLoadedTime = ''
    this.driveTimeForDayIsLoading = date

    const object = localStorage.getItem('drag-item')

    if (object) {
      const data = JSON.parse(object)

      this.caregiverPlanningService
        .getDriveTimeForDay(this.caregiverId, data.id, time, date)
        .subscribe((response: any) => {
          this.driveTimeLoadedTime = response
        })
    }
  }

  private playBounce(): void {
    if (this.changedIds.length) {
      setTimeout(() => {
        this.changedIds.forEach((changedId: any) => {
          const element = document.body.querySelector(
            `.times-wrapper[data-id="${changedId}"]`
          )

          if (element) {
            // @ts-ignore
            element.classList.add('gelatine')

            setTimeout(() => {
              // @ts-ignore
              element.classList.remove('gelatine')
            }, 1000)
          }
        })

        this.changedIds = []
      }, 0)
    }
  }
}
