<div class="content-actions">
  <h2>
    <i class="tio">star</i>
    Kundenanfragen
  </h2>
</div>

<div class="content">
  <header class="content-header">
    <div class="content-header-menu-tabs">
      <a
        routerLink="/customer-requests"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        Erstellt
      </a>

      <a
        routerLink="/customer-requests/edited"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        In Bearbeitung
      </a>

      <a
        routerLink="/customer-requests/finished"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        Erledigt
      </a>
    </div>

    <!--    <div class="content-header-buttons">-->
    <!--      <p-checkbox-->
    <!--        label="BK-Wünsche"-->
    <!--        value="DATE_REQUEST"-->
    <!--        name="DATE_REQUEST"-->
    <!--        [(ngModel)]="filters"-->
    <!--        (onChange)="loadFeedbacks()"-->
    <!--      ></p-checkbox>-->
    <!--      <p-checkbox-->
    <!--        label="Feedback"-->
    <!--        value="FEEDBACK"-->
    <!--        name="FEEDBACK"-->
    <!--        [(ngModel)]="filters"-->
    <!--        (onChange)="loadFeedbacks()"-->
    <!--      ></p-checkbox>-->
    <!--      <p-checkbox-->
    <!--        label="Dauer"-->
    <!--        value="TIME_CHANGE"-->
    <!--        name="TIME_CHANGE"-->
    <!--        [(ngModel)]="filters"-->
    <!--        (onChange)="loadFeedbacks()"-->
    <!--      ></p-checkbox>-->
    <!--      <p-checkbox-->
    <!--        label="Fahrzeit"-->
    <!--        value="DRIVE_TIME_FEEDBACK"-->
    <!--        name="DRIVE_TIME_FEEDBACK"-->
    <!--        [(ngModel)]="filters"-->
    <!--        (onChange)="loadFeedbacks()"-->
    <!--      ></p-checkbox>-->
    <!--      <p-checkbox-->
    <!--        label="KM-Fahrt"-->
    <!--        value="TRIP"-->
    <!--        name="TRIP"-->
    <!--        [(ngModel)]="filters"-->
    <!--        (onChange)="loadFeedbacks()"-->
    <!--      ></p-checkbox>-->

    <!--      <div class="table-search-container">-->
    <!--        <div class="p-input-icon-left">-->
    <!--          <i class="pi pi-search"></i>-->
    <!--          <input-->
    <!--            pInputText-->
    <!--            (input)="loading = true; filterTableGlobal()"-->
    <!--            placeholder="Globale Suche..."-->
    <!--            [(ngModel)]="globalFilterValue"-->
    <!--          />-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
  </header>
</div>

<app-loader *ngIf="globalFilterValue" [loading]="loading"></app-loader>

<!--<div class="content" *ngIf="!loading && globalFilterValue">-->
<!--  <div class="content-body" *ngIf="results.length == 0">-->
<!--    <span class="content-no-message">Kein Feedback gefunden</span>-->
<!--  </div>-->

<!--  <div class="content-body without-padding" *ngIf="results.length > 0">-->
<!--    <p-table [value]="results" [rowHover]="true" [autoLayout]="true">-->
<!--      <ng-template pTemplate="header">-->
<!--        <tr class="table-headline-highlight">-->
<!--          <th style="width: 160px" pSortableColumn="process_status">-->
<!--            Status <p-sortIcon field="process_status"></p-sortIcon>-->
<!--          </th>-->
<!--          <th pSortableColumn="type">-->
<!--            Typ <p-sortIcon field="type"></p-sortIcon>-->
<!--          </th>-->
<!--          <th pSortableColumn="data.caregiver_id">-->
<!--            Betreuungskraft-->
<!--            <p-sortIcon field="data.caregiver_id"></p-sortIcon>-->
<!--          </th>-->
<!--          <th pSortableColumn="data.real_from">-->
<!--            Datum-->
<!--            <p-sortIcon field="data.real_from"></p-sortIcon>-->
<!--          </th>-->
<!--          <th pSortableColumn="data.real_from_h">-->
<!--            Uhrzeit-->
<!--            <p-sortIcon field="data.real_from_h"></p-sortIcon>-->
<!--          </th>-->
<!--          <th style="max-width: 260px" pSortableColumn="data.patient_id">-->
<!--            Patient-->
<!--            <p-sortIcon field="data.patient_id"></p-sortIcon>-->
<!--          </th>-->
<!--          <th>Details</th>-->
<!--          <th pSortableColumn="created_at">-->
<!--            Erstellt am-->
<!--            <p-sortIcon field="created_at"></p-sortIcon>-->
<!--          </th>-->
<!--        </tr>-->
<!--      </ng-template>-->
<!--      <ng-template pTemplate="body" let-item>-->
<!--        <tr class="clickable">-->
<!--          <td (click)="openPlansFeedbackDialog(item)">-->
<!--            <span-->
<!--              *ngIf="item.process_status === 'CREATED'"-->
<!--              class="p-tag p-tag-rounded p-tag-{{ item.process_status }}"-->
<!--              >Erstellt</span-->
<!--            >-->
<!--            <span-->
<!--              *ngIf="item.process_status === 'EDITED'"-->
<!--              class="p-tag p-tag-rounded p-tag-{{ item.process_status }}"-->
<!--              >In Bearbeitung</span-->
<!--            >-->
<!--            <span-->
<!--              *ngIf="item.process_status === 'FINISHED'"-->
<!--              class="p-tag p-tag-rounded p-tag-{{ item.process_status }}"-->
<!--              >Erledigt</span-->
<!--            >-->
<!--          </td>-->
<!--          <td (click)="openPlansFeedbackDialog(item)">-->
<!--            <div-->
<!--              style="display: flex; align-items: center; gap: 10px"-->
<!--              *ngIf="item.type === 'FEEDBACK'"-->
<!--            >-->
<!--              <i class="pi pi-envelope"></i>-->
<!--              Feedback-->
<!--            </div>-->

<!--            <div-->
<!--              style="display: flex; align-items: center; gap: 10px"-->
<!--              *ngIf="item.type === 'TRIP'"-->
<!--            >-->
<!--              <i class="pi pi-car"></i>-->
<!--              KM-Fahrt-->
<!--            </div>-->

<!--            <div-->
<!--              style="display: flex; align-items: center; gap: 10px"-->
<!--              *ngIf="item.type === 'TIME_CHANGE'"-->
<!--            >-->
<!--              <i class="pi pi-clock color-dark" style="font-size: 1rem"></i>-->
<!--              Dauer-->
<!--            </div>-->

<!--            <div-->
<!--              class="text-center-container"-->
<!--              *ngIf="item.type === 'VACATION_REQUEST'"-->
<!--            >-->
<!--              <i class="pi pi-clock color-gray" style="font-size: 1rem"></i>-->
<!--              Urlaub-->
<!--            </div>-->

<!--            <div-->
<!--              class="text-center-container"-->
<!--              *ngIf="item.type === 'FREE_TIME_REQUEST'"-->
<!--            >-->
<!--              <i class="pi pi-clock color-gray" style="font-size: 1rem"></i>-->
<!--              Wunschfrei-->
<!--            </div>-->

<!--            <div-->
<!--              style="display: flex; align-items: center; gap: 10px"-->
<!--              *ngIf="item.type === 'DRIVE_TIME_FEEDBACK'"-->
<!--            >-->
<!--              <i class="pi pi-clock color-dark" style="font-size: 1rem"></i>-->
<!--              Fahrzeit-->
<!--            </div>-->
<!--          </td>-->
<!--          <td>-->
<!--            <a routerLink="/caregivers/{{ item.data.caregiver_id }}/overview"-->
<!--              >{{ item.data.caregiver.first_name }}-->
<!--              {{ item.data.caregiver.last_name }}</a-->
<!--            >-->
<!--          </td>-->

<!--          &lt;!&ndash; Datum &ndash;&gt;-->
<!--          <td-->
<!--            *ngIf="!item.is_date_request"-->
<!--            style="max-width: 150px"-->
<!--            (click)="openPlansFeedbackDialog(item)"-->
<!--          >-->
<!--            {{ item.data.real_from | date: "dd.MM.y" }} ({{-->
<!--              item.data.weekday[0]-->
<!--            }}{{ item.data.weekday[1] }})-->
<!--          </td>-->

<!--          &lt;!&ndash; Datum Abwesenheitsanfragen &ndash;&gt;-->
<!--          <td-->
<!--            *ngIf="item.is_date_request"-->
<!--            style="max-width: 150px"-->
<!--            (click)="openPlansFeedbackDialog(item)"-->
<!--          >-->
<!--            {{ item.data.from | date: "dd.MM.y" }} - -->
<!--            {{ item.data.to | date: "dd.MM.y" }}-->
<!--          </td>-->

<!--          &lt;!&ndash; Uhrzeit &ndash;&gt;-->
<!--          <td-->
<!--            *ngIf="!item.is_date_request"-->
<!--            style="max-width: 150px"-->
<!--            (click)="openPlansFeedbackDialog(item)"-->
<!--          >-->
<!--            {{ item.data.real_from_h }} - {{ item.data.real_to_h }}-->
<!--          </td>-->

<!--          &lt;!&ndash; Uhrzeit Abwesenheitsanfragen &ndash;&gt;-->
<!--          <td-->
<!--            *ngIf="item.is_date_request"-->
<!--            style="max-width: 150px"-->
<!--            (click)="openPlansFeedbackDialog(item)"-->
<!--          >-->
<!--            {{ item.data.from_time }} - {{ item.data.to_time }}-->
<!--          </td>-->

<!--          &lt;!&ndash; Patient &ndash;&gt;-->
<!--          <td-->
<!--            *ngIf="!item.is_date_request"-->
<!--            style="max-width: 260px"-->
<!--            (click)="openPlansFeedbackDialog(item)"-->
<!--          >-->
<!--            <a routerLink="/patients/{{ item.data.patient.id }}"-->
<!--              >{{ item.data.patient.first_name }}-->
<!--              {{ item.data.patient.last_name }}</a-->
<!--            >-->
<!--          </td>-->

<!--          &lt;!&ndash; Patient &ndash;&gt;-->
<!--          <td-->
<!--            *ngIf="item.is_date_request"-->
<!--            style="max-width: 260px"-->
<!--            (click)="openPlansFeedbackDialog(item)"-->
<!--          >-->
<!--            - -->
<!--          </td>-->

<!--          <td (click)="openPlansFeedbackDialog(item)">-->
<!--            <div *ngIf="item.type === 'FEEDBACK'">-->
<!--              Feedback erstellt-->
<!--              <i-->
<!--                class="pi pi-info-circle color-gray"-->
<!--                style="font-size: 15px; margin: 0 0 0 10px"-->
<!--                [pTooltip]="item.tooltip"-->
<!--                tooltipStyleClass="tooltip-wider tooltip-wider-light"-->
<!--                tooltipPosition="left"-->
<!--              ></i>-->
<!--            </div>-->
<!--            &lt;!&ndash; Urlaubswunsch &ndash;&gt;-->
<!--            <div-->
<!--              class="text-center-container"-->
<!--              *ngIf="-->
<!--                item.type === 'VACATION_REQUEST' ||-->
<!--                item.type === 'FREE_TIME_REQUEST'-->
<!--              "-->
<!--            >-->
<!--              {{ item.data.comment || "-" }}-->
<!--            </div>-->
<!--            <div *ngIf="item.type === 'TRIP'">-->
<!--              {{-->
<!--                item.data.caregiver_appointment_trip.mileage-->
<!--                  .toString()-->
<!--                  .replace(".", ",")-->
<!--              }}-->
<!--              KM-->
<!--              <i-->
<!--                class="pi pi-info-circle color-gray"-->
<!--                style="font-size: 15px; margin: 0 0 0 10px"-->
<!--                [pTooltip]="item.tooltip"-->
<!--                tooltipStyleClass="tooltip-wider tooltip-wider-light"-->
<!--                tooltipPosition="left"-->
<!--              ></i>-->
<!--            </div>-->
<!--            &lt;!&ndash; Dauer &ndash;&gt;-->
<!--            <div-->
<!--              style="display: flex; align-items: center"-->
<!--              *ngIf="item.type === 'TIME_CHANGE'"-->
<!--            >-->
<!--              <span *ngIf="item.data.caregiver_appointment_change.time > 0">-->
<!--                +</span-->
<!--              >{{-->
<!--                item.data.caregiver_appointment_change.time-->
<!--                  .toString()-->
<!--                  .replace(".", ",")-->
<!--              }}-->
<!--              Std-->
<!--              <i-->
<!--                class="pi pi-info-circle color-gray"-->
<!--                style="font-size: 15px; margin: 0 0 0 10px"-->
<!--                *ngIf="item.data.caregiver_appointment_change.time_feedback"-->
<!--                [pTooltip]="-->
<!--                  item.data.caregiver_appointment_change.time_feedback-->
<!--                "-->
<!--                tooltipPosition="left"-->
<!--              ></i>-->
<!--            </div>-->
<!--            &lt;!&ndash; Fahrzeit &ndash;&gt;-->
<!--            <div-->
<!--              style="display: flex; align-items: center"-->
<!--              *ngIf="item.type === 'DRIVE_TIME_FEEDBACK'"-->
<!--            >-->
<!--              <span-->
<!--                *ngIf="item.data.caregiver_appointment_change.drive_time > 0"-->
<!--              >-->
<!--                +</span-->
<!--              >{{-->
<!--                item.data.caregiver_appointment_change.drive_time-->
<!--                  .toString()-->
<!--                  .replace(".", ",")-->
<!--              }}-->
<!--              min-->
<!--              <i-->
<!--                class="pi pi-info-circle color-gray"-->
<!--                style="font-size: 15px; margin: 0 0 0 10px"-->
<!--                *ngIf="-->
<!--                  item.data.caregiver_appointment_change.drive_time_feedback-->
<!--                "-->
<!--                [pTooltip]="-->
<!--                  item.data.caregiver_appointment_change.drive_time_feedback-->
<!--                "-->
<!--                tooltipPosition="left"-->
<!--              ></i>-->
<!--            </div>-->
<!--          </td>-->
<!--          <td (click)="openPlansFeedbackDialog(item)">-->
<!--            {{ item.created_at | date: "dd.MM.y, H:mm" }} Uhr-->
<!--          </td>-->
<!--        </tr>-->
<!--      </ng-template>-->
<!--    </p-table>-->
<!--  </div>-->
<!--</div>-->

<ng-container *ngIf="!globalFilterValue">
  <router-outlet></router-outlet>
</ng-container>
