import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { Observable } from 'rxjs'
import { PhoneCallsListItemModel } from '../models/phone-call/phone-calls-list.model'
import { CaregiverProofGroupedModel } from '../models/caregiver/caregiver-proof.model'
import { CaregiverDetailGeneralDataModel } from '../models/caregiver/caregiver-detail-general.model'
import {
  CaregiverDetailDocumentsModel,
  CaregiverDocumentTypesModel,
} from '../models/caregiver/caregiver-detail-documents.model'
import {
  DocumentModel,
  DocumentModelSimple,
} from '../models/document/document.model'
import { CaregiverHospitationFeedbackModel } from '../models/caregiver/caregiver-hospitation-feedback.model'

@Injectable({
  providedIn: 'root',
})
export class UserCustomerService {
  constructor(private http: HttpClient) {}

  public loadDocuments(): Observable<DocumentModelSimple[]> {
    return this.http.get<DocumentModelSimple[]>(
      `${environment.api}/user-customer/documents`
    )
  }

  public loadRequests(): Observable<DocumentModelSimple[]> {
    return this.http.get<DocumentModelSimple[]>(
      `${environment.api}/user-customer/requests`
    )
  }

  public updateDateRequest(requestId: any, values: any): Observable<[]> {
    return this.http.put<any>(
      `${environment.api}/user-customer/request-date/${requestId}`,
      {
        ...values,
      }
    )
  }

  public storeDateRequest(values: any): Observable<[]> {
    return this.http.post<any>(
      `${environment.api}/user-customer/request-date`,
      {
        ...values,
      }
    )
  }

  public loadData(): Observable<any> {
    return this.http.get<any>(`${environment.api}/user-customer/data`)
  }

  public getDownloadProofDocumentLink(uuid: string): string {
    return `${environment.api}/user-caregiver/download-proof/${uuid}`
  }

  public getDownloadDocumentLink(uuid: string): string {
    return `${environment.api}/user-customer/download/${uuid}`
  }

  public getDownloadApplicationDocumentLink(uuid: string): string {
    return `${environment.api}/user-caregiver/download-application-document/${uuid}`
  }

  public getZipOfProofsLink(month: string, year: string): string {
    return `${environment.api}/user-caregiver/download-zip-of-proofs?month=${month}&year=${year}`
  }
}
