import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { EventBusService, GlobalEvent } from 'src/app/services/eventbus.service'
import { ToastService } from 'src/app/services/toast.service'
import { NgForm } from '@angular/forms'
import { Subscription } from 'rxjs'
import { UserSystemSimpleModel } from '../../../models/user/user-system.model'
import { UserSystemService } from '../../../services/user-system.service'
import { AuthService } from '../../../services/auth.service'
import { CaregiverStatusModel } from '../../../models/caregiver/caregiver-status.model'
import { HelperService } from '../../../services/helper.service'
import { CaregiverService } from '../../../services/caregivers.service'
import { CaregiverDetailModel } from '../../../models/caregiver/caregiver-detail.model'
import { CaregiverDependenciesModel } from '../../../models/caregiver/caregiver-dependencies.model'
import { CaregiverFormModel } from '../../../models/caregiver/caregiver-form.model'
import * as dayjs from 'dayjs'
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import * as utc from 'dayjs/plugin/utc'

@Component({
  selector: 'app-change-status-caregiver-dialog',
  templateUrl: './change-status-caregiver-dialog.component.html',
})
export class ChangeStatusCaregiverDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  public dependencies: CaregiverDependenciesModel = new CaregiverDependenciesModel()
  public caregiver: CaregiverDetailModel = new CaregiverDetailModel()
  private formSubscription: Subscription | null | undefined = null
  private isDirty = false
  public isEdit = false
  public appointments: any[] = []

  submitted = false
  submittedDelete = false

  public canceledTypes = []
  public userSystemOptions: any[] = []
  public activeCaregiverOptions: any[] = []
  public values: CaregiverStatusModel = new CaregiverStatusModel()

  constructor(
    private authService: AuthService,
    private userSystemService: UserSystemService,
    public caregiverService: CaregiverService,
    private ref: DynamicDialogRef,
    private helperService: HelperService,
    private config: DynamicDialogConfig,
    private eventbus: EventBusService,
    private toastService: ToastService
  ) {
    dayjs.locale('de')
    dayjs.extend(customParseFormat)
    dayjs.extend(utc)
  }

  public ngOnInit(): void {
    this.caregiver = this.config.data.caregiver
    this.isEdit = this.config.data.isEdit

    this.formSubscription = this.form.valueChanges?.subscribe(() => {
      if (!this.form.pristine) {
        this.isDirty = true
      }
    })

    this.loadDependencies()
    this.loadAllUserSystem()

    this.helperService.dependencies$.subscribe((data: any) => {
      this.canceledTypes = data['canceled_types']

      this.activeCaregiverOptions = data['caregivers_active'].filter(
        (caregiver: any) => {
          return caregiver.is_mentor
        }
      )
    })

    // Wenn der aktuelle Status noch auf Hospitation steht, soll
    // das "Beginn möglich am" als Platzhalter genommen werden.
    if (
      this.caregiver.current_status.status === 'HOSPITATION' &&
      !this.isEdit
    ) {
      this.caregiver.current_employment.employed_since_date_string =
        this.caregiver.current_employment.employed_since_date_string ||
        this.caregiver.employment_possible_at_string ||
        ''
    }
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  public dayEmployedUntilSelected(event: any): void {
    this.loadCaregiverAppointments()
  }

  private loadCaregiverAppointments(): void {
    this.appointments = []

    this.caregiverService
      .loadPatientsSinceDate(
        this.caregiver.id,
        this.values.employed_until_string
      )
      .subscribe((response: any[]) => {
        this.appointments = response
      })
  }

  /**
   * Lädt alle Abhängigkeiten (Erfahrungen, Autos, etc) aus der Datenbank.
   */
  public loadDependencies(): void {
    this.caregiverService
      .dependencies()
      .subscribe((dependencies: CaregiverDependenciesModel) => {
        this.dependencies = dependencies
      })
  }

  /**
   * Sobald das Häkchen bei "Befristet" entfernt wird, soll auch
   * das Datum bei "Angestellt bis" entfernt werden.
   * @param event
   */
  public employedTemporaryChanged(event: any): void {
    if (!this.caregiver.current_employment.employed_temporary) {
      this.caregiver.current_employment.employed_until_date_string = ''
    }
  }

  /**
   * Sobald ein Datum in "Angestellt bis" eingetragen wird, soll das "Befristet Häkchen" auch gesetzt werden.
   * @param event
   */
  public untilDateChanged(event: any): void {
    if (this.caregiver.current_employment.employed_until_date_string) {
      this.caregiver.current_employment.employed_temporary = true
    }
  }

  private loadAllUserSystem(): void {
    this.userSystemService
      .listActiveSimple()
      .subscribe((data: UserSystemSimpleModel[]) => {
        for (let userSystem of data) {
          this.userSystemOptions.push({
            id: userSystem.id,
            full_name: userSystem.first_name + ' ' + userSystem.last_name,
          })
        }

        // Der aktuell eingeloggte Benutzer soll automatisch ausgewählt werden.
        const user = this.authService.getUser()
        const id = user?.id || null
        this.values.canceled_from_user_id = id
        this.values.hospitation_coordination_user_id = id

        if (this.isEdit) {
          this.values = this.config.data.caregiver_status
        }
      })
  }

  public save(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    // const dateFrom = dayjs(this.values.from, 'DD.MM.YYYY')
    // const dateTo = dayjs(this.values.to, 'DD.MM.YYYY')
    //
    // if (dateFrom.isAfter(dateTo)) {
    //   alert('"Tag von" darf nicht größer als "Tag bis" sein')
    //   return
    // }

    this.submitted = true

    const subscription = this.isEdit
      ? this.caregiverService.changeStatusEdit(this.values.id, this.values)
      : this.caregiverService.changeStatus(this.caregiver, this.values)

    subscription.subscribe(
      () => {
        this.submitted = false
        this.toastService.success(
          'Status geändert',
          'Der Status wurde erfolgreich geändert'
        )

        location.reload()
      },
      () => {
        this.toastService.error(
          'Etwas ist schiefgelaufen...',
          'Bitte wenden Sie sich an den Support'
        )

        this.submitted = false
      }
    )
  }
}
