import { Component, OnDestroy, OnInit } from '@angular/core'
import { ToastService } from '../../../services/toast.service'
import { DocumentModelSimple } from '../../../models/document/document.model'
import { MediaModel } from '../../../models/document/media.model'
import { TitleService } from '../../../services/title.service'
import { UserCustomerService } from '../../../services/user-customer.service'

@Component({
  selector: 'app-user-caregiver-overview',
  templateUrl: './user-customer-overview.component.html',
})
export class UserCustomerOverviewComponent implements OnInit, OnDestroy {
  public loading = true

  public documents: DocumentModelSimple[] = []
  public documentNames: string[] = []

  constructor(
    public userCustomerService: UserCustomerService,
    private toastService: ToastService,
    private titleService: TitleService
  ) {}

  ngOnInit(): void {
    document.body.classList.remove('mobile-nav-visible')
    document.body.classList.add('customer-login-view')

    this.titleService.setTitle(`Übersicht`)

    this.loadDocuments()
  }

  ngOnDestroy(): void {
    document.body.classList.remove('customer-login-view')
  }

  public openDownloadTab(document: MediaModel): void {
    window.open(this.userCustomerService.getDownloadDocumentLink(document.uuid))
  }

  public loadDocuments(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    this.userCustomerService
      .loadDocuments()
      .subscribe((data: DocumentModelSimple[]) => {
        this.documents = data
        this.documentNames = Object.keys(data)

        this.loading = false
      })
  }
}
