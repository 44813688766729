<div class="content" *ngIf="!loading">
  <header class="content-header">
    <div class="content-header-patients-tabs">
      <a
        href="/patients/{{ customer.first_patient.id }}/budget"
        [class.active]="selectedPatient === 'first_patient'"
      >
        <i
          pTooltip="Terminversand gesperrt"
          class="pi pi-lock color-alert"
          *ngIf="customer.first_patient.appointment_lock"
        ></i>
        <i
          class="light-symbol light-symbol-blue"
          *ngIf="customer.first_patient.from_campaign"
          pTooltip="Aus Kampagne"
        ></i>
        <i class="pi pi-user"></i>
        {{ customer.first_patient.full_name }}
        <i
          *ngIf="customer.first_patient.status"
          class="p-tag p-tag-rounded p-tag-{{ customer.first_patient.status }}"
          >{{ customer.first_patient.status_string }}</i
        >
        <i
          *ngIf="customer.first_patient.is_paused"
          class="p-tag p-tag-rounded p-tag-PAUSED"
          >Pausiert</i
        >
      </a>
      <a
        href="/patients/{{ customer.second_patient.id }}/budget"
        [class.active]="selectedPatient === 'second_patient'"
        *ngIf="customer.second_patient"
      >
        <i
          pTooltip="Terminversand gesperrt"
          class="pi pi-lock color-alert"
          *ngIf="customer.second_patient.appointment_lock"
        ></i>
        <i class="pi pi-users"></i>
        {{ customer.second_patient.full_name }}

        <i
          *ngIf="customer.second_patient.status"
          class="p-tag p-tag-rounded p-tag-{{ customer.second_patient.status }}"
          >{{ customer.second_patient.status_string }}</i
        >
        <i
          *ngIf="customer.second_patient.is_paused"
          class="p-tag p-tag-rounded p-tag-PAUSED"
          >Pausiert</i
        >
      </a>
    </div>
  </header>
  <header class="content-header">
    <div class="content-header-menu-tabs">
      <a
        routerLink="/patients/{{ patientId }}/budget"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        >Budgets</a
      >
      <a
        routerLink="/patients/{{ patientId }}/budget/appointments"
        routerLinkActive="active"
        >Termine</a
      >
    </div>
  </header>
</div>

<ng-container *ngIf="!loading">
  <router-outlet></router-outlet>
</ng-container>
