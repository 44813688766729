<app-loader [loading]="loading"></app-loader>

<div class="content is-sticky" *ngIf="!loading">
  <header class="content-header">
    <div class="content-header-patients-tabs">
      <a
        href="/patients/{{ patients.first_patient.id }}/documents"
        [class.active]="selectedPatient === 'first_patient'"
      >
        <i
          pTooltip="Terminversand gesperrt"
          class="pi pi-lock color-alert"
          *ngIf="patients.first_patient.appointment_lock"
        ></i>
        <i class="pi pi-user"></i>
        {{ patients.first_patient.full_name }}

        <i
          *ngIf="patients.first_patient.status"
          class="p-tag p-tag-rounded p-tag-{{ patients.first_patient.status }}"
          >{{ patients.first_patient.status_string }}</i
        >

        <i
          *ngIf="patients.first_patient.is_paused"
          class="p-tag p-tag-rounded p-tag-PAUSED"
          >Pausiert</i
        >
      </a>
      <a
        href="/patients/{{ patients.second_patient.id }}/documents"
        [class.active]="selectedPatient === 'second_patient'"
        *ngIf="patients.second_patient"
      >
        <i
          pTooltip="Terminversand gesperrt"
          class="pi pi-lock color-alert"
          *ngIf="patients.second_patient.appointment_lock"
        ></i>
        <i class="pi pi-users"></i>
        {{ patients.second_patient.full_name }}

        <i
          *ngIf="patients.second_patient.status"
          class="p-tag p-tag-rounded p-tag-{{ patients.second_patient.status }}"
          >{{ patients.second_patient.status_string }}</i
        >

        <i
          *ngIf="patients.second_patient.is_paused"
          class="p-tag p-tag-rounded p-tag-PAUSED"
          >Pausiert</i
        >
      </a>
    </div>
    <div class="content-header-buttons">
      <p-checkbox
        label="Listenansicht"
        [binary]="true"
        (onChange)="loadDocuments()"
        [(ngModel)]="listView"
        name="list_view"
      ></p-checkbox>

      <p-checkbox
        label="nur archivierte"
        [binary]="true"
        (onChange)="loadDocuments()"
        [(ngModel)]="onlyArchived"
        name="only_archived"
      ></p-checkbox>

      <button
        pButton
        label="Dokument erstellen"
        (click)="openAddDocumentDialog()"
        type="button"
        class="p-button-outlined"
        icon="pi pi-plus"
        iconPos="left"
      ></button>

      <div class="table-search-container">
        <div class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            [disabled]="!listView"
            [pTooltip]="listView ? '' : 'Nur in Listenansicht verfügbar'"
            tooltipStyleClass="tooltip-wider"
            pInputText
            (input)="filterTableGlobal($event)"
            placeholder="Suche..."
            [value]="globalFilterValue"
          />
        </div>
      </div>
    </div>
  </header>
</div>

<div class="content" style="margin: 0" *ngIf="!loading && listView">
  <div class="content-body content-body-full-height-with-tabs without-padding">
    <span
      class="content-no-message with-padding"
      *ngIf="patients[selectedPatient]?.list?.length === 0"
    >
      Keine Dokumente hinterlegt
    </span>

    <p-table
      #dt
      *ngIf="
        patients[selectedPatient]?.list &&
        patients[selectedPatient]?.list?.length
      "
      [value]="patients[selectedPatient]?.list || []"
      [rowHover]="true"
      [paginator]="true"
      [rows]="50"
      scrollHeight="100%"
      [scrollable]="true"
      [globalFilterFields]="[
        'name',
        'custom_properties.created_from_name',
        'type_string'
      ]"
      [(first)]="firstPage"
      stateStorage="local"
      stateKey="state-patients-detail-documents"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{totalRecords} Dokumente"
    >
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th pSortableColumn="name">
            Name <p-sortIcon field="name"></p-sortIcon>
          </th>
          <th style="max-width: 50px" pSortableColumn="approved_for_user">
            <i pTooltip="Für Kunde freigegeben" class="pi pi-user"></i>
            <p-sortIcon field="approved_for_user"></p-sortIcon>
          </th>
          <th style="max-width: 200px" pSortableColumn="type_string">
            Typ <p-sortIcon field="type_string"></p-sortIcon>
          </th>
          <th style="max-width: 200px" pSortableColumn="created_at">
            Erstellt am <p-sortIcon field="created_at"></p-sortIcon>
          </th>
          <th
            style="max-width: 200px"
            pSortableColumn="custom_properties.created_from_name"
          >
            Erstellt von
            <p-sortIcon
              field="custom_properties.created_from_name"
            ></p-sortIcon>
          </th>
          <th style="max-width: 100px"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-document>
        <tr class="clickable">
          <td (click)="openDownloadTab(document)">{{ document.name }}</td>
          <td style="max-width: 50px" (click)="openDownloadTab(document)">
            <i
              *ngIf="document.approved_for_user"
              pTooltip="Für Kunde freigegeben"
              class="pi pi-user color-main1"
            ></i>
          </td>
          <td style="max-width: 200px" (click)="openDownloadTab(document)">
            <strong>{{ document.type_string }}</strong>
          </td>
          <td style="max-width: 200px" (click)="openDownloadTab(document)">
            {{ document.created_at | date: "dd.MM.y, H:mm" }}
          </td>
          <td style="max-width: 200px" (click)="openDownloadTab(document)">
            {{ document.custom_properties.created_from_name || "-" }}
          </td>
          <td class="table-action" style="max-width: 100px">
            <i
              (click)="openAddDocumentDialog(document, document.type_string)"
              class="pi pi-pencil with-margin-right"
              tooltipPosition="left"
              pTooltip="Bearbeiten"
            ></i>

            <a
              [href]="patientService.getDocumentDownloadLink(document.uuid)"
              target="_blank"
            >
              <i
                pTooltip="Herunterladen"
                tooltipPosition="left"
                class="pi pi-download"
              ></i>
            </a>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<div class="content" style="margin: 0" *ngIf="!loading && !listView">
  <ng-container>
    <ng-container *ngFor="let type of documentTypes[selectedPatient]">
      <div class="detail-information-header">
        {{ type.name }}
      </div>
      <div class="content-body without-padding auto-height">
        <span
          class="content-no-message with-padding"
          *ngIf="type.documents.length === 0"
        >
          Keine Dokumente hinterlegt
        </span>

        <p-table
          [value]="type.documents"
          [rowHover]="true"
          *ngIf="type.documents.length > 0"
          [paginator]="type.documents.length > 10"
          [rows]="10"
        >
          <ng-template pTemplate="header">
            <tr>
              <th>Name</th>
              <th style="width: 30px"></th>
              <th style="width: 200px">Erstellt am</th>
              <th style="width: 200px">Erstellt von</th>
              <th style="width: 100px"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-document>
            <tr class="clickable">
              <td (click)="openDownloadTab(document)">{{ document.name }}</td>
              <td (click)="openDownloadTab(document)">
                <i
                  *ngIf="document.approved_for_user"
                  pTooltip="Für Kunde freigegeben"
                  class="pi pi-user color-main1"
                ></i>
              </td>
              <td (click)="openDownloadTab(document)">
                {{ document.created_at | date: "dd.MM.y, H:mm" }}
              </td>
              <td (click)="openDownloadTab(document)">
                {{ document.custom_properties.created_from_name || "-" }}
              </td>
              <td class="table-action" style="width: 100px">
                <i
                  (click)="openAddDocumentDialog(document, type.name)"
                  class="pi pi-pencil with-margin-right"
                  tooltipPosition="left"
                  pTooltip="Bearbeiten"
                ></i>

                <a
                  [href]="patientService.getDocumentDownloadLink(document.uuid)"
                  target="_blank"
                >
                  <i
                    pTooltip="Herunterladen"
                    class="pi pi-download"
                    tooltipPosition="left"
                  ></i>
                </a>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </ng-container>
  </ng-container>
</div>
