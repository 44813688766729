import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { NgForm } from '@angular/forms'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { Subscription } from 'rxjs'
import { HelperService } from '../../../services/helper.service'
import { PlanningService } from '../../../services/planning.service'
import { RequiredNotificationService } from '../../../services/required-notification.service'
import { HttpErrorResponse } from '@angular/common/http'
import { StatusCodes } from 'http-status-codes'
import { ToastService } from '../../../services/toast.service'

@Component({
  selector: 'app-required-notification-dialog',
  templateUrl: './required-notification-dialog.component.html',
})
export class RequiredNotificationDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm

  private formSubscription: Subscription | null | undefined = null

  public data: any = {}
  public submitted = false

  public values = {
    selected_value: null,
  }

  constructor(
    private toastService: ToastService,
    private requiredNotificationService: RequiredNotificationService,
    private config: DynamicDialogConfig
  ) {}

  ngOnInit(): void {
    this.data = this.config.data
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe()
  }

  public save(): void {
    if (!this.form.form.valid) {
      this.submitted = false
      this.form.form.markAllAsTouched()
      return
    }

    this.requiredNotificationService.store(this.data.id, this.values).subscribe(
      () => {
        window.location.reload()
      },
      () => {
        this.toastService.error(
          'Etwas ist schief gelaufen...',
          'Bitte wenden Sie sich an den Support'
        )
      }
    )
  }
}
