import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class SystemMessageInternService {
  constructor(private http: HttpClient) {}

  public store(data: any): Observable<[]> {
    return this.http.post<[]>(`${environment.api}/system-messages-intern`, {
      ...data,
    })
  }

  public loadAll(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.api}/system-messages-intern`)
  }

  public setAsRead(): Observable<[]> {
    return this.http.delete<[]>(`${environment.api}/system-messages-intern`, {})
  }
}
