<app-loader [loading]="loading"></app-loader>

<div class="content new-content-container" style="margin: 0" *ngIf="!loading">
  <header class="content-header">
    <span>Offene Anfragen</span>

    <div
      class="table-search-container"
      [class.searchfield-active]="globalFilterValue != ''"
      *ngIf="data.length > 0"
    >
      <div class="p-input-icon-left">
        <i class="pi pi-search"></i>
        <input
          pInputText
          (input)="filterTableGlobal($event)"
          placeholder="Suche..."
          [(ngModel)]="globalFilterValue"
        />
      </div>
    </div>
  </header>

  <div
    class="content-body"
    [class.content-loading]="contentLoading"
    *ngIf="data.length == 0"
  >
    <span class="content-no-message">Kein Anfragen vorhanden</span>
  </div>

  <div
    class="content-body content-body-full-height-with-tabs without-padding"
    [class.content-loading]="contentLoading"
    *ngIf="data.length > 0"
  >
    <p-table
      #dt
      [value]="data"
      [rowHover]="true"
      [paginator]="true"
      [globalFilterFields]="[
        'customer.first_patient.first_name',
        'customer.first_patient.last_name',
        'date_type_string',
      ]"
      [rows]="50"
      stateStorage="local"
      stateKey="customer-requests-created"
      scrollHeight="100%"
      [(first)]="firstPage"
      [scrollable]="true"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{totalRecords} Anfragen"
    >
      <ng-template pTemplate="header">
        <tr class="table-headline-highlight">
          <th style="max-width: 250px" pSortableColumn="date_type">
            Typ <p-sortIcon field="date_type"></p-sortIcon>
          </th>
          <th style="max-width: 260px" pSortableColumn="data.patient.last_name">
            Patient
            <p-sortIcon field="data.patient.last_name"></p-sortIcon>
          </th>
          <th style="max-width: 150px" pSortableColumn="from">
            Datum
            <p-sortIcon field="from"></p-sortIcon>
          </th>
          <th style="max-width: 150px">Uhrzeit</th>
          <th>Details</th>
          <th style="max-width: 200px" pSortableColumn="created_at">
            Erstellt am
            <p-sortIcon field="created_at"></p-sortIcon>
          </th>
          <th style="max-width: 100px"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr class="clickable">
          <td style="max-width: 250px">
            {{ item.date_type_string }}
          </td>

          <td style="max-width: 260px">
            <a routerLink="/patients/{{ item.customer.first_patient.id }}">
              {{ item.customer.first_patient.last_name }},
              {{ item.customer.first_patient.first_name }}
            </a>
          </td>

          <td style="max-width: 150px" *ngIf="item.from != item.to">
            {{ item.from | date: "dd.MM.y" }} -
            {{ item.to | date: "dd.MM.y" }}
          </td>
          <td style="max-width: 150px" *ngIf="item.from == item.to">
            {{ item.from | date: "dd.MM.y" }}
          </td>

          <td style="max-width: 150px">
            {{ item.from_time }} - {{ item.to_time }}
          </td>
          <td>
            {{ item.comment || "-" }}
          </td>
          <td style="max-width: 200px">
            {{ item.created_at | date: "dd.MM.y, H:mm" }} Uhr
          </td>
          <td class="table-action" style="max-width: 100px">
            <i
              tooltipPosition="left"
              pTooltip="Bearbeiten"
              class="pi pi-pencil"
            ></i>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
