import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { ToastService } from '../../../../../services/toast.service'
import { DialogService } from 'primeng/dynamicdialog'
import {
  EventBusService,
  GlobalEvent,
} from '../../../../../services/eventbus.service'
import { Subscription } from 'rxjs'
import { PatientService } from '../../../../../services/patient.service'
import { ConfirmBudgetDialogComponent } from '../../../../../components/dialogs/confirm-budget-dialog/confirm-budget-dialog.component'
import {
  CustomerDetailBudgetModel,
  PatientBudget,
} from '../../../../../models/customer-patient/patient-detail-budget.model'
import * as dayjs from 'dayjs'
import { LatestChangeModel } from '../../../../../models/history/latest-change.model'
import { ChangeBudgetDialogComponent } from '../../../../../components/dialogs/change-budget-dialog/change-budget-dialog.component'

@Component({
  selector: 'app-patients-detail-budget',
  templateUrl: './patients-detail-budget.component.html',
})
export class PatientsDetailBudgetComponent implements OnInit, OnDestroy {
  public loading = true
  public currentHover = ''
  public year = ''

  private eventBusSubscription: Subscription = new Subscription()
  public patients: CustomerDetailBudgetModel = new CustomerDetailBudgetModel()

  private selectedYear = 0

  public patientId: string | null = null
  public selectedPatient: 'first_patient' | 'second_patient' = 'first_patient'

  public budgetCaregivers = {
    first_patient: new LatestChangeModel(),
    second_patient: new LatestChangeModel(),
  }

  public budgetOverwritten = {
    first_patient: new LatestChangeModel(),
    second_patient: new LatestChangeModel(),
  }

  public yearOptions = [
    { label: '2021', value: '2021' },
    { label: '2022', value: '2022' },
    { label: '2023', value: '2023' },
    { label: '2024', value: '2024' },
    { label: '2025', value: '2025' },
  ]

  constructor(
    private patientService: PatientService,
    private toastService: ToastService,
    private eventbus: EventBusService,
    private dialogService: DialogService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    const date = new Date()
    this.selectedYear = date.getFullYear()
    this.year = this.selectedYear.toString()

    if (this.route.parent?.parent) {
      this.patientId = this.route.parent.parent.snapshot.params.id

      this.loadBudgets()
    }

    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
  }

  public setHover(type: string): void {
    this.currentHover = type
  }

  public changePatient(type: 'first_patient' | 'second_patient'): void {
    this.selectedPatient = type
  }

  public enableBudget(type: string): void {
    const confirm = window.confirm(
      `Budget §${type} für das Jahr ${this.selectedYear} hinzufügen?`
    )

    if (confirm) {
      this.patientService
        .enableBudget(
          this.patients[this.selectedPatient]?.id,
          type,
          this.selectedYear
        )
        .subscribe(() => {
          this.loadBudgets(false)
        })
    }
  }

  public openConfirmDialogDialog(): void {
    this.dialogService.open(ConfirmBudgetDialogComponent, {
      data: {
        type_id: this.patients[this.selectedPatient]?.id,
        type: 'patients',
        year: this.year,
      },
      header: `Budgets für ${this.year} bestätigen`,
      styleClass: 'dialog-container',
      dismissableMask: false,
      width: '450px',
    })
  }

  public openChangeBudgetDialog(
    budget: PatientBudget | undefined,
    type: string,
    date: string
  ): void {
    this.dialogService.open(ChangeBudgetDialogComponent, {
      data: {
        patient_id: this.patients[this.selectedPatient].id,
        budget,
        type,
        date,
      },
      header: `Budget ${type} bearbeiten für ${date}`,
      styleClass: 'dialog-container',
      dismissableMask: false,
      width: '450px',
    })
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.BudgetChanged:
            this.loadBudgets(false)
        }
      }
    )
  }

  public getPersplanCaregivers(budgetDate: string, budgetType: string): void {
    this.patientService
      .getPersplanCaregivers(
        this.patients[this.selectedPatient]?.id,
        budgetDate,
        budgetType
      )
      .subscribe((caregivers: string) => {
        this.budgetCaregivers[this.selectedPatient][
          budgetDate + budgetType
        ] = caregivers
      })
  }

  public getBudgetOverwritten(budgetDate: string, budgetType: string): void {
    this.patientService
      .getBudgetOverwritten(
        this.patients[this.selectedPatient]?.id,
        budgetDate,
        budgetType
      )
      .subscribe((text: string) => {
        this.budgetOverwritten[this.selectedPatient][
          budgetDate + budgetType
        ] = text
      })
  }

  public loadBudgets(withLoading: boolean = true): void {
    if (withLoading) {
      this.loading = true
    }

    this.patientService
      .loadBudgets(this.patientId, this.year)
      .subscribe((data: CustomerDetailBudgetModel) => {
        this.patients = data

        if (withLoading) {
          this.scrollIntoCurrentMonth()
          this.loading = false
        }
      })
  }

  /**
   * Springt zum letzten Jahr.
   */
  public goToPreviousYear(): void {
    const newYear = this.selectedYear - 1

    const hasYearOption = this.yearOptions.find(
      (data: any) => data.value == newYear
    )

    if (hasYearOption) {
      this.selectedYear--
      this.year = this.selectedYear.toString()

      this.loadBudgets(false)
    }
  }

  /**
   * Springt zum nächsten Jahr.
   */
  public goToNextYear(): void {
    const newYear = this.selectedYear + 1

    const hasYearOption = this.yearOptions.find(
      (data: any) => data.value == newYear
    )

    if (hasYearOption) {
      this.selectedYear++
      this.year = this.selectedYear.toString()

      this.loadBudgets(false)
    }
  }

  /**
   * Scrollt den aktuellen Monat in die Mitte.
   */
  private scrollIntoCurrentMonth(): void {
    const monthNumber = dayjs().format('M')

    setTimeout(() => {
      const budgetContainer = document.querySelector('.patient-budget-boxes')

      if (budgetContainer) {
        budgetContainer.scrollLeft = (+monthNumber - 2) * 520
      }
    }, 200)
  }
}
