import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { TitleService } from 'src/app/services/title.service'
import { PhoneCallService } from '../../services/phone-call.service'
import { HelperService } from '../../services/helper.service'
import { EventBusService, GlobalEvent } from '../../services/eventbus.service'
import { HandoverService } from '../../services/handover.service'
import { SendAppointmentsDialogComponent } from '../../components/dialogs/send-appointments-dialog/send-appointments-dialog.component'
import { DialogService } from 'primeng/dynamicdialog'
import { Subject, Subscription } from 'rxjs'
import { switchMap } from 'rxjs/operators'

@Component({
  selector: 'app-handover',
  templateUrl: './handover.component.html',
})
export class HandoverComponent implements OnInit, OnDestroy {
  @ViewChild('dt') table: ElementRef | any = null

  public loading = false
  public contentLoading = true
  public globalFilterValue = ''

  private loadTrigger$ = new Subject<void>()
  private subscription: Subscription = new Subscription()

  public caregivers: any[] = []

  public month = ''
  public year = ''

  private selectedYear = 0
  private selectedMonth = 0
  private eventBusSubscription: Subscription = new Subscription()

  public monthOptions: any = []
  public yearOptions = [
    { label: '2022', value: '2022' },
    { label: '2023', value: '2023' },
    { label: '2024', value: '2024' },
    { label: '2025', value: '2025' },
  ]

  constructor(
    public phoneCallService: PhoneCallService,
    private dialogService: DialogService,
    private eventbus: EventBusService,
    private handoverService: HandoverService,
    private helperService: HelperService,
    private titleService: TitleService
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle('Terminübergabe')

    const date = new Date()
    this.selectedYear = date.getFullYear()
    this.selectedMonth = date.getMonth()

    if (this.selectedMonth === 11) {
      this.selectedYear++
      this.selectedMonth = 0
    } else {
      this.selectedMonth++
    }

    this.helperService.dependencies$.subscribe((data: any) => {
      this.monthOptions = data.months
      this.month = this.monthOptions[this.selectedMonth].label
      this.year = this.selectedYear.toString()
    })

    this.activateDataLoading()

    this.loadCaregivers()
    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
    this.subscription.unsubscribe()
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.HandoverSent:
            this.loadCaregivers(false)
        }
      }
    )
  }

  public openDialog(caregiverData: any): void {
    this.dialogService.open(SendAppointmentsDialogComponent, {
      header: 'Termine versenden',
      width: '900px',
      styleClass: 'dialog-container',
      data: {
        year: this.year,
        month: this.month,
        from_handover: true,
        caregiver_data: caregiverData,
      },
    })
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }

  /**
   * Springt zum letzten Monat.
   */
  public goToPreviousMonth(): void {
    if (this.selectedMonth === 0) {
      const lastYear = this.selectedYear - 1

      const hasYearOption = this.yearOptions.find(
        (data: any) => data.value == lastYear
      )

      if (hasYearOption) {
        this.selectedYear = lastYear
        this.selectedMonth = 11
      }
    } else {
      this.selectedMonth--
    }

    this.year = this.selectedYear.toString()
    this.month = this.monthOptions[this.selectedMonth].label

    this.loadCaregivers(false)
  }

  /**
   * Springt zum nächsten Monat.
   */
  public goToNextMonth(): void {
    if (this.selectedMonth === 11) {
      const nextYear = this.selectedYear + 1

      const hasYearOption = this.yearOptions.find(
        (data: any) => data.value == nextYear
      )

      if (hasYearOption) {
        this.selectedYear = nextYear
        this.selectedMonth = 0
      }
    } else {
      this.selectedMonth++
    }

    this.year = this.selectedYear.toString()
    this.month = this.monthOptions[this.selectedMonth].label

    this.loadCaregivers(false)
  }

  public loadCaregivers(withLoading: boolean = true): void {
    const foundMonth = this.monthOptions.findIndex((month: any) => {
      return month.label === this.month
    })

    if (foundMonth != null) {
      this.selectedMonth = foundMonth
    }

    this.selectedYear = +this.year

    this.contentLoading = true

    if (withLoading) {
      this.loading = true
    }

    this.loadTrigger$.next()
  }

  private activateDataLoading(): void {
    this.subscription.add(
      this.loadTrigger$
        .pipe(switchMap(() => this.handoverService.load(this.month, this.year)))
        .subscribe((data: any) => {
          this.caregivers = data

          this.contentLoading = false
          this.loading = false
        })
    )
  }
}
