import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class RequiredNotificationService {
  constructor(private http: HttpClient) {}

  public store(id: any, data: any): Observable<[]> {
    return this.http.post<[]>(
      `${environment.api}/required-notifications/${id}`,
      {
        ...data,
      }
    )
  }
}
