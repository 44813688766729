import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { PhoneCallService } from '../../../services/phone-call.service'
import { HelperService } from '../../../services/helper.service'
import { ShowCaregiverOverviewReleaseDialogComponent } from '../../../components/dialogs/show-caregiver-release-overview-dialog/show-caregiver-overview-release-dialog.component'
import { DialogService } from 'primeng/dynamicdialog'
import {
  EventBusService,
  GlobalEvent,
} from '../../../services/eventbus.service'
import { Subject, Subscription } from 'rxjs'
import { PlanService } from '../../../services/plan.service'
import { switchMap } from 'rxjs/operators'

@Component({
  selector: 'app-plans-approvals',
  templateUrl: './plans-approvals.component.html',
  styleUrls: ['./plans-approvals.component.scss'],
})
export class PlansApprovalsComponent implements OnInit, OnDestroy {
  @ViewChild('dt') table: ElementRef | any = null

  private eventBusSubscription: Subscription = new Subscription()

  public globalFilterValue = ''
  public loading = true
  public contentLoading = true

  private loadTrigger$ = new Subject<void>()
  private subscription: Subscription = new Subscription()

  public caregivers: any[] = []

  public month = ''
  public year = ''

  private selectedYear = 0
  private selectedMonth = 0

  public monthOptions: any = []
  public yearOptions = [
    { label: '2022', value: '2022' },
    { label: '2023', value: '2023' },
    { label: '2024', value: '2024' },
    { label: '2025', value: '2025' },
  ]

  constructor(
    private dialogService: DialogService,
    private eventbus: EventBusService,
    private helperService: HelperService,
    public phoneCallService: PhoneCallService,
    private planService: PlanService
  ) {}

  ngOnInit(): void {
    const date = new Date()
    this.selectedYear = date.getFullYear()
    this.selectedMonth = date.getMonth()

    if (this.selectedMonth === 0) {
      this.selectedYear--
      this.selectedMonth = 11
    } else {
      this.selectedMonth--
    }

    this.helperService.dependencies$.subscribe((data: any) => {
      this.monthOptions = data.months
      this.month = this.monthOptions[this.selectedMonth].label
      this.year = this.selectedYear.toString()
    })

    this.activateDataLoading()

    this.loadCaregivers()
    this.listenForEventbus()
  }

  ngOnDestroy(): void {
    this.eventBusSubscription.unsubscribe()
  }

  private listenForEventbus(): void {
    this.eventBusSubscription = this.eventbus.subject.subscribe(
      (event: GlobalEvent) => {
        switch (event) {
          case GlobalEvent.CaregiverOverviewChanged:
            this.loadCaregivers(false)
        }
      }
    )
  }

  public filterTableGlobal(event: any): void {
    this.table && this.table.filterGlobal(event.target.value.trim(), 'contains')
  }

  public loadCaregivers(withLoading: boolean = true): void {
    const foundMonth = this.monthOptions.findIndex((month: any) => {
      return month.label === this.month
    })

    if (foundMonth != null) {
      this.selectedMonth = foundMonth
    }

    this.selectedYear = +this.year

    this.contentLoading = true

    if (withLoading) {
      this.loading = true
    }

    this.loadTrigger$.next()
  }

  private activateDataLoading(): void {
    this.subscription.add(
      this.loadTrigger$
        .pipe(
          switchMap(() => this.planService.approvals(this.month, this.year))
        )
        .subscribe((data: any) => {
          this.caregivers = data

          this.contentLoading = false
          this.loading = false
        })
    )
  }

  public openOverviewReleaseDialog(caregiver: any): void {
    this.dialogService.open(ShowCaregiverOverviewReleaseDialogComponent, {
      data: {
        caregiver,
        month: this.month,
        year: this.year,
      },
      header: 'Dienstplan Details',
      styleClass: 'dialog-container dialog-with-sidebar-secondary',
      dismissableMask: true,
      width: '960px',
      height: '600px',
    })
  }

  /**
   * Springt zum letzten Monat.
   */
  public goToPreviousMonth(): void {
    if (this.selectedMonth === 0) {
      const lastYear = this.selectedYear - 1

      const hasYearOption = this.yearOptions.find(
        (data: any) => data.value == lastYear
      )

      if (hasYearOption) {
        this.selectedYear = lastYear
        this.selectedMonth = 11
      }
    } else {
      this.selectedMonth--
    }

    this.year = this.selectedYear.toString()
    this.month = this.monthOptions[this.selectedMonth].label

    this.loadCaregivers(false)
  }

  /**
   * Springt zum nächsten Monat.
   */
  public goToNextMonth(): void {
    if (this.selectedMonth === 11) {
      const nextYear = this.selectedYear + 1

      const hasYearOption = this.yearOptions.find(
        (data: any) => data.value == nextYear
      )

      if (hasYearOption) {
        this.selectedYear = nextYear
        this.selectedMonth = 0
      }
    } else {
      this.selectedMonth++
    }

    this.year = this.selectedYear.toString()
    this.month = this.monthOptions[this.selectedMonth].label

    this.loadCaregivers(false)
  }
}
