<form #form="ngForm" class="dialog-form">
  <div class="dialog-form-body">
    <!--    <div-->
    <!--      class="detail-information-banner light-version"-->
    <!--      *ngIf="freeTimeRequestToLate && values.date_type === 'FREE_TIME_REQUEST'"-->
    <!--    >-->
    <!--      <span-->
    <!--        >Für den Folgemonat kann kein Wunschfrei eingetragen werden. Bitte-->
    <!--        wenden Sie sich an das Büro.</span-->
    <!--      >-->
    <!--    </div>-->

    <div class="p-field" *ngIf="!isEdit">
      <label for="date_type">Thema *</label>
      <p-dropdown
        inputId="date_type"
        name="date_type"
        placeholder="Bitte auswählen"
        [options]="dateTypes"
        [(ngModel)]="values.date_type"
        [required]="true"
        (onChange)="dateTypeChanged()"
        scrollHeight="360px"
        #date_type="ngModel"
        [class.p-invalid]="
          date_type.invalid && (date_type.dirty || date_type.touched)
        "
      ></p-dropdown>
    </div>

    <div class="p-field" *ngIf="isEdit">
      <label>Thema</label>
      <strong>{{ data.request.date_type_string }}</strong>
    </div>

    <!-- Tag -->
    <div
      class="p-fluid p-formgrid p-grid without-border"
      *ngIf="values.date_type"
      style="padding: 0"
    >
      <div class="p-field p-col-6">
        <label for="from" style="display: flex; justify-content: space-between"
          >Tag von *

          <!-- Uhrzeit checkbox nur für bestimmte Typen anzeigen -->
          <div
            class="p-formgroup-inline"
            *ngIf="
              values.date_type === 'APPOINTMENT_REQUEST' ||
              values.date_type === 'PURCHASE_APPOINTMENT_REQUEST' ||
              values.date_type === 'CONSULTING_REQUEST' ||
              values.date_type === 'DOCTOR_APPOINTMENT_REQUEST'
            "
          >
            <div class="p-field-checkbox" style="margin: 0 !important">
              <p-checkbox
                label="mit Uhrzeit"
                name="with_time"
                [binary]="true"
                [(ngModel)]="values.with_time"
              ></p-checkbox>
            </div></div
        ></label>
        <p-calendar
          (onSelect)="dayFromSelected($event)"
          (onBlur)="dayFromSelected($event)"
          [firstDayOfWeek]="1"
          [showWeek]="true"
          inputId="from"
          name="from"
          dateFormat="dd.mm.yy"
          appendTo="body"
          [(ngModel)]="values.from"
          dataType="string"
          [showButtonBar]="true"
          [required]="true"
          #from="ngModel"
          [class.p-invalid]="from.invalid && (from.dirty || from.touched)"
        ></p-calendar>
      </div>
      <!--      [minDate]="-->
      <!--      values.date_type === 'FREE_TIME_REQUEST'-->
      <!--      ? minDateFreeTimeRequest-->
      <!--      : minDateVacationRequest-->
      <!--      "-->

      <!-- Tag bis soll nur für AU und Urlaub angezeigt werden -->
      <div
        class="p-field p-col-6"
        *ngIf="values.date_type === 'VACATION_REQUEST'"
      >
        <label for="to">Tag bis *</label>
        <p-calendar
          (onSelect)="dayToSelected($event)"
          (onBlur)="dayToSelected($event)"
          [firstDayOfWeek]="1"
          [showWeek]="true"
          inputId="to"
          name="to"
          dateFormat="dd.mm.yy"
          appendTo="body"
          [showButtonBar]="true"
          [(ngModel)]="values.to"
          dataType="string"
          [required]="true"
          #to="ngModel"
          [class.p-invalid]="to.invalid && (to.dirty || to.touched)"
        ></p-calendar>
      </div>
      <!--      [minDate]="-->
      <!--      values.date_type === 'FREE_TIME_REQUEST'-->
      <!--      ? minDateFreeTimeRequest-->
      <!--      : minDateVacationRequest-->
      <!--      "-->

      <!-- von -->
      <div class="p-field p-col-3" *ngIf="values.with_time">
        <label for="from_time">von *</label>
        <p-calendar
          (onBlur)="checkForTimes($event, 'from_time')"
          [stepMinute]="15"
          inputId="from_time"
          name="from_time"
          [timeOnly]="true"
          appendTo="body"
          [(ngModel)]="values.from_time"
          dataType="string"
          [required]="true"
          #from_time="ngModel"
          [showButtonBar]="false"
          [class.p-invalid]="
            from_time.invalid && (from_time.dirty || from_time.touched)
          "
        ></p-calendar>
      </div>

      <!-- bis -->
      <div class="p-field p-col-3" *ngIf="values.with_time">
        <label
          for="to_time"
          style="display: flex; justify-content: space-between"
          >bis *
        </label>
        <p-calendar
          (onBlur)="checkForTimes($event, 'to_time')"
          [stepMinute]="15"
          inputId="to_time"
          name="to_time"
          [timeOnly]="true"
          appendTo="body"
          [(ngModel)]="values.to_time"
          dataType="string"
          [required]="true"
          #to_time="ngModel"
          [showButtonBar]="false"
          [class.p-invalid]="
            to_time.invalid && (to_time.dirty || to_time.touched)
          "
        ></p-calendar>
      </div>
    </div>

    <div class="p-field">
      <label for="comment">Weitere Informationen</label>
      <textarea
        id="comment"
        name="comment"
        [(ngModel)]="values.comment"
        [rows]="3"
        pInputTextarea
      ></textarea>
    </div>

    <!--    <div class="detail-information-banner" *ngIf="hasVacation">-->
    <!--      <span>In diesem Zeitraum ist bereits Urlaub oder AU eingetragen.</span>-->
    <!--    </div>-->
  </div>
  <div class="dialog-form-footer">
    <i></i>
    <button
      (click)="upload()"
      pButton
      label="Speichern"
      type="button"
      [disabled]="submitted"
      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
    ></button>
  </div>
</form>

<p-confirmPopup></p-confirmPopup>
