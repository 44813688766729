<app-loader [loading]="loading"></app-loader>

<div class="content-actions" *ngIf="!loading">
  <h2>
    <i class="tio">file_add_outlined</i>
    {{ isEditMode ? "Angebot bearbeiten" : "Neues Angebot erstellen" }}
  </h2>
</div>

<form #form="ngForm" class="content-offer-container">
  <div class="content-offer-forms limit-width" *ngIf="!loading">
    <div class="content limit-width">
      <div class="offer-type-container">
        <div
          class="offer-type"
          (click)="selectOfferType('OFFER')"
          [class.selected]="data.offer_type === 'OFFER'"
        >
          <p-radioButton
            [(ngModel)]="data.offer_type"
            label="Normales Angebot"
            name="offer-type"
            value="OFFER"
          ></p-radioButton>
          <i class="pi pi-user"></i>
        </div>
        <div
          class="offer-type"
          (click)="selectOfferType('CALL')"
          [class.selected]="data.offer_type === 'CALL'"
        >
          <p-radioButton
            [(ngModel)]="data.offer_type"
            label="Neukunden Call"
            name="offer-type"
            value="CALL"
          ></p-radioButton>
          <i class="pi pi-phone"></i>
        </div>
      </div>
    </div>

    <ng-container *ngIf="data.offer_type === 'CALL'">
      <div class="content limit-width">
        <header class="content-header">
          <span>Personen</span>
        </header>

        <div class="content-body">
          <div class="offer-type-container">
            <div
              class="offer-type"
              (click)="selectPersonType('A')"
              [class.selected]="data.patient_type === 'A'"
            >
              <p-radioButton
                [(ngModel)]="data.patient_type"
                label="A"
                name="patient-type"
                value="A"
              ></p-radioButton>
              <i
                class="pi pi-info-circle"
                pTooltip="Die Person lebt allein, eine weitere Person ist nicht im Haushalt."
              ></i>
            </div>
            <div
              class="offer-type"
              (click)="selectPersonType('B')"
              [class.selected]="data.patient_type === 'B'"
            >
              <p-radioButton
                [(ngModel)]="data.patient_type"
                label="B"
                name="patient-type"
                value="B"
              ></p-radioButton>
              <i
                class="pi pi-info-circle"
                pTooltip="Die Person lebt mit einer weiteren Person im Haushalt. Die zweite Person ist hilfebedürftig."
              ></i>
            </div>
            <div
              class="offer-type"
              (click)="selectPersonType('C')"
              [class.selected]="data.patient_type === 'C'"
            >
              <p-radioButton
                [(ngModel)]="data.patient_type"
                label="C"
                name="patient-type"
                value="C"
              ></p-radioButton>
              <i
                class="pi pi-info-circle"
                pTooltip="Die Person lebt mit einer weiteren Person / Personen im Haushalt. Die zweite Person ist nicht hilfebedürftig. Lediglich Hilfestellung durch die Betreuungskraft in der Hauswirtschaft wird gewünscht (Kochen, Kleiderwaschen, Bügeln, Einkäufe, Raumpflege)."
              ></i>
            </div>
          </div>
        </div>
      </div>

      <div class="content limit-width content-highlight-1">
        <header class="content-header">
          <span>Grunddaten Person 1</span>
        </header>

        <div class="content-body">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-3">
              <label for="first-person-form-of-address">Anrede *</label>
              <p-dropdown
                [(ngModel)]="data.first_patient.form_of_address"
                [options]="formOfAddress"
                inputId="first-person-form-of-address"
                #first_person_form_of_address="ngModel"
                name="first-person-form-of-address"
                placeholder="Bitte auswählen"
                [required]="true"
              ></p-dropdown>
              <div
                *ngIf="
                  first_person_form_of_address.invalid &&
                  (first_person_form_of_address.dirty ||
                    first_person_form_of_address.touched)
                "
                class="p-invalid"
              >
                <div *ngIf="first_person_form_of_address.errors?.required">
                  <small>Bitte wählen Sie eine Anrede</small>
                </div>
              </div>
            </div>

            <div class="p-field p-col-3">
              <label for="first-person-first-name">Vorname *</label>
              <input
                id="first-person-first-name"
                name="first-person-first-name"
                [(ngModel)]="data.first_patient.first_name"
                type="text"
                pInputText
                #first_person_first_name="ngModel"
                required
                [class.p-invalid]="
                  first_person_first_name.invalid &&
                  (first_person_first_name.dirty ||
                    first_person_first_name.touched)
                "
              />
              <div
                *ngIf="
                  first_person_first_name.invalid &&
                  (first_person_first_name.dirty ||
                    first_person_first_name.touched)
                "
                class="p-invalid"
              >
                <div *ngIf="first_person_first_name.errors?.required">
                  <small>Bitte geben Sie einen Vornamen ein</small>
                </div>
              </div>
            </div>

            <div class="p-field p-col-3">
              <label for="first-person-last-name">Nachname *</label>
              <input
                id="first-person-last-name"
                name="first-person-last-name"
                [(ngModel)]="data.first_patient.last_name"
                type="text"
                #first_person_last_name="ngModel"
                required
                [class.p-invalid]="
                  first_person_last_name.invalid &&
                  (first_person_last_name.dirty ||
                    first_person_last_name.touched)
                "
                pInputText
              />
              <div
                *ngIf="
                  first_person_last_name.invalid &&
                  (first_person_last_name.dirty ||
                    first_person_last_name.touched)
                "
                class="p-invalid"
              >
                <div *ngIf="first_person_last_name.errors?.required">
                  <small>Bitte geben Sie einen Nachnamen ein</small>
                </div>
              </div>
            </div>
          </div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-4">
              <label for="first-person-street-house-number"
                >Straße / Hausnummer *</label
              >
              <input
                id="first-person-street-house-number"
                name="first-person-street-house-number"
                [(ngModel)]="data.first_patient.street_house_number"
                type="text"
                pInputText
                #first_person_street_house_number="ngModel"
                required
                [class.p-invalid]="
                  first_person_street_house_number.invalid &&
                  (first_person_street_house_number.dirty ||
                    first_person_street_house_number.touched)
                "
              />
              <div
                *ngIf="
                  first_person_street_house_number.invalid &&
                  (first_person_street_house_number.dirty ||
                    first_person_street_house_number.touched)
                "
                class="p-invalid"
              >
                <div *ngIf="first_person_street_house_number.errors?.required">
                  <small>Bitte geben Sie einen Wert ein</small>
                </div>
              </div>
            </div>

            <div class="p-field p-col-4">
              <label for="first-person-zipcode">PLZ *</label>
              <input
                id="first-person-zipcode"
                name="first-person-zipcode"
                (change)="loadCity('first_patient')"
                [(ngModel)]="data.first_patient.zipcode"
                type="text"
                pInputText
                #first_person_zipcode="ngModel"
                required
                [class.p-invalid]="
                  first_person_zipcode.invalid &&
                  (first_person_zipcode.dirty || first_person_zipcode.touched)
                "
              />
              <div
                *ngIf="
                  first_person_zipcode.invalid &&
                  (first_person_zipcode.dirty || first_person_zipcode.touched)
                "
                class="p-invalid"
              >
                <div *ngIf="first_person_zipcode.errors?.required">
                  <small>Bitte geben Sie einen Wert ein</small>
                </div>
              </div>
            </div>

            <div class="p-field p-col-4">
              <label
                for="first-person-city"
                style="justify-content: space-between"
                >Ort *
                <i
                  class="pi pi-info-circle color-gray"
                  style="font-size: 12px"
                  pTooltip="Wird durch PLZ übernommen"
                ></i
              ></label>
              <span class="p-input-icon-right">
                <i *ngIf="cityLoading" class="pi pi-spin pi-spinner"></i>
                <input
                  id="first-person-city"
                  name="first-person-city"
                  [(ngModel)]="data.first_patient.city"
                  type="text"
                  pInputText
                  #first_person_city="ngModel"
                  required
                  readonly
                  [class.p-invalid]="
                    first_person_city.invalid &&
                    (first_person_city.dirty || first_person_city.touched)
                  "
                />
              </span>
              <div
                *ngIf="
                  first_person_city.invalid &&
                  (first_person_city.dirty || first_person_city.touched)
                "
                class="p-invalid"
              >
                <div *ngIf="first_person_city.errors?.required">
                  <small>Bitte geben Sie einen Wert ein</small>
                </div>
              </div>
            </div>
          </div>

          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-4">
              <label for="first-person-city-part">Ortsteil</label>
              <span class="p-input-icon-right">
                <input
                  id="first-person-city-part"
                  name="first-person-city-part"
                  [(ngModel)]="data.first_patient.city_part"
                  type="text"
                  pInputText
                />
              </span>
            </div>

            <div class="p-field p-col-4">
              <label
                for="first-person-county"
                style="justify-content: space-between"
                >Landkreis
                <i
                  class="pi pi-info-circle color-gray"
                  style="font-size: 12px"
                  pTooltip="Wird durch PLZ übernommen"
                ></i
              ></label>
              <span class="p-input-icon-right">
                <input
                  id="first-person-county"
                  name="first-person-county"
                  [(ngModel)]="data.first_patient.county"
                  type="text"
                  readonly
                  pInputText
                />
              </span>
            </div>

            <div class="p-field p-col-4">
              <label
                for="first-person-state"
                style="justify-content: space-between"
                >Bundesland *
                <i
                  class="pi pi-info-circle color-gray"
                  style="font-size: 12px"
                  pTooltip="Wird durch PLZ übernommen"
                ></i
              ></label>
              <span class="p-input-icon-right">
                <input
                  id="first-person-state"
                  name="first-person-state"
                  [(ngModel)]="data.first_patient.state"
                  type="text"
                  pInputText
                  #first_person_state="ngModel"
                  required
                  readonly
                  [class.p-invalid]="
                    first_person_state.invalid &&
                    (first_person_state.dirty || first_person_state.touched)
                  "
                />
              </span>
              <div
                *ngIf="
                  first_person_state.invalid &&
                  (first_person_state.dirty || first_person_state.touched)
                "
                class="p-invalid"
              >
                <div *ngIf="first_person_state.errors?.required">
                  <small>Bitte geben Sie einen Wert ein</small>
                </div>
              </div>
            </div>
          </div>

          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-4">
              <label for="attention_from">Aufmerksam durch</label>
              <p-dropdown
                inputId="attention_from"
                name="attention_from"
                placeholder="Bitte auswählen"
                appendTo="body"
                [options]="attentionFrom"
                [(ngModel)]="data.attention_from_id"
                optionLabel="name"
                optionValue="id"
              ></p-dropdown>
            </div>

            <div class="p-field p-col-8">
              <label for="attention-from-info">Weitere Informationen</label>
              <input
                id="attention-from-info"
                name="attention-from-info"
                [(ngModel)]="data.attention_from_info"
                type="text"
                pInputText
              />
            </div>
          </div>

          <!-- Die Empfehlungen sollen nur als Super Admin bearbeitbar sein -->
          <div
            *ngIf="authService.isSuperAdmin() || !alreadyRecommended"
            class="p-fluid p-formgrid p-grid background-hightlight-blue"
            style="padding-bottom: 20px"
          >
            <div class="p-field p-col-6">
              <label for="recommendation_type">Empfehlung durch</label>
              <div class="p-formgroup-inline">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.recommendation_type"
                    label="Keine Empfehlung"
                    name="recommendation_type"
                    [value]="null"
                    (onClick)="resetRecommendation()"
                    [disabled]="
                      data.created_from_multiplier ||
                      data.created_from_caregiver
                    "
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Betreuungskraft"
                    [(ngModel)]="data.recommendation_type"
                    name="recommendation_type"
                    value="CAREGIVER"
                    [disabled]="
                      data.created_from_multiplier ||
                      data.created_from_caregiver
                    "
                    (onClick)="resetRecommendation()"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Multiplikator"
                    [(ngModel)]="data.recommendation_type"
                    name="recommendation_type"
                    value="MULTIPLIER"
                    [disabled]="
                      data.created_from_multiplier ||
                      data.created_from_caregiver
                    "
                    (onClick)="resetRecommendation()"
                  ></p-radioButton>
                </div>
              </div>
            </div>

            <div class="p-field p-col-6" *ngIf="data.recommendation_type">
              <label
                >Suche nach
                {{
                  data.recommendation_type === "CAREGIVER"
                    ? "Betreuungskraft"
                    : "Multiplikator"
                }}
                *</label
              >

              <!-- Betreuungskraft -->
              <p-autoComplete
                *ngIf="data.recommendation_type === 'CAREGIVER'"
                field="full_name"
                inputId="recommendation_caregiver"
                name="recommendation_caregiver"
                appendTo="body"
                [forceSelection]="true"
                [(ngModel)]="data.recommendation_id"
                [suggestions]="caregivers"
                (completeMethod)="searchCaregivers($event)"
                [required]="true"
                [disabled]="
                  data.created_from_multiplier || data.created_from_caregiver
                "
                #recommendation_caregiver="ngModel"
                [class.p-invalid]="
                  recommendation_caregiver.invalid &&
                  (recommendation_caregiver.dirty ||
                    recommendation_caregiver.touched)
                "
              >
                <ng-template let-caregiver pTemplate="item">
                  <div>{{ caregiver.full_name }}</div>
                </ng-template>
              </p-autoComplete>

              <!-- Multiplikator -->
              <p-autoComplete
                *ngIf="data.recommendation_type === 'MULTIPLIER'"
                field="full_name"
                inputId="recommendation_multiplier"
                name="recommendation_multiplier"
                appendTo="body"
                [forceSelection]="true"
                [(ngModel)]="data.recommendation_id"
                [suggestions]="multipliers"
                (completeMethod)="searchMultipliers($event)"
                [required]="true"
                [disabled]="
                  data.created_from_multiplier || data.created_from_caregiver
                "
                #recommendation_multiplier="ngModel"
                [class.p-invalid]="
                  recommendation_multiplier.invalid &&
                  (recommendation_multiplier.dirty ||
                    recommendation_multiplier.touched)
                "
              >
                <ng-template let-multiplier pTemplate="item">
                  <div>{{ multiplier.full_name }}</div>
                </ng-template>
              </p-autoComplete>
            </div>
          </div>
        </div>
      </div>

      <div class="content limit-width content-highlight-1">
        <header class="content-header">
          <span>Kontaktdaten Person 1</span>
        </header>

        <div class="content-body">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-4">
              <div class="p-field">
                <label for="first-person-phone-1">Telefon 1</label>
                <input
                  id="first-person-phone-1"
                  name="first-person-phone-1"
                  [(ngModel)]="data.first_patient.phone_1"
                  type="text"
                  pInputText
                />
              </div>
              <div class="p-field" style="margin-top: 5px">
                <input
                  id="first-person-phone-1-info"
                  name="first-person-phone-1-info"
                  placeholder="Informationen zu Telefon 1"
                  [(ngModel)]="data.first_patient.phone_1_info"
                  type="text"
                  pInputText
                />
              </div>
            </div>

            <div class="p-field p-col-4">
              <div class="p-field">
                <label for="first-person-phone-2">Telefon 2</label>
                <input
                  id="first-person-phone-2"
                  name="first-person-phone-2"
                  [(ngModel)]="data.first_patient.phone_2"
                  type="text"
                  pInputText
                />
              </div>
              <div class="p-field" style="margin-top: 5px">
                <input
                  id="first-person-phone-2-info"
                  name="first-person-phone-2-info"
                  placeholder="Informationen zu Telefon 2"
                  [(ngModel)]="data.first_patient.phone_2_info"
                  type="text"
                  pInputText
                />
              </div>
            </div>

            <div class="p-field p-col-4">
              <div class="p-field p-col-12">
                <label for="first-person-email">E-Mail</label>
                <input
                  id="first-person-email"
                  name="first-person-email"
                  [(ngModel)]="data.first_patient.email"
                  type="email"
                  pInputText
                  #first_person_email="ngModel"
                  email
                />
              </div>
              <div class="p-field p-col-12" style="margin-top: 5px">
                <input
                  id="first-person-email-info"
                  name="first-person-email-info"
                  placeholder="Informationen zur E-Mail"
                  [(ngModel)]="data.first_patient.email_info"
                  type="email"
                  pInputText
                />

                <div
                  *ngIf="
                    first_person_email.invalid &&
                    (first_person_email.dirty || first_person_email.touched)
                  "
                  class="p-invalid"
                >
                  <div *ngIf="first_person_email.errors?.email">
                    <small>Keine richtige E-Mail</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="content limit-width content-highlight-1">
        <header class="content-header">
          <span> Versicherungs- und Leistungsdaten Person 1 </span>
        </header>

        <div class="content-body">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
              <div
                class="p-formgroup-inline has-form-height"
                style="align-items: center"
              >
                <div class="p-field-checkbox">
                  <p-checkbox
                    label="Selbstzahler"
                    name="first-person-insured-via"
                    value="Selbstzahler"
                    [(ngModel)]="data.first_patient.insured_via"
                    (onChange)="calculateBudget('first_patient')"
                    [required]="true"
                    #first_person_insured_via="ngModel"
                  ></p-checkbox>
                </div>
                <div class="p-field-checkbox">
                  <p-checkbox
                    label="Beihilfe"
                    value="Beihilfe"
                    name="first-person-insured-via"
                    [(ngModel)]="data.first_patient.insured_via"
                    (onChange)="calculateBudget('first_patient')"
                    [required]="true"
                    #first_person_insured_via="ngModel"
                  ></p-checkbox>
                </div>
                <div class="p-field-checkbox">
                  <p-checkbox
                    label="GKV"
                    name="first-person-insured-via"
                    value="GKV"
                    [(ngModel)]="data.first_patient.insured_via"
                    (onChange)="calculateBudget('first_patient')"
                    [required]="true"
                    #first_person_insured_via="ngModel"
                  ></p-checkbox>
                </div>
                <div class="p-field-checkbox">
                  <p-checkbox
                    label="PKV"
                    value="PKV"
                    name="first-person-insured-via"
                    [(ngModel)]="data.first_patient.insured_via"
                    (onChange)="calculateBudget('first_patient')"
                    [required]="true"
                    #first_person_insured_via="ngModel"
                  ></p-checkbox>
                </div>
                <div class="p-field-checkbox">
                  <p-checkbox
                    label="UV"
                    value="UV"
                    name="first-person-insured-via"
                    [(ngModel)]="data.first_patient.insured_via"
                    (onChange)="calculateBudget('first_patient')"
                    [required]="true"
                    #first_person_insured_via="ngModel"
                  ></p-checkbox>
                </div>
                <div class="p-field-checkbox">
                  <p-checkbox
                    label="SGB V"
                    value="SGB V"
                    name="first-person-insured-via"
                    [(ngModel)]="data.first_patient.insured_via"
                    (onChange)="calculateBudget('first_patient')"
                    [required]="true"
                    #first_person_insured_via="ngModel"
                  ></p-checkbox>
                </div>
                <div class="p-field-checkbox">
                  <p-checkbox
                    label="Kostenträger"
                    value="Kostenträger"
                    name="first-person-insured-via"
                    [(ngModel)]="data.first_patient.insured_via"
                    (onChange)="calculateBudget('first_patient')"
                    [required]="true"
                    #first_person_insured_via="ngModel"
                  ></p-checkbox>
                </div>
                <!--              <div class="p-field-checkbox full-width">-->
                <div class="p-field-checkbox">
                  <p-checkbox
                    label="Sonstiges"
                    value="Sonstiges"
                    name="first-person-insured-via"
                    [(ngModel)]="data.first_patient.insured_via"
                    (onChange)="calculateBudget('first_patient')"
                    [required]="true"
                    #first_person_insured_via="ngModel"
                  ></p-checkbox>
                </div>

                <div
                  class="p-field p-col-3"
                  style="margin: 0"
                  *ngIf="data.first_patient.insured_via.includes('Sonstiges')"
                >
                  <input
                    id="first-person-insured_via_other_text"
                    name="first-person-insured_via_other_text"
                    [(ngModel)]="data.first_patient.insured_via_other_text"
                    type="text"
                    pInputText
                  />
                </div>
              </div>
              <div
                *ngIf="
                  first_person_insured_via.invalid &&
                  (first_person_insured_via.dirty ||
                    first_person_insured_via.touched)
                "
                class="p-invalid"
              >
                <div *ngIf="first_person_insured_via.errors?.required">
                  <small>Bitte wählen Sie einen Abrechnungsstatus</small>
                </div>
              </div>
            </div>
          </div>

          <div
            class="p-fluid p-formgrid p-grid"
            *ngIf="
              data.first_patient.insured_via.includes('Selbstzahler') ||
              data.first_patient.insured_via.includes('Kostenträger')
            "
          >
            <!-- Selbstzahler -->
            <div
              class="p-field p-col-4"
              *ngIf="data.first_patient.insured_via.includes('Selbstzahler')"
            >
              <label>Selbstzahler Budget</label>
              <div class="p-formgroup-inline" style="align-items: center">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.first_patient.selbstzahler_budget_type"
                    label="Flexibel"
                    name="first-person-selbstzahler-budget-type"
                    value="Flexibel"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.first_patient.selbstzahler_budget_type"
                    label="Max"
                    name="first-person-selbstzahler-budget-type"
                    value="Max"
                  ></p-radioButton>
                </div>
                <div class="p-inputgroup" style="width: 130px">
                  <p-inputNumber
                    inputId="first-person-selbstzahler-budget-max"
                    name="first-person-selbstzahler-budget-max"
                    [(ngModel)]="data.first_patient.selbstzahler_budget_max"
                    [useGrouping]="false"
                    [disabled]="
                      data.first_patient.selbstzahler_budget_type !== 'Max'
                    "
                    mode="decimal"
                    locale="de-DE"
                    [minFractionDigits]="2"
                  ></p-inputNumber>
                  <span class="p-inputgroup-addon">Std</span>
                </div>
              </div>
            </div>

            <!-- Kostenträger Budget -->
            <div
              class="p-field p-col-3"
              *ngIf="data.first_patient.insured_via.includes('Kostenträger')"
            >
              <label>Kostenträger Budget</label>
              <div class="p-inputgroup kostentraeger-input-number">
                <p-inputNumber
                  inputId="first-person-kostentraeger_budget"
                  name="first-person-kostentraeger_budget"
                  [(ngModel)]="data.first_patient.kostentraeger_budget"
                  [useGrouping]="false"
                  mode="decimal"
                  locale="de-DE"
                  [minFractionDigits]="2"
                ></p-inputNumber>
                <span style="padding: 0 8px" class="p-inputgroup-addon"
                  >Std / Woche</span
                >
              </div>
            </div>

            <!-- Kostenträger Gültigkeit -->
            <div
              class="p-field p-col-3"
              *ngIf="data.first_patient.insured_via.includes('Kostenträger')"
            >
              <label>Kostenträger Gültigkeit</label>
              <div class="p-formgroup-inline" style="align-items: center">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.first_patient.kostentraeger_valid_type"
                    label="Unbegrenzt"
                    name="first-person-kostentraeger_valid_type"
                    value="Unbegrenzt"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.first_patient.kostentraeger_valid_type"
                    label="Datum"
                    name="first-person-kostentraeger_valid_type"
                    value="Datum"
                  ></p-radioButton>
                </div>
              </div>
            </div>

            <!-- Kostenträger Gültig bis -->
            <div
              class="p-field p-col-2"
              *ngIf="
                data.first_patient.insured_via.includes('Kostenträger') &&
                data.first_patient.kostentraeger_valid_type === 'Datum'
              "
            >
              <label>Gültig bis</label>
              <p-calendar
                [firstDayOfWeek]="1"
                [showWeek]="true"
                inputId="first_patient_kostentraeger_valid_until_date_string"
                name="first_patient_kostentraeger_valid_until_date_string"
                dateFormat="dd.mm.yy"
                appendTo="body"
                [(ngModel)]="
                  data.first_patient.kostentraeger_valid_until_date_string
                "
                dataType="string"
                [required]="true"
                #first_patient_kostentraeger_valid_until_date_string="ngModel"
                [showButtonBar]="true"
                [class.p-invalid]="
                  first_patient_kostentraeger_valid_until_date_string.invalid &&
                  (first_patient_kostentraeger_valid_until_date_string.dirty ||
                    first_patient_kostentraeger_valid_until_date_string.touched)
                "
              ></p-calendar>
              <div
                *ngIf="
                  first_patient_kostentraeger_valid_until_date_string.invalid &&
                  (first_patient_kostentraeger_valid_until_date_string.dirty ||
                    first_patient_kostentraeger_valid_until_date_string.touched)
                "
                class="p-invalid"
              >
                <div
                  *ngIf="
                    first_patient_kostentraeger_valid_until_date_string.errors
                      ?.required
                  "
                >
                  <small>Bitte geben Sie ein Datum ein</small>
                </div>
              </div>
            </div>
          </div>

          <!-- Kostenträger Name -->
          <div
            class="p-fluid p-formgrid p-grid"
            *ngIf="data.first_patient.insured_via.includes('Kostenträger')"
          >
            <div class="p-field p-col-4">
              <label for="first-person-cost-unit">
                Kostenträger
                <i
                  class="pi pi-info-circle"
                  pTooltip="Automatische Suche bei 4 Zeichen"
                ></i>
              </label>
              <p-autoComplete
                field="name"
                inputId="first-person-cost-unit"
                name="first-person-cost-unit"
                [minLength]="4"
                [(ngModel)]="data.first_patient.cost_unit"
                [suggestions]="kostentraeger"
                (completeMethod)="searchKostentraeger($event)"
              >
                <ng-template let-multi pTemplate="item">
                  <div>{{ multi.name }}</div>
                </ng-template>
              </p-autoComplete>
            </div>
            <div class="p-field p-col-4">
              <label for="first-person-file-number">Unser Aktenzeichen</label>
              <input
                id="first-person-file-number"
                name="first-person-file-number"
                [(ngModel)]="data.first_patient.file_number"
                type="text"
                pInputText
              />
            </div>
          </div>

          <div
            class="p-fluid p-formgrid p-grid"
            *ngIf="
              !onlySelbstzahlerSelected('first_patient') &&
              data.first_patient.degree_of_care
            "
            [class.alert-highlight]="
              !data.first_patient.request_37 &&
              isGkvOrPkvSelected('first_patient')
            "
          >
            <div
              class="p-field p-col-4"
              [pTooltip]="
                !isGkvOrPkvSelected('first_patient')
                  ? 'Nur bei GKV oder PKV'
                  : ''
              "
            >
              <label for="first-person-nursing-person"
                >Pflegeperson vorhanden?</label
              >
              <div class="p-formgroup-inline has-form-height">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.first_patient.nursing_person"
                    (onClick)="calculateBudget('first_patient')"
                    label="Ja"
                    [disabled]="!isGkvOrPkvSelected('first_patient')"
                    name="first-person-nursing-person"
                    value="Ja"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Nein"
                    [(ngModel)]="data.first_patient.nursing_person"
                    (onClick)="calculateBudget('first_patient')"
                    [disabled]="!isGkvOrPkvSelected('first_patient')"
                    name="first-person-nursing-person"
                    value="Nein"
                  ></p-radioButton>
                </div>
              </div>
            </div>

            <div
              class="p-field p-col-5"
              *ngIf="data.first_patient.nursing_person === 'Ja'"
            >
              <label for="first-person-nursing-person-name"
                >Name Pflegeperson</label
              >
              <input
                id="first-person-nursing-person-name"
                name="first-person-nursing-person-name"
                [(ngModel)]="data.first_patient.nursing_person_name"
                type="text"
                pInputText
              />
            </div>
          </div>
          <div
            class="p-fluid p-formgrid p-grid"
            *ngIf="!onlySelbstzahlerSelected('first_patient')"
          >
            <div class="p-field p-col-4">
              <label for="first-person-degree-of-care">Pflegegrad</label>
              <p-dropdown
                inputId="first-person-degree-of-care"
                name="first-person-degree-of-care"
                placeholder="Auswählen"
                optionValue="value"
                [showClear]="true"
                (onChange)="calculateBudget('first_patient')"
                [(ngModel)]="data.first_patient.degree_of_care"
                [options]="dependencies.degree_of_care_all"
              ></p-dropdown>
            </div>

            <div class="p-field p-col-4">
              <label for="first-person-degree-of-care-since"
                >Pflegegrad seit</label
              >
              <p-calendar
                [firstDayOfWeek]="1"
                [showWeek]="true"
                [showButtonBar]="true"
                inputId="first-person-degree-of-care-since"
                name="first-person-degree-of-care-since"
                [(ngModel)]="data.first_patient.degree_of_care_since_string"
                dateFormat="dd.mm.yy"
                (onSelect)="calculateBudget('first_patient')"
                (onInput)="calculateBudget('first_patient')"
                dataType="string"
              ></p-calendar>
            </div>
          </div>
        </div>
      </div>

      <div
        class="content limit-width content-highlight-2"
        *ngIf="
          data.patient_type === 'B' ||
          (data.patient_type === 'C' && !isEditMode)
        "
      >
        <header class="content-header">
          <span *ngIf="data.patient_type === 'B'">Grunddaten Person 2</span>
          <span *ngIf="data.patient_type === 'C'"
            >Ansprechpartner im gleichen Haushalt</span
          >
        </header>

        <div class="content-body">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-3" *ngIf="data.second_patient">
              <label for="second-person-form-of-address">Anrede *</label>
              <p-dropdown
                [(ngModel)]="data.second_patient.form_of_address"
                [options]="formOfAddress"
                inputId="second-person-form-of-address"
                #second_person_form_of_address="ngModel"
                name="second-person-form-of-address"
                placeholder="Bitte auswählen"
                [required]="true"
              ></p-dropdown>
              <div
                *ngIf="
                  second_person_form_of_address.invalid &&
                  (second_person_form_of_address.dirty ||
                    second_person_form_of_address.touched)
                "
                class="p-invalid"
              >
                <div *ngIf="second_person_form_of_address.errors?.required">
                  <small>Bitte wählen Sie eine Anrede</small>
                </div>
              </div>
            </div>

            <div class="p-field p-col-3">
              <label for="second-person-first-name">Vorname *</label>
              <input
                id="second-person-first-name"
                name="second-person-first-name"
                [(ngModel)]="data.second_patient.first_name"
                type="text"
                pInputText
                #second_person_first_name="ngModel"
                required
                [class.p-invalid]="
                  second_person_first_name.invalid &&
                  (second_person_first_name.dirty ||
                    second_person_first_name.touched)
                "
              />
              <div
                *ngIf="
                  second_person_first_name.invalid &&
                  (second_person_first_name.dirty ||
                    second_person_first_name.touched)
                "
                class="p-invalid"
              >
                <div *ngIf="second_person_first_name.errors?.required">
                  <small>Bitte geben Sie einen Vornamen ein</small>
                </div>
              </div>
            </div>

            <div class="p-field p-col-3">
              <label for="second-person-last-name">Nachname *</label>
              <input
                id="second-person-last-name"
                name="second-person-last-name"
                [(ngModel)]="data.second_patient.last_name"
                type="text"
                pInputText
                #second_person_last_name="ngModel"
                required
                [class.p-invalid]="
                  first_person_last_name.invalid &&
                  (first_person_last_name.dirty ||
                    first_person_last_name.touched)
                "
              />
              <div
                *ngIf="
                  second_person_last_name.invalid &&
                  (second_person_last_name.dirty ||
                    second_person_last_name.touched)
                "
                class="p-invalid"
              >
                <div *ngIf="second_person_last_name.errors?.required">
                  <small>Bitte geben Sie einen Nachnamen ein</small>
                </div>
              </div>
            </div>

            <div class="p-field p-col-3">
              <label for="second-person-birthday">Geburtsdatum</label>
              <p-calendar
                [showButtonBar]="false"
                [firstDayOfWeek]="1"
                [(ngModel)]="data.second_patient.birthday_date_string"
                inputId="second-person-birthday"
                name="second-person-birthday"
                dateFormat="dd.mm.yy"
                dataType="string"
              ></p-calendar>
            </div>
          </div>
        </div>
      </div>

      <div
        class="content limit-width content-highlight-2"
        *ngIf="
          data.patient_type === 'B' ||
          (data.patient_type === 'C' && !isEditMode)
        "
      >
        <header class="content-header">
          <span *ngIf="data.patient_type === 'B'">Kontaktdaten Person 2</span>
          <span *ngIf="data.patient_type === 'C'"
            >Kontaktdaten Ansprechpartner im gleichen Haushalt</span
          >
        </header>

        <div class="content-body">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-4">
              <div class="p-field">
                <label for="second-person-phone-1">Telefon 1</label>
                <input
                  id="second-person-phone-1"
                  name="second-person-phone-1"
                  [(ngModel)]="data.second_patient.phone_1"
                  type="text"
                  pInputText
                />
              </div>
              <div class="p-field" style="margin-top: 5px">
                <input
                  id="second-person-phone-1-info"
                  name="second-person-phone-1-info"
                  placeholder="Informationen zu Telefon 1"
                  [(ngModel)]="data.second_patient.phone_1_info"
                  type="text"
                  pInputText
                />
              </div>
            </div>

            <div class="p-field p-col-4">
              <div class="p-field">
                <label for="second-person-phone-2">Telefon 2</label>
                <input
                  id="second-person-phone-2"
                  name="second-person-phone-2"
                  [(ngModel)]="data.second_patient.phone_2"
                  type="text"
                  pInputText
                />
              </div>
              <div class="p-field" style="margin-top: 5px">
                <input
                  id="second-person-phone-2-info"
                  name="second-person-phone-2-info"
                  placeholder="Informationen zu Telefon 2"
                  [(ngModel)]="data.second_patient.phone_2_info"
                  type="text"
                  pInputText
                />
              </div>
            </div>

            <div class="p-field p-col-4">
              <div class="p-field">
                <label for="second-person-email">E-Mail</label>
                <input
                  id="second-person-email"
                  name="second-person-email"
                  [(ngModel)]="data.second_patient.email"
                  type="email"
                  pInputText
                  #second_person_email="ngModel"
                  email
                />
              </div>
              <div class="p-field" style="margin-top: 5px">
                <input
                  id="second-person-email-info"
                  name="second-person-email-info"
                  placeholder="Informationen zur E-Mail"
                  [(ngModel)]="data.second_patient.email_info"
                  type="email"
                  pInputText
                />

                <div
                  *ngIf="
                    second_person_email.invalid &&
                    (second_person_email.dirty || second_person_email.touched)
                  "
                  class="p-invalid"
                >
                  <div *ngIf="second_person_email.errors?.email">
                    <small>Keine richtige E-Mail</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="content limit-width content-highlight-2"
        *ngIf="data.patient_type === 'B'"
      >
        <header class="content-header">
          <span>Versicherungs- und Leistungsdaten Person 2</span>
        </header>

        <div class="content-body">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
              <div class="p-formgroup-inline" style="align-items: center">
                <div class="p-field-checkbox">
                  <p-checkbox
                    label="Selbstzahler"
                    name="second-person-insured-via"
                    value="Selbstzahler"
                    [(ngModel)]="data.second_patient.insured_via"
                    (onChange)="calculateBudget('second_patient')"
                    [required]="true"
                    #second_person_insured_via="ngModel"
                  ></p-checkbox>
                </div>
                <div class="p-field-checkbox">
                  <p-checkbox
                    label="Beihilfe"
                    value="Beihilfe"
                    name="second-person-insured-via"
                    [(ngModel)]="data.second_patient.insured_via"
                    (onChange)="calculateBudget('second_patient')"
                    [required]="true"
                    #second_person_insured_via="ngModel"
                  ></p-checkbox>
                </div>
                <div class="p-field-checkbox">
                  <p-checkbox
                    label="GKV"
                    name="second-person-insured-via"
                    value="GKV"
                    [(ngModel)]="data.second_patient.insured_via"
                    (onChange)="calculateBudget('second_patient')"
                    [required]="true"
                    #second_person_insured_via="ngModel"
                  ></p-checkbox>
                </div>
                <div class="p-field-checkbox">
                  <p-checkbox
                    label="PKV"
                    value="PKV"
                    name="second-person-insured-via"
                    [(ngModel)]="data.second_patient.insured_via"
                    (onChange)="calculateBudget('second_patient')"
                    [required]="true"
                    #second_person_insured_via="ngModel"
                  ></p-checkbox>
                </div>
                <div class="p-field-checkbox">
                  <p-checkbox
                    label="UV"
                    value="UV"
                    name="second-person-insured-via"
                    [(ngModel)]="data.second_patient.insured_via"
                    (onChange)="calculateBudget('second_patient')"
                    [required]="true"
                    #second_person_insured_via="ngModel"
                  ></p-checkbox>
                </div>
                <div class="p-field-checkbox">
                  <p-checkbox
                    label="SGB V"
                    value="SGB V"
                    name="second-person-insured-via"
                    [(ngModel)]="data.second_patient.insured_via"
                    (onChange)="calculateBudget('second_patient')"
                    [required]="true"
                    #second_person_insured_via="ngModel"
                  ></p-checkbox>
                </div>
                <div class="p-field-checkbox">
                  <p-checkbox
                    label="Kostenträger"
                    value="Kostenträger"
                    name="second-person-insured-via"
                    [(ngModel)]="data.second_patient.insured_via"
                    (onChange)="calculateBudget('second_patient')"
                    [required]="true"
                    #second_person_insured_via="ngModel"
                  ></p-checkbox>
                </div>
                <div class="p-field-checkbox">
                  <p-checkbox
                    label="Sonstiges"
                    value="Sonstiges"
                    name="second-person-insured-via"
                    [(ngModel)]="data.second_patient.insured_via"
                    (onChange)="calculateBudget('second_patient')"
                    [required]="true"
                    #second_person_insured_via="ngModel"
                  ></p-checkbox>
                </div>

                <div
                  class="p-field p-col-3"
                  style="margin: 0"
                  *ngIf="data.second_patient.insured_via.includes('Sonstiges')"
                >
                  <input
                    id="second-person-insured_via_other_text"
                    name="second-person-insured_via_other_text"
                    [(ngModel)]="data.second_patient.insured_via_other_text"
                    type="text"
                    pInputText
                  />
                </div>
              </div>
              <div
                *ngIf="
                  second_person_insured_via.invalid &&
                  (second_person_insured_via.dirty ||
                    second_person_insured_via.touched)
                "
                class="p-invalid"
              >
                <div *ngIf="second_person_insured_via.errors?.required">
                  <small>Bitte wählen Sie einen Abrechnungsstatus</small>
                </div>
              </div>
            </div>
          </div>

          <div
            class="p-fluid p-formgrid p-grid"
            *ngIf="
              data.second_patient.insured_via.includes('Selbstzahler') ||
              data.second_patient.insured_via.includes('Kostenträger')
            "
          >
            <div
              class="p-field p-col-4"
              *ngIf="data.second_patient.insured_via.includes('Selbstzahler')"
            >
              <label>Selbstzahler Budget</label>
              <div class="p-formgroup-inline" style="align-items: center">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.second_patient.selbstzahler_budget_type"
                    label="Flexibel"
                    name="second-person-selbstzahler-budget-type"
                    value="Flexibel"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.second_patient.selbstzahler_budget_type"
                    label="Max"
                    name="second-person-selbstzahler-budget-type"
                    value="Max"
                  ></p-radioButton>
                </div>
                <div class="p-inputgroup" style="width: 130px">
                  <p-inputNumber
                    inputId="second-person-selbstzahler-budget-max"
                    name="second-person-selbstzahler-budget-max"
                    [(ngModel)]="data.second_patient.selbstzahler_budget_max"
                    [useGrouping]="false"
                    [disabled]="
                      data.second_patient.selbstzahler_budget_type !== 'Max'
                    "
                    mode="decimal"
                    locale="de-DE"
                    [minFractionDigits]="2"
                  ></p-inputNumber>
                  <span class="p-inputgroup-addon">Std</span>
                </div>
              </div>
            </div>

            <!-- Kostenträger Budget -->
            <div
              class="p-field p-col-3"
              *ngIf="data.second_patient.insured_via.includes('Kostenträger')"
            >
              <label>Kostenträger Budget</label>
              <div class="p-inputgroup kostentraeger-input-number">
                <p-inputNumber
                  inputId="second-person-kostentraeger_budget"
                  name="second-person-kostentraeger_budget"
                  [(ngModel)]="data.second_patient.kostentraeger_budget"
                  [useGrouping]="false"
                  mode="decimal"
                  locale="de-DE"
                  [minFractionDigits]="2"
                ></p-inputNumber>
                <span style="padding: 0 8px" class="p-inputgroup-addon"
                  >Std / Woche</span
                >
              </div>
            </div>

            <!-- Kostenträger Gültigkeit -->
            <div
              class="p-field p-col-3"
              *ngIf="data.second_patient.insured_via.includes('Kostenträger')"
            >
              <label>Kostenträger Gültigkeit</label>
              <div class="p-formgroup-inline" style="align-items: center">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.second_patient.kostentraeger_valid_type"
                    label="Unbegrenzt"
                    name="second-person-kostentraeger_valid_type"
                    value="Unbegrenzt"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.second_patient.kostentraeger_valid_type"
                    label="Datum"
                    name="second-person-kostentraeger_valid_type"
                    value="Datum"
                  ></p-radioButton>
                </div>
              </div>
            </div>

            <!-- Kostenträger Gültig bis -->
            <div
              class="p-field p-col-2"
              *ngIf="
                data.second_patient.insured_via.includes('Kostenträger') &&
                data.second_patient.kostentraeger_valid_type === 'Datum'
              "
            >
              <label>Gültig bis</label>
              <p-calendar
                [firstDayOfWeek]="1"
                [showWeek]="true"
                inputId="second-patient-kostentraeger_valid_until_date_string"
                name="second-patient-kostentraeger_valid_until_date_string"
                dateFormat="dd.mm.yy"
                appendTo="body"
                [(ngModel)]="
                  data.second_patient.kostentraeger_valid_until_date_string
                "
                dataType="string"
                [required]="true"
                #second_patient_kostentraeger_valid_until_date_string="ngModel"
                [showButtonBar]="true"
                [class.p-invalid]="
                  second_patient_kostentraeger_valid_until_date_string.invalid &&
                  (second_patient_kostentraeger_valid_until_date_string.dirty ||
                    second_patient_kostentraeger_valid_until_date_string.touched)
                "
              ></p-calendar>
              <div
                *ngIf="
                  second_patient_kostentraeger_valid_until_date_string.invalid &&
                  (second_patient_kostentraeger_valid_until_date_string.dirty ||
                    second_patient_kostentraeger_valid_until_date_string.touched)
                "
                class="p-invalid"
              >
                <div
                  *ngIf="
                    second_patient_kostentraeger_valid_until_date_string.errors
                      ?.required
                  "
                >
                  <small>Bitte geben Sie ein Datum ein</small>
                </div>
              </div>
            </div>
          </div>

          <!-- Kostenträger Name -->
          <div
            class="p-fluid p-formgrid p-grid"
            *ngIf="data.second_patient.insured_via.includes('Kostenträger')"
          >
            <div class="p-field p-col-4">
              <label for="second-person-cost-unit">
                Kostenträger
                <i
                  class="pi pi-info-circle"
                  pTooltip="Automatische Suche bei 4 Zeichen"
                ></i>
              </label>
              <p-autoComplete
                field="name"
                inputId="second-person-cost-unit"
                name="second-person-cost-unit"
                [minLength]="4"
                [(ngModel)]="data.second_patient.cost_unit"
                [suggestions]="kostentraeger"
                (completeMethod)="searchKostentraeger($event)"
              >
                <ng-template let-multi pTemplate="item">
                  <div>{{ multi.name }}</div>
                </ng-template>
              </p-autoComplete>
            </div>
            <div class="p-field p-col-4">
              <label for="second-person-file-number">Unser Aktenzeichen</label>
              <input
                id="second-person-file-number"
                name="second-person-file-number"
                [(ngModel)]="data.second_patient.file_number"
                type="text"
                pInputText
              />
            </div>
          </div>

          <div
            class="p-fluid p-formgrid p-grid"
            *ngIf="
              !onlySelbstzahlerSelected('second_patient') &&
              data.second_patient.degree_of_care
            "
            [class.alert-highlight]="
              !data.second_patient.request_37 &&
              isGkvOrPkvSelected('second_patient')
            "
          >
            <div
              class="p-field p-col-4"
              [pTooltip]="
                !isGkvOrPkvSelected('second_patient')
                  ? 'Nur bei GKV oder PKV'
                  : ''
              "
            >
              <label for="second-person-nursing-person"
                >Pflegeperson vorhanden?</label
              >
              <div class="p-formgroup-inline has-form-height">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.second_patient.nursing_person"
                    (onClick)="calculateBudget('second_patient')"
                    label="Ja"
                    [disabled]="!isGkvOrPkvSelected('second_patient')"
                    name="second-person-nursing-person"
                    value="Ja"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Nein"
                    [(ngModel)]="data.second_patient.nursing_person"
                    (onClick)="calculateBudget('second_patient')"
                    [disabled]="!isGkvOrPkvSelected('second_patient')"
                    name="second-person-nursing-person"
                    value="Nein"
                  ></p-radioButton>
                </div>
              </div>
            </div>

            <div
              class="p-field p-col-5"
              *ngIf="data.second_patient.nursing_person === 'Ja'"
            >
              <label for="second-person-nursing-person-name"
                >Name Pflegeperson</label
              >
              <input
                id="second-person-nursing-person-name"
                name="second-person-nursing-person-name"
                [(ngModel)]="data.second_patient.nursing_person_name"
                type="text"
                pInputText
              />
            </div>
          </div>

          <div
            class="p-fluid p-formgrid p-grid"
            *ngIf="!onlySelbstzahlerSelected('second_patient')"
          >
            <div class="p-field p-col-4">
              <label for="second-person-degree-of-care">Pflegegrad</label>
              <p-dropdown
                inputId="second-person-degree-of-care"
                name="second-person-degree-of-care"
                placeholder="Auswählen"
                optionValue="value"
                [showClear]="true"
                (onChange)="calculateBudget('second_patient')"
                [(ngModel)]="data.second_patient.degree_of_care"
                [options]="dependencies.degree_of_care_all"
              ></p-dropdown>
            </div>

            <div class="p-field p-col-4">
              <label for="second-person-degree-of-care-since"
                >Pflegegrad seit</label
              >
              <p-calendar
                [firstDayOfWeek]="1"
                [showWeek]="true"
                [showButtonBar]="true"
                inputId="second-person-degree-of-care-since"
                name="second-person-degree-of-care-since"
                [(ngModel)]="data.second_patient.degree_of_care_since_string"
                dateFormat="dd.mm.yy"
                (onSelect)="calculateBudget('second_patient')"
                (onInput)="calculateBudget('second_patient')"
                dataType="string"
              ></p-calendar>
            </div>
          </div>
        </div>
      </div>

      <div class="content limit-width">
        <header class="content-header">
          <span>Leistungsbeginn</span>
        </header>

        <div class="content-body">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-5">
              <label>Einsatzbeginn</label>
              <div class="p-formgroup-inline has-form-height">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Zum nächst möglichen Termin"
                    name="first-person-start-of-operation"
                    [(ngModel)]="data.start_of_operation"
                    value="Zum nächst möglichen Termin"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Datum"
                    name="first-person-start-of-operation"
                    [(ngModel)]="data.start_of_operation"
                    value="Datum"
                  ></p-radioButton>
                </div>
              </div>
            </div>
            <!-- TODO: das datum im backend leeren wenn "Nächster Termin" ausgewählt wurde -->
            <div
              class="p-field p-col-3"
              *ngIf="data.start_of_operation === 'Datum'"
            >
              <label for="start-of-operation-date">Wann</label>
              <p-calendar
                [firstDayOfWeek]="1"
                [showWeek]="true"
                [showButtonBar]="true"
                [(ngModel)]="data.start_of_operation_date_string"
                inputId="start-of-operation-date"
                name="start-of-operation-date"
                dateFormat="dd.mm.yy"
                dataType="string"
              ></p-calendar>
            </div>
          </div>
        </div>
      </div>

      <div class="content limit-width content-highlight-1">
        <header class="content-header">
          <span>Leistungsart Person 1</span>
        </header>

        <div class="content-body">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-6">
              <label>Leistung als *</label>
              <div class="p-formgroup-inline">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.first_patient.service_for"
                    label="Reine HW"
                    name="first-person-service-for"
                    value="Reine HW"
                    #first_person_service_for="ngModel"
                    required
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.first_patient.service_for"
                    label="Reine BE"
                    name="first-person-service-for"
                    value="Reine BE"
                    #first_person_service_for="ngModel"
                    required
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.first_patient.service_for"
                    label="HW und BE"
                    name="first-person-service-for"
                    value="HW und BE"
                    #first_person_service_for="ngModel"
                    required
                  ></p-radioButton>
                </div>
              </div>
              <div
                *ngIf="
                  first_person_service_for.invalid &&
                  (first_person_service_for.dirty ||
                    first_person_service_for.touched)
                "
                class="p-invalid"
              >
                <div *ngIf="first_person_service_for.errors?.required">
                  <small>Bitte wählen Sie etwas aus</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="content limit-width content-highlight-2"
        *ngIf="data.patient_type === 'B'"
      >
        <header class="content-header">
          <span>Leistungsart Person 2</span>
        </header>

        <div class="content-body">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-6">
              <label>Leistung als *</label>
              <div class="p-formgroup-inline">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.second_patient.service_for"
                    label="Reine HW"
                    name="second-person-service-for"
                    value="Reine HW"
                    #second_person_service_for="ngModel"
                    required
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.second_patient.service_for"
                    label="Reine BE"
                    name="second-person-service-for"
                    value="Reine BE"
                    #second_person_service_for="ngModel"
                    required
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.second_patient.service_for"
                    label="HW und BE"
                    name="second-person-service-for"
                    value="HW und BE"
                    #second_person_service_for="ngModel"
                    required
                  ></p-radioButton>
                </div>
              </div>
              <div
                *ngIf="
                  second_person_service_for.invalid &&
                  (second_person_service_for.dirty ||
                    second_person_service_for.touched)
                "
                class="p-invalid"
              >
                <div *ngIf="second_person_service_for.errors?.required">
                  <small>Bitte wählen Sie etwas aus</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="content limit-width">
        <header class="content-header">
          <span>QM</span>
        </header>

        <div class="content-body">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-3">
              <label for="qm-initial-consultation-date">Erstberatung</label>
              <p-calendar
                [firstDayOfWeek]="1"
                [showWeek]="true"
                [showButtonBar]="true"
                [(ngModel)]="data.qm.initial_consultation_date_string"
                inputId="qm-initial-consultation-date"
                name="qm-initial-consultation-date"
                dateFormat="dd.mm.yy"
                dataType="string"
              ></p-calendar>
            </div>
            <div class="p-field p-col-3">
              <label for="qm-initial-consultation-from">Mitarbeiter</label>
              <input
                id="qm-initial-consultation-from"
                name="qm-initial-consultation-from"
                [(ngModel)]="data.qm.initial_consultation_from"
                type="text"
                pInputText
              />
            </div>
            <div class="p-field p-col-4 align-bottom">
              <div class="p-formgroup-inline">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Telefonisch"
                    name="qm-initial-consultation-type"
                    [(ngModel)]="data.qm.initial_consultation_type"
                    value="Telefonisch"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Persönlich"
                    name="qm-initial-consultation-type"
                    [(ngModel)]="data.qm.initial_consultation_type"
                    value="Persönlich"
                  ></p-radioButton>
                </div>
              </div>
            </div>
          </div>

          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
              <label for="comment_new_customer_consultant"
                >Kommentar für NK-Berater</label
              >

              <textarea
                id="comment_new_customer_consultant"
                [rows]="4"
                name="comment_new_customer_consultant"
                [(ngModel)]="data.qm.comment_new_customer_consultant"
                pInputTextarea
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="data.offer_type === 'OFFER'">
      <div class="content limit-width">
        <header class="content-header">
          <span>Personen</span>
        </header>

        <div class="content-body">
          <div class="offer-type-container">
            <div
              class="offer-type"
              (click)="selectPersonType('A')"
              [class.selected]="data.patient_type === 'A'"
            >
              <p-radioButton
                [(ngModel)]="data.patient_type"
                label="A"
                name="patient-type"
                value="A"
              ></p-radioButton>
              <i
                class="pi pi-info-circle"
                pTooltip="Die Person lebt allein, eine weitere Person ist nicht im Haushalt."
              ></i>
            </div>
            <div
              class="offer-type"
              (click)="selectPersonType('B')"
              [class.selected]="data.patient_type === 'B'"
            >
              <p-radioButton
                [(ngModel)]="data.patient_type"
                label="B"
                name="patient-type"
                value="B"
              ></p-radioButton>
              <i
                class="pi pi-info-circle"
                pTooltip="Die Person lebt mit einer weiteren Person im Haushalt. Die zweite Person ist hilfebedürftig."
              ></i>
            </div>
            <div
              class="offer-type"
              (click)="selectPersonType('C')"
              [class.selected]="data.patient_type === 'C'"
            >
              <p-radioButton
                [(ngModel)]="data.patient_type"
                label="C"
                name="patient-type"
                value="C"
              ></p-radioButton>
              <i
                class="pi pi-info-circle"
                pTooltip="Die Person lebt mit einer weiteren Person / Personen im Haushalt. Die zweite Person ist nicht hilfebedürftig. Lediglich Hilfestellung durch die Betreuungskraft in der Hauswirtschaft wird gewünscht (Kochen, Kleiderwaschen, Bügeln, Einkäufe, Raumpflege)."
              ></i>
            </div>

            <div
              class="offer-type"
              [class.selected]="data.consulting_assignment"
            >
              <p-checkbox
                [(ngModel)]="data.consulting_assignment"
                label="Beratungseinsatz"
                name="consulting_assignment"
                [binary]="true"
              ></p-checkbox>
              <!--            <i class="pi pi-info-circle" pTooltip="Beratungseinsatz"></i>-->
            </div>
          </div>
        </div>
      </div>

      <div class="content limit-width content-highlight-1">
        <header class="content-header">
          <span>Grunddaten Person 1</span>
        </header>

        <div class="content-body">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
              <div class="p-formgroup-inline">
                <div class="p-field-checkbox">
                  <p-checkbox
                    label="Ist Kunde bei Pflegeherzen"
                    name="is-pflegeherzen-customer"
                    [binary]="true"
                    [(ngModel)]="data.first_patient.is_pflegeherzen_customer"
                  ></p-checkbox>
                </div>

                <div class="p-field-checkbox">
                  <p-checkbox
                    label="Patient 1 ist AP"
                    [(ngModel)]="data.first_patient.is_contact_person"
                    [binary]="true"
                    name="is_contact_person"
                  ></p-checkbox>
                </div>
              </div>
            </div>
          </div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-3">
              <label for="first-person-form-of-address">Anrede *</label>
              <p-dropdown
                [(ngModel)]="data.first_patient.form_of_address"
                [options]="formOfAddress"
                inputId="first-person-form-of-address"
                #first_person_form_of_address="ngModel"
                name="first-person-form-of-address"
                placeholder="Bitte auswählen"
                [required]="true"
              ></p-dropdown>
              <div
                *ngIf="
                  first_person_form_of_address.invalid &&
                  (first_person_form_of_address.dirty ||
                    first_person_form_of_address.touched)
                "
                class="p-invalid"
              >
                <div *ngIf="first_person_form_of_address.errors?.required">
                  <small>Bitte wählen Sie eine Anrede</small>
                </div>
              </div>
            </div>

            <div class="p-field p-col-3">
              <label for="first-person-first-name">Vorname *</label>
              <input
                id="first-person-first-name"
                name="first-person-first-name"
                [(ngModel)]="data.first_patient.first_name"
                type="text"
                pInputText
                #first_person_first_name="ngModel"
                required
                [class.p-invalid]="
                  first_person_first_name.invalid &&
                  (first_person_first_name.dirty ||
                    first_person_first_name.touched)
                "
              />
              <div
                *ngIf="
                  first_person_first_name.invalid &&
                  (first_person_first_name.dirty ||
                    first_person_first_name.touched)
                "
                class="p-invalid"
              >
                <div *ngIf="first_person_first_name.errors?.required">
                  <small>Bitte geben Sie einen Vornamen ein</small>
                </div>
              </div>
            </div>

            <div class="p-field p-col-3">
              <label for="first-person-last-name">Nachname *</label>
              <input
                id="first-person-last-name"
                name="first-person-last-name"
                [(ngModel)]="data.first_patient.last_name"
                type="text"
                #first_person_last_name="ngModel"
                required
                [class.p-invalid]="
                  first_person_last_name.invalid &&
                  (first_person_last_name.dirty ||
                    first_person_last_name.touched)
                "
                pInputText
              />
              <div
                *ngIf="
                  first_person_last_name.invalid &&
                  (first_person_last_name.dirty ||
                    first_person_last_name.touched)
                "
                class="p-invalid"
              >
                <div *ngIf="first_person_last_name.errors?.required">
                  <small>Bitte geben Sie einen Nachnamen ein</small>
                </div>
              </div>
            </div>

            <div class="p-field p-col-3">
              <label for="first-person-birthday">Geburtsdatum</label>
              <p-calendar
                [firstDayOfWeek]="1"
                [showButtonBar]="false"
                [(ngModel)]="data.first_patient.birthday_date_string"
                inputId="first-person-birthday"
                name="first-person-birthday"
                dateFormat="dd.mm.yy"
                dataType="string"
              ></p-calendar>
            </div>
          </div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-4">
              <label for="first-person-street-house-number"
                >Straße / Hausnummer *</label
              >
              <input
                id="first-person-street-house-number"
                name="first-person-street-house-number"
                [(ngModel)]="data.first_patient.street_house_number"
                type="text"
                pInputText
                #first_person_street_house_number="ngModel"
                required
                [class.p-invalid]="
                  first_person_street_house_number.invalid &&
                  (first_person_street_house_number.dirty ||
                    first_person_street_house_number.touched)
                "
              />
              <div
                *ngIf="
                  first_person_street_house_number.invalid &&
                  (first_person_street_house_number.dirty ||
                    first_person_street_house_number.touched)
                "
                class="p-invalid"
              >
                <div *ngIf="first_person_street_house_number.errors?.required">
                  <small>Bitte geben Sie einen Wert ein</small>
                </div>
              </div>
            </div>

            <div class="p-field p-col-4">
              <label for="first-person-zipcode">PLZ *</label>
              <input
                id="first-person-zipcode"
                name="first-person-zipcode"
                (change)="loadCity('first_patient')"
                [(ngModel)]="data.first_patient.zipcode"
                type="text"
                pInputText
                #first_person_zipcode="ngModel"
                required
                [class.p-invalid]="
                  first_person_zipcode.invalid &&
                  (first_person_zipcode.dirty || first_person_zipcode.touched)
                "
              />
              <div
                *ngIf="
                  first_person_zipcode.invalid &&
                  (first_person_zipcode.dirty || first_person_zipcode.touched)
                "
                class="p-invalid"
              >
                <div *ngIf="first_person_zipcode.errors?.required">
                  <small>Bitte geben Sie einen Wert ein</small>
                </div>
              </div>
            </div>

            <div class="p-field p-col-4">
              <label
                for="first-person-city"
                style="justify-content: space-between"
                >Ort *
                <i
                  class="pi pi-info-circle color-gray"
                  style="font-size: 12px"
                  pTooltip="Wird durch PLZ übernommen"
                ></i
              ></label>
              <span class="p-input-icon-right">
                <i *ngIf="cityLoading" class="pi pi-spin pi-spinner"></i>
                <input
                  id="first-person-city"
                  name="first-person-city"
                  [(ngModel)]="data.first_patient.city"
                  type="text"
                  pInputText
                  #first_person_city="ngModel"
                  required
                  readonly
                  [class.p-invalid]="
                    first_person_city.invalid &&
                    (first_person_city.dirty || first_person_city.touched)
                  "
                />
              </span>
              <div
                *ngIf="
                  first_person_city.invalid &&
                  (first_person_city.dirty || first_person_city.touched)
                "
                class="p-invalid"
              >
                <div *ngIf="first_person_city.errors?.required">
                  <small>Bitte geben Sie einen Wert ein</small>
                </div>
              </div>
            </div>
          </div>

          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-4">
              <label for="first-person-city-part">Ortsteil *</label>
              <span class="p-input-icon-right">
                <input
                  id="first-person-city-part"
                  name="first-person-city-part"
                  [(ngModel)]="data.first_patient.city_part"
                  type="text"
                  pInputText
                  #first_person_city_part="ngModel"
                  required
                  [class.p-invalid]="
                    first_person_city_part.invalid &&
                    (first_person_city_part.dirty ||
                      first_person_city_part.touched)
                  "
                />
              </span>
              <div
                *ngIf="
                  first_person_city_part.invalid &&
                  (first_person_city_part.dirty ||
                    first_person_city_part.touched)
                "
                class="p-invalid"
              >
                <div *ngIf="first_person_city_part.errors?.required">
                  <small>Bitte geben Sie einen Wert ein</small>
                </div>
              </div>
            </div>

            <div class="p-field p-col-4">
              <label
                for="first-person-county"
                style="justify-content: space-between"
                >Landkreis
                <i
                  class="pi pi-info-circle color-gray"
                  style="font-size: 12px"
                  pTooltip="Wird durch PLZ übernommen"
                ></i
              ></label>
              <span class="p-input-icon-right">
                <input
                  id="first-person-county"
                  name="first-person-county"
                  [(ngModel)]="data.first_patient.county"
                  type="text"
                  readonly
                  pInputText
                />
              </span>
            </div>

            <div class="p-field p-col-4">
              <label
                for="first-person-state"
                style="justify-content: space-between"
                >Bundesland *
                <i
                  class="pi pi-info-circle color-gray"
                  style="font-size: 12px"
                  pTooltip="Wird durch PLZ übernommen"
                ></i
              ></label>
              <span class="p-input-icon-right">
                <input
                  id="first-person-state"
                  name="first-person-state"
                  [(ngModel)]="data.first_patient.state"
                  type="text"
                  pInputText
                  #first_person_state="ngModel"
                  required
                  readonly
                  [class.p-invalid]="
                    first_person_state.invalid &&
                    (first_person_state.dirty || first_person_state.touched)
                  "
                />
              </span>
              <div
                *ngIf="
                  first_person_state.invalid &&
                  (first_person_state.dirty || first_person_state.touched)
                "
                class="p-invalid"
              >
                <div *ngIf="first_person_state.errors?.required">
                  <small>Bitte geben Sie einen Wert ein</small>
                </div>
              </div>
            </div>
          </div>

          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
              <label for="first-person-local_specialties"
                >Örtliche Besonderheiten</label
              >
              <textarea
                id="first-person-local_specialties"
                name="first-person-local_specialties"
                [(ngModel)]="data.first_patient.local_specialties"
                pInputTextarea
              ></textarea>
            </div>
          </div>

          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-4">
              <label for="first-person-parking_possible"
                >Parken problemlos möglich?</label
              >
              <div class="p-formgroup-inline has-form-height">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.first_patient.parking_possible"
                    label="Ja"
                    name="first-person-parking_possible"
                    value="Ja"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Nein"
                    [(ngModel)]="data.first_patient.parking_possible"
                    name="first-person-parking_possible"
                    value="Nein"
                  ></p-radioButton>
                </div>
              </div>
            </div>

            <div
              class="p-field p-col-8"
              *ngIf="data.first_patient.parking_possible === 'Nein'"
            >
              <label for="first-person-parking_possible_info">Details</label>
              <input
                id="first-person-parking_possible_info"
                name="first-person-parking_possible_info"
                [(ngModel)]="data.first_patient.parking_possible_info"
                type="text"
                pInputText
              />
            </div>
          </div>

          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-4">
              <label for="attention_from">Aufmerksam durch</label>
              <p-dropdown
                inputId="attention_from"
                name="attention_from"
                placeholder="Bitte auswählen"
                appendTo="body"
                [options]="attentionFrom"
                [(ngModel)]="data.attention_from_id"
                optionLabel="name"
                optionValue="id"
              ></p-dropdown>
            </div>

            <div class="p-field p-col-8">
              <label for="attention-from-info">Weitere Informationen</label>
              <input
                id="attention-from-info"
                name="attention-from-info"
                [(ngModel)]="data.attention_from_info"
                type="text"
                pInputText
              />
            </div>
          </div>

          <!-- Die Empfehlungen sollen nur als Super Admin bearbeitbar sein -->
          <div
            *ngIf="authService.isSuperAdmin() || !alreadyRecommended"
            class="p-fluid p-formgrid p-grid background-hightlight-blue"
            style="padding-bottom: 20px"
          >
            <div class="p-field p-col-6">
              <label for="recommendation_type">Empfehlung durch</label>
              <div class="p-formgroup-inline">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.recommendation_type"
                    label="Keine Empfehlung"
                    name="recommendation_type"
                    [value]="null"
                    (onClick)="resetRecommendation()"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Betreuungskraft"
                    [(ngModel)]="data.recommendation_type"
                    name="recommendation_type"
                    value="CAREGIVER"
                    (onClick)="resetRecommendation()"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Multiplikator"
                    [(ngModel)]="data.recommendation_type"
                    name="recommendation_type"
                    value="MULTIPLIER"
                    (onClick)="resetRecommendation()"
                  ></p-radioButton>
                </div>
              </div>
            </div>

            <div class="p-field p-col-6" *ngIf="data.recommendation_type">
              <label
                >Suche nach
                {{
                  data.recommendation_type === "CAREGIVER"
                    ? "Betreuungskraft"
                    : "Multiplikator"
                }}
                *</label
              >

              <!-- Betreuungskraft -->
              <p-autoComplete
                *ngIf="data.recommendation_type === 'CAREGIVER'"
                field="full_name"
                inputId="recommendation_caregiver"
                name="recommendation_caregiver"
                appendTo="body"
                [forceSelection]="true"
                [(ngModel)]="data.recommendation_id"
                [suggestions]="caregivers"
                (completeMethod)="searchCaregivers($event)"
                [required]="true"
                #recommendation_caregiver="ngModel"
                [class.p-invalid]="
                  recommendation_caregiver.invalid &&
                  (recommendation_caregiver.dirty ||
                    recommendation_caregiver.touched)
                "
              >
                <ng-template let-caregiver pTemplate="item">
                  <div>{{ caregiver.full_name }}</div>
                </ng-template>
              </p-autoComplete>

              <!-- Multiplikator -->
              <p-autoComplete
                *ngIf="data.recommendation_type === 'MULTIPLIER'"
                field="full_name"
                inputId="recommendation_multiplier"
                name="recommendation_multiplier"
                appendTo="body"
                [forceSelection]="true"
                [(ngModel)]="data.recommendation_id"
                [suggestions]="multipliers"
                (completeMethod)="searchMultipliers($event)"
                [required]="true"
                #recommendation_multiplier="ngModel"
                [class.p-invalid]="
                  recommendation_multiplier.invalid &&
                  (recommendation_multiplier.dirty ||
                    recommendation_multiplier.touched)
                "
              >
                <ng-template let-multiplier pTemplate="item">
                  <div>{{ multiplier.full_name }}</div>
                </ng-template>
              </p-autoComplete>
            </div>
          </div>
        </div>
      </div>

      <div class="content limit-width content-highlight-1">
        <header class="content-header">
          <span>Kontaktdaten Person 1</span>
        </header>

        <div class="content-body">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-4">
              <div class="p-field">
                <label for="first-person-phone-1">Telefon 1</label>
                <input
                  id="first-person-phone-1"
                  name="first-person-phone-1"
                  [(ngModel)]="data.first_patient.phone_1"
                  type="text"
                  pInputText
                />
              </div>
              <div class="p-field" style="margin-top: 5px">
                <input
                  id="first-person-phone-1-info"
                  name="first-person-phone-1-info"
                  placeholder="Informationen zu Telefon 1"
                  [(ngModel)]="data.first_patient.phone_1_info"
                  type="text"
                  pInputText
                />
              </div>
            </div>

            <div class="p-field p-col-4">
              <div class="p-field">
                <label for="first-person-phone-2">Telefon 2</label>
                <input
                  id="first-person-phone-2"
                  name="first-person-phone-2"
                  [(ngModel)]="data.first_patient.phone_2"
                  type="text"
                  pInputText
                />
              </div>
              <div class="p-field" style="margin-top: 5px">
                <input
                  id="first-person-phone-2-info"
                  name="first-person-phone-2-info"
                  placeholder="Informationen zu Telefon 2"
                  [(ngModel)]="data.first_patient.phone_2_info"
                  type="text"
                  pInputText
                />
              </div>
            </div>

            <div class="p-field p-col-4">
              <div class="p-field p-col-12">
                <label for="first-person-email">E-Mail</label>
                <input
                  id="first-person-email"
                  name="first-person-email"
                  [(ngModel)]="data.first_patient.email"
                  type="email"
                  pInputText
                  #first_person_email="ngModel"
                  email
                />
              </div>
              <div class="p-field p-col-12" style="margin-top: 5px">
                <input
                  id="first-person-email-info"
                  name="first-person-email-info"
                  placeholder="Informationen zur E-Mail"
                  [(ngModel)]="data.first_patient.email_info"
                  type="email"
                  pInputText
                />

                <div
                  *ngIf="
                    first_person_email.invalid &&
                    (first_person_email.dirty || first_person_email.touched)
                  "
                  class="p-invalid"
                >
                  <div *ngIf="first_person_email.errors?.email">
                    <small>Keine richtige E-Mail</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="content limit-width content-highlight-1">
        <header class="content-header">
          <span> Versicherungs- und Leistungsdaten Person 1 </span>
        </header>

        <div class="content-body">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
              <div
                class="p-formgroup-inline has-form-height"
                style="align-items: center"
              >
                <div class="p-field-checkbox">
                  <p-checkbox
                    label="Selbstzahler"
                    name="first-person-insured-via"
                    value="Selbstzahler"
                    [(ngModel)]="data.first_patient.insured_via"
                    (onChange)="calculateBudget('first_patient')"
                    [required]="true"
                    #first_person_insured_via="ngModel"
                  ></p-checkbox>
                </div>
                <div class="p-field-checkbox">
                  <p-checkbox
                    label="Beihilfe"
                    value="Beihilfe"
                    name="first-person-insured-via"
                    [(ngModel)]="data.first_patient.insured_via"
                    (onChange)="calculateBudget('first_patient')"
                    [required]="true"
                    #first_person_insured_via="ngModel"
                  ></p-checkbox>
                </div>
                <div class="p-field-checkbox">
                  <p-checkbox
                    label="GKV"
                    name="first-person-insured-via"
                    value="GKV"
                    [(ngModel)]="data.first_patient.insured_via"
                    (onChange)="calculateBudget('first_patient')"
                    [required]="true"
                    #first_person_insured_via="ngModel"
                  ></p-checkbox>
                </div>
                <div class="p-field-checkbox">
                  <p-checkbox
                    label="PKV"
                    value="PKV"
                    name="first-person-insured-via"
                    [(ngModel)]="data.first_patient.insured_via"
                    (onChange)="calculateBudget('first_patient')"
                    [required]="true"
                    #first_person_insured_via="ngModel"
                  ></p-checkbox>
                </div>
                <div class="p-field-checkbox">
                  <p-checkbox
                    label="UV"
                    value="UV"
                    name="first-person-insured-via"
                    [(ngModel)]="data.first_patient.insured_via"
                    (onChange)="calculateBudget('first_patient')"
                    [required]="true"
                    #first_person_insured_via="ngModel"
                  ></p-checkbox>
                </div>
                <div class="p-field-checkbox">
                  <p-checkbox
                    label="SGB V"
                    value="SGB V"
                    name="first-person-insured-via"
                    [(ngModel)]="data.first_patient.insured_via"
                    (onChange)="calculateBudget('first_patient')"
                    [required]="true"
                    #first_person_insured_via="ngModel"
                  ></p-checkbox>
                </div>
                <div class="p-field-checkbox">
                  <p-checkbox
                    label="Kostenträger"
                    value="Kostenträger"
                    name="first-person-insured-via"
                    [(ngModel)]="data.first_patient.insured_via"
                    (onChange)="calculateBudget('first_patient')"
                    [required]="true"
                    #first_person_insured_via="ngModel"
                  ></p-checkbox>
                </div>
                <!--              <div class="p-field-checkbox full-width">-->
                <div class="p-field-checkbox">
                  <p-checkbox
                    label="Sonstiges"
                    value="Sonstiges"
                    name="first-person-insured-via"
                    [(ngModel)]="data.first_patient.insured_via"
                    (onChange)="calculateBudget('first_patient')"
                    [required]="true"
                    #first_person_insured_via="ngModel"
                  ></p-checkbox>
                </div>

                <div
                  class="p-field p-col-3"
                  style="margin: 0"
                  *ngIf="data.first_patient.insured_via.includes('Sonstiges')"
                >
                  <input
                    id="first-person-insured_via_other_text"
                    name="first-person-insured_via_other_text"
                    [(ngModel)]="data.first_patient.insured_via_other_text"
                    type="text"
                    pInputText
                  />
                </div>
              </div>
              <div
                *ngIf="
                  first_person_insured_via.invalid &&
                  (first_person_insured_via.dirty ||
                    first_person_insured_via.touched)
                "
                class="p-invalid"
              >
                <div *ngIf="first_person_insured_via.errors?.required">
                  <small>Bitte wählen Sie einen Abrechnungsstatus</small>
                </div>
              </div>
            </div>
          </div>

          <div
            class="p-fluid p-formgrid p-grid"
            *ngIf="
              data.first_patient.insured_via.includes('Selbstzahler') ||
              data.first_patient.insured_via.includes('Kostenträger')
            "
          >
            <!-- Selbstzahler -->
            <div
              class="p-field p-col-4"
              *ngIf="data.first_patient.insured_via.includes('Selbstzahler')"
            >
              <label>Selbstzahler Budget</label>
              <div class="p-formgroup-inline" style="align-items: center">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.first_patient.selbstzahler_budget_type"
                    label="Flexibel"
                    name="first-person-selbstzahler-budget-type"
                    value="Flexibel"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.first_patient.selbstzahler_budget_type"
                    label="Max"
                    name="first-person-selbstzahler-budget-type"
                    value="Max"
                  ></p-radioButton>
                </div>
                <div class="p-inputgroup" style="width: 130px">
                  <p-inputNumber
                    inputId="first-person-selbstzahler-budget-max"
                    name="first-person-selbstzahler-budget-max"
                    [(ngModel)]="data.first_patient.selbstzahler_budget_max"
                    [useGrouping]="false"
                    [disabled]="
                      data.first_patient.selbstzahler_budget_type !== 'Max'
                    "
                    mode="decimal"
                    locale="de-DE"
                    [minFractionDigits]="2"
                  ></p-inputNumber>
                  <span class="p-inputgroup-addon">Std</span>
                </div>
              </div>
            </div>

            <!-- Kostenträger Budget -->
            <div
              class="p-field p-col-3"
              *ngIf="data.first_patient.insured_via.includes('Kostenträger')"
            >
              <label>Kostenträger Budget</label>
              <div class="p-inputgroup kostentraeger-input-number">
                <p-inputNumber
                  inputId="first-person-kostentraeger_budget"
                  name="first-person-kostentraeger_budget"
                  [(ngModel)]="data.first_patient.kostentraeger_budget"
                  [useGrouping]="false"
                  mode="decimal"
                  locale="de-DE"
                  [minFractionDigits]="2"
                ></p-inputNumber>
                <span style="padding: 0 8px" class="p-inputgroup-addon"
                  >Std / Woche</span
                >
              </div>
            </div>

            <!-- Kostenträger Gültigkeit -->
            <div
              class="p-field p-col-3"
              *ngIf="data.first_patient.insured_via.includes('Kostenträger')"
            >
              <label>Kostenträger Gültigkeit</label>
              <div class="p-formgroup-inline" style="align-items: center">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.first_patient.kostentraeger_valid_type"
                    label="Unbegrenzt"
                    name="first-person-kostentraeger_valid_type"
                    value="Unbegrenzt"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.first_patient.kostentraeger_valid_type"
                    label="Datum"
                    name="first-person-kostentraeger_valid_type"
                    value="Datum"
                  ></p-radioButton>
                </div>
              </div>
            </div>

            <!-- Kostenträger Gültig bis -->
            <div
              class="p-field p-col-2"
              *ngIf="
                data.first_patient.insured_via.includes('Kostenträger') &&
                data.first_patient.kostentraeger_valid_type === 'Datum'
              "
            >
              <label>Gültig bis</label>
              <p-calendar
                [firstDayOfWeek]="1"
                [showWeek]="true"
                inputId="first_patient_kostentraeger_valid_until_date_string"
                name="first_patient_kostentraeger_valid_until_date_string"
                dateFormat="dd.mm.yy"
                appendTo="body"
                [(ngModel)]="
                  data.first_patient.kostentraeger_valid_until_date_string
                "
                dataType="string"
                [required]="true"
                #first_patient_kostentraeger_valid_until_date_string="ngModel"
                [showButtonBar]="true"
                [class.p-invalid]="
                  first_patient_kostentraeger_valid_until_date_string.invalid &&
                  (first_patient_kostentraeger_valid_until_date_string.dirty ||
                    first_patient_kostentraeger_valid_until_date_string.touched)
                "
              ></p-calendar>
              <div
                *ngIf="
                  first_patient_kostentraeger_valid_until_date_string.invalid &&
                  (first_patient_kostentraeger_valid_until_date_string.dirty ||
                    first_patient_kostentraeger_valid_until_date_string.touched)
                "
                class="p-invalid"
              >
                <div
                  *ngIf="
                    first_patient_kostentraeger_valid_until_date_string.errors
                      ?.required
                  "
                >
                  <small>Bitte geben Sie ein Datum ein</small>
                </div>
              </div>
            </div>
          </div>

          <!-- Kostenträger Name -->
          <div
            class="p-fluid p-formgrid p-grid"
            *ngIf="data.first_patient.insured_via.includes('Kostenträger')"
          >
            <div class="p-field p-col-4">
              <label for="first-person-cost-unit">
                Kostenträger
                <i
                  class="pi pi-info-circle"
                  pTooltip="Automatische Suche bei 4 Zeichen"
                ></i>
              </label>
              <p-autoComplete
                field="name"
                inputId="first-person-cost-unit"
                name="first-person-cost-unit"
                [minLength]="4"
                [(ngModel)]="data.first_patient.cost_unit"
                [suggestions]="kostentraeger"
                (completeMethod)="searchKostentraeger($event)"
              >
                <ng-template let-multi pTemplate="item">
                  <div>{{ multi.name }}</div>
                </ng-template>
              </p-autoComplete>
            </div>
            <div class="p-field p-col-4">
              <label for="first-person-file-number">Unser Aktenzeichen</label>
              <input
                id="first-person-file-number"
                name="first-person-file-number"
                [(ngModel)]="data.first_patient.file_number"
                type="text"
                pInputText
              />
            </div>
          </div>

          <div
            class="p-fluid p-formgrid p-grid"
            *ngIf="
              !onlySelbstzahlerSelected('first_patient') &&
              data.first_patient.degree_of_care
            "
            [class.alert-highlight]="
              !data.first_patient.request_37 &&
              isGkvOrPkvSelected('first_patient')
            "
          >
            <div
              class="p-field p-col-4"
              [pTooltip]="
                !isGkvOrPkvSelected('first_patient')
                  ? 'Nur bei GKV oder PKV'
                  : ''
              "
            >
              <label for="first-person-nursing-person"
                >Pflegeperson vorhanden?</label
              >
              <div class="p-formgroup-inline has-form-height">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.first_patient.nursing_person"
                    (onClick)="calculateBudget('first_patient')"
                    label="Ja"
                    [disabled]="!isGkvOrPkvSelected('first_patient')"
                    name="first-person-nursing-person"
                    value="Ja"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Nein"
                    [(ngModel)]="data.first_patient.nursing_person"
                    (onClick)="calculateBudget('first_patient')"
                    [disabled]="!isGkvOrPkvSelected('first_patient')"
                    name="first-person-nursing-person"
                    value="Nein"
                  ></p-radioButton>
                </div>
              </div>
            </div>

            <div
              class="p-field p-col-5"
              *ngIf="data.first_patient.nursing_person === 'Ja'"
            >
              <label for="first-person-nursing-person-name"
                >Name Pflegeperson</label
              >
              <input
                id="first-person-nursing-person-name"
                name="first-person-nursing-person-name"
                [(ngModel)]="data.first_patient.nursing_person_name"
                type="text"
                pInputText
              />
            </div>
          </div>
          <div
            class="p-fluid p-formgrid p-grid"
            *ngIf="!onlySelbstzahlerSelected('first_patient')"
          >
            <div class="p-field p-col-4">
              <label for="first-person-degree-of-care">Pflegegrad</label>
              <p-dropdown
                inputId="first-person-degree-of-care"
                name="first-person-degree-of-care"
                placeholder="Auswählen"
                optionValue="value"
                [showClear]="true"
                (onChange)="calculateBudget('first_patient')"
                [(ngModel)]="data.first_patient.degree_of_care"
                [options]="dependencies.degree_of_care_all"
              ></p-dropdown>
            </div>

            <div class="p-field p-col-4">
              <label for="first-person-degree-of-care-since"
                >Pflegegrad seit</label
              >
              <p-calendar
                [firstDayOfWeek]="1"
                [showWeek]="true"
                [showButtonBar]="true"
                inputId="first-person-degree-of-care-since"
                name="first-person-degree-of-care-since"
                [(ngModel)]="data.first_patient.degree_of_care_since_string"
                dateFormat="dd.mm.yy"
                (onSelect)="calculateBudget('first_patient')"
                (onInput)="calculateBudget('first_patient')"
                dataType="string"
              ></p-calendar>
            </div>

            <div class="p-field-checkbox p-col-4">
              <p-checkbox
                label="alter Pflegegrad vorhanden"
                [(ngModel)]="data.first_patient.has_degree_of_care_old"
                [binary]="true"
                (onChange)="calculateBudget('first_patient')"
                name="first_person_has_degree_of_care_old"
              ></p-checkbox>
            </div>
          </div>
          <div
            class="p-fluid p-formgrid p-grid alert-highlight"
            *ngIf="
              data.first_patient.has_degree_of_care_old &&
              !onlySelbstzahlerSelected('first_patient')
            "
          >
            <div class="p-field p-col-4">
              <label for="first-person-degree-of-care-old"
                >Alter Pflegegrad</label
              >
              <p-dropdown
                inputId="first-person-degree-of-care-old"
                name="first-person-degree-of-care-old"
                placeholder="Auswählen"
                optionValue="value"
                [showClear]="true"
                (onChange)="calculateBudget('first_patient')"
                [(ngModel)]="data.first_patient.degree_of_care_old"
                [options]="dependencies.degree_of_care_all"
              ></p-dropdown>
            </div>

            <div class="p-field p-col-4">
              <label for="first-person-degree-of-care-old-since"
                >Alter Pflegegrad seit</label
              >
              <p-calendar
                [firstDayOfWeek]="1"
                [showWeek]="true"
                [showButtonBar]="true"
                inputId="first-person-degree-of-care-old-since"
                name="first-person-degree-of-care-old-since"
                [(ngModel)]="data.first_patient.degree_of_care_old_since_string"
                dateFormat="dd.mm.yy"
                (onSelect)="calculateBudget('first_patient')"
                (onInput)="calculateBudget('first_patient')"
                dataType="string"
              ></p-calendar>
            </div>
          </div>
          <div
            class="p-fluid p-formgrid p-grid"
            *ngIf="!onlySelbstzahlerSelected('first_patient')"
          >
            <div class="p-field p-col-4">
              <label for="first-person-care-insurance-number">
                Pflegekassennummer (IK)
                <i
                  class="pi pi-info-circle"
                  pTooltip="Automatische Suche bei 4 Zeichen"
                ></i>
              </label>
              <p-autoComplete
                field="pflege_ik"
                inputId="first-person-care-insurance-number"
                name="first-person-care-insurance-number"
                [minLength]="4"
                [(ngModel)]="data.first_patient.care_insurance"
                [suggestions]="pflegekassen"
                (completeMethod)="searchPflegekassen($event)"
              >
                <ng-template let-multi pTemplate="item">
                  <div>{{ multi.name }}</div>
                </ng-template>
              </p-autoComplete>
            </div>

            <div class="p-field p-col-4">
              <label for="first-person-care-insurance">Pflegekasse</label>
              <input
                id="first-person-care-insurance"
                name="first-person-care-insurance"
                [(ngModel)]="data.first_patient.care_insurance.name"
                type="text"
                readonly
                pInputText
              />
            </div>

            <div class="p-field p-col-4">
              <label for="first-person-insurer-number">VS Nr</label>
              <input
                id="first-person-insurer-number"
                name="first-person-insurer-number"
                [(ngModel)]="data.first_patient.insurer_number"
                type="text"
                pInputText
              />
            </div>
          </div>

          <!-- Pflege-Gesetze mit BE -->
          <div
            class="p-fluid p-formgrid p-grid"
            *ngIf="data.consulting_assignment"
          >
            <div class="p-field p-col-4">
              <label for="first-person-request_39_question"
                >Wird §39 genutzt?</label
              >
              <div class="p-formgroup-inline has-form-height">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.first_patient.request_39_question"
                    label="Ja"
                    name="first-person-request_39_question"
                    value="Ja"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Nein"
                    [(ngModel)]="data.first_patient.request_39_question"
                    name="first-person-request_39_question"
                    value="Nein"
                  ></p-radioButton>
                </div>
              </div>
            </div>

            <div
              class="p-field p-col-5"
              *ngIf="data.first_patient.request_39_question === 'Ja'"
            >
              <label for="first-person-request_39_question_info">Info</label>
              <input
                id="first-person-request_39_question_info"
                name="first-person-request_39_question_info"
                [(ngModel)]="data.first_patient.request_39_question_info"
                type="text"
                pInputText
              />
            </div>
          </div>

          <!-- Pflege-Gesetze mit BE -->
          <div
            class="p-fluid p-formgrid p-grid"
            *ngIf="data.consulting_assignment"
          >
            <div class="p-field p-col-4">
              <label for="first-person-request_45b_question"
                >Wird §45b genutzt?</label
              >
              <div class="p-formgroup-inline has-form-height">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.first_patient.request_45b_question"
                    label="Ja"
                    name="first-person-request_45b_question"
                    value="Ja"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Nein"
                    [(ngModel)]="data.first_patient.request_45b_question"
                    name="first-person-request_45b_question"
                    value="Nein"
                  ></p-radioButton>
                </div>
              </div>
            </div>

            <div
              class="p-field p-col-5"
              *ngIf="data.first_patient.request_45b_question === 'Ja'"
            >
              <label for="first-person-request_45b_question_info">Info</label>
              <input
                id="first-person-request_45b_question_info"
                name="first-person-request_45b_question_info"
                [(ngModel)]="data.first_patient.request_45b_question_info"
                type="text"
                pInputText
              />
            </div>
          </div>

          <!-- Pflege-Gesetze mit BE -->
          <div
            class="p-fluid p-formgrid p-grid"
            *ngIf="data.consulting_assignment"
          >
            <div class="p-field p-col-4">
              <label for="first-person-nursing_aids">Pflegehilfsmittel?</label>
              <div class="p-formgroup-inline has-form-height">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.first_patient.nursing_aids"
                    label="Ja"
                    name="first-person-nursing_aids"
                    value="Ja"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Nein"
                    [(ngModel)]="data.first_patient.nursing_aids"
                    name="first-person-nursing_aids"
                    value="Nein"
                  ></p-radioButton>
                </div>
              </div>
            </div>

            <div
              class="p-field p-col-5"
              *ngIf="data.first_patient.nursing_aids === 'Ja'"
            >
              <label for="first-person-nursing_aids_info">Info</label>
              <input
                id="first-person-nursing_aids_info"
                name="first-person-nursing_aids_info"
                [(ngModel)]="data.first_patient.nursing_aids_info"
                type="text"
                pInputText
              />
            </div>
          </div>

          <!-- Pflege-Gesetze mit BE -->
          <div
            class="p-fluid p-formgrid p-grid"
            *ngIf="data.consulting_assignment"
          >
            <div class="p-field p-col-4">
              <label for="first-already_consulting_assignment"
                >BE bereits vorhanden?</label
              >
              <div class="p-formgroup-inline has-form-height">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="
                      data.first_patient.already_consulting_assignment
                    "
                    label="Ja"
                    name="first-already_consulting_assignment"
                    value="Ja"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Nein, erster Termin"
                    [(ngModel)]="
                      data.first_patient.already_consulting_assignment
                    "
                    name="first-person-already_consulting_assignment"
                    value="Nein"
                  ></p-radioButton>
                </div>
              </div>
            </div>

            <div
              class="p-field p-col-5"
              *ngIf="data.first_patient.already_consulting_assignment === 'Ja'"
            >
              <label for="first-person-already_consulting_assignment_info"
                >Info</label
              >
              <input
                id="first-person-already_consulting_assignment_info"
                name="first-person-already_consulting_assignment_info"
                [(ngModel)]="
                  data.first_patient.already_consulting_assignment_info
                "
                type="text"
                pInputText
              />
            </div>
          </div>

          <!-- Pflege-Gesetze ohne BE Reihe 1 -->
          <div
            class="p-fluid p-formgrid p-grid"
            *ngIf="!onlySelbstzahlerSelected('first_patient')"
          >
            <div
              class="p-field p-col-4"
              [pTooltip]="get37DisableTooltip('first_patient').tooltip"
            >
              <label for="first-person-request_37"
                >§37 Pflegegeld einplanen?</label
              >
              <div class="p-formgroup-inline">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.first_patient.request_37"
                    label="Ja"
                    [disabled]="get37DisableTooltip('first_patient').disabled"
                    (onClick)="calculateBudget('first_patient')"
                    name="first-person-request_37"
                    value="Ja"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Nein"
                    [disabled]="get37DisableTooltip('first_patient').disabled"
                    (onClick)="calculateBudget('first_patient')"
                    [(ngModel)]="data.first_patient.request_37"
                    name="first-person-request_37"
                    value="Nein"
                  ></p-radioButton>
                </div>
              </div>
            </div>
            <div
              class="p-field p-col-4"
              *ngIf="!isEditMode"
              [pTooltip]="get39DisableTooltip('first_patient').tooltip"
            >
              <label for="first-person-request-39-vpf"
                >§39 VPF einplanen?</label
              >
              <div class="p-formgroup-inline">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.first_patient.request_39_vpf"
                    (onClick)="calculateBudget('first_patient')"
                    [disabled]="get39DisableTooltip('first_patient').disabled"
                    label="Ja"
                    name="first-person-request-39-vpf"
                    value="Ja"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Nein"
                    [disabled]="get39DisableTooltip('first_patient').disabled"
                    [(ngModel)]="data.first_patient.request_39_vpf"
                    (onClick)="calculateBudget('first_patient')"
                    name="first-person-request-39-vpf"
                    value="Nein"
                  ></p-radioButton>
                </div>
              </div>
            </div>
            <div
              *ngIf="!isEditMode"
              class="p-field p-col-4"
              [pTooltip]="get42DisableTooltip('first_patient').tooltip"
            >
              <label for="first-person-request-42-kzpf"
                >§39 plus einplanen?</label
              >
              <div class="p-formgroup-inline">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.first_patient.request_42_kzpf"
                    label="Ja"
                    [disabled]="get42DisableTooltip('first_patient').disabled"
                    (onClick)="calculateBudget('first_patient')"
                    name="first-person-request-42-kzpf"
                    value="Ja"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Nein"
                    [disabled]="get42DisableTooltip('first_patient').disabled"
                    (onClick)="calculateBudget('first_patient')"
                    [(ngModel)]="data.first_patient.request_42_kzpf"
                    name="first-person-request-42-kzpf"
                    value="Nein"
                  ></p-radioButton>
                </div>
              </div>
            </div>
          </div>

          <!-- Pflege-Gesetze ohne BE Reihe 2 -->
          <div
            class="p-fluid p-formgrid p-grid"
            *ngIf="!onlySelbstzahlerSelected('first_patient')"
          >
            <div
              class="p-field p-col-4"
              [pTooltip]="get45aDisableTooltip('first_patient').tooltip"
            >
              <label for="first-person-request-45a"
                >§45a Umwidmung einplanen?</label
              >
              <div class="p-formgroup-inline">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    (onClick)="calculateBudget('first_patient')"
                    [(ngModel)]="data.first_patient.request_45a"
                    label="Ja"
                    [disabled]="get45aDisableTooltip('first_patient').disabled"
                    name="first-person-request-45a"
                    value="Ja"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Nein"
                    (onClick)="calculateBudget('first_patient')"
                    [(ngModel)]="data.first_patient.request_45a"
                    [disabled]="get45aDisableTooltip('first_patient').disabled"
                    name="first-person-request-45a"
                    value="Nein"
                  ></p-radioButton>
                </div>
              </div>
            </div>
            <div class="p-field p-col-4">
              <label for="first-person-request-45b">§45b einplanen?</label>
              <div class="p-formgroup-inline">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.first_patient.request_45b"
                    label="Ja"
                    (onClick)="calculateBudget('first_patient')"
                    name="first-person-request-45b"
                    value="Ja"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Nein"
                    (onClick)="calculateBudget('first_patient')"
                    [(ngModel)]="data.first_patient.request_45b"
                    name="first-person-request-45b"
                    value="Nein"
                  ></p-radioButton>
                </div>
              </div>
            </div>
            <div class="p-field p-col-4">
              <label for="first-person-other-service-provider"
                >Andere Dienstleister?</label
              >
              <div class="p-formgroup-inline">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.first_patient.other_service_provider"
                    label="Ja"
                    name="first-person-other-service-provider"
                    value="Ja"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Nein"
                    [(ngModel)]="data.first_patient.other_service_provider"
                    name="first-person-other-service-provider"
                    value="Nein"
                  ></p-radioButton>
                </div>
              </div>
            </div>
          </div>

          <div
            class="p-fluid p-formgrid p-grid"
            *ngIf="data.first_patient.other_service_provider === 'Ja'"
          >
            <div class="p-field p-col-12">
              <label for="first-person-other-service-provider-text"
                >Derzeitige oder vergangene Dienstleistungen
                <i
                  class="pi pi-info-circle"
                  pTooltip="Pflegedienst, Tagespflege, Demenzbetreuung oder Hauswirtschaft"
                ></i
              ></label>
              <textarea
                id="first-person-other-service-provider-text"
                name="first-person-other-service-provider-text"
                [(ngModel)]="data.first_patient.other_service_provider_text"
                pInputTextarea
              ></textarea>
            </div>
          </div>

          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
              <label for="first-person-budget-information"
                >Kommentar zur Budgetierung</label
              >
              <textarea
                id="first-person-budget-information"
                name="first-person-budget-information"
                [(ngModel)]="data.first_patient.budget_information"
                pInputTextarea
              ></textarea>
            </div>
          </div>

          <div
            class="p-fluid p-formgrid p-grid"
            *ngIf="data.consulting_assignment"
          >
            <div class="p-field p-col-12">
              <label for="first-person-other_service_providers"
                >Andere Dienstleister</label
              >
              <textarea
                id="first-person-other_service_providers"
                name="first-person-other_service_providers"
                [(ngModel)]="data.first_patient.other_service_providers"
                pInputTextarea
              ></textarea>
            </div>
          </div>
        </div>
      </div>

      <div
        class="content limit-width content-highlight-2"
        *ngIf="
          data.patient_type === 'B' ||
          (data.patient_type === 'C' && !isEditMode)
        "
      >
        <header class="content-header">
          <span *ngIf="data.patient_type === 'B'">Grunddaten Person 2</span>
          <span *ngIf="data.patient_type === 'C'"
            >Ansprechpartner im gleichen Haushalt</span
          >
        </header>

        <div class="content-body">
          <div
            class="p-fluid p-formgrid p-grid"
            *ngIf="data.patient_type === 'B'"
          >
            <div class="p-field p-col-12">
              <div class="p-formgroup-inline">
                <div class="p-field-checkbox">
                  <p-checkbox
                    label="Ist Kunde bei Pflegeherzen"
                    name="is-pflegeherzen-customer-second"
                    [binary]="true"
                    [(ngModel)]="data.second_patient.is_pflegeherzen_customer"
                  ></p-checkbox>
                </div>
              </div>
            </div>
          </div>

          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-3" *ngIf="data.second_patient">
              <label for="second-person-form-of-address">Anrede *</label>
              <p-dropdown
                [(ngModel)]="data.second_patient.form_of_address"
                [options]="formOfAddress"
                inputId="second-person-form-of-address"
                #second_person_form_of_address="ngModel"
                name="second-person-form-of-address"
                placeholder="Bitte auswählen"
                [required]="true"
              ></p-dropdown>
              <div
                *ngIf="
                  second_person_form_of_address.invalid &&
                  (second_person_form_of_address.dirty ||
                    second_person_form_of_address.touched)
                "
                class="p-invalid"
              >
                <div *ngIf="second_person_form_of_address.errors?.required">
                  <small>Bitte wählen Sie eine Anrede</small>
                </div>
              </div>
            </div>

            <div class="p-field p-col-3">
              <label for="second-person-first-name">Vorname *</label>
              <input
                id="second-person-first-name"
                name="second-person-first-name"
                [(ngModel)]="data.second_patient.first_name"
                type="text"
                pInputText
                #second_person_first_name="ngModel"
                required
                [class.p-invalid]="
                  second_person_first_name.invalid &&
                  (second_person_first_name.dirty ||
                    second_person_first_name.touched)
                "
              />
              <div
                *ngIf="
                  second_person_first_name.invalid &&
                  (second_person_first_name.dirty ||
                    second_person_first_name.touched)
                "
                class="p-invalid"
              >
                <div *ngIf="second_person_first_name.errors?.required">
                  <small>Bitte geben Sie einen Vornamen ein</small>
                </div>
              </div>
            </div>

            <div class="p-field p-col-3">
              <label for="second-person-last-name">Nachname *</label>
              <input
                id="second-person-last-name"
                name="second-person-last-name"
                [(ngModel)]="data.second_patient.last_name"
                type="text"
                pInputText
                #second_person_last_name="ngModel"
                required
                [class.p-invalid]="
                  first_person_last_name.invalid &&
                  (first_person_last_name.dirty ||
                    first_person_last_name.touched)
                "
              />
              <div
                *ngIf="
                  second_person_last_name.invalid &&
                  (second_person_last_name.dirty ||
                    second_person_last_name.touched)
                "
                class="p-invalid"
              >
                <div *ngIf="second_person_last_name.errors?.required">
                  <small>Bitte geben Sie einen Nachnamen ein</small>
                </div>
              </div>
            </div>

            <div class="p-field p-col-3">
              <label for="second-person-birthday">Geburtsdatum</label>
              <p-calendar
                [showButtonBar]="false"
                [firstDayOfWeek]="1"
                [(ngModel)]="data.second_patient.birthday_date_string"
                inputId="second-person-birthday"
                name="second-person-birthday"
                dateFormat="dd.mm.yy"
                dataType="string"
              ></p-calendar>
            </div>
          </div>
        </div>
      </div>

      <div
        class="content limit-width content-highlight-2"
        *ngIf="
          data.patient_type === 'B' ||
          (data.patient_type === 'C' && !isEditMode)
        "
      >
        <header class="content-header">
          <span *ngIf="data.patient_type === 'B'">Kontaktdaten Person 2</span>
          <span *ngIf="data.patient_type === 'C'"
            >Kontaktdaten Ansprechpartner im gleichen Haushalt</span
          >
        </header>

        <div class="content-body">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-4">
              <div class="p-field">
                <label for="second-person-phone-1">Telefon 1</label>
                <input
                  id="second-person-phone-1"
                  name="second-person-phone-1"
                  [(ngModel)]="data.second_patient.phone_1"
                  type="text"
                  pInputText
                />
              </div>
              <div class="p-field" style="margin-top: 5px">
                <input
                  id="second-person-phone-1-info"
                  name="second-person-phone-1-info"
                  placeholder="Informationen zu Telefon 1"
                  [(ngModel)]="data.second_patient.phone_1_info"
                  type="text"
                  pInputText
                />
              </div>
            </div>

            <div class="p-field p-col-4">
              <div class="p-field">
                <label for="second-person-phone-2">Telefon 2</label>
                <input
                  id="second-person-phone-2"
                  name="second-person-phone-2"
                  [(ngModel)]="data.second_patient.phone_2"
                  type="text"
                  pInputText
                />
              </div>
              <div class="p-field" style="margin-top: 5px">
                <input
                  id="second-person-phone-2-info"
                  name="second-person-phone-2-info"
                  placeholder="Informationen zu Telefon 2"
                  [(ngModel)]="data.second_patient.phone_2_info"
                  type="text"
                  pInputText
                />
              </div>
            </div>

            <div class="p-field p-col-4">
              <div class="p-field">
                <label for="second-person-email">E-Mail</label>
                <input
                  id="second-person-email"
                  name="second-person-email"
                  [(ngModel)]="data.second_patient.email"
                  type="email"
                  pInputText
                  #second_person_email="ngModel"
                  email
                />
              </div>
              <div class="p-field" style="margin-top: 5px">
                <input
                  id="second-person-email-info"
                  name="second-person-email-info"
                  placeholder="Informationen zur E-Mail"
                  [(ngModel)]="data.second_patient.email_info"
                  type="email"
                  pInputText
                />

                <div
                  *ngIf="
                    second_person_email.invalid &&
                    (second_person_email.dirty || second_person_email.touched)
                  "
                  class="p-invalid"
                >
                  <div *ngIf="second_person_email.errors?.email">
                    <small>Keine richtige E-Mail</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="content limit-width content-highlight-2"
        *ngIf="data.patient_type === 'B'"
      >
        <header class="content-header">
          <span>Versicherungs- und Leistungsdaten Person 2</span>
        </header>

        <div class="content-body">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
              <div class="p-formgroup-inline" style="align-items: center">
                <div class="p-field-checkbox">
                  <p-checkbox
                    label="Selbstzahler"
                    name="second-person-insured-via"
                    value="Selbstzahler"
                    [(ngModel)]="data.second_patient.insured_via"
                    (onChange)="calculateBudget('second_patient')"
                    [required]="true"
                    #second_person_insured_via="ngModel"
                  ></p-checkbox>
                </div>
                <div class="p-field-checkbox">
                  <p-checkbox
                    label="Beihilfe"
                    value="Beihilfe"
                    name="second-person-insured-via"
                    [(ngModel)]="data.second_patient.insured_via"
                    (onChange)="calculateBudget('second_patient')"
                    [required]="true"
                    #second_person_insured_via="ngModel"
                  ></p-checkbox>
                </div>
                <div class="p-field-checkbox">
                  <p-checkbox
                    label="GKV"
                    name="second-person-insured-via"
                    value="GKV"
                    [(ngModel)]="data.second_patient.insured_via"
                    (onChange)="calculateBudget('second_patient')"
                    [required]="true"
                    #second_person_insured_via="ngModel"
                  ></p-checkbox>
                </div>
                <div class="p-field-checkbox">
                  <p-checkbox
                    label="PKV"
                    value="PKV"
                    name="second-person-insured-via"
                    [(ngModel)]="data.second_patient.insured_via"
                    (onChange)="calculateBudget('second_patient')"
                    [required]="true"
                    #second_person_insured_via="ngModel"
                  ></p-checkbox>
                </div>
                <div class="p-field-checkbox">
                  <p-checkbox
                    label="UV"
                    value="UV"
                    name="second-person-insured-via"
                    [(ngModel)]="data.second_patient.insured_via"
                    (onChange)="calculateBudget('second_patient')"
                    [required]="true"
                    #second_person_insured_via="ngModel"
                  ></p-checkbox>
                </div>
                <div class="p-field-checkbox">
                  <p-checkbox
                    label="SGB V"
                    value="SGB V"
                    name="second-person-insured-via"
                    [(ngModel)]="data.second_patient.insured_via"
                    (onChange)="calculateBudget('second_patient')"
                    [required]="true"
                    #second_person_insured_via="ngModel"
                  ></p-checkbox>
                </div>
                <div class="p-field-checkbox">
                  <p-checkbox
                    label="Kostenträger"
                    value="Kostenträger"
                    name="second-person-insured-via"
                    [(ngModel)]="data.second_patient.insured_via"
                    (onChange)="calculateBudget('second_patient')"
                    [required]="true"
                    #second_person_insured_via="ngModel"
                  ></p-checkbox>
                </div>
                <div class="p-field-checkbox">
                  <p-checkbox
                    label="Sonstiges"
                    value="Sonstiges"
                    name="second-person-insured-via"
                    [(ngModel)]="data.second_patient.insured_via"
                    (onChange)="calculateBudget('second_patient')"
                    [required]="true"
                    #second_person_insured_via="ngModel"
                  ></p-checkbox>
                </div>

                <div
                  class="p-field p-col-3"
                  style="margin: 0"
                  *ngIf="data.second_patient.insured_via.includes('Sonstiges')"
                >
                  <input
                    id="second-person-insured_via_other_text"
                    name="second-person-insured_via_other_text"
                    [(ngModel)]="data.second_patient.insured_via_other_text"
                    type="text"
                    pInputText
                  />
                </div>
              </div>
              <div
                *ngIf="
                  second_person_insured_via.invalid &&
                  (second_person_insured_via.dirty ||
                    second_person_insured_via.touched)
                "
                class="p-invalid"
              >
                <div *ngIf="second_person_insured_via.errors?.required">
                  <small>Bitte wählen Sie einen Abrechnungsstatus</small>
                </div>
              </div>
            </div>
          </div>

          <div
            class="p-fluid p-formgrid p-grid"
            *ngIf="
              data.second_patient.insured_via.includes('Selbstzahler') ||
              data.second_patient.insured_via.includes('Kostenträger')
            "
          >
            <!-- Selbstzahler -->
            <div
              class="p-field p-col-4"
              *ngIf="data.second_patient.insured_via.includes('Selbstzahler')"
            >
              <label>Selbstzahler Budget</label>
              <div class="p-formgroup-inline" style="align-items: center">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.second_patient.selbstzahler_budget_type"
                    label="Flexibel"
                    name="second-person-selbstzahler-budget-type"
                    value="Flexibel"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.second_patient.selbstzahler_budget_type"
                    label="Max"
                    name="second-person-selbstzahler-budget-type"
                    value="Max"
                  ></p-radioButton>
                </div>
                <div class="p-inputgroup" style="width: 130px">
                  <p-inputNumber
                    inputId="second-person-selbstzahler-budget-max"
                    name="second-person-selbstzahler-budget-max"
                    [(ngModel)]="data.second_patient.selbstzahler_budget_max"
                    [useGrouping]="false"
                    [disabled]="
                      data.second_patient.selbstzahler_budget_type !== 'Max'
                    "
                    mode="decimal"
                    locale="de-DE"
                    [minFractionDigits]="2"
                  ></p-inputNumber>
                  <span class="p-inputgroup-addon">Std</span>
                </div>
              </div>
            </div>

            <!-- Kostenträger Budget -->
            <div
              class="p-field p-col-3"
              *ngIf="data.second_patient.insured_via.includes('Kostenträger')"
            >
              <label>Kostenträger Budget</label>
              <div class="p-inputgroup kostentraeger-input-number">
                <p-inputNumber
                  inputId="second-person-kostentraeger_budget"
                  name="second-person-kostentraeger_budget"
                  [(ngModel)]="data.second_patient.kostentraeger_budget"
                  [useGrouping]="false"
                  mode="decimal"
                  locale="de-DE"
                  [minFractionDigits]="2"
                ></p-inputNumber>
                <span style="padding: 0 8px" class="p-inputgroup-addon"
                  >Std / Woche</span
                >
              </div>
            </div>

            <!-- Kostenträger Gültigkeit -->
            <div
              class="p-field p-col-3"
              *ngIf="data.second_patient.insured_via.includes('Kostenträger')"
            >
              <label>Kostenträger Gültigkeit</label>
              <div class="p-formgroup-inline" style="align-items: center">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.second_patient.kostentraeger_valid_type"
                    label="Unbegrenzt"
                    name="second-person-kostentraeger_valid_type"
                    value="Unbegrenzt"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.second_patient.kostentraeger_valid_type"
                    label="Datum"
                    name="second-person-kostentraeger_valid_type"
                    value="Datum"
                  ></p-radioButton>
                </div>
              </div>
            </div>

            <!-- Kostenträger Gültig bis -->
            <div
              class="p-field p-col-2"
              *ngIf="
                data.second_patient.insured_via.includes('Kostenträger') &&
                data.second_patient.kostentraeger_valid_type === 'Datum'
              "
            >
              <label>Gültig bis</label>
              <p-calendar
                [firstDayOfWeek]="1"
                [showWeek]="true"
                inputId="second-patient-kostentraeger_valid_until_date_string"
                name="second-patient-kostentraeger_valid_until_date_string"
                dateFormat="dd.mm.yy"
                appendTo="body"
                [(ngModel)]="
                  data.second_patient.kostentraeger_valid_until_date_string
                "
                dataType="string"
                [required]="true"
                #second_patient_kostentraeger_valid_until_date_string="ngModel"
                [showButtonBar]="true"
                [class.p-invalid]="
                  second_patient_kostentraeger_valid_until_date_string.invalid &&
                  (second_patient_kostentraeger_valid_until_date_string.dirty ||
                    second_patient_kostentraeger_valid_until_date_string.touched)
                "
              ></p-calendar>
              <div
                *ngIf="
                  second_patient_kostentraeger_valid_until_date_string.invalid &&
                  (second_patient_kostentraeger_valid_until_date_string.dirty ||
                    second_patient_kostentraeger_valid_until_date_string.touched)
                "
                class="p-invalid"
              >
                <div
                  *ngIf="
                    second_patient_kostentraeger_valid_until_date_string.errors
                      ?.required
                  "
                >
                  <small>Bitte geben Sie ein Datum ein</small>
                </div>
              </div>
            </div>
          </div>

          <!-- Kostenträger Name -->
          <div
            class="p-fluid p-formgrid p-grid"
            *ngIf="data.second_patient.insured_via.includes('Kostenträger')"
          >
            <div class="p-field p-col-4">
              <label for="second-person-cost-unit">
                Kostenträger
                <i
                  class="pi pi-info-circle"
                  pTooltip="Automatische Suche bei 4 Zeichen"
                ></i>
              </label>
              <p-autoComplete
                field="name"
                inputId="second-person-cost-unit"
                name="second-person-cost-unit"
                [minLength]="4"
                [(ngModel)]="data.second_patient.cost_unit"
                [suggestions]="kostentraeger"
                (completeMethod)="searchKostentraeger($event)"
              >
                <ng-template let-multi pTemplate="item">
                  <div>{{ multi.name }}</div>
                </ng-template>
              </p-autoComplete>
            </div>
            <div class="p-field p-col-4">
              <label for="second-person-file-number">Unser Aktenzeichen</label>
              <input
                id="second-person-file-number"
                name="second-person-file-number"
                [(ngModel)]="data.second_patient.file_number"
                type="text"
                pInputText
              />
            </div>
          </div>

          <div
            class="p-fluid p-formgrid p-grid"
            *ngIf="
              !onlySelbstzahlerSelected('second_patient') &&
              data.second_patient.degree_of_care
            "
            [class.alert-highlight]="
              !data.second_patient.request_37 &&
              isGkvOrPkvSelected('second_patient')
            "
          >
            <div
              class="p-field p-col-4"
              [pTooltip]="
                !isGkvOrPkvSelected('second_patient')
                  ? 'Nur bei GKV oder PKV'
                  : ''
              "
            >
              <label for="second-person-nursing-person"
                >Pflegeperson vorhanden?</label
              >
              <div class="p-formgroup-inline has-form-height">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.second_patient.nursing_person"
                    (onClick)="calculateBudget('second_patient')"
                    label="Ja"
                    [disabled]="!isGkvOrPkvSelected('second_patient')"
                    name="second-person-nursing-person"
                    value="Ja"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Nein"
                    [(ngModel)]="data.second_patient.nursing_person"
                    (onClick)="calculateBudget('second_patient')"
                    [disabled]="!isGkvOrPkvSelected('second_patient')"
                    name="second-person-nursing-person"
                    value="Nein"
                  ></p-radioButton>
                </div>
              </div>
            </div>

            <div
              class="p-field p-col-5"
              *ngIf="data.second_patient.nursing_person === 'Ja'"
            >
              <label for="second-person-nursing-person-name"
                >Name Pflegeperson</label
              >
              <input
                id="second-person-nursing-person-name"
                name="second-person-nursing-person-name"
                [(ngModel)]="data.second_patient.nursing_person_name"
                type="text"
                pInputText
              />
            </div>
          </div>

          <div
            class="p-fluid p-formgrid p-grid"
            *ngIf="!onlySelbstzahlerSelected('second_patient')"
          >
            <div class="p-field p-col-4">
              <label for="second-person-degree-of-care">Pflegegrad</label>
              <p-dropdown
                inputId="second-person-degree-of-care"
                name="second-person-degree-of-care"
                placeholder="Auswählen"
                optionValue="value"
                [showClear]="true"
                (onChange)="calculateBudget('second_patient')"
                [(ngModel)]="data.second_patient.degree_of_care"
                [options]="dependencies.degree_of_care_all"
              ></p-dropdown>
            </div>

            <div class="p-field p-col-4">
              <label for="second-person-degree-of-care-since"
                >Pflegegrad seit</label
              >
              <p-calendar
                [firstDayOfWeek]="1"
                [showWeek]="true"
                [showButtonBar]="true"
                inputId="second-person-degree-of-care-since"
                name="second-person-degree-of-care-since"
                [(ngModel)]="data.second_patient.degree_of_care_since_string"
                dateFormat="dd.mm.yy"
                (onSelect)="calculateBudget('second_patient')"
                (onInput)="calculateBudget('second_patient')"
                dataType="string"
              ></p-calendar>
            </div>

            <div class="p-field-checkbox p-col-4">
              <p-checkbox
                label="alter Pflegegrad vorhanden"
                [(ngModel)]="data.second_patient.has_degree_of_care_old"
                [binary]="true"
                (onChange)="calculateBudget('second_patient')"
                name="second_person_has_degree_of_care_old"
              ></p-checkbox>
            </div>
            <!--          <div class="p-field p-col-4">-->
            <!--            <label for="second-person-degree-of-care-old"-->
            <!--              >Alter Pflegegrad</label-->
            <!--            >-->
            <!--            <p-dropdown-->
            <!--              inputId="second-person-degree-of-care-old"-->
            <!--              name="second-person-degree-of-care-old"-->
            <!--              placeholder="Auswählen"-->
            <!--              [showClear]="true"-->
            <!--              [(ngModel)]="data.second_patient.degree_of_care_old"-->
            <!--              [options]="dependencies.degree_of_care_all"-->
            <!--            ></p-dropdown>-->
            <!--          </div>-->
          </div>

          <div
            class="p-fluid p-formgrid p-grid alert-highlight"
            *ngIf="
              data.second_patient.has_degree_of_care_old &&
              !onlySelbstzahlerSelected('second_patient')
            "
          >
            <div class="p-field p-col-4">
              <label for="second-person-degree-of-care-old"
                >Alter Pflegegrad</label
              >
              <p-dropdown
                inputId="second-person-degree-of-care-old"
                name="second-person-degree-of-care-old"
                placeholder="Auswählen"
                optionValue="value"
                [showClear]="true"
                (onChange)="calculateBudget('second_patient')"
                [(ngModel)]="data.second_patient.degree_of_care_old"
                [options]="dependencies.degree_of_care_all"
              ></p-dropdown>
            </div>

            <div class="p-field p-col-4">
              <label for="second-person-degree-of-care-old-since"
                >Alter Pflegegrad seit</label
              >
              <p-calendar
                [firstDayOfWeek]="1"
                [showWeek]="true"
                [showButtonBar]="true"
                inputId="second-person-degree-of-care-old-since"
                name="second-person-degree-of-care-old-since"
                [(ngModel)]="
                  data.second_patient.degree_of_care_old_since_string
                "
                dateFormat="dd.mm.yy"
                (onSelect)="calculateBudget('second_patient')"
                (onInput)="calculateBudget('second_patient')"
                dataType="string"
              ></p-calendar>
            </div>
          </div>

          <div
            class="p-fluid p-formgrid p-grid"
            *ngIf="!onlySelbstzahlerSelected('second_patient')"
          >
            <div class="p-field p-col-4">
              <label for="second-person-care-insurance-number">
                Pflegekassennummer (IK)
                <i
                  class="pi pi-info-circle"
                  pTooltip="Automatische Suche bei 4 Zeichen"
                ></i>
              </label>
              <p-autoComplete
                field="pflege_ik"
                inputId="second-person-care-insurance-number"
                name="second-person-care-insurance-number"
                [minLength]="4"
                [(ngModel)]="data.second_patient.care_insurance"
                [suggestions]="pflegekassen"
                (completeMethod)="searchPflegekassen($event)"
              >
                <ng-template let-multi pTemplate="item">
                  <div>{{ multi.name }}</div>
                </ng-template>
              </p-autoComplete>
            </div>

            <div class="p-field p-col-4">
              <label for="second-person-care-insurance">Pflegekasse</label>
              <input
                id="second-person-care-insurance"
                name="second-person-care-insurance"
                [(ngModel)]="data.second_patient.care_insurance.name"
                type="text"
                readonly
                pInputText
              />
            </div>

            <div class="p-field p-col-4">
              <label for="second-person-insurer-number">VS Nr</label>
              <input
                id="second-person-insurer-number"
                name="second-person-insurer-number"
                [(ngModel)]="data.second_patient.insurer_number"
                type="text"
                pInputText
              />
            </div>
          </div>

          <!-- Pflege-Gesetze mit BE -->
          <div
            class="p-fluid p-formgrid p-grid"
            *ngIf="data.consulting_assignment"
          >
            <div class="p-field p-col-4">
              <label for="second-person-request_39_question"
                >Wird §39 genutzt?</label
              >
              <div class="p-formgroup-inline has-form-height">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.second_patient.request_39_question"
                    label="Ja"
                    name="second-person-request_39_question"
                    value="Ja"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Nein"
                    [(ngModel)]="data.second_patient.request_39_question"
                    name="second-person-request_39_question"
                    value="Nein"
                  ></p-radioButton>
                </div>
              </div>
            </div>

            <div
              class="p-field p-col-5"
              *ngIf="data.second_patient.request_39_question === 'Ja'"
            >
              <label for="second-person-request_39_question_info">Info</label>
              <input
                id="second-person-request_39_question_info"
                name="second-person-request_39_question_info"
                [(ngModel)]="data.second_patient.request_39_question_info"
                type="text"
                pInputText
              />
            </div>
          </div>

          <!-- Pflege-Gesetze mit BE -->
          <div
            class="p-fluid p-formgrid p-grid"
            *ngIf="data.consulting_assignment"
          >
            <div class="p-field p-col-4">
              <label for="second-person-request_45b_question"
                >Wird §45b genutzt?</label
              >
              <div class="p-formgroup-inline has-form-height">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.second_patient.request_45b_question"
                    label="Ja"
                    name="second-person-request_45b_question"
                    value="Ja"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Nein"
                    [(ngModel)]="data.second_patient.request_45b_question"
                    name="second-person-request_45b_question"
                    value="Nein"
                  ></p-radioButton>
                </div>
              </div>
            </div>

            <div
              class="p-field p-col-5"
              *ngIf="data.second_patient.request_45b_question === 'Ja'"
            >
              <label for="second-person-request_45b_question_info">Info</label>
              <input
                id="second-person-request_45b_question_info"
                name="second-person-request_45b_question_info"
                [(ngModel)]="data.second_patient.request_45b_question_info"
                type="text"
                pInputText
              />
            </div>
          </div>

          <!-- Pflege-Gesetze mit BE -->
          <div
            class="p-fluid p-formgrid p-grid"
            *ngIf="data.consulting_assignment"
          >
            <div class="p-field p-col-4">
              <label for="second-person-nursing_aids">Pflegehilfsmittel?</label>
              <div class="p-formgroup-inline has-form-height">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.second_patient.nursing_aids"
                    label="Ja"
                    name="second-person-nursing_aids"
                    value="Ja"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Nein"
                    [(ngModel)]="data.second_patient.nursing_aids"
                    name="second-person-nursing_aids"
                    value="Nein"
                  ></p-radioButton>
                </div>
              </div>
            </div>

            <div
              class="p-field p-col-5"
              *ngIf="data.second_patient.nursing_aids === 'Ja'"
            >
              <label for="second-person-nursing_aids_info">Info</label>
              <input
                id="second-person-nursing_aids_info"
                name="second-person-nursing_aids_info"
                [(ngModel)]="data.second_patient.nursing_aids_info"
                type="text"
                pInputText
              />
            </div>
          </div>

          <!-- Pflege-Gesetze mit BE -->
          <div
            class="p-fluid p-formgrid p-grid"
            *ngIf="data.consulting_assignment"
          >
            <div class="p-field p-col-4">
              <label for="second-already_consulting_assignment"
                >BE bereits vorhanden?</label
              >
              <div class="p-formgroup-inline has-form-height">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="
                      data.second_patient.already_consulting_assignment
                    "
                    label="Ja"
                    name="second-already_consulting_assignment"
                    value="Ja"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Nein, erster Termin"
                    [(ngModel)]="
                      data.second_patient.already_consulting_assignment
                    "
                    name="second-person-already_consulting_assignment"
                    value="Nein"
                  ></p-radioButton>
                </div>
              </div>
            </div>

            <div
              class="p-field p-col-5"
              *ngIf="data.second_patient.already_consulting_assignment === 'Ja'"
            >
              <label for="second-person-already_consulting_assignment_info"
                >Info</label
              >
              <input
                id="second-person-already_consulting_assignment_info"
                name="second-person-already_consulting_assignment_info"
                [(ngModel)]="
                  data.second_patient.already_consulting_assignment_info
                "
                type="text"
                pInputText
              />
            </div>
          </div>

          <!-- Pflege-Gesetze ohne BE Reihe 1 -->
          <div
            class="p-fluid p-formgrid p-grid"
            *ngIf="!onlySelbstzahlerSelected('second_patient')"
          >
            <div
              class="p-field p-col-4"
              [pTooltip]="get37DisableTooltip('second_patient').tooltip"
            >
              <label for="second-person-request_37"
                >§37 Pflegegeld einplanen?</label
              >
              <div class="p-formgroup-inline">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.second_patient.request_37"
                    label="Ja"
                    [disabled]="get37DisableTooltip('second_patient').disabled"
                    (onClick)="calculateBudget('second_patient')"
                    name="second-person-request_37"
                    value="Ja"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Nein"
                    [disabled]="get37DisableTooltip('second_patient').disabled"
                    (onClick)="calculateBudget('second_patient')"
                    [(ngModel)]="data.second_patient.request_37"
                    name="second-person-request_37"
                    value="Nein"
                  ></p-radioButton>
                </div>
              </div>
            </div>

            <div
              *ngIf="!isEditMode"
              class="p-field p-col-4"
              [pTooltip]="get39DisableTooltip('second_patient').tooltip"
            >
              <label for="second-person-request-39-vpf"
                >§39 VPF einplanen?</label
              >
              <div class="p-formgroup-inline">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.second_patient.request_39_vpf"
                    (onClick)="calculateBudget('second_patient')"
                    [disabled]="get39DisableTooltip('second_patient').disabled"
                    label="Ja"
                    name="second-person-request-39-vpf"
                    value="Ja"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Nein"
                    [disabled]="get39DisableTooltip('second_patient').disabled"
                    [(ngModel)]="data.second_patient.request_39_vpf"
                    (onClick)="calculateBudget('second_patient')"
                    name="second-person-request-39-vpf"
                    value="Nein"
                  ></p-radioButton>
                </div>
              </div>
            </div>
            <div
              *ngIf="!isEditMode"
              class="p-field p-col-4"
              [pTooltip]="get42DisableTooltip('second_patient').tooltip"
            >
              <label for="second-person-request-42-kzpf"
                >§39 plus einplanen?</label
              >
              <div class="p-formgroup-inline">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.second_patient.request_42_kzpf"
                    label="Ja"
                    [disabled]="get42DisableTooltip('second_patient').disabled"
                    (onClick)="calculateBudget('second_patient')"
                    name="second-person-request-42-kzpf"
                    value="Ja"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Nein"
                    [disabled]="get42DisableTooltip('second_patient').disabled"
                    (onClick)="calculateBudget('second_patient')"
                    [(ngModel)]="data.second_patient.request_42_kzpf"
                    name="second-person-request-42-kzpf"
                    value="Nein"
                  ></p-radioButton>
                </div>
              </div>
            </div>
          </div>

          <!-- Pflege-Gesetze ohne BE Reihe 2 -->
          <div
            class="p-fluid p-formgrid p-grid"
            *ngIf="!onlySelbstzahlerSelected('second_patient')"
          >
            <div
              class="p-field p-col-4"
              [pTooltip]="get45aDisableTooltip('second_patient').tooltip"
            >
              <label for="second-person-request-45a"
                >§45a Umwidmung einplanen?</label
              >
              <div class="p-formgroup-inline">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    (onClick)="calculateBudget('second_patient')"
                    [disabled]="get45aDisableTooltip('second_patient').disabled"
                    [(ngModel)]="data.second_patient.request_45a"
                    label="Ja"
                    name="second-person-request-45a"
                    value="Ja"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Nein"
                    (onClick)="calculateBudget('second_patient')"
                    [disabled]="get45aDisableTooltip('second_patient').disabled"
                    [(ngModel)]="data.second_patient.request_45a"
                    name="second-person-request-45a"
                    value="Nein"
                  ></p-radioButton>
                </div>
              </div>
            </div>

            <div class="p-field p-col-4">
              <label for="second-person-request-45b">§45b einplanen?</label>
              <div class="p-formgroup-inline">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.second_patient.request_45b"
                    label="Ja"
                    (onClick)="calculateBudget('second_patient')"
                    name="second-person-request-45b"
                    value="Ja"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Nein"
                    (onClick)="calculateBudget('second_patient')"
                    [(ngModel)]="data.second_patient.request_45b"
                    name="second-person-request-45b"
                    value="Nein"
                  ></p-radioButton>
                </div>
              </div>
            </div>

            <div class="p-field p-col-4">
              <label for="second-person-other-service-provider"
                >Andere Dienstleister?</label
              >
              <div class="p-formgroup-inline">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.second_patient.other_service_provider"
                    label="Ja"
                    name="second-person-other-service-provider"
                    value="Ja"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Nein"
                    [(ngModel)]="data.second_patient.other_service_provider"
                    name="second-person-other-service-provider"
                    value="Nein"
                  ></p-radioButton>
                </div>
              </div>
            </div>
          </div>

          <div
            class="p-fluid p-formgrid p-grid"
            *ngIf="data.second_patient.other_service_provider === 'Ja'"
          >
            <div class="p-field p-col-12">
              <label for="second-person-other-service-provider-text"
                >Derzeitige oder vergangene Dienstleistungen
                <i
                  class="pi pi-info-circle"
                  pTooltip="Pflegedienst, Tagespflege, Demenzbetreuung oder Hauswirtschaft"
                ></i
              ></label>
              <textarea
                id="second-person-other-service-provider-text"
                name="second-person-other-service-provider-text"
                [(ngModel)]="data.second_patient.other_service_provider_text"
                pInputTextarea
              ></textarea>
            </div>
          </div>

          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
              <label for="second-person-budget-information"
                >Kommentar zur Budgetierung</label
              >
              <textarea
                id="second-person-budget-information"
                name="second-person-budget-information"
                [(ngModel)]="data.second_patient.budget_information"
                pInputTextarea
              ></textarea>
            </div>
          </div>

          <div
            class="p-fluid p-formgrid p-grid"
            *ngIf="data.consulting_assignment"
          >
            <div class="p-field p-col-12">
              <label for="second-person-other_service_providers"
                >Andere Dienstleister</label
              >
              <textarea
                id="second-person-other_service_providers"
                name="second-person-other_service_providers"
                [(ngModel)]="data.second_patient.other_service_providers"
                pInputTextarea
              ></textarea>
            </div>
          </div>
        </div>
      </div>

      <div class="content limit-width" *ngIf="!isEditMode">
        <header class="content-header">
          <span class="p-justify-between">
            Ansprechpartner
            <p-checkbox
              label="Patient 1 ist AP"
              [(ngModel)]="data.first_patient.is_contact_person"
              [binary]="true"
              name="is_contact_person"
            ></p-checkbox>
          </span>

          <span *ngIf="!data.first_patient.is_contact_person">
            <p-checkbox
              label="für Notfall"
              [(ngModel)]="data.contact_person.is_emergency"
              [binary]="true"
              name="contact_person_is_emergency"
            ></p-checkbox>
            <p-checkbox
              label="für Termine"
              [(ngModel)]="data.contact_person.is_for_appointments"
              [binary]="true"
              name="contact_person_for_appointments"
            ></p-checkbox>
            <p-checkbox
              label="für Rechnungen"
              [(ngModel)]="data.contact_person.is_for_invoices"
              [binary]="true"
              name="contact_person_for_invoices"
            ></p-checkbox>
            <p-checkbox
              label="AP vor Ort"
              [(ngModel)]="data.contact_person.is_at_home"
              [binary]="true"
              name="contact_person_at_home"
            ></p-checkbox>
          </span>
        </header>

        <div class="content-body" *ngIf="!data.first_patient.is_contact_person">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-4">
              <label for="contact-person-form-of-address">Anrede</label>
              <p-dropdown
                [(ngModel)]="data.contact_person.form_of_address"
                [options]="formOfAddress"
                inputId="contact-person-form-of-address"
                name="contact-partner-form-of-address"
                placeholder="Bitte auswählen"
              ></p-dropdown>
            </div>

            <div class="p-field p-col-4">
              <label for="contact-person-first-name">Vorname</label>
              <input
                id="contact-person-first-name"
                name="contact-person-first-name"
                [(ngModel)]="data.contact_person.first_name"
                type="text"
                pInputText
              />
            </div>

            <div class="p-field p-col-4">
              <label for="contact-person-last-name">Nachname</label>
              <input
                id="contact-person-last-name"
                name="contact-person-last-name"
                [(ngModel)]="data.contact_person.last_name"
                type="text"
                pInputText
              />
            </div>
          </div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-6">
              <label for="contact-person-relation">Verhältnis</label>
              <input
                id="contact-person-relation"
                name="contact-person-relation"
                [(ngModel)]="data.contact_person.relation"
                type="text"
                pInputText
              />
            </div>

            <div class="p-field p-col-6">
              <label for="contact_person-birthday">Geburtsdatum</label>
              <p-calendar
                [firstDayOfWeek]="1"
                [showButtonBar]="false"
                [(ngModel)]="data.contact_person.birthday_string"
                inputId="contact_person-birthday"
                name="contact_person-birthday"
                dateFormat="dd.mm.yy"
                dataType="string"
              ></p-calendar>
            </div>
          </div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-4">
              <label for="contact-person-street-house-number"
                >Straße / Hausnummer</label
              >
              <input
                id="contact-person-street-house-number"
                name="contact-person-street-house-number"
                [(ngModel)]="data.contact_person.street_house_number"
                type="text"
                pInputText
              />
            </div>

            <div class="p-field p-col-4">
              <label for="contact-person-zipcode">PLZ</label>
              <input
                (change)="loadCity('contact_person')"
                id="contact-person-zipcode"
                name="contact-person-zipcode"
                [(ngModel)]="data.contact_person.zipcode"
                type="text"
                pInputText
              />
            </div>

            <div class="p-field p-col-4">
              <label for="contact-person-city">Ort</label>
              <span class="p-input-icon-right">
                <i *ngIf="cityLoading" class="pi pi-spin pi-spinner"></i>
                <input
                  id="contact-person-city"
                  name="contact-person-city"
                  [(ngModel)]="data.contact_person.city"
                  type="text"
                  readonly
                  pInputText
                />
              </span>
            </div>
          </div>

          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-4">
              <label for="contact-person-city-part">Ortsteil</label>
              <span class="p-input-icon-right">
                <input
                  id="contact-person-city-part"
                  name="contact-person-city-part"
                  [(ngModel)]="data.contact_person.city_part"
                  type="text"
                  pInputText
                />
              </span>
            </div>

            <div class="p-field p-col-4">
              <label for="contact-person-county">Landkreis</label>
              <span class="p-input-icon-right">
                <input
                  id="contact-person-county"
                  name="contact-person-county"
                  [(ngModel)]="data.contact_person.county"
                  type="text"
                  readonly
                  pInputText
                />
              </span>
            </div>

            <div class="p-field p-col-4">
              <label for="contact-person-state">Bundesland</label>
              <span class="p-input-icon-right">
                <input
                  id="contact-person-state"
                  name="contact-person-state"
                  [(ngModel)]="data.contact_person.state"
                  type="text"
                  readonly
                  pInputText
                />
              </span>
            </div>
          </div>

          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-3">
              <div class="p-field">
                <label for="contact-person-email-private">E-Mail Privat</label>
                <input
                  id="contact-person-email-private"
                  name="contact-person-email"
                  [(ngModel)]="data.contact_person.email_private"
                  type="email"
                  pInputText
                  #contact_person_email_private="ngModel"
                  email
                />
              </div>
              <div class="p-field" style="margin-top: 5px">
                <input
                  id="contact-person-email-private-info"
                  name="contact-person-email-private-info"
                  placeholder="Informationen zur E-Mail"
                  [(ngModel)]="data.contact_person.email_private_info"
                  type="text"
                  pInputText
                />

                <div
                  *ngIf="
                    contact_person_email_private.invalid &&
                    (contact_person_email_private.dirty ||
                      contact_person_email_private.touched)
                  "
                  class="p-invalid"
                >
                  <div *ngIf="contact_person_email_private.errors?.email">
                    <small>Keine richtige E-Mail</small>
                  </div>
                </div>
              </div>
            </div>

            <div class="p-field p-col-3">
              <div class="p-field">
                <label for="contact-person-email-work"
                  >E-Mail Geschäftlich</label
                >
                <input
                  id="contact-person-email-work"
                  name="contact-person-email-work"
                  [(ngModel)]="data.contact_person.email_work"
                  type="email"
                  pInputText
                  #contact_person_email_work="ngModel"
                  email
                />
              </div>
              <div class="p-field" style="margin-top: 5px">
                <input
                  id="contact-person-email-work-info"
                  name="contact-person-email-work-info"
                  placeholder="Informationen zur E-Mail"
                  [(ngModel)]="data.contact_person.email_work_info"
                  type="text"
                  pInputText
                />

                <div
                  *ngIf="
                    contact_person_email_work.invalid &&
                    (contact_person_email_work.dirty ||
                      contact_person_email_work.touched)
                  "
                  class="p-invalid"
                >
                  <div *ngIf="contact_person_email_work.errors?.email">
                    <small>Keine richtige E-Mail</small>
                  </div>
                </div>
              </div>
            </div>

            <div class="p-field p-col-3">
              <div class="p-field">
                <label for="contact-person-phone-1">Telefon 1</label>
                <input
                  id="contact-person-phone-1"
                  name="contact-person-phone-1"
                  [(ngModel)]="data.contact_person.phone_1"
                  type="text"
                  pInputText
                />
              </div>
              <div class="p-field" style="margin-top: 5px">
                <input
                  id="contact-person-email-phone_1-info"
                  name="contact-person-email-phone_1-info"
                  placeholder="Informationen zu Telefon 1"
                  [(ngModel)]="data.contact_person.phone_1_info"
                  type="text"
                  pInputText
                />
              </div>
            </div>

            <div class="p-field p-col-3">
              <div class="p-field">
                <label for="contact-person-phone-2">Telefon 2</label>
                <input
                  id="contact-person-phone-2"
                  name="contact-person-phone-2"
                  [(ngModel)]="data.contact_person.phone_2"
                  type="text"
                  pInputText
                />
              </div>
              <div class="p-field" style="margin-top: 5px">
                <input
                  id="contact-person-email-phone_2-info"
                  name="contact-person-email-phone_2-info"
                  placeholder="Informationen zu Telefon 2"
                  [(ngModel)]="data.contact_person.phone_2_info"
                  type="text"
                  pInputText
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="content limit-width">
        <header class="content-header">
          <span>Leistungsbeginn</span>
        </header>

        <div class="content-body">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-5">
              <label>Einsatzbeginn</label>
              <div class="p-formgroup-inline has-form-height">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Zum nächst möglichen Termin"
                    name="first-person-start-of-operation"
                    [(ngModel)]="data.start_of_operation"
                    value="Zum nächst möglichen Termin"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Datum"
                    name="first-person-start-of-operation"
                    [(ngModel)]="data.start_of_operation"
                    value="Datum"
                  ></p-radioButton>
                </div>
              </div>
            </div>
            <!-- TODO: das datum im backend leeren wenn "Nächster Termin" ausgewählt wurde -->
            <div
              class="p-field p-col-3"
              *ngIf="data.start_of_operation === 'Datum'"
            >
              <label for="start-of-operation-date">Wann</label>
              <p-calendar
                [firstDayOfWeek]="1"
                [showWeek]="true"
                [showButtonBar]="true"
                [(ngModel)]="data.start_of_operation_date_string"
                inputId="start-of-operation-date"
                name="start-of-operation-date"
                dateFormat="dd.mm.yy"
                dataType="string"
              ></p-calendar>
            </div>
          </div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
              <label for="first-person-service-scope">Leistungsumfang</label>
              <textarea
                id="first-person-service-scope"
                name="first-person-service-scope"
                [(ngModel)]="data.service_scope"
                pInputTextarea
              ></textarea>
            </div>
          </div>
          <div
            class="p-fluid p-formgrid p-grid"
            *ngIf="data.consulting_assignment && !isEditMode"
          >
            <div
              class="p-field p-col-3"
              style="display: flex; align-items: center"
            >
              <button
                pButton
                (click)="openConsultingDialog()"
                label="Beratungseinsatz bearbeiten"
                type="button"
                icon="pi pi-pencil"
                iconPos="left"
              ></button>
            </div>

            <div class="p-field p-col-4 p-offset-1">
              <label>Beratungseinsatz am</label>
              <strong *ngIf="previewConsulting"
                >{{ previewConsulting.home_visit_date_string }}
                {{ previewConsulting.home_visit_time }}</strong
              >
              <strong *ngIf="!previewConsulting">-</strong>
            </div>

            <div class="p-field p-col-4">
              <label>Beratungseinsatz mit</label>
              <strong *ngIf="previewConsulting">{{
                previewConsulting.home_visit_from
              }}</strong>
              <strong *ngIf="!previewConsulting">-</strong>
            </div>
          </div>
        </div>
      </div>

      <div class="content limit-width content-highlight-1">
        <header class="content-header">
          <span>Leistungsart Person 1</span>
        </header>

        <div class="content-body">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-2">
              <label for="first-person-hours-per-month"
                >Stunden pro Monat
                {{ data.consulting_assignment ? "" : "*" }}</label
              >
              <div class="p-inputgroup">
                <p-inputNumber
                  inputId="first-person-hours-per-month"
                  name="first-person-hours-per-month"
                  [(ngModel)]="data.first_patient.hours_per_month"
                  [useGrouping]="false"
                  [required]="!data.consulting_assignment"
                  mode="decimal"
                  locale="de-DE"
                  [minFractionDigits]="2"
                  #first_person_hours_per_month="ngModel"
                  [class.p-invalid]="
                    first_person_hours_per_month.invalid &&
                    (first_person_hours_per_month.dirty ||
                      first_person_hours_per_month.touched)
                  "
                ></p-inputNumber>

                <span class="p-inputgroup-addon">Std</span>
              </div>
              <div
                *ngIf="
                  first_person_hours_per_month.invalid &&
                  (first_person_hours_per_month.dirty ||
                    first_person_hours_per_month.touched)
                "
                class="p-invalid"
              >
                <div *ngIf="first_person_hours_per_month.errors?.required">
                  <small>Bitte geben Sie einen Wert ein</small>
                </div>
              </div>
            </div>
            <div class="p-field p-col-9 p-offset-1">
              <label>Intervall</label>
              <div class="p-formgroup-inline">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.first_patient.appointment_interval"
                    label="Täglich"
                    name="first-person-interval"
                    value="Täglich"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.first_patient.appointment_interval"
                    label="Wöchentlich"
                    name="first-person-interval"
                    value="Wöchentlich"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.first_patient.appointment_interval"
                    label="Alle 2 Wochen"
                    name="first-person-interval"
                    value="Alle 2 Wochen"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.first_patient.appointment_interval"
                    label="Monatlich"
                    name="first-person-interval"
                    value="Monatlich"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.first_patient.appointment_interval"
                    label="Flexibel"
                    name="first-person-interval"
                    value="Flexibel"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.first_patient.appointment_interval"
                    label="Fixer Termin"
                    name="first-person-interval"
                    value="Fixer Termin"
                  ></p-radioButton>
                </div>
              </div>
            </div>
          </div>

          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
              <div class="caregiver-times-container">
                <p-checkbox
                  [binary]="true"
                  [(ngModel)]="data.first_patient.flexible_times"
                  label="Flexible Zeiten"
                  (onChange)="setFlexibleTimes($event, 'first_patient')"
                  name="first_patient_flexible_times"
                ></p-checkbox>
              </div>
            </div>
            <div class="p-field p-col-12">
              <!-- Montag -->
              <div class="caregiver-times-container">
                <p-checkbox
                  [(ngModel)]="
                    data.first_patient.times.days_with_key.monday.checked
                  "
                  [binary]="true"
                  (onChange)="timeChecked($event, 'monday', 'first_patient')"
                  label="Montag"
                  name="first_patient_monday"
                ></p-checkbox>
                <div class="caregiver-times-lines p-flex-row">
                  <!-- Montag erster Block -->
                  <div class="caregiver-times-line">
                    <span>von</span>
                    <p-dropdown
                      (onChange)="
                        checkForTimes($event, 'monday', 'first_patient')
                      "
                      [(ngModel)]="
                        data.first_patient.times.days_with_key.monday
                          .start_first
                      "
                      [disabled]="
                        !data.first_patient.times.days_with_key.monday.checked
                      "
                      scrollHeight="500px"
                      appendTo="body"
                      name="first_patient_monday_start_first"
                      placeholder="-"
                      [options]="times"
                      optionValue="value"
                      optionLabel="value"
                      [editable]="false"
                      [required]="
                        data.first_patient.times.days_with_key.monday.checked
                      "
                      #first_patient_monday_start_first="ngModel"
                      [class.p-invalid]="
                        errors.first_patient_monday_start_first ||
                        (first_patient_monday_start_first.invalid &&
                          (first_patient_monday_start_first.dirty ||
                            first_patient_monday_start_first.touched))
                      "
                    ></p-dropdown>
                    <span>bis</span>
                    <p-dropdown
                      (onChange)="
                        checkForTimes($event, 'monday', 'first_patient')
                      "
                      [(ngModel)]="
                        data.first_patient.times.days_with_key.monday.end_first
                      "
                      [disabled]="
                        !data.first_patient.times.days_with_key.monday.checked
                      "
                      scrollHeight="500px"
                      appendTo="body"
                      placeholder="-"
                      name="first_patient_monday_end_first"
                      [options]="times"
                      optionValue="value"
                      optionLabel="value"
                      [editable]="false"
                      [required]="
                        data.first_patient.times.days_with_key.monday.checked
                      "
                      #first_patient_monday_end_first="ngModel"
                      [class.p-invalid]="
                        errors.first_patient_monday_end_first ||
                        (first_patient_monday_end_first.invalid &&
                          (first_patient_monday_end_first.dirty ||
                            first_patient_monday_end_first.touched))
                      "
                    ></p-dropdown>
                    <i
                      (click)="setSecondBlock('monday', 'first_patient')"
                      *ngIf="
                        data.first_patient.times.days_with_key.monday.checked &&
                        !data.first_patient.times.days_with_key.monday
                          .has_second_block
                      "
                      pTooltip="Neuer Block"
                      class="pi pi-plus"
                    ></i>
                  </div>

                  <!-- Montag zweiter Block -->
                  <div
                    class="caregiver-times-line"
                    *ngIf="
                      data.first_patient.times.days_with_key.monday
                        .has_second_block
                    "
                  >
                    <span class="color-main1"><strong>oder</strong></span>
                    <span>von</span>
                    <p-dropdown
                      (onChange)="
                        checkForTimes($event, 'monday', 'first_patient')
                      "
                      [(ngModel)]="
                        data.first_patient.times.days_with_key.monday
                          .start_second
                      "
                      [disabled]="
                        !data.first_patient.times.days_with_key.monday.checked
                      "
                      scrollHeight="500px"
                      appendTo="body"
                      placeholder="-"
                      name="first_patient_monday_start_second"
                      [options]="times"
                      optionValue="value"
                      optionLabel="value"
                      [editable]="false"
                      [required]="
                        data.first_patient.times.days_with_key.monday.checked
                      "
                      #first_patient_monday_start_second="ngModel"
                      [class.p-invalid]="
                        errors.first_patient_monday_start_second ||
                        (first_patient_monday_start_second.invalid &&
                          (first_patient_monday_start_second.dirty ||
                            first_patient_monday_start_second.touched))
                      "
                    ></p-dropdown>
                    <span>bis</span>
                    <p-dropdown
                      (onChange)="
                        checkForTimes($event, 'monday', 'first_patient')
                      "
                      [(ngModel)]="
                        data.first_patient.times.days_with_key.monday.end_second
                      "
                      [disabled]="
                        !data.first_patient.times.days_with_key.monday.checked
                      "
                      scrollHeight="500px"
                      appendTo="body"
                      placeholder="-"
                      name="first_patient_monday_end_second"
                      [options]="times"
                      optionValue="value"
                      optionLabel="value"
                      [editable]="false"
                      [required]="
                        data.first_patient.times.days_with_key.monday.checked
                      "
                      #first_patient_monday_end_second="ngModel"
                      [class.p-invalid]="
                        errors.first_patient_monday_end_second ||
                        (first_patient_monday_end_second.invalid &&
                          (first_patient_monday_end_second.dirty ||
                            first_patient_monday_end_second.touched))
                      "
                    ></p-dropdown>
                    <i
                      *ngIf="
                        data.first_patient.times.days_with_key.monday.checked &&
                        data.first_patient.times.days_with_key.monday
                          .has_second_block
                      "
                      (click)="removeSecondBlock('monday', 'first_patient')"
                      pTooltip="Block entfernen"
                      class="pi pi-minus"
                    ></i>
                  </div>
                </div>
              </div>

              <!-- Dienstag -->
              <div class="caregiver-times-container">
                <p-checkbox
                  [(ngModel)]="
                    data.first_patient.times.days_with_key.tuesday.checked
                  "
                  [binary]="true"
                  (onChange)="timeChecked($event, 'tuesday', 'first_patient')"
                  label="Dienstag"
                  name="first_patient_tuesday"
                ></p-checkbox>
                <div class="caregiver-times-lines p-flex-row">
                  <!-- Dienstag erster Block -->
                  <div class="caregiver-times-line">
                    <span>von</span>
                    <p-dropdown
                      (onChange)="
                        checkForTimes($event, 'tuesday', 'first_patient')
                      "
                      [(ngModel)]="
                        data.first_patient.times.days_with_key.tuesday
                          .start_first
                      "
                      [disabled]="
                        !data.first_patient.times.days_with_key.tuesday.checked
                      "
                      scrollHeight="500px"
                      appendTo="body"
                      placeholder="-"
                      name="first_patient_tuesday_start_first"
                      [options]="times"
                      optionValue="value"
                      optionLabel="value"
                      [editable]="false"
                      [required]="
                        data.first_patient.times.days_with_key.tuesday.checked
                      "
                      #first_patient_tuesday_start_first="ngModel"
                      [class.p-invalid]="
                        errors.first_patient_tuesday_start_first ||
                        (first_patient_tuesday_start_first.invalid &&
                          (first_patient_tuesday_start_first.dirty ||
                            first_patient_tuesday_start_first.touched))
                      "
                    ></p-dropdown>
                    <span>bis</span>
                    <p-dropdown
                      (onChange)="
                        checkForTimes($event, 'tuesday', 'first_patient')
                      "
                      [(ngModel)]="
                        data.first_patient.times.days_with_key.tuesday.end_first
                      "
                      [disabled]="
                        !data.first_patient.times.days_with_key.tuesday.checked
                      "
                      scrollHeight="500px"
                      appendTo="body"
                      placeholder="-"
                      name="first_patient_tuesday_end_first"
                      [options]="times"
                      optionValue="value"
                      optionLabel="value"
                      [editable]="false"
                      [required]="
                        data.first_patient.times.days_with_key.tuesday.checked
                      "
                      #first_patient_tuesday_end_first="ngModel"
                      [class.p-invalid]="
                        errors.first_patient_tuesday_end_first ||
                        (first_patient_tuesday_end_first.invalid &&
                          (first_patient_tuesday_end_first.dirty ||
                            first_patient_tuesday_end_first.touched))
                      "
                    ></p-dropdown>
                    <i
                      (click)="setSecondBlock('tuesday', 'first_patient')"
                      *ngIf="
                        data.first_patient.times.days_with_key.tuesday
                          .checked &&
                        !data.first_patient.times.days_with_key.tuesday
                          .has_second_block
                      "
                      pTooltip="Neuer Block"
                      class="pi pi-plus"
                    ></i>
                  </div>

                  <!-- Dienstag zweiter Block -->
                  <div
                    class="caregiver-times-line"
                    *ngIf="
                      data.first_patient.times.days_with_key.tuesday
                        .has_second_block
                    "
                  >
                    <span class="color-main1"><strong>oder</strong></span>
                    <span>von</span>
                    <p-dropdown
                      (onChange)="
                        checkForTimes($event, 'tuesday', 'first_patient')
                      "
                      [(ngModel)]="
                        data.first_patient.times.days_with_key.tuesday
                          .start_second
                      "
                      [disabled]="
                        !data.first_patient.times.days_with_key.tuesday.checked
                      "
                      scrollHeight="500px"
                      appendTo="body"
                      placeholder="-"
                      name="first_patient_tuesday_start_second"
                      [options]="times"
                      optionValue="value"
                      optionLabel="value"
                      [editable]="false"
                      [required]="
                        data.first_patient.times.days_with_key.tuesday.checked
                      "
                      #first_patient_tuesday_start_second="ngModel"
                      [class.p-invalid]="
                        errors.first_patient_tuesday_start_second ||
                        (first_patient_tuesday_start_second.invalid &&
                          (first_patient_tuesday_start_second.dirty ||
                            first_patient_tuesday_start_second.touched))
                      "
                    ></p-dropdown>
                    <span>bis</span>
                    <p-dropdown
                      (onChange)="
                        checkForTimes($event, 'tuesday', 'first_patient')
                      "
                      [(ngModel)]="
                        data.first_patient.times.days_with_key.tuesday
                          .end_second
                      "
                      [disabled]="
                        !data.first_patient.times.days_with_key.tuesday.checked
                      "
                      scrollHeight="500px"
                      appendTo="body"
                      placeholder="-"
                      name="first_patient_tuesday_end_second"
                      [options]="times"
                      optionValue="value"
                      optionLabel="value"
                      [editable]="false"
                      [required]="
                        data.first_patient.times.days_with_key.tuesday.checked
                      "
                      #first_patient_tuesday_end_second="ngModel"
                      [class.p-invalid]="
                        errors.first_patient_tuesday_end_second ||
                        (first_patient_tuesday_end_second.invalid &&
                          (first_patient_tuesday_end_second.dirty ||
                            first_patient_tuesday_end_second.touched))
                      "
                    ></p-dropdown>
                    <i
                      *ngIf="
                        data.first_patient.times.days_with_key.tuesday
                          .checked &&
                        data.first_patient.times.days_with_key.tuesday
                          .has_second_block
                      "
                      (click)="removeSecondBlock('tuesday', 'first_patient')"
                      pTooltip="Block entfernen"
                      class="pi pi-minus"
                    ></i>
                  </div>
                </div>
              </div>

              <!-- Mittwoch -->
              <div class="caregiver-times-container">
                <p-checkbox
                  [(ngModel)]="
                    data.first_patient.times.days_with_key.wednesday.checked
                  "
                  [binary]="true"
                  (onChange)="timeChecked($event, 'wednesday', 'first_patient')"
                  label="Mittwoch"
                  name="first_patient_wednesday"
                ></p-checkbox>
                <div class="caregiver-times-lines p-flex-row">
                  <!-- Mittwoch erster Block -->
                  <div class="caregiver-times-line">
                    <span>von</span>
                    <p-dropdown
                      (onChange)="
                        checkForTimes($event, 'wednesday', 'first_patient')
                      "
                      [(ngModel)]="
                        data.first_patient.times.days_with_key.wednesday
                          .start_first
                      "
                      [disabled]="
                        !data.first_patient.times.days_with_key.wednesday
                          .checked
                      "
                      scrollHeight="500px"
                      appendTo="body"
                      placeholder="-"
                      name="first_patient_wednesday_start_first"
                      [options]="times"
                      optionValue="value"
                      optionLabel="value"
                      [editable]="false"
                      [required]="
                        data.first_patient.times.days_with_key.wednesday.checked
                      "
                      #first_patient_wednesday_start_first="ngModel"
                      [class.p-invalid]="
                        errors.first_patient_wednesday_start_first ||
                        (first_patient_wednesday_start_first.invalid &&
                          (first_patient_wednesday_start_first.dirty ||
                            first_patient_wednesday_start_first.touched))
                      "
                    ></p-dropdown>
                    <span>bis</span>
                    <p-dropdown
                      (onChange)="
                        checkForTimes($event, 'wednesday', 'first_patient')
                      "
                      [(ngModel)]="
                        data.first_patient.times.days_with_key.wednesday
                          .end_first
                      "
                      [disabled]="
                        !data.first_patient.times.days_with_key.wednesday
                          .checked
                      "
                      scrollHeight="500px"
                      appendTo="body"
                      placeholder="-"
                      name="first_patient_wednesday_end_first"
                      [options]="times"
                      optionValue="value"
                      optionLabel="value"
                      [editable]="false"
                      [required]="
                        data.first_patient.times.days_with_key.wednesday.checked
                      "
                      #first_patient_wednesday_end_first="ngModel"
                      [class.p-invalid]="
                        errors.first_patient_wednesday_end_first ||
                        (first_patient_wednesday_end_first.invalid &&
                          (first_patient_wednesday_end_first.dirty ||
                            first_patient_wednesday_end_first.touched))
                      "
                    ></p-dropdown>
                    <i
                      (click)="setSecondBlock('wednesday', 'first_patient')"
                      *ngIf="
                        data.first_patient.times.days_with_key.wednesday
                          .checked &&
                        !data.first_patient.times.days_with_key.wednesday
                          .has_second_block
                      "
                      pTooltip="Neuer Block"
                      class="pi pi-plus"
                    ></i>
                  </div>

                  <!-- Mittwoch zweiter Block -->
                  <div
                    class="caregiver-times-line"
                    *ngIf="
                      data.first_patient.times.days_with_key.wednesday
                        .has_second_block
                    "
                  >
                    <span class="color-main1"><strong>oder</strong></span>
                    <span>von</span>
                    <p-dropdown
                      (onChange)="
                        checkForTimes($event, 'wednesday', 'first_patient')
                      "
                      [(ngModel)]="
                        data.first_patient.times.days_with_key.wednesday
                          .start_second
                      "
                      [disabled]="
                        !data.first_patient.times.days_with_key.wednesday
                          .checked
                      "
                      scrollHeight="500px"
                      appendTo="body"
                      placeholder="-"
                      name="first_patient_wednesday_start_second"
                      [options]="times"
                      optionValue="value"
                      optionLabel="value"
                      [editable]="false"
                      [required]="
                        data.first_patient.times.days_with_key.wednesday.checked
                      "
                      #first_patient_wednesday_start_second="ngModel"
                      [class.p-invalid]="
                        errors.first_patient_wednesday_start_second ||
                        (first_patient_wednesday_start_second.invalid &&
                          (first_patient_wednesday_start_second.dirty ||
                            first_patient_wednesday_start_second.touched))
                      "
                    ></p-dropdown>
                    <span>bis</span>
                    <p-dropdown
                      (onChange)="
                        checkForTimes($event, 'wednesday', 'first_patient')
                      "
                      [(ngModel)]="
                        data.first_patient.times.days_with_key.wednesday
                          .end_second
                      "
                      [disabled]="
                        !data.first_patient.times.days_with_key.wednesday
                          .checked
                      "
                      scrollHeight="500px"
                      appendTo="body"
                      placeholder="-"
                      name="first_patient_wednesday_end_second"
                      [options]="times"
                      optionValue="value"
                      optionLabel="value"
                      [editable]="false"
                      [required]="
                        data.first_patient.times.days_with_key.wednesday.checked
                      "
                      #first_patient_wednesday_end_second="ngModel"
                      [class.p-invalid]="
                        errors.first_patient_wednesday_end_second ||
                        (first_patient_wednesday_end_second.invalid &&
                          (first_patient_wednesday_end_second.dirty ||
                            first_patient_wednesday_end_second.touched))
                      "
                    ></p-dropdown>
                    <i
                      *ngIf="
                        data.first_patient.times.days_with_key.wednesday
                          .checked &&
                        data.first_patient.times.days_with_key.wednesday
                          .has_second_block
                      "
                      (click)="removeSecondBlock('wednesday', 'first_patient')"
                      pTooltip="Block entfernen"
                      class="pi pi-minus"
                    ></i>
                  </div>
                </div>
              </div>

              <!-- Donnerstag -->
              <div class="caregiver-times-container">
                <p-checkbox
                  [(ngModel)]="
                    data.first_patient.times.days_with_key.thursday.checked
                  "
                  [binary]="true"
                  (onChange)="timeChecked($event, 'thursday', 'first_patient')"
                  label="Donnerstag"
                  name="first_patient_thursday"
                ></p-checkbox>
                <div class="caregiver-times-lines p-flex-row">
                  <!-- Donnerstag erster Block -->
                  <div class="caregiver-times-line">
                    <span>von</span>
                    <p-dropdown
                      (onChange)="
                        checkForTimes($event, 'thursday', 'first_patient')
                      "
                      [(ngModel)]="
                        data.first_patient.times.days_with_key.thursday
                          .start_first
                      "
                      [disabled]="
                        !data.first_patient.times.days_with_key.thursday.checked
                      "
                      scrollHeight="500px"
                      appendTo="body"
                      placeholder="-"
                      name="first_patient_thursday_start_first"
                      [options]="times"
                      optionValue="value"
                      optionLabel="value"
                      [editable]="false"
                      [required]="
                        data.first_patient.times.days_with_key.thursday.checked
                      "
                      #first_patient_thursday_start_first="ngModel"
                      [class.p-invalid]="
                        errors.first_patient_thursday_start_first ||
                        (first_patient_thursday_start_first.invalid &&
                          (first_patient_thursday_start_first.dirty ||
                            first_patient_thursday_start_first.touched))
                      "
                    ></p-dropdown>
                    <span>bis</span>
                    <p-dropdown
                      (onChange)="
                        checkForTimes($event, 'thursday', 'first_patient')
                      "
                      [(ngModel)]="
                        data.first_patient.times.days_with_key.thursday
                          .end_first
                      "
                      [disabled]="
                        !data.first_patient.times.days_with_key.thursday.checked
                      "
                      scrollHeight="500px"
                      appendTo="body"
                      placeholder="-"
                      name="first_patient_thursday_end_first"
                      [options]="times"
                      optionValue="value"
                      optionLabel="value"
                      [editable]="false"
                      [required]="
                        data.first_patient.times.days_with_key.thursday.checked
                      "
                      #first_patient_thursday_end_first="ngModel"
                      [class.p-invalid]="
                        errors.first_patient_thursday_end_first ||
                        (first_patient_thursday_end_first.invalid &&
                          (first_patient_thursday_end_first.dirty ||
                            first_patient_thursday_end_first.touched))
                      "
                    ></p-dropdown>
                    <i
                      (click)="setSecondBlock('thursday', 'first_patient')"
                      *ngIf="
                        data.first_patient.times.days_with_key.thursday
                          .checked &&
                        !data.first_patient.times.days_with_key.thursday
                          .has_second_block
                      "
                      pTooltip="Neuer Block"
                      class="pi pi-plus"
                    ></i>
                  </div>

                  <!-- Donnerstag zweiter Block -->
                  <div
                    class="caregiver-times-line"
                    *ngIf="
                      data.first_patient.times.days_with_key.thursday
                        .has_second_block
                    "
                  >
                    <span class="color-main1"><strong>oder</strong></span>
                    <span>von</span>
                    <p-dropdown
                      (onChange)="
                        checkForTimes($event, 'thursday', 'first_patient')
                      "
                      [(ngModel)]="
                        data.first_patient.times.days_with_key.thursday
                          .start_second
                      "
                      [disabled]="
                        !data.first_patient.times.days_with_key.thursday.checked
                      "
                      scrollHeight="500px"
                      appendTo="body"
                      placeholder="-"
                      name="first_patient_thursday_start_second"
                      [options]="times"
                      optionValue="value"
                      optionLabel="value"
                      [editable]="false"
                      [required]="
                        data.first_patient.times.days_with_key.thursday.checked
                      "
                      #first_patient_thursday_start_second="ngModel"
                      [class.p-invalid]="
                        errors.first_patient_thursday_start_second ||
                        (first_patient_thursday_start_second.invalid &&
                          (first_patient_thursday_start_second.dirty ||
                            first_patient_thursday_start_second.touched))
                      "
                    ></p-dropdown>
                    <span>bis</span>
                    <p-dropdown
                      (onChange)="
                        checkForTimes($event, 'thursday', 'first_patient')
                      "
                      [(ngModel)]="
                        data.first_patient.times.days_with_key.thursday
                          .end_second
                      "
                      [disabled]="
                        !data.first_patient.times.days_with_key.thursday.checked
                      "
                      scrollHeight="500px"
                      appendTo="body"
                      placeholder="-"
                      name="first_patient_thursday_end_second"
                      [options]="times"
                      optionValue="value"
                      optionLabel="value"
                      [editable]="false"
                      [required]="
                        data.first_patient.times.days_with_key.thursday.checked
                      "
                      #first_patient_thursday_end_second="ngModel"
                      [class.p-invalid]="
                        errors.first_patient_thursday_end_second ||
                        (first_patient_thursday_end_second.invalid &&
                          (first_patient_thursday_end_second.dirty ||
                            first_patient_thursday_end_second.touched))
                      "
                    ></p-dropdown>
                    <i
                      *ngIf="
                        data.first_patient.times.days_with_key.thursday
                          .checked &&
                        data.first_patient.times.days_with_key.thursday
                          .has_second_block
                      "
                      (click)="removeSecondBlock('thursday', 'first_patient')"
                      pTooltip="Block entfernen"
                      class="pi pi-minus"
                    ></i>
                  </div>
                </div>
              </div>

              <!-- Freitag -->
              <div class="caregiver-times-container">
                <p-checkbox
                  [(ngModel)]="
                    data.first_patient.times.days_with_key.friday.checked
                  "
                  [binary]="true"
                  (onChange)="timeChecked($event, 'friday', 'first_patient')"
                  label="Freitag"
                  name="first_patient_friday"
                ></p-checkbox>
                <div class="caregiver-times-lines p-flex-row">
                  <!-- Freitag erster Block -->
                  <div class="caregiver-times-line">
                    <span>von</span>
                    <p-dropdown
                      (onChange)="
                        checkForTimes($event, 'friday', 'first_patient')
                      "
                      [(ngModel)]="
                        data.first_patient.times.days_with_key.friday
                          .start_first
                      "
                      [disabled]="
                        !data.first_patient.times.days_with_key.friday.checked
                      "
                      scrollHeight="500px"
                      appendTo="body"
                      placeholder="-"
                      name="first_patient_friday_start_first"
                      [options]="times"
                      optionValue="value"
                      optionLabel="value"
                      [editable]="false"
                      [required]="
                        data.first_patient.times.days_with_key.friday.checked
                      "
                      #first_patient_friday_start_first="ngModel"
                      [class.p-invalid]="
                        errors.first_patient_friday_start_first ||
                        (first_patient_friday_start_first.invalid &&
                          (first_patient_friday_start_first.dirty ||
                            first_patient_friday_start_first.touched))
                      "
                    ></p-dropdown>
                    <span>bis</span>
                    <p-dropdown
                      (onChange)="
                        checkForTimes($event, 'friday', 'first_patient')
                      "
                      [(ngModel)]="
                        data.first_patient.times.days_with_key.friday.end_first
                      "
                      [disabled]="
                        !data.first_patient.times.days_with_key.friday.checked
                      "
                      scrollHeight="500px"
                      appendTo="body"
                      placeholder="-"
                      name="first_patient_friday_end_first"
                      [options]="times"
                      optionValue="value"
                      optionLabel="value"
                      [editable]="false"
                      [required]="
                        data.first_patient.times.days_with_key.friday.checked
                      "
                      #first_patient_friday_end_first="ngModel"
                      [class.p-invalid]="
                        errors.first_patient_friday_end_first ||
                        (first_patient_friday_end_first.invalid &&
                          (first_patient_friday_end_first.dirty ||
                            first_patient_friday_end_first.touched))
                      "
                    ></p-dropdown>
                    <i
                      (click)="setSecondBlock('friday', 'first_patient')"
                      *ngIf="
                        data.first_patient.times.days_with_key.friday.checked &&
                        !data.first_patient.times.days_with_key.friday
                          .has_second_block
                      "
                      pTooltip="Neuer Block"
                      class="pi pi-plus"
                    ></i>
                  </div>

                  <!-- Freitag zweiter Block -->
                  <div
                    class="caregiver-times-line"
                    *ngIf="
                      data.first_patient.times.days_with_key.friday
                        .has_second_block
                    "
                  >
                    <span class="color-main1"><strong>oder</strong></span>
                    <span>von</span>
                    <p-dropdown
                      (onChange)="
                        checkForTimes($event, 'friday', 'first_patient')
                      "
                      [(ngModel)]="
                        data.first_patient.times.days_with_key.friday
                          .start_second
                      "
                      [disabled]="
                        !data.first_patient.times.days_with_key.friday.checked
                      "
                      scrollHeight="500px"
                      appendTo="body"
                      placeholder="-"
                      name="first_patient_friday_start_second"
                      [options]="times"
                      optionValue="value"
                      optionLabel="value"
                      [editable]="false"
                      [required]="
                        data.first_patient.times.days_with_key.friday.checked
                      "
                      #first_patient_friday_start_second="ngModel"
                      [class.p-invalid]="
                        errors.first_patient_friday_start_second ||
                        (first_patient_friday_start_second.invalid &&
                          (first_patient_friday_start_second.dirty ||
                            first_patient_friday_start_second.touched))
                      "
                    ></p-dropdown>
                    <span>bis</span>
                    <p-dropdown
                      (onChange)="
                        checkForTimes($event, 'friday', 'first_patient')
                      "
                      [(ngModel)]="
                        data.first_patient.times.days_with_key.friday.end_second
                      "
                      [disabled]="
                        !data.first_patient.times.days_with_key.friday.checked
                      "
                      scrollHeight="500px"
                      appendTo="body"
                      placeholder="-"
                      name="first_patient_friday_end_second"
                      [options]="times"
                      optionValue="value"
                      optionLabel="value"
                      [editable]="false"
                      [required]="
                        data.first_patient.times.days_with_key.friday.checked
                      "
                      #first_patient_friday_end_second="ngModel"
                      [class.p-invalid]="
                        errors.first_patient_friday_end_second ||
                        (first_patient_friday_end_second.invalid &&
                          (first_patient_friday_end_second.dirty ||
                            first_patient_friday_end_second.touched))
                      "
                    ></p-dropdown>
                    <i
                      *ngIf="
                        data.first_patient.times.days_with_key.friday.checked &&
                        data.first_patient.times.days_with_key.friday
                          .has_second_block
                      "
                      (click)="removeSecondBlock('friday', 'first_patient')"
                      pTooltip="Block entfernen"
                      class="pi pi-minus"
                    ></i>
                  </div>
                </div>
              </div>

              <!-- Samstag -->
              <div class="caregiver-times-container">
                <p-checkbox
                  [(ngModel)]="
                    data.first_patient.times.days_with_key.saturday.checked
                  "
                  [binary]="true"
                  (onChange)="timeChecked($event, 'saturday', 'first_patient')"
                  label="Samstag"
                  name="first_patient_saturday"
                ></p-checkbox>
                <div class="caregiver-times-lines p-flex-row">
                  <!-- Samstag erster Block -->
                  <div class="caregiver-times-line">
                    <span>von</span>
                    <p-dropdown
                      (onChange)="
                        checkForTimes($event, 'saturday', 'first_patient')
                      "
                      [(ngModel)]="
                        data.first_patient.times.days_with_key.saturday
                          .start_first
                      "
                      [disabled]="
                        !data.first_patient.times.days_with_key.saturday.checked
                      "
                      scrollHeight="500px"
                      appendTo="body"
                      placeholder="-"
                      name="first_patient_saturday_start_first"
                      [options]="times"
                      optionValue="value"
                      optionLabel="value"
                      [editable]="false"
                      [required]="
                        data.first_patient.times.days_with_key.saturday.checked
                      "
                      #first_patient_saturday_start_first="ngModel"
                      [class.p-invalid]="
                        errors.first_patient_saturday_start_first ||
                        (first_patient_saturday_start_first.invalid &&
                          (first_patient_saturday_start_first.dirty ||
                            first_patient_saturday_start_first.touched))
                      "
                    ></p-dropdown>
                    <span>bis</span>
                    <p-dropdown
                      (onChange)="
                        checkForTimes($event, 'saturday', 'first_patient')
                      "
                      [(ngModel)]="
                        data.first_patient.times.days_with_key.saturday
                          .end_first
                      "
                      [disabled]="
                        !data.first_patient.times.days_with_key.saturday.checked
                      "
                      scrollHeight="500px"
                      appendTo="body"
                      placeholder="-"
                      name="first_patient_saturday_end_first"
                      [options]="times"
                      optionValue="value"
                      optionLabel="value"
                      [editable]="false"
                      [required]="
                        data.first_patient.times.days_with_key.saturday.checked
                      "
                      #first_patient_saturday_end_first="ngModel"
                      [class.p-invalid]="
                        errors.first_patient_saturday_end_first ||
                        (first_patient_saturday_end_first.invalid &&
                          (first_patient_saturday_end_first.dirty ||
                            first_patient_saturday_end_first.touched))
                      "
                    ></p-dropdown>
                    <i
                      (click)="setSecondBlock('saturday', 'first_patient')"
                      *ngIf="
                        data.first_patient.times.days_with_key.saturday
                          .checked &&
                        !data.first_patient.times.days_with_key.saturday
                          .has_second_block
                      "
                      pTooltip="Neuer Block"
                      class="pi pi-plus"
                    ></i>
                  </div>

                  <!-- Samstag zweiter Block -->
                  <div
                    class="caregiver-times-line"
                    *ngIf="
                      data.first_patient.times.days_with_key.saturday
                        .has_second_block
                    "
                  >
                    <span class="color-main1"><strong>oder</strong></span>
                    <span>von</span>
                    <p-dropdown
                      (onChange)="
                        checkForTimes($event, 'saturday', 'first_patient')
                      "
                      [(ngModel)]="
                        data.first_patient.times.days_with_key.saturday
                          .start_second
                      "
                      [disabled]="
                        !data.first_patient.times.days_with_key.saturday.checked
                      "
                      scrollHeight="500px"
                      appendTo="body"
                      placeholder="-"
                      name="first_patient_saturday_start_second"
                      [options]="times"
                      optionValue="value"
                      optionLabel="value"
                      [editable]="false"
                      [required]="
                        data.first_patient.times.days_with_key.saturday.checked
                      "
                      #first_patient_saturday_start_second="ngModel"
                      [class.p-invalid]="
                        errors.first_patient_saturday_start_second ||
                        (first_patient_saturday_start_second.invalid &&
                          (first_patient_saturday_start_second.dirty ||
                            first_patient_saturday_start_second.touched))
                      "
                    ></p-dropdown>
                    <span>bis</span>
                    <p-dropdown
                      (onChange)="
                        checkForTimes($event, 'saturday', 'first_patient')
                      "
                      [(ngModel)]="
                        data.first_patient.times.days_with_key.saturday
                          .end_second
                      "
                      [disabled]="
                        !data.first_patient.times.days_with_key.saturday.checked
                      "
                      scrollHeight="500px"
                      appendTo="body"
                      placeholder="-"
                      name="first_patient_saturday_end_second"
                      [options]="times"
                      optionValue="value"
                      optionLabel="value"
                      [editable]="false"
                      [required]="
                        data.first_patient.times.days_with_key.saturday.checked
                      "
                      #first_patient_saturday_end_second="ngModel"
                      [class.p-invalid]="
                        errors.first_patient_saturday_end_second ||
                        (first_patient_saturday_end_second.invalid &&
                          (first_patient_saturday_end_second.dirty ||
                            first_patient_saturday_end_second.touched))
                      "
                    ></p-dropdown>
                    <i
                      *ngIf="
                        data.first_patient.times.days_with_key.saturday
                          .checked &&
                        data.first_patient.times.days_with_key.saturday
                          .has_second_block
                      "
                      (click)="removeSecondBlock('saturday', 'first_patient')"
                      pTooltip="Block entfernen"
                      class="pi pi-minus"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-6">
              <label
                >Leistung als {{ data.consulting_assignment ? "" : "*" }}</label
              >
              <div class="p-formgroup-inline">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.first_patient.service_for"
                    label="Reine HW"
                    name="first-person-service-for"
                    value="Reine HW"
                    #first_person_service_for="ngModel"
                    [required]="!data.consulting_assignment"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.first_patient.service_for"
                    label="Reine BE"
                    name="first-person-service-for"
                    value="Reine BE"
                    #first_person_service_for="ngModel"
                    [required]="!data.consulting_assignment"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.first_patient.service_for"
                    label="HW und BE"
                    name="first-person-service-for"
                    value="HW und BE"
                    #first_person_service_for="ngModel"
                    [required]="!data.consulting_assignment"
                  ></p-radioButton>
                </div>
              </div>
              <div
                *ngIf="
                  first_person_service_for.invalid &&
                  (first_person_service_for.dirty ||
                    first_person_service_for.touched)
                "
                class="p-invalid"
              >
                <div *ngIf="first_person_service_for.errors?.required">
                  <small>Bitte wählen Sie etwas aus</small>
                </div>
              </div>
            </div>
            <div class="p-field p-col-3">
              <label for="first_patient_transfer_needed_general"
                >Transfer notwendig?</label
              >
              <div class="p-formgroup-inline has-form-height">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Nein"
                    name="first_patient_transfer_needed_general"
                    [(ngModel)]="data.first_patient.transfer_needed_general"
                    value="Nein"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Ja"
                    name="first_patient_transfer_needed_general"
                    [(ngModel)]="data.first_patient.transfer_needed_general"
                    value="Ja"
                  ></p-radioButton>
                </div>
              </div>
            </div>
            <div
              class="p-field p-col-3"
              *ngIf="data.first_patient.transfer_needed_general === 'Ja'"
            >
              <label for="first-patient-transfer_needed_general_info"
                >Info</label
              >
              <input
                id="first-patient-transfer_needed_general_info"
                name="first-patient-transfer_needed_general_info"
                [(ngModel)]="data.first_patient.transfer_needed_general_info"
                type="text"
                pInputText
              />
            </div>
          </div>

          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-3">
              <label>Führerschein notwendig?</label>
              <div class="p-formgroup-inline">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.first_patient.driving_license_needed"
                    label="Ja"
                    name="first-person-driving_license_needed"
                    value="Ja"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.first_patient.driving_license_needed"
                    label="Nein"
                    name="first-person-driving_license_needed"
                    value="Nein"
                  ></p-radioButton>
                </div>
              </div>
            </div>

            <div class="p-field p-col-3">
              <label>Raucher erlaubt?</label>
              <div class="p-formgroup-inline">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.first_patient.smoker_allowed"
                    label="Ja"
                    name="first-person-smoker_allowed"
                    value="Ja"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.first_patient.smoker_allowed"
                    label="Nein"
                    name="first-person-smoker_allowed"
                    value="Nein"
                  ></p-radioButton>
                </div>
              </div>
            </div>

            <div class="p-field p-col-4">
              <label>Soll geimpft sein?</label>
              <div class="p-formgroup-inline">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.first_patient.should_be_vaccinated"
                    label="Ja"
                    name="first-person-should_be_vaccinated"
                    value="Ja"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.first_patient.should_be_vaccinated"
                    label="Ja oder genesen"
                    name="first-person-should_be_vaccinated"
                    value="Ja oder genesen"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.first_patient.should_be_vaccinated"
                    label="Egal"
                    name="first-person-should_be_vaccinated"
                    value="Egal"
                  ></p-radioButton>
                </div>
              </div>
            </div>
          </div>

          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-6">
              <span class="content-sub-headline">Betreuung</span>
              <div
                class="p-field p-col-12"
                *ngFor="let service of dependencies.services_care"
              >
                <div class="p-formgroup-inline">
                  <div class="p-field-checkbox">
                    <p-checkbox
                      [(ngModel)]="data.first_patient.service_ids"
                      [label]="service.name"
                      [value]="service.id"
                      (onChange)="serviceChanged('first_patient')"
                      name="first-person-services"
                    ></p-checkbox>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-field p-col-6">
              <span class="content-sub-headline">Hauswirtschaft</span>
              <div
                class="p-field p-col-12"
                *ngFor="let service of dependencies.services_housekeeping"
              >
                <div class="p-formgroup-inline">
                  <div class="p-field-checkbox">
                    <p-checkbox
                      [(ngModel)]="data.first_patient.service_ids"
                      [label]="service.name"
                      [value]="service.id"
                      (onChange)="serviceChanged('first_patient')"
                      name="first-person-services"
                    ></p-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
              <label for="first-person-services-text"
                >Erläuterungen / Sonstiges</label
              >
              <textarea
                id="first-person-services-text"
                name="first-person-services-text"
                [(ngModel)]="data.first_patient.services_text"
                pInputTextarea
              ></textarea>
            </div>
          </div>
        </div>
      </div>

      <div
        class="content limit-width content-highlight-2"
        *ngIf="data.patient_type === 'B'"
      >
        <header class="content-header">
          <span>Leistungsart Person 2</span>
          <button
            (click)="takeoverServicesFromFirstPerson()"
            pButton
            label="Übernahme aus Person 1"
            type="button"
            class="p-button-outlined"
            icon="pi pi-refresh"
            iconPos="left"
          ></button>
        </header>

        <div class="content-body">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-2">
              <label for="second-person-hours-per-month"
                >Stunden pro Monat
                {{ data.consulting_assignment ? "" : "*" }}</label
              >
              <div class="p-inputgroup">
                <p-inputNumber
                  inputId="second-person-hours-per-month"
                  name="second-person-hours-per-month"
                  [(ngModel)]="data.second_patient.hours_per_month"
                  [useGrouping]="false"
                  [required]="!data.consulting_assignment"
                  mode="decimal"
                  locale="de-DE"
                  [minFractionDigits]="2"
                  #second_person_hours_per_month="ngModel"
                  [class.p-invalid]="
                    second_person_hours_per_month.invalid &&
                    (second_person_hours_per_month.dirty ||
                      second_person_hours_per_month.touched)
                  "
                ></p-inputNumber>

                <span class="p-inputgroup-addon">Std</span>
              </div>

              <div
                *ngIf="
                  second_person_hours_per_month.invalid &&
                  (second_person_hours_per_month.dirty ||
                    second_person_hours_per_month.touched)
                "
                class="p-invalid"
              >
                <div *ngIf="second_person_hours_per_month.errors?.required">
                  <small>Bitte geben Sie einen Wert ein</small>
                </div>
              </div>
            </div>
            <div class="p-field p-col-9 p-offset-1">
              <label>Intervall</label>
              <div class="p-formgroup-inline">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.second_patient.appointment_interval"
                    label="Täglich"
                    name="second-person-interval"
                    value="Täglich"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.second_patient.appointment_interval"
                    label="Wöchentlich"
                    name="second-person-interval"
                    value="Wöchentlich"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.second_patient.appointment_interval"
                    label="Alle 2 Wochen"
                    name="second-person-interval"
                    value="Alle 2 Wochen"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.second_patient.appointment_interval"
                    label="Monatlich"
                    name="second-person-interval"
                    value="Monatlich"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.second_patient.appointment_interval"
                    label="Flexibel"
                    name="second-person-interval"
                    value="Flexibel"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.second_patient.appointment_interval"
                    label="Fixer Termin"
                    name="second-person-interval"
                    value="Fixer Termin"
                  ></p-radioButton>
                </div>
              </div>
            </div>
          </div>

          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
              <div class="caregiver-times-container">
                <p-checkbox
                  [binary]="true"
                  [(ngModel)]="data.second_patient.flexible_times"
                  label="Flexible Zeiten"
                  (onChange)="setFlexibleTimes($event, 'second_patient')"
                  name="first_patient_flexible_times"
                ></p-checkbox>
              </div>
            </div>
            <div class="p-field p-col-12">
              <!-- Montag -->
              <div class="caregiver-times-container">
                <p-checkbox
                  [(ngModel)]="
                    data.second_patient.times.days_with_key.monday.checked
                  "
                  [binary]="true"
                  (onChange)="timeChecked($event, 'monday', 'second_patient')"
                  label="Montag"
                  name="second_patient_monday"
                ></p-checkbox>
                <div class="caregiver-times-lines p-flex-row">
                  <!-- Montag erster Block -->
                  <div class="caregiver-times-line">
                    <span>von</span>
                    <p-dropdown
                      (onChange)="
                        checkForTimes($event, 'monday', 'second_patient')
                      "
                      [(ngModel)]="
                        data.second_patient.times.days_with_key.monday
                          .start_first
                      "
                      [disabled]="
                        !data.second_patient.times.days_with_key.monday.checked
                      "
                      scrollHeight="500px"
                      appendTo="body"
                      name="second_patient_monday_start_first"
                      placeholder="-"
                      [options]="times"
                      optionValue="value"
                      optionLabel="value"
                      [editable]="false"
                      [required]="
                        data.second_patient.times.days_with_key.monday.checked
                      "
                      #second_patient_monday_start_first="ngModel"
                      [class.p-invalid]="
                        errors.second_patient_monday_start_first ||
                        (second_patient_monday_start_first.invalid &&
                          (second_patient_monday_start_first.dirty ||
                            second_patient_monday_start_first.touched))
                      "
                    ></p-dropdown>
                    <span>bis</span>
                    <p-dropdown
                      (onChange)="
                        checkForTimes($event, 'monday', 'second_patient')
                      "
                      [(ngModel)]="
                        data.second_patient.times.days_with_key.monday.end_first
                      "
                      [disabled]="
                        !data.second_patient.times.days_with_key.monday.checked
                      "
                      scrollHeight="500px"
                      appendTo="body"
                      placeholder="-"
                      name="second_patient_monday_end_first"
                      [options]="times"
                      optionValue="value"
                      optionLabel="value"
                      [editable]="false"
                      [required]="
                        data.second_patient.times.days_with_key.monday.checked
                      "
                      #second_patient_monday_end_first="ngModel"
                      [class.p-invalid]="
                        errors.second_patient_monday_end_first ||
                        (second_patient_monday_end_first.invalid &&
                          (second_patient_monday_end_first.dirty ||
                            second_patient_monday_end_first.touched))
                      "
                    ></p-dropdown>
                    <i
                      (click)="setSecondBlock('monday', 'second_patient')"
                      *ngIf="
                        data.second_patient.times.days_with_key.monday
                          .checked &&
                        !data.second_patient.times.days_with_key.monday
                          .has_second_block
                      "
                      pTooltip="Neuer Block"
                      class="pi pi-plus"
                    ></i>
                  </div>

                  <!-- Montag zweiter Block -->
                  <div
                    class="caregiver-times-line"
                    *ngIf="
                      data.second_patient.times.days_with_key.monday
                        .has_second_block
                    "
                  >
                    <span class="color-main1"><strong>oder</strong></span>
                    <span>von</span>
                    <p-dropdown
                      (onChange)="
                        checkForTimes($event, 'monday', 'second_patient')
                      "
                      [(ngModel)]="
                        data.second_patient.times.days_with_key.monday
                          .start_second
                      "
                      [disabled]="
                        !data.second_patient.times.days_with_key.monday.checked
                      "
                      scrollHeight="500px"
                      appendTo="body"
                      placeholder="-"
                      name="second_patient_monday_start_second"
                      [options]="times"
                      optionValue="value"
                      optionLabel="value"
                      [editable]="false"
                      [required]="
                        data.second_patient.times.days_with_key.monday.checked
                      "
                      #second_patient_monday_start_second="ngModel"
                      [class.p-invalid]="
                        errors.second_patient_monday_start_second ||
                        (second_patient_monday_start_second.invalid &&
                          (second_patient_monday_start_second.dirty ||
                            second_patient_monday_start_second.touched))
                      "
                    ></p-dropdown>
                    <span>bis</span>
                    <p-dropdown
                      (onChange)="
                        checkForTimes($event, 'monday', 'second_patient')
                      "
                      [(ngModel)]="
                        data.second_patient.times.days_with_key.monday
                          .end_second
                      "
                      [disabled]="
                        !data.second_patient.times.days_with_key.monday.checked
                      "
                      scrollHeight="500px"
                      appendTo="body"
                      placeholder="-"
                      name="second_patient_monday_end_second"
                      [options]="times"
                      optionValue="value"
                      optionLabel="value"
                      [editable]="false"
                      [required]="
                        data.second_patient.times.days_with_key.monday.checked
                      "
                      #second_patient_monday_end_second="ngModel"
                      [class.p-invalid]="
                        errors.second_patient_monday_end_second ||
                        (second_patient_monday_end_second.invalid &&
                          (second_patient_monday_end_second.dirty ||
                            second_patient_monday_end_second.touched))
                      "
                    ></p-dropdown>
                    <i
                      *ngIf="
                        data.second_patient.times.days_with_key.monday
                          .checked &&
                        data.second_patient.times.days_with_key.monday
                          .has_second_block
                      "
                      (click)="removeSecondBlock('monday', 'second_patient')"
                      pTooltip="Block entfernen"
                      class="pi pi-minus"
                    ></i>
                  </div>
                </div>
              </div>

              <!-- Dienstag -->
              <div class="caregiver-times-container">
                <p-checkbox
                  [(ngModel)]="
                    data.second_patient.times.days_with_key.tuesday.checked
                  "
                  [binary]="true"
                  (onChange)="timeChecked($event, 'tuesday', 'second_patient')"
                  label="Dienstag"
                  name="second_patient_tuesday"
                ></p-checkbox>
                <div class="caregiver-times-lines p-flex-row">
                  <!-- Dienstag erster Block -->
                  <div class="caregiver-times-line">
                    <span>von</span>
                    <p-dropdown
                      (onChange)="
                        checkForTimes($event, 'tuesday', 'second_patient')
                      "
                      [(ngModel)]="
                        data.second_patient.times.days_with_key.tuesday
                          .start_first
                      "
                      [disabled]="
                        !data.second_patient.times.days_with_key.tuesday.checked
                      "
                      scrollHeight="500px"
                      appendTo="body"
                      placeholder="-"
                      name="second_patient_tuesday_start_first"
                      [options]="times"
                      optionValue="value"
                      optionLabel="value"
                      [editable]="false"
                      [required]="
                        data.second_patient.times.days_with_key.tuesday.checked
                      "
                      #second_patient_tuesday_start_first="ngModel"
                      [class.p-invalid]="
                        errors.second_patient_tuesday_start_first ||
                        (second_patient_tuesday_start_first.invalid &&
                          (second_patient_tuesday_start_first.dirty ||
                            second_patient_tuesday_start_first.touched))
                      "
                    ></p-dropdown>
                    <span>bis</span>
                    <p-dropdown
                      (onChange)="
                        checkForTimes($event, 'tuesday', 'second_patient')
                      "
                      [(ngModel)]="
                        data.second_patient.times.days_with_key.tuesday
                          .end_first
                      "
                      [disabled]="
                        !data.second_patient.times.days_with_key.tuesday.checked
                      "
                      scrollHeight="500px"
                      appendTo="body"
                      placeholder="-"
                      name="second_patient_tuesday_end_first"
                      [options]="times"
                      optionValue="value"
                      optionLabel="value"
                      [editable]="false"
                      [required]="
                        data.second_patient.times.days_with_key.tuesday.checked
                      "
                      #second_patient_tuesday_end_first="ngModel"
                      [class.p-invalid]="
                        errors.second_patient_tuesday_end_first ||
                        (second_patient_tuesday_end_first.invalid &&
                          (second_patient_tuesday_end_first.dirty ||
                            second_patient_tuesday_end_first.touched))
                      "
                    ></p-dropdown>
                    <i
                      (click)="setSecondBlock('tuesday', 'second_patient')"
                      *ngIf="
                        data.second_patient.times.days_with_key.tuesday
                          .checked &&
                        !data.second_patient.times.days_with_key.tuesday
                          .has_second_block
                      "
                      pTooltip="Neuer Block"
                      class="pi pi-plus"
                    ></i>
                  </div>

                  <!-- Dienstag zweiter Block -->
                  <div
                    class="caregiver-times-line"
                    *ngIf="
                      data.second_patient.times.days_with_key.tuesday
                        .has_second_block
                    "
                  >
                    <span class="color-main1"><strong>oder</strong></span>
                    <span>von</span>
                    <p-dropdown
                      (onChange)="
                        checkForTimes($event, 'tuesday', 'second_patient')
                      "
                      [(ngModel)]="
                        data.second_patient.times.days_with_key.tuesday
                          .start_second
                      "
                      [disabled]="
                        !data.second_patient.times.days_with_key.tuesday.checked
                      "
                      scrollHeight="500px"
                      appendTo="body"
                      placeholder="-"
                      name="second_patient_tuesday_start_second"
                      [options]="times"
                      optionValue="value"
                      optionLabel="value"
                      [editable]="false"
                      [required]="
                        data.second_patient.times.days_with_key.tuesday.checked
                      "
                      #second_patient_tuesday_start_second="ngModel"
                      [class.p-invalid]="
                        errors.second_patient_tuesday_start_second ||
                        (second_patient_tuesday_start_second.invalid &&
                          (second_patient_tuesday_start_second.dirty ||
                            second_patient_tuesday_start_second.touched))
                      "
                    ></p-dropdown>
                    <span>bis</span>
                    <p-dropdown
                      (onChange)="
                        checkForTimes($event, 'tuesday', 'second_patient')
                      "
                      [(ngModel)]="
                        data.second_patient.times.days_with_key.tuesday
                          .end_second
                      "
                      [disabled]="
                        !data.second_patient.times.days_with_key.tuesday.checked
                      "
                      scrollHeight="500px"
                      appendTo="body"
                      placeholder="-"
                      name="second_patient_tuesday_end_second"
                      [options]="times"
                      optionValue="value"
                      optionLabel="value"
                      [editable]="false"
                      [required]="
                        data.second_patient.times.days_with_key.tuesday.checked
                      "
                      #second_patient_tuesday_end_second="ngModel"
                      [class.p-invalid]="
                        errors.second_patient_tuesday_end_second ||
                        (second_patient_tuesday_end_second.invalid &&
                          (second_patient_tuesday_end_second.dirty ||
                            second_patient_tuesday_end_second.touched))
                      "
                    ></p-dropdown>
                    <i
                      *ngIf="
                        data.second_patient.times.days_with_key.tuesday
                          .checked &&
                        data.second_patient.times.days_with_key.tuesday
                          .has_second_block
                      "
                      (click)="removeSecondBlock('tuesday', 'second_patient')"
                      pTooltip="Block entfernen"
                      class="pi pi-minus"
                    ></i>
                  </div>
                </div>
              </div>

              <!-- Mittwoch -->
              <div class="caregiver-times-container">
                <p-checkbox
                  [(ngModel)]="
                    data.second_patient.times.days_with_key.wednesday.checked
                  "
                  [binary]="true"
                  (onChange)="
                    timeChecked($event, 'wednesday', 'second_patient')
                  "
                  label="Mittwoch"
                  name="second_patient_wednesday"
                ></p-checkbox>
                <div class="caregiver-times-lines p-flex-row">
                  <!-- Mittwoch erster Block -->
                  <div class="caregiver-times-line">
                    <span>von</span>
                    <p-dropdown
                      (onChange)="
                        checkForTimes($event, 'wednesday', 'second_patient')
                      "
                      [(ngModel)]="
                        data.second_patient.times.days_with_key.wednesday
                          .start_first
                      "
                      [disabled]="
                        !data.second_patient.times.days_with_key.wednesday
                          .checked
                      "
                      scrollHeight="500px"
                      appendTo="body"
                      placeholder="-"
                      name="second_patient_wednesday_start_first"
                      [options]="times"
                      optionValue="value"
                      optionLabel="value"
                      [editable]="false"
                      [required]="
                        data.second_patient.times.days_with_key.wednesday
                          .checked
                      "
                      #second_patient_wednesday_start_first="ngModel"
                      [class.p-invalid]="
                        errors.second_patient_wednesday_start_first ||
                        (second_patient_wednesday_start_first.invalid &&
                          (second_patient_wednesday_start_first.dirty ||
                            second_patient_wednesday_start_first.touched))
                      "
                    ></p-dropdown>
                    <span>bis</span>
                    <p-dropdown
                      (onChange)="
                        checkForTimes($event, 'wednesday', 'second_patient')
                      "
                      [(ngModel)]="
                        data.second_patient.times.days_with_key.wednesday
                          .end_first
                      "
                      [disabled]="
                        !data.second_patient.times.days_with_key.wednesday
                          .checked
                      "
                      scrollHeight="500px"
                      appendTo="body"
                      placeholder="-"
                      name="second_patient_wednesday_end_first"
                      [options]="times"
                      optionValue="value"
                      optionLabel="value"
                      [editable]="false"
                      [required]="
                        data.second_patient.times.days_with_key.wednesday
                          .checked
                      "
                      #second_patient_wednesday_end_first="ngModel"
                      [class.p-invalid]="
                        errors.second_patient_wednesday_end_first ||
                        (second_patient_wednesday_end_first.invalid &&
                          (second_patient_wednesday_end_first.dirty ||
                            second_patient_wednesday_end_first.touched))
                      "
                    ></p-dropdown>
                    <i
                      (click)="setSecondBlock('wednesday', 'second_patient')"
                      *ngIf="
                        data.second_patient.times.days_with_key.wednesday
                          .checked &&
                        !data.second_patient.times.days_with_key.wednesday
                          .has_second_block
                      "
                      pTooltip="Neuer Block"
                      class="pi pi-plus"
                    ></i>
                  </div>

                  <!-- Mittwoch zweiter Block -->
                  <div
                    class="caregiver-times-line"
                    *ngIf="
                      data.second_patient.times.days_with_key.wednesday
                        .has_second_block
                    "
                  >
                    <span class="color-main1"><strong>oder</strong></span>
                    <span>von</span>
                    <p-dropdown
                      (onChange)="
                        checkForTimes($event, 'wednesday', 'second_patient')
                      "
                      [(ngModel)]="
                        data.second_patient.times.days_with_key.wednesday
                          .start_second
                      "
                      [disabled]="
                        !data.second_patient.times.days_with_key.wednesday
                          .checked
                      "
                      scrollHeight="500px"
                      appendTo="body"
                      placeholder="-"
                      name="second_patient_wednesday_start_second"
                      [options]="times"
                      optionValue="value"
                      optionLabel="value"
                      [editable]="false"
                      [required]="
                        data.second_patient.times.days_with_key.wednesday
                          .checked
                      "
                      #second_patient_wednesday_start_second="ngModel"
                      [class.p-invalid]="
                        errors.second_patient_wednesday_start_second ||
                        (second_patient_wednesday_start_second.invalid &&
                          (second_patient_wednesday_start_second.dirty ||
                            second_patient_wednesday_start_second.touched))
                      "
                    ></p-dropdown>
                    <span>bis</span>
                    <p-dropdown
                      (onChange)="
                        checkForTimes($event, 'wednesday', 'second_patient')
                      "
                      [(ngModel)]="
                        data.second_patient.times.days_with_key.wednesday
                          .end_second
                      "
                      [disabled]="
                        !data.second_patient.times.days_with_key.wednesday
                          .checked
                      "
                      scrollHeight="500px"
                      appendTo="body"
                      placeholder="-"
                      name="second_patient_wednesday_end_second"
                      [options]="times"
                      optionValue="value"
                      optionLabel="value"
                      [editable]="false"
                      [required]="
                        data.second_patient.times.days_with_key.wednesday
                          .checked
                      "
                      #second_patient_wednesday_end_second="ngModel"
                      [class.p-invalid]="
                        errors.second_patient_wednesday_end_second ||
                        (second_patient_wednesday_end_second.invalid &&
                          (second_patient_wednesday_end_second.dirty ||
                            second_patient_wednesday_end_second.touched))
                      "
                    ></p-dropdown>
                    <i
                      *ngIf="
                        data.second_patient.times.days_with_key.wednesday
                          .checked &&
                        data.second_patient.times.days_with_key.wednesday
                          .has_second_block
                      "
                      (click)="removeSecondBlock('wednesday', 'second_patient')"
                      pTooltip="Block entfernen"
                      class="pi pi-minus"
                    ></i>
                  </div>
                </div>
              </div>

              <!-- Donnerstag -->
              <div class="caregiver-times-container">
                <p-checkbox
                  [(ngModel)]="
                    data.second_patient.times.days_with_key.thursday.checked
                  "
                  [binary]="true"
                  (onChange)="timeChecked($event, 'thursday', 'second_patient')"
                  label="Donnerstag"
                  name="second_patient_thursday"
                ></p-checkbox>
                <div class="caregiver-times-lines p-flex-row">
                  <!-- Donnerstag erster Block -->
                  <div class="caregiver-times-line">
                    <span>von</span>
                    <p-dropdown
                      (onChange)="
                        checkForTimes($event, 'thursday', 'second_patient')
                      "
                      [(ngModel)]="
                        data.second_patient.times.days_with_key.thursday
                          .start_first
                      "
                      [disabled]="
                        !data.second_patient.times.days_with_key.thursday
                          .checked
                      "
                      scrollHeight="500px"
                      appendTo="body"
                      placeholder="-"
                      name="second_patient_thursday_start_first"
                      [options]="times"
                      optionValue="value"
                      optionLabel="value"
                      [editable]="false"
                      [required]="
                        data.second_patient.times.days_with_key.thursday.checked
                      "
                      #second_patient_thursday_start_first="ngModel"
                      [class.p-invalid]="
                        errors.second_patient_thursday_start_first ||
                        (second_patient_thursday_start_first.invalid &&
                          (second_patient_thursday_start_first.dirty ||
                            second_patient_thursday_start_first.touched))
                      "
                    ></p-dropdown>
                    <span>bis</span>
                    <p-dropdown
                      (onChange)="
                        checkForTimes($event, 'thursday', 'second_patient')
                      "
                      [(ngModel)]="
                        data.second_patient.times.days_with_key.thursday
                          .end_first
                      "
                      [disabled]="
                        !data.second_patient.times.days_with_key.thursday
                          .checked
                      "
                      scrollHeight="500px"
                      appendTo="body"
                      placeholder="-"
                      name="second_patient_thursday_end_first"
                      [options]="times"
                      optionValue="value"
                      optionLabel="value"
                      [editable]="false"
                      [required]="
                        data.second_patient.times.days_with_key.thursday.checked
                      "
                      #second_patient_thursday_end_first="ngModel"
                      [class.p-invalid]="
                        errors.second_patient_thursday_end_first ||
                        (second_patient_thursday_end_first.invalid &&
                          (second_patient_thursday_end_first.dirty ||
                            second_patient_thursday_end_first.touched))
                      "
                    ></p-dropdown>
                    <i
                      (click)="setSecondBlock('thursday', 'second_patient')"
                      *ngIf="
                        data.second_patient.times.days_with_key.thursday
                          .checked &&
                        !data.second_patient.times.days_with_key.thursday
                          .has_second_block
                      "
                      pTooltip="Neuer Block"
                      class="pi pi-plus"
                    ></i>
                  </div>

                  <!-- Donnerstag zweiter Block -->
                  <div
                    class="caregiver-times-line"
                    *ngIf="
                      data.second_patient.times.days_with_key.thursday
                        .has_second_block
                    "
                  >
                    <span class="color-main1"><strong>oder</strong></span>
                    <span>von</span>
                    <p-dropdown
                      (onChange)="
                        checkForTimes($event, 'thursday', 'second_patient')
                      "
                      [(ngModel)]="
                        data.second_patient.times.days_with_key.thursday
                          .start_second
                      "
                      [disabled]="
                        !data.second_patient.times.days_with_key.thursday
                          .checked
                      "
                      scrollHeight="500px"
                      appendTo="body"
                      placeholder="-"
                      name="second_patient_thursday_start_second"
                      [options]="times"
                      optionValue="value"
                      optionLabel="value"
                      [editable]="false"
                      [required]="
                        data.second_patient.times.days_with_key.thursday.checked
                      "
                      #second_patient_thursday_start_second="ngModel"
                      [class.p-invalid]="
                        errors.second_patient_thursday_start_second ||
                        (second_patient_thursday_start_second.invalid &&
                          (second_patient_thursday_start_second.dirty ||
                            second_patient_thursday_start_second.touched))
                      "
                    ></p-dropdown>
                    <span>bis</span>
                    <p-dropdown
                      (onChange)="
                        checkForTimes($event, 'thursday', 'second_patient')
                      "
                      [(ngModel)]="
                        data.second_patient.times.days_with_key.thursday
                          .end_second
                      "
                      [disabled]="
                        !data.second_patient.times.days_with_key.thursday
                          .checked
                      "
                      scrollHeight="500px"
                      appendTo="body"
                      placeholder="-"
                      name="second_patient_thursday_end_second"
                      [options]="times"
                      optionValue="value"
                      optionLabel="value"
                      [editable]="false"
                      [required]="
                        data.second_patient.times.days_with_key.thursday.checked
                      "
                      #second_patient_thursday_end_second="ngModel"
                      [class.p-invalid]="
                        errors.second_patient_thursday_end_second ||
                        (second_patient_thursday_end_second.invalid &&
                          (second_patient_thursday_end_second.dirty ||
                            second_patient_thursday_end_second.touched))
                      "
                    ></p-dropdown>
                    <i
                      *ngIf="
                        data.second_patient.times.days_with_key.thursday
                          .checked &&
                        data.second_patient.times.days_with_key.thursday
                          .has_second_block
                      "
                      (click)="removeSecondBlock('thursday', 'second_patient')"
                      pTooltip="Block entfernen"
                      class="pi pi-minus"
                    ></i>
                  </div>
                </div>
              </div>

              <!-- Freitag -->
              <div class="caregiver-times-container">
                <p-checkbox
                  [(ngModel)]="
                    data.second_patient.times.days_with_key.friday.checked
                  "
                  [binary]="true"
                  (onChange)="timeChecked($event, 'friday', 'second_patient')"
                  label="Freitag"
                  name="second_patient_friday"
                ></p-checkbox>
                <div class="caregiver-times-lines p-flex-row">
                  <!-- Freitag erster Block -->
                  <div class="caregiver-times-line">
                    <span>von</span>
                    <p-dropdown
                      (onChange)="
                        checkForTimes($event, 'friday', 'second_patient')
                      "
                      [(ngModel)]="
                        data.second_patient.times.days_with_key.friday
                          .start_first
                      "
                      [disabled]="
                        !data.second_patient.times.days_with_key.friday.checked
                      "
                      scrollHeight="500px"
                      appendTo="body"
                      placeholder="-"
                      name="second_patient_friday_start_first"
                      [options]="times"
                      optionValue="value"
                      optionLabel="value"
                      [editable]="false"
                      [required]="
                        data.second_patient.times.days_with_key.friday.checked
                      "
                      #second_patient_friday_start_first="ngModel"
                      [class.p-invalid]="
                        errors.second_patient_friday_start_first ||
                        (second_patient_friday_start_first.invalid &&
                          (second_patient_friday_start_first.dirty ||
                            second_patient_friday_start_first.touched))
                      "
                    ></p-dropdown>
                    <span>bis</span>
                    <p-dropdown
                      (onChange)="
                        checkForTimes($event, 'friday', 'second_patient')
                      "
                      [(ngModel)]="
                        data.second_patient.times.days_with_key.friday.end_first
                      "
                      [disabled]="
                        !data.second_patient.times.days_with_key.friday.checked
                      "
                      scrollHeight="500px"
                      appendTo="body"
                      placeholder="-"
                      name="second_patient_friday_end_first"
                      [options]="times"
                      optionValue="value"
                      optionLabel="value"
                      [editable]="false"
                      [required]="
                        data.second_patient.times.days_with_key.friday.checked
                      "
                      #second_patient_friday_end_first="ngModel"
                      [class.p-invalid]="
                        errors.second_patient_friday_end_first ||
                        (second_patient_friday_end_first.invalid &&
                          (second_patient_friday_end_first.dirty ||
                            second_patient_friday_end_first.touched))
                      "
                    ></p-dropdown>
                    <i
                      (click)="setSecondBlock('friday', 'second_patient')"
                      *ngIf="
                        data.second_patient.times.days_with_key.friday
                          .checked &&
                        !data.second_patient.times.days_with_key.friday
                          .has_second_block
                      "
                      pTooltip="Neuer Block"
                      class="pi pi-plus"
                    ></i>
                  </div>

                  <!-- Freitag zweiter Block -->
                  <div
                    class="caregiver-times-line"
                    *ngIf="
                      data.second_patient.times.days_with_key.friday
                        .has_second_block
                    "
                  >
                    <span class="color-main1"><strong>oder</strong></span>
                    <span>von</span>
                    <p-dropdown
                      (onChange)="
                        checkForTimes($event, 'friday', 'second_patient')
                      "
                      [(ngModel)]="
                        data.second_patient.times.days_with_key.friday
                          .start_second
                      "
                      [disabled]="
                        !data.second_patient.times.days_with_key.friday.checked
                      "
                      scrollHeight="500px"
                      appendTo="body"
                      placeholder="-"
                      name="second_patient_friday_start_second"
                      [options]="times"
                      optionValue="value"
                      optionLabel="value"
                      [editable]="false"
                      [required]="
                        data.second_patient.times.days_with_key.friday.checked
                      "
                      #second_patient_friday_start_second="ngModel"
                      [class.p-invalid]="
                        errors.second_patient_friday_start_second ||
                        (second_patient_friday_start_second.invalid &&
                          (second_patient_friday_start_second.dirty ||
                            second_patient_friday_start_second.touched))
                      "
                    ></p-dropdown>
                    <span>bis</span>
                    <p-dropdown
                      (onChange)="
                        checkForTimes($event, 'friday', 'second_patient')
                      "
                      [(ngModel)]="
                        data.second_patient.times.days_with_key.friday
                          .end_second
                      "
                      [disabled]="
                        !data.second_patient.times.days_with_key.friday.checked
                      "
                      scrollHeight="500px"
                      appendTo="body"
                      placeholder="-"
                      name="second_patient_friday_end_second"
                      [options]="times"
                      optionValue="value"
                      optionLabel="value"
                      [editable]="false"
                      [required]="
                        data.second_patient.times.days_with_key.friday.checked
                      "
                      #second_patient_friday_end_second="ngModel"
                      [class.p-invalid]="
                        errors.second_patient_friday_end_second ||
                        (second_patient_friday_end_second.invalid &&
                          (second_patient_friday_end_second.dirty ||
                            second_patient_friday_end_second.touched))
                      "
                    ></p-dropdown>
                    <i
                      *ngIf="
                        data.second_patient.times.days_with_key.friday
                          .checked &&
                        data.second_patient.times.days_with_key.friday
                          .has_second_block
                      "
                      (click)="removeSecondBlock('friday', 'second_patient')"
                      pTooltip="Block entfernen"
                      class="pi pi-minus"
                    ></i>
                  </div>
                </div>
              </div>

              <!-- Samstag -->
              <div class="caregiver-times-container">
                <p-checkbox
                  [(ngModel)]="
                    data.second_patient.times.days_with_key.saturday.checked
                  "
                  [binary]="true"
                  (onChange)="timeChecked($event, 'saturday', 'second_patient')"
                  label="Samstag"
                  name="second_patient_saturday"
                ></p-checkbox>
                <div class="caregiver-times-lines p-flex-row">
                  <!-- Samstag erster Block -->
                  <div class="caregiver-times-line">
                    <span>von</span>
                    <p-dropdown
                      (onChange)="
                        checkForTimes($event, 'saturday', 'second_patient')
                      "
                      [(ngModel)]="
                        data.second_patient.times.days_with_key.saturday
                          .start_first
                      "
                      [disabled]="
                        !data.second_patient.times.days_with_key.saturday
                          .checked
                      "
                      scrollHeight="500px"
                      appendTo="body"
                      placeholder="-"
                      name="second_patient_saturday_start_first"
                      [options]="times"
                      optionValue="value"
                      optionLabel="value"
                      [editable]="false"
                      [required]="
                        data.second_patient.times.days_with_key.saturday.checked
                      "
                      #second_patient_saturday_start_first="ngModel"
                      [class.p-invalid]="
                        errors.second_patient_saturday_start_first ||
                        (second_patient_saturday_start_first.invalid &&
                          (second_patient_saturday_start_first.dirty ||
                            second_patient_saturday_start_first.touched))
                      "
                    ></p-dropdown>
                    <span>bis</span>
                    <p-dropdown
                      (onChange)="
                        checkForTimes($event, 'saturday', 'second_patient')
                      "
                      [(ngModel)]="
                        data.second_patient.times.days_with_key.saturday
                          .end_first
                      "
                      [disabled]="
                        !data.second_patient.times.days_with_key.saturday
                          .checked
                      "
                      scrollHeight="500px"
                      appendTo="body"
                      placeholder="-"
                      name="second_patient_saturday_end_first"
                      [options]="times"
                      optionValue="value"
                      optionLabel="value"
                      [editable]="false"
                      [required]="
                        data.second_patient.times.days_with_key.saturday.checked
                      "
                      #second_patient_saturday_end_first="ngModel"
                      [class.p-invalid]="
                        errors.second_patient_saturday_end_first ||
                        (second_patient_saturday_end_first.invalid &&
                          (second_patient_saturday_end_first.dirty ||
                            second_patient_saturday_end_first.touched))
                      "
                    ></p-dropdown>
                    <i
                      (click)="setSecondBlock('saturday', 'second_patient')"
                      *ngIf="
                        data.second_patient.times.days_with_key.saturday
                          .checked &&
                        !data.second_patient.times.days_with_key.saturday
                          .has_second_block
                      "
                      pTooltip="Neuer Block"
                      class="pi pi-plus"
                    ></i>
                  </div>

                  <!-- Samstag zweiter Block -->
                  <div
                    class="caregiver-times-line"
                    *ngIf="
                      data.second_patient.times.days_with_key.saturday
                        .has_second_block
                    "
                  >
                    <span class="color-main1"><strong>oder</strong></span>
                    <span>von</span>
                    <p-dropdown
                      (onChange)="
                        checkForTimes($event, 'saturday', 'second_patient')
                      "
                      [(ngModel)]="
                        data.second_patient.times.days_with_key.saturday
                          .start_second
                      "
                      [disabled]="
                        !data.second_patient.times.days_with_key.saturday
                          .checked
                      "
                      scrollHeight="500px"
                      appendTo="body"
                      placeholder="-"
                      name="second_patient_saturday_start_second"
                      [options]="times"
                      optionValue="value"
                      optionLabel="value"
                      [editable]="false"
                      [required]="
                        data.second_patient.times.days_with_key.saturday.checked
                      "
                      #second_patient_saturday_start_second="ngModel"
                      [class.p-invalid]="
                        errors.second_patient_saturday_start_second ||
                        (second_patient_saturday_start_second.invalid &&
                          (second_patient_saturday_start_second.dirty ||
                            second_patient_saturday_start_second.touched))
                      "
                    ></p-dropdown>
                    <span>bis</span>
                    <p-dropdown
                      (onChange)="
                        checkForTimes($event, 'saturday', 'second_patient')
                      "
                      [(ngModel)]="
                        data.second_patient.times.days_with_key.saturday
                          .end_second
                      "
                      [disabled]="
                        !data.second_patient.times.days_with_key.saturday
                          .checked
                      "
                      scrollHeight="500px"
                      appendTo="body"
                      placeholder="-"
                      name="second_patient_saturday_end_second"
                      [options]="times"
                      optionValue="value"
                      optionLabel="value"
                      [editable]="false"
                      [required]="
                        data.second_patient.times.days_with_key.saturday.checked
                      "
                      #second_patient_saturday_end_second="ngModel"
                      [class.p-invalid]="
                        errors.second_patient_saturday_end_second ||
                        (second_patient_saturday_end_second.invalid &&
                          (second_patient_saturday_end_second.dirty ||
                            second_patient_saturday_end_second.touched))
                      "
                    ></p-dropdown>
                    <i
                      *ngIf="
                        data.second_patient.times.days_with_key.saturday
                          .checked &&
                        data.second_patient.times.days_with_key.saturday
                          .has_second_block
                      "
                      (click)="removeSecondBlock('saturday', 'second_patient')"
                      pTooltip="Block entfernen"
                      class="pi pi-minus"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-6">
              <label
                >Leistung als {{ data.consulting_assignment ? "" : "*" }}</label
              >
              <div class="p-formgroup-inline">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.second_patient.service_for"
                    label="Reine HW"
                    name="second-person-service-for"
                    value="Reine HW"
                    #second_person_service_for="ngModel"
                    [required]="!data.consulting_assignment"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.second_patient.service_for"
                    label="Reine BE"
                    name="second-person-service-for"
                    value="Reine BE"
                    #second_person_service_for="ngModel"
                    [required]="!data.consulting_assignment"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.second_patient.service_for"
                    label="HW und BE"
                    name="second-person-service-for"
                    value="HW und BE"
                    #second_person_service_for="ngModel"
                    [required]="!data.consulting_assignment"
                  ></p-radioButton>
                </div>
              </div>
              <div
                *ngIf="
                  second_person_service_for.invalid &&
                  (second_person_service_for.dirty ||
                    second_person_service_for.touched)
                "
                class="p-invalid"
              >
                <div *ngIf="second_person_service_for.errors?.required">
                  <small>Bitte wählen Sie etwas aus</small>
                </div>
              </div>
            </div>
            <div class="p-field p-col-3">
              <label for="second_patient_transfer_needed_general"
                >Transfer notwendig?</label
              >
              <div class="p-formgroup-inline has-form-height">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Nein"
                    name="second_patient_transfer_needed_general"
                    [(ngModel)]="data.second_patient.transfer_needed_general"
                    value="Nein"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Ja"
                    name="second_patient_transfer_needed_general"
                    [(ngModel)]="data.second_patient.transfer_needed_general"
                    value="Ja"
                  ></p-radioButton>
                </div>
              </div>
            </div>
            <div
              class="p-field p-col-3"
              *ngIf="data.second_patient.transfer_needed_general === 'Ja'"
            >
              <label for="second-patient-transfer_needed_general_info"
                >Info</label
              >
              <input
                id="second-patient-transfer_needed_general_info"
                name="second-patient-transfer_needed_general_info"
                [(ngModel)]="data.second_patient.transfer_needed_general_info"
                type="text"
                pInputText
              />
            </div>
          </div>

          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-3">
              <label>Führerschein notwendig?</label>
              <div class="p-formgroup-inline">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.second_patient.driving_license_needed"
                    label="Ja"
                    name="second-person-driving_license_needed"
                    value="Ja"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.second_patient.driving_license_needed"
                    label="Nein"
                    name="second-person-driving_license_needed"
                    value="Nein"
                  ></p-radioButton>
                </div>
              </div>
            </div>

            <div class="p-field p-col-3">
              <label>Raucher erlaubt?</label>
              <div class="p-formgroup-inline">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.second_patient.smoker_allowed"
                    label="Ja"
                    name="second-person-smoker_allowed"
                    value="Ja"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.second_patient.smoker_allowed"
                    label="Nein"
                    name="second-person-smoker_allowed"
                    value="Nein"
                  ></p-radioButton>
                </div>
              </div>
            </div>

            <div class="p-field p-col-4">
              <label>Soll geimpft sein?</label>
              <div class="p-formgroup-inline">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.second_patient.should_be_vaccinated"
                    label="Ja"
                    name="second-person-should_be_vaccinated"
                    value="Ja"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.second_patient.should_be_vaccinated"
                    label="Ja oder genesen"
                    name="second-person-should_be_vaccinated"
                    value="Ja oder genesen"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.second_patient.should_be_vaccinated"
                    label="Egal"
                    name="second-person-should_be_vaccinated"
                    value="Egal"
                  ></p-radioButton>
                </div>
              </div>
            </div>
          </div>

          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-6">
              <span class="content-sub-headline">Betreuung</span>
              <div
                class="p-field p-col-12"
                *ngFor="let service of dependencies.services_care"
              >
                <div class="p-formgroup-inline">
                  <div class="p-field-checkbox">
                    <p-checkbox
                      [(ngModel)]="data.second_patient.service_ids"
                      [label]="service.name"
                      (onChange)="serviceChanged('second_patient')"
                      [value]="service.id"
                      name="second-person-services"
                    ></p-checkbox>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-field p-col-6">
              <span class="content-sub-headline">Hauswirtschaft</span>
              <div
                class="p-field p-col-12"
                *ngFor="let service of dependencies.services_housekeeping"
              >
                <div class="p-formgroup-inline">
                  <div class="p-field-checkbox">
                    <p-checkbox
                      [(ngModel)]="data.second_patient.service_ids"
                      [label]="service.name"
                      (onChange)="serviceChanged('second_patient')"
                      [value]="service.id"
                      name="second-person-services"
                    ></p-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
              <label for="second-person-services-text"
                >Erläuterungen / Sonstiges</label
              >
              <textarea
                id="second-person-services-text"
                name="second-person-services-text"
                [(ngModel)]="data.second_patient.services_text"
                pInputTextarea
              ></textarea>
            </div>
          </div>
        </div>
      </div>

      <div class="content limit-width">
        <header class="content-header">
          <span>Haushalt</span>
        </header>

        <div class="content-body">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
              <div class="p-formgroup-inline">
                <div class="p-field-checkbox">
                  <p-checkbox
                    label="Kann drucken"
                    [binary]="true"
                    inputId="can_print"
                    [(ngModel)]="data.household.can_print"
                    name="can_print"
                  ></p-checkbox>
                </div>
              </div>
            </div>
          </div>

          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-6">
              <label>Es handelt sich um ein</label>
              <div class="p-formgroup-inline">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.household.type"
                    label="Einfamilienhaus"
                    name="household-type"
                    value="Einfamilienhaus"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Mehrfamilienhaus"
                    [(ngModel)]="data.household.type"
                    name="household-type"
                    value="Mehrfamilienhaus"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Hochhaus"
                    name="household-type"
                    [(ngModel)]="data.household.type"
                    value="Hochhaus"
                  ></p-radioButton>
                </div>
              </div>
            </div>
            <div class="p-field p-col-2">
              <label for="household-living-space">Wohnfläche ca.</label>
              <div class="p-inputgroup">
                <input
                  id="household-living-space"
                  name="household-living-space"
                  [(ngModel)]="data.household.living_space"
                  type="text"
                  pInputText
                />
                <span class="p-inputgroup-addon">qm</span>
              </div>
            </div>
          </div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
              <label>Zustand Haushalt</label>
              <div class="p-formgroup-inline">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    [(ngModel)]="data.household.condition"
                    label="Gehoben"
                    name="condition"
                    value="Gehoben"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Normal"
                    [(ngModel)]="data.household.condition"
                    name="condition"
                    value="Normal"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Unordentlich"
                    name="condition"
                    [(ngModel)]="data.household.condition"
                    value="Unordentlich"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Desolat"
                    name="condition"
                    [(ngModel)]="data.household.condition"
                    value="Desolat"
                  ></p-radioButton>
                </div>
              </div>
            </div>
          </div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-2">
              <label for="household-living-floor">Person wohnt in</label>
              <div class="p-formgroup-inline has-form-height">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="EG"
                    name="household-living-floor"
                    [(ngModel)]="data.household.living_floor"
                    value="EG"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="OG"
                    name="household-living-floor"
                    [(ngModel)]="data.household.living_floor"
                    value="OG"
                  ></p-radioButton>
                </div>
              </div>
            </div>
            <div
              class="p-field p-col-2"
              *ngIf="data.household.living_floor === 'OG'"
            >
              <label for="household-living-floor-number">Welches OG</label>
              <input
                id="household-living-floor-number"
                name="household-living-floor-number"
                [(ngModel)]="data.household.living_floor_number"
                type="text"
                pInputText
              />
            </div>

            <div
              class="p-field p-col-6"
              [class.p-offset-4]="data.household.living_floor !== 'OG'"
              [class.p-offset-2]="data.household.living_floor === 'OG'"
            >
              <label for="household-transfer-need"
                >Ist ein Transfer zwischen den Stockwerken notwendig?</label
              >
              <div class="p-formgroup-inline">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Ja"
                    name="household-transfer-need"
                    [(ngModel)]="data.household.transfer_needed"
                    value="Ja"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Nein"
                    name="household-transfer-need"
                    [(ngModel)]="data.household.transfer_needed"
                    value="Nein"
                  ></p-radioButton>
                </div>
              </div>
            </div>
          </div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-4">
              <label for="household-smoking">Rauchen Sie im Haushalt?</label>
              <div class="p-formgroup-inline">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Ja"
                    name="household-smoking"
                    [(ngModel)]="data.household.smoking"
                    value="Ja"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Nein"
                    name="household-smoking"
                    [(ngModel)]="data.household.smoking"
                    value="Nein"
                  ></p-radioButton>
                </div>
              </div>
            </div>
          </div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-3">
              <label for="household-pets">Haustiere im Haushalt?</label>
              <div class="p-formgroup-inline has-form-height">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Ja"
                    name="household-pets"
                    [(ngModel)]="data.household.pets"
                    value="Ja"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Nein"
                    name="household-pets"
                    [(ngModel)]="data.household.pets"
                    value="Nein"
                  ></p-radioButton>
                </div>
              </div>
            </div>
            <div class="p-field p-col-6" *ngIf="data.household.pets === 'Ja'">
              <label>Welche?</label>
              <div class="p-formgroup-inline" style="align-items: center">
                <div class="p-field-checkbox">
                  <p-checkbox
                    label="Hunde"
                    [binary]="true"
                    [(ngModel)]="data.household.dogs"
                    name="household-dogs"
                  ></p-checkbox>
                </div>
                <div class="p-field-checkbox">
                  <p-checkbox
                    label="Katzen"
                    [binary]="true"
                    [(ngModel)]="data.household.cats"
                    name="household-cats"
                  ></p-checkbox>
                </div>
                <div class="p-field-checkbox">
                  <input
                    placeholder="Andere..."
                    id="household-pets-info"
                    [(ngModel)]="data.household.pets_info"
                    name="household-pets-info"
                    type="text"
                    pInputText
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-6">
              <label for="household-key_case">Schlüsselkasten vorhanden?</label>
              <div class="p-formgroup-inline">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Ja"
                    name="household-key_case"
                    [(ngModel)]="data.household.key_case"
                    value="Ja"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Nein"
                    name="household-key_case"
                    [(ngModel)]="data.household.key_case"
                    value="Nein"
                  ></p-radioButton>
                </div>
              </div>
            </div>
            <div class="p-field p-col-6">
              <label for="household-cleaning_stuff"
                >Reinigungsmittel vorhanden?</label
              >
              <div class="p-formgroup-inline">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Ja"
                    name="household-cleaning_stuff"
                    [(ngModel)]="data.household.cleaning_stuff"
                    value="Ja"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Nein"
                    name="household-cleaning_stuff"
                    [(ngModel)]="data.household.cleaning_stuff"
                    value="Nein"
                  ></p-radioButton>
                </div>
              </div>
            </div>
          </div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
              <label for="household-specials"
                >Besonderheiten im Haushalt / Haushaltscheck</label
              >
              <textarea
                id="household-specials"
                name="household-specials"
                [(ngModel)]="data.household.specials"
                pInputTextarea
              ></textarea>
            </div>
          </div>
        </div>
      </div>

      <div class="content limit-width content-highlight-1">
        <header class="content-header">
          <span>Krankheitsmerkmale Person 1</span>
        </header>

        <div class="content-body">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-3">
              <label for="first-person-weight">Gewicht</label>
              <div class="p-inputgroup">
                <input
                  id="first-person-weight"
                  name="first-person-weight"
                  [(ngModel)]="data.first_patient.weight"
                  type="text"
                  pInputText
                />
                <span class="p-inputgroup-addon">kg</span>
              </div>
            </div>

            <div class="p-field p-col-3">
              <label for="first-person-size">Größe</label>
              <div class="p-inputgroup">
                <input
                  id="first-person-size"
                  name="first-person-size"
                  [(ngModel)]="data.first_patient.size"
                  type="text"
                  pInputText
                />
                <span class="p-inputgroup-addon">cm</span>
              </div>
            </div>
          </div>

          <div class="p-fluid p-formgrid p-grid">
            <div
              class="p-field p-col-4"
              *ngFor="let disease of dependencies.diseases"
            >
              <div class="p-formgroup-inline">
                <div class="p-field-checkbox">
                  <p-checkbox
                    [label]="disease.name"
                    [value]="disease.id"
                    [(ngModel)]="data.first_patient.disease_ids"
                    name="first-person-diseases"
                    [class.please-describe-label]="disease.describe_label"
                  ></p-checkbox>
                </div>
                <div
                  class="p-field-checkbox"
                  *ngIf="disease.name === 'Schlaganfall'"
                >
                  <p-checkbox
                    [label]="'L'"
                    [binary]="true"
                    [(ngModel)]="data.first_patient.stroke_left"
                    name="first-person-stroke_left"
                  ></p-checkbox>
                </div>
                <div
                  class="p-field-checkbox"
                  *ngIf="disease.name === 'Schlaganfall'"
                >
                  <p-checkbox
                    [label]="'R'"
                    [binary]="true"
                    [(ngModel)]="data.first_patient.stroke_right"
                    name="first-person-stroke_right"
                  ></p-checkbox>
                </div>
              </div>
            </div>

            <div style="display: flex; width: 100%">
              <div class="content-inner-container">
                <div class="p-field">
                  <label>Ansteckende Krankheiten? *</label>
                  <div class="p-formgroup-inline">
                    <div class="p-field-radiobutton">
                      <p-radioButton
                        label="Nein"
                        name="first-person-infectious_diseases"
                        [(ngModel)]="data.first_patient.infectious_diseases"
                        value="Nein"
                        #first_person_infectious_diseases="ngModel"
                        required
                      ></p-radioButton>
                    </div>
                    <div class="p-field-radiobutton">
                      <p-radioButton
                        label="Ja"
                        name="first-person-infectious_diseases"
                        [(ngModel)]="data.first_patient.infectious_diseases"
                        value="Ja"
                        #first_person_infectious_diseases="ngModel"
                        required
                      ></p-radioButton>
                    </div>
                  </div>
                  <div
                    *ngIf="
                      first_person_infectious_diseases.invalid &&
                      (first_person_infectious_diseases.dirty ||
                        first_person_infectious_diseases.touched)
                    "
                    class="p-invalid"
                  >
                    <div
                      *ngIf="first_person_infectious_diseases.errors?.required"
                    >
                      <small>Bitte wählen Sie etwas aus</small>
                    </div>
                  </div>
                </div>

                <div class="p-field">
                  <label for="first-person-infectious_diseases_info"
                    >Informationen</label
                  >
                  <input
                    id="first-person-infectious_diseases_info"
                    name="first-person-infectious_diseases_info"
                    [(ngModel)]="data.first_patient.infectious_diseases_info"
                    type="text"
                    pInputText
                  />
                </div>
              </div>

              <div class="content-inner-container">
                <div class="p-field">
                  <label>Blutverdünner? *</label>
                  <div class="p-formgroup-inline">
                    <div class="p-field-radiobutton">
                      <p-radioButton
                        label="Nein"
                        name="first-person-blood_thinner"
                        [(ngModel)]="data.first_patient.blood_thinner"
                        value="Nein"
                        #first_person_blood_thinner="ngModel"
                        required
                      ></p-radioButton>
                    </div>
                    <div class="p-field-radiobutton">
                      <p-radioButton
                        label="Ja"
                        name="first-person-blood_thinner"
                        [(ngModel)]="data.first_patient.blood_thinner"
                        value="Ja"
                        #first_person_blood_thinner="ngModel"
                        required
                      ></p-radioButton>
                    </div>
                  </div>
                  <div
                    *ngIf="
                      first_person_blood_thinner.invalid &&
                      (first_person_blood_thinner.dirty ||
                        first_person_blood_thinner.touched)
                    "
                    class="p-invalid"
                  >
                    <div *ngIf="first_person_blood_thinner.errors?.required">
                      <small>Bitte wählen Sie etwas aus</small>
                    </div>
                  </div>
                </div>

                <div class="p-field">
                  <label for="first-person-blood_thinner_info"
                    >Informationen</label
                  >
                  <input
                    id="first-person-blood_thinner_info"
                    name="first-person-blood_thinner_info"
                    [(ngModel)]="data.first_patient.blood_thinner_info"
                    type="text"
                    pInputText
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
              <label for="first-person-diseases-text">Erläuterungen</label>
              <textarea
                id="first-person-diseases-text"
                name="first-person-diseases-text"
                [(ngModel)]="data.first_patient.diseases_text"
                pInputTextarea
              ></textarea>
            </div>
          </div>
        </div>
      </div>

      <div
        class="content limit-width content-highlight-2"
        *ngIf="data.patient_type === 'B'"
      >
        <header class="content-header">
          <span>Krankheitsmerkmale Person 2</span>
        </header>

        <div class="content-body">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-3">
              <label for="second-person-weight">Gewicht</label>
              <div class="p-inputgroup">
                <input
                  id="second-person-weight"
                  name="second-person-weight"
                  [(ngModel)]="data.second_patient.weight"
                  type="text"
                  pInputText
                />
                <span class="p-inputgroup-addon">kg</span>
              </div>
            </div>

            <div class="p-field p-col-3">
              <label for="second-person-size">Größe</label>
              <div class="p-inputgroup">
                <input
                  id="second-person-size"
                  name="second-person-size"
                  [(ngModel)]="data.second_patient.size"
                  type="text"
                  pInputText
                />
                <span class="p-inputgroup-addon">cm</span>
              </div>
            </div>
          </div>

          <div class="p-fluid p-formgrid p-grid">
            <div
              class="p-field p-col-4"
              *ngFor="let disease of dependencies.diseases"
            >
              <div class="p-formgroup-inline">
                <div class="p-field-checkbox">
                  <p-checkbox
                    [label]="disease.name"
                    name="second-person-diseases"
                    [value]="disease.id"
                    [(ngModel)]="data.second_patient.disease_ids"
                    [class.please-describe-label]="disease.describe_label"
                  ></p-checkbox>
                </div>
                <div
                  class="p-field-checkbox"
                  *ngIf="disease.name === 'Schlaganfall'"
                >
                  <p-checkbox
                    [label]="'L'"
                    [binary]="true"
                    [(ngModel)]="data.second_patient.stroke_left"
                    name="second-person-stroke_left"
                  ></p-checkbox>
                </div>
                <div
                  class="p-field-checkbox"
                  *ngIf="disease.name === 'Schlaganfall'"
                >
                  <p-checkbox
                    [label]="'R'"
                    [binary]="true"
                    [(ngModel)]="data.second_patient.stroke_right"
                    name="second-person-stroke_right"
                  ></p-checkbox>
                </div>
              </div>
            </div>

            <div style="display: flex; width: 100%">
              <div class="content-inner-container">
                <div class="p-field">
                  <label>Ansteckende Krankheiten? *</label>
                  <div class="p-formgroup-inline">
                    <div class="p-field-radiobutton">
                      <p-radioButton
                        label="Nein"
                        name="second-person-infectious_diseases"
                        [(ngModel)]="data.second_patient.infectious_diseases"
                        value="Nein"
                        #second_person_infectious_diseases="ngModel"
                        required
                      ></p-radioButton>
                    </div>
                    <div class="p-field-radiobutton">
                      <p-radioButton
                        label="Ja"
                        name="second-person-infectious_diseases"
                        [(ngModel)]="data.second_patient.infectious_diseases"
                        value="Ja"
                        #second_person_infectious_diseases="ngModel"
                        required
                      ></p-radioButton>
                    </div>
                  </div>
                  <div
                    *ngIf="
                      second_person_infectious_diseases.invalid &&
                      (second_person_infectious_diseases.dirty ||
                        second_person_infectious_diseases.touched)
                    "
                    class="p-invalid"
                  >
                    <div
                      *ngIf="second_person_infectious_diseases.errors?.required"
                    >
                      <small>Bitte wählen Sie etwas aus</small>
                    </div>
                  </div>
                </div>

                <div class="p-field">
                  <label for="second-person-infectious_diseases_info"
                    >Informationen</label
                  >
                  <input
                    id="second-person-infectious_diseases_info"
                    name="second-person-infectious_diseases_info"
                    [(ngModel)]="data.second_patient.infectious_diseases_info"
                    type="text"
                    pInputText
                  />
                </div>
              </div>

              <div class="content-inner-container">
                <div class="p-field">
                  <label>Blutverdünner? *</label>
                  <div class="p-formgroup-inline">
                    <div class="p-field-radiobutton">
                      <p-radioButton
                        label="Nein"
                        name="second-person-blood_thinner"
                        [(ngModel)]="data.second_patient.blood_thinner"
                        value="Nein"
                        #second_person_blood_thinner="ngModel"
                        required
                      ></p-radioButton>
                    </div>
                    <div class="p-field-radiobutton">
                      <p-radioButton
                        label="Ja"
                        name="second-person-blood_thinner"
                        [(ngModel)]="data.second_patient.blood_thinner"
                        value="Ja"
                        #second_person_blood_thinner="ngModel"
                        required
                      ></p-radioButton>
                    </div>
                  </div>
                  <div
                    *ngIf="
                      second_person_blood_thinner.invalid &&
                      (second_person_blood_thinner.dirty ||
                        second_person_blood_thinner.touched)
                    "
                    class="p-invalid"
                  >
                    <div *ngIf="second_person_blood_thinner.errors?.required">
                      <small>Bitte wählen Sie etwas aus</small>
                    </div>
                  </div>
                </div>

                <div class="p-field">
                  <label for="second-person-blood_thinner_info"
                    >Informationen</label
                  >
                  <input
                    id="second-person-blood_thinner_info"
                    name="second-person-blood_thinner_info"
                    [(ngModel)]="data.second_patient.blood_thinner_info"
                    type="text"
                    pInputText
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
              <label for="second-person-diseases-text">Erläuterungen</label>
              <textarea
                id="second-person-diseases-text"
                name="second-person-diseases-text"
                [(ngModel)]="data.second_patient.diseases_text"
                pInputTextarea
              ></textarea>
            </div>
          </div>
        </div>
      </div>

      <div class="content limit-width content-highlight-1">
        <header class="content-header">
          <span>
            Inkontinenz Person 1
            <p-checkbox
              [binary]="true"
              (onChange)="noIncontinenceChoosed('first_patient')"
              [(ngModel)]="data.first_patient.no_incontinence"
              label="Es besteht keine Inkontinenz"
              name="first-person-no-incontinence"
            ></p-checkbox>
          </span>
        </header>

        <div class="content-body">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-4">
              <label for="first-person-incontinence-urine">Urin</label>
              <p-dropdown
                inputId="first-person-incontinence-urine"
                name="first-person-incontinence-urine"
                placeholder="Bitte auswählen"
                (onChange)="incontinenceChoosed('first_patient')"
                [options]="dependencies.incontinence"
                [(ngModel)]="data.first_patient.incontinence_urine"
              ></p-dropdown>
            </div>
            <div class="p-field p-col-4">
              <label for="first-person-incontinence-stool">Stuhl</label>
              <p-dropdown
                inputId="first-person-incontinence-stool"
                name="first-person-incontinence-stool"
                placeholder="Bitte auswählen"
                (onChange)="incontinenceChoosed('first_patient')"
                [options]="dependencies.incontinence"
                [(ngModel)]="data.first_patient.incontinence_stool"
              ></p-dropdown>
            </div>
          </div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
              <label for="first-person-incontinence-text">Erläuterungen</label>
              <textarea
                id="first-person-incontinence-text"
                name="first-person-incontinence-text"
                [(ngModel)]="data.first_patient.incontinence_text"
                pInputTextarea
              ></textarea>
            </div>
          </div>
        </div>
      </div>

      <div
        class="content limit-width content-highlight-2"
        *ngIf="data.patient_type === 'B'"
      >
        <header class="content-header">
          <span>
            Inkontinenz Person 2
            <p-checkbox
              [binary]="true"
              (onChange)="noIncontinenceChoosed('second_patient')"
              [(ngModel)]="data.second_patient.no_incontinence"
              label="Es besteht keine Inkontinenz"
              name="second-person-no-incontinence"
            ></p-checkbox>
          </span>
        </header>

        <div class="content-body">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-4">
              <label for="second-person-incontinence-urine">Urin</label>
              <p-dropdown
                inputId="second-person-incontinence-urine"
                name="second-person-incontinence-urine"
                placeholder="Bitte auswählen"
                (onChange)="incontinenceChoosed('second_patient')"
                [options]="dependencies.incontinence"
                [(ngModel)]="data.second_patient.incontinence_urine"
              ></p-dropdown>
            </div>
            <div class="p-field p-col-4">
              <label for="second-person-incontinence-stool">Stuhl</label>

              <p-dropdown
                inputId="second-person-incontinence-stool"
                name="second-person-incontinence-stool"
                placeholder="Bitte auswählen"
                (onChange)="incontinenceChoosed('second_patient')"
                [options]="dependencies.incontinence"
                [(ngModel)]="data.second_patient.incontinence_stool"
              ></p-dropdown>
            </div>
          </div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
              <label for="second-person-incontinence-text">Erläuterungen</label>
              <textarea
                id="second-person-incontinence-text"
                name="second-person-incontinence-text"
                [(ngModel)]="data.second_patient.incontinence_text"
                pInputTextarea
              ></textarea>
            </div>
          </div>
        </div>
      </div>

      <div class="content limit-width content-highlight-1">
        <header class="content-header">
          <span>Hilfsmittel / Mobilität Person 1</span>
        </header>

        <div class="content-body">
          <div class="p-fluid p-formgrid p-grid">
            <div
              class="p-field p-col-3"
              *ngFor="let tool of dependencies.tools"
            >
              <div class="p-formgroup-inline">
                <div class="p-field-checkbox">
                  <p-checkbox
                    [label]="tool.name"
                    [value]="tool.id"
                    [(ngModel)]="data.first_patient.tool_ids"
                    name="first-person-tools"
                  ></p-checkbox>
                </div>
              </div>
            </div>
          </div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
              <label for="first-person-tools-text"
                >Erläuterungen / Sonstiges</label
              >
              <textarea
                id="first-person-tools-text"
                name="first-person-tools-text"
                [(ngModel)]="data.first_patient.tools_text"
                pInputTextarea
              ></textarea>
            </div>
          </div>
        </div>
      </div>

      <div
        class="content limit-width content-highlight-2"
        *ngIf="data.patient_type === 'B'"
      >
        <header class="content-header">
          <span>Hilfsmittel / Mobilität Person 2</span>
        </header>

        <div class="content-body">
          <div class="p-fluid p-formgrid p-grid">
            <div
              class="p-field p-col-3"
              *ngFor="let tool of dependencies.tools"
            >
              <div class="p-formgroup-inline">
                <div class="p-field-checkbox">
                  <p-checkbox
                    [label]="tool.name"
                    [value]="tool.id"
                    [(ngModel)]="data.second_patient.tool_ids"
                    name="second-person-tools"
                  ></p-checkbox>
                </div>
              </div>
            </div>
          </div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
              <label for="second-person-tools-text"
                >Erläuterungen / Sonstiges</label
              >
              <textarea
                id="second-person-tools-text"
                name="second-person-tools-text"
                [(ngModel)]="data.second_patient.tools_text"
                pInputTextarea
              ></textarea>
            </div>
          </div>
        </div>
      </div>

      <div class="content limit-width content-highlight-1">
        <header class="content-header">
          <span>Kommunikationsfähigkeit Person 1</span>
        </header>

        <div class="content-body">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-4">
              <label>Vollständig erhalten?</label>
              <div class="p-formgroup-inline">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Ja"
                    name="first-person-communication-complete-maintained"
                    [(ngModel)]="
                      data.first_patient.communication_complete_maintained
                    "
                    value="Ja"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Nein, eingeschränkt"
                    name="first-person-communication-complete-maintained"
                    [(ngModel)]="
                      data.first_patient.communication_complete_maintained
                    "
                    value="Nein, eingeschränkt"
                  ></p-radioButton>
                </div>
              </div>
            </div>
            <!-- TODO: diese einträge im backend leeren wenn "Ja" ausgewählt wurde -->
            <div
              class="p-field p-col-8"
              *ngIf="
                data.first_patient.communication_complete_maintained ===
                'Nein, eingeschränkt'
              "
            >
              <label>Bezüglich</label>
              <div class="p-formgroup-inline">
                <div
                  class="p-field-checkbox"
                  *ngFor="
                    let communicationType of dependencies.communication_types
                  "
                >
                  <p-checkbox
                    [label]="communicationType.name"
                    [value]="communicationType.id"
                    [(ngModel)]="data.first_patient.communication_type_ids"
                    name="first-person-communication-restricted-regarding"
                  ></p-checkbox>
                </div>
              </div>
            </div>
          </div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
              <label for="first-person-communication-text"
                >Erläuterungen / Sonstiges</label
              >
              <textarea
                id="first-person-communication-text"
                name="first-person-communication-text"
                [(ngModel)]="data.first_patient.communication_text"
                pInputTextarea
              ></textarea>
            </div>
          </div>
        </div>
      </div>

      <div
        class="content limit-width content-highlight-2"
        *ngIf="data.patient_type === 'B'"
      >
        <header class="content-header">
          <span>Kommunikationsfähigkeit Person 2</span>
        </header>

        <div class="content-body">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-4">
              <label>Vollständig erhalten?</label>
              <div class="p-formgroup-inline">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Ja"
                    name="second-person-communication-complete-maintained"
                    [(ngModel)]="
                      data.second_patient.communication_complete_maintained
                    "
                    value="Nein"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Nein, eingeschränkt"
                    name="second-person-communication-complete-maintained"
                    [(ngModel)]="
                      data.second_patient.communication_complete_maintained
                    "
                    value="Nein, eingeschränkt"
                  ></p-radioButton>
                </div>
              </div>
            </div>
            <!-- TODO: diese einträge im backend leeren wenn "Ja" ausgewählt wurde -->
            <div
              class="p-field p-col-8"
              *ngIf="
                data.second_patient.communication_complete_maintained ===
                'Nein, eingeschränkt'
              "
            >
              <label>Bezüglich</label>
              <div class="p-formgroup-inline">
                <div
                  class="p-field-checkbox"
                  *ngFor="
                    let communicationType of dependencies.communication_types
                  "
                >
                  <p-checkbox
                    [label]="communicationType.name"
                    [value]="communicationType.id"
                    [(ngModel)]="data.second_patient.communication_type_ids"
                    name="second-person-communication-restricted-regarding"
                  ></p-checkbox>
                </div>
              </div>
            </div>
          </div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
              <label for="second-person-communication-text"
                >Erläuterungen / Sonstiges</label
              >
              <textarea
                id="second-person-communication-text"
                name="second-person-communication-text"
                [(ngModel)]="data.second_patient.communication_text"
                pInputTextarea
              ></textarea>
            </div>
          </div>
        </div>
      </div>

      <div class="content limit-width content-highlight-1">
        <header class="content-header">
          <span>
            Charakter Person 1
            <p-checkbox
              [binary]="true"
              [(ngModel)]="data.first_patient.no_character_text"
              label="Keine Angaben getätigt"
              name="first-person-no-character-text"
            ></p-checkbox>
          </span>
        </header>

        <div class="content-body">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
              <label for="first-person-character"
                >Beschreibung des Charakters des zu Betreuenden</label
              >
              <textarea
                id="first-person-character"
                name="first-person-character"
                [(ngModel)]="data.first_patient.character"
                pInputTextarea
              ></textarea>
            </div>
          </div>
        </div>
      </div>

      <div
        class="content limit-width content-highlight-2"
        *ngIf="data.patient_type === 'B'"
      >
        <header class="content-header">
          <span>
            Charakter Person 2
            <p-checkbox
              [binary]="true"
              [(ngModel)]="data.second_patient.no_character_text"
              label="Keine Angaben getätigt"
              name="second-person-no-character-text"
            ></p-checkbox>
          </span>
        </header>

        <div class="content-body">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
              <label for="second-person-character"
                >Beschreibung des Charakters des zu Betreuenden</label
              >
              <textarea
                id="second-person-character"
                name="second-person-character"
                [(ngModel)]="data.second_patient.character"
                pInputTextarea
              ></textarea>
            </div>
          </div>
        </div>
      </div>

      <div class="content limit-width">
        <header class="content-header">
          <span>QM</span>
        </header>

        <div class="content-body">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-3">
              <label for="qm-initial-consultation-date">Erstberatung</label>
              <p-calendar
                [firstDayOfWeek]="1"
                [showWeek]="true"
                [showButtonBar]="true"
                [(ngModel)]="data.qm.initial_consultation_date_string"
                inputId="qm-initial-consultation-date"
                name="qm-initial-consultation-date"
                dateFormat="dd.mm.yy"
                dataType="string"
              ></p-calendar>
            </div>
            <div class="p-field p-col-3">
              <label for="qm-initial-consultation-from">Mitarbeiter</label>
              <input
                id="qm-initial-consultation-from"
                name="qm-initial-consultation-from"
                [(ngModel)]="data.qm.initial_consultation_from"
                type="text"
                pInputText
              />
            </div>
            <div class="p-field p-col-4 align-bottom">
              <div class="p-formgroup-inline">
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Telefonisch"
                    name="qm-initial-consultation-type"
                    [(ngModel)]="data.qm.initial_consultation_type"
                    value="Telefonisch"
                  ></p-radioButton>
                </div>
                <div class="p-field-radiobutton">
                  <p-radioButton
                    label="Persönlich"
                    name="qm-initial-consultation-type"
                    [(ngModel)]="data.qm.initial_consultation_type"
                    value="Persönlich"
                  ></p-radioButton>
                </div>
              </div>
            </div>
          </div>

          <div class="p-fluid p-formgrid p-grid" *ngIf="isEditMode">
            <div class="p-field p-col-3">
              <label for="qm-home-visit-date">THC</label>
              <strong>{{ data.qm.home_visit_date_string || "-" }}</strong>
              <!--            <p-calendar-->
              <!--              [firstDayOfWeek]="1"-->
              <!--              [showWeek]="true"-->
              <!--              inputId="qm-home-visit-date"-->
              <!--              name="qm-home-visit-date"-->
              <!--              [showTime]="true"-->
              <!--              [showButtonBar]="true"-->
              <!--              hourFormat="24"-->
              <!--              dateFormat="dd.mm.yy,"-->
              <!--              appendTo="body"-->
              <!--              [(ngModel)]="data.qm.home_visit_date_string"-->
              <!--              dataType="string"-->
              <!--            ></p-calendar>-->
            </div>
            <div class="p-field p-col-3">
              <label for="home_visit_from">Mitarbeiter</label>
              <strong>{{ data.qm.home_visit_from || "-" }}</strong>
              <!--            <p-dropdown-->
              <!--              name="home_visit_from"-->
              <!--              inputId="home_visit_from"-->
              <!--              placeholder="Bitte auswählen"-->
              <!--              optionLabel="full_name"-->
              <!--              optionValue="full_name"-->
              <!--              [(ngModel)]="data.qm.home_visit_from"-->
              <!--              [options]="homeVisitFrom"-->
              <!--            ></p-dropdown>-->
              <!--            <br />-->
              <!--            <small>Alter Wert: {{ data.qm.home_visit_from }}</small>-->
            </div>

            <div
              class="p-field p-col-2"
              style="display: flex; align-items: center"
            >
              <button
                pButton
                (click)="openThcDateDialog()"
                label="THC bearbeiten"
                type="button"
                icon="pi pi-pencil"
                iconPos="left"
              ></button>
            </div>

            <!--          <div class="p-field p-col-3 align-bottom">-->
            <!--            <div class="p-formgroup-inline">-->
            <!--              <div class="p-field-checkbox">-->
            <!--                <p-checkbox-->
            <!--                  label="in Bearbeitung"-->
            <!--                  name="qm-in-progress"-->
            <!--                  [binary]="true"-->
            <!--                  [(ngModel)]="data.qm.in_progress"-->
            <!--                ></p-checkbox>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--          </div>-->
            <!--          <div class="p-field p-col-3" *ngIf="data.qm.in_progress">-->
            <!--            <label for="qm-in-progress-from">von</label>-->
            <!--            <input-->
            <!--              id="qm-in-progress-from"-->
            <!--              name="qm-in-progress-from"-->
            <!--              [(ngModel)]="data.qm.in_progress_from"-->
            <!--              type="text"-->
            <!--              pInputText-->
            <!--            />-->
            <!--          </div>-->
          </div>

          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
              <label for="comment_thc">Kommentar für THC</label>
              <textarea
                id="comment_thc"
                [rows]="4"
                name="comment_thc"
                [(ngModel)]="data.qm.comment_thc"
                pInputTextarea
              ></textarea>
            </div>
          </div>

          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-3">
              <label for="qm-postal-shipping-date">Post Versand</label>
              <p-calendar
                [firstDayOfWeek]="1"
                [showWeek]="true"
                [showButtonBar]="true"
                inputId="qm-postal-shipping-date"
                name="qm-postal-shipping-date"
                [(ngModel)]="data.qm.postal_shipping_date_string"
                dateFormat="dd.mm.yy"
                dataType="string"
              ></p-calendar>
            </div>
            <div class="p-field p-col-3">
              <label for="qm-postal-return-date">Post Rücklauf</label>
              <p-calendar
                [firstDayOfWeek]="1"
                [showWeek]="true"
                [showButtonBar]="true"
                inputId="qm-postal-return-date"
                name="qm-postal-return-date"
                [(ngModel)]="data.qm.postal_return_date_string"
                dateFormat="dd.mm.yy"
                dataType="string"
              ></p-calendar>
            </div>
          </div>

          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
              <label for="qm-comment"
                >Kommentar für QM und Kundenbetreuung</label
              >
              <textarea
                id="qm-comment"
                [rows]="4"
                name="qm-comment"
                [(ngModel)]="data.qm.comment"
                pInputTextarea
              ></textarea>
            </div>
          </div>
        </div>
      </div>

      <div class="content limit-width">
        <header class="content-header">
          <span>Abschluss</span>
        </header>

        <div class="content-body">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
              <label for="summary_customer"
                >Zusammenfassung für den Kunden</label
              >
              <textarea
                id="summary_customer"
                name="summary_customer"
                [rows]="5"
                [(ngModel)]="data.summary_customer"
                pInputTextarea
              ></textarea>
            </div>
          </div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
              <label for="comment_customer">Kommentar für den Kunden</label>
              <textarea
                id="comment_customer"
                name="comment_customer"
                [rows]="5"
                [(ngModel)]="data.comment_customer"
                pInputTextarea
              ></textarea>
            </div>
          </div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
              <label for="summary_intern_person1"
                >Interne Zusammenfassung Person 1</label
              >
              <textarea
                id="summary_intern_person1"
                name="summary_intern_person1"
                [rows]="5"
                [(ngModel)]="data.first_patient.summary_intern"
                pInputTextarea
              ></textarea>
            </div>
          </div>

          <div
            class="p-fluid p-formgrid p-grid"
            *ngIf="data.patient_type === 'B'"
          >
            <div class="p-field p-col-12">
              <label for="summary_intern_person2"
                >Interne Zusammenfassung Person 2</label
              >
              <textarea
                id="summary_intern_person2"
                name="summary_intern_person2"
                [rows]="5"
                [(ngModel)]="data.second_patient.summary_intern"
                pInputTextarea
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="content-footer limit-width" [class.sticky]="isEditMode">
      <span></span>
      <button
        pButton
        (click)="save()"
        [label]="isEditMode ? 'Änderungen speichern' : 'Angebot erstellen'"
        type="button"
        [disabled]="submitted"
        [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
      ></button>
    </div>
  </div>
  <div
    class="content-offer-budget"
    *ngIf="!loading && data.offer_type === 'OFFER'"
  >
    <div class="budgets">
      <div class="budget-container budget-container-1">
        <h3>
          Person 1
          <i
            (click)="toggleBudgetOpenFirst()"
            class="pi"
            [class.pi-chevron-up]="budgetsOpen.first"
            [class.pi-chevron-down]="!budgetsOpen.first"
          ></i>
        </h3>

        <!-- TODO: auch auf detailseite anzeigen -->
        <span
          class="content-alert-message with-margin"
          *ngIf="data.first_patient.other_service_provider === 'Ja'"
        >
          Bitte Budget prüfen wegen alten Dienstleistern!
        </span>

        <!-- TODO: auch auf detailseite anzeigen -->
        <span
          class="content-alert-message with-margin"
          *ngIf="data.first_patient.has_degree_of_care_old"
        >
          Bitte alles individuell prüfen!
        </span>

        <div class="budget-items" *ngIf="budgetsOpen.first">
          <div class="budget-item">
            <span class="budget-type">Selbstzahler</span>
            <span class="budget-value">{{
              data.first_patient.insured_via.includes("Selbstzahler")
                ? "ja"
                : "nein"
            }}</span>
          </div>
          <div class="budget-item">
            <span class="budget-type">SGB V</span>
            <span class="budget-value">{{
              data.first_patient.insured_via.includes("SGB V") ? "ja" : "nein"
            }}</span>
          </div>
          <div class="budget-item">
            <span class="budget-type">Unfallkasse</span>
            <span class="budget-value">{{
              data.first_patient.insured_via.includes("UV") ? "ja" : "nein"
            }}</span>
          </div>
          <div class="budget-item">
            <span class="budget-type">Kostenträger</span>
            <span class="budget-value">{{
              data.first_patient.insured_via.includes("Kostenträger")
                ? "ja"
                : "nein"
            }}</span>
          </div>
          <div class="budget-item">
            <span class="budget-type">§37</span>
            <span class="budget-value">{{
              data.first_patient.budget_calculations.budget_37.value
            }}</span>
            <i
              class="pi pi-exclamation-triangle"
              *ngIf="data.first_patient.budget_calculations.budget_37.tooltip"
              tooltipPosition="left"
              [pTooltip]="
                data.first_patient.budget_calculations.budget_37.tooltip
              "
            ></i>
          </div>
          <div class="budget-item">
            <span class="budget-type">§45a</span>
            <span class="budget-value">{{
              data.first_patient.budget_calculations.budget_45a.value
            }}</span>
            <i
              class="pi pi-exclamation-triangle"
              *ngIf="data.first_patient.budget_calculations.budget_45a.tooltip"
              tooltipPosition="left"
              [pTooltip]="
                data.first_patient.budget_calculations.budget_45a.tooltip
              "
            ></i>
          </div>
          <div class="budget-item">
            <span class="budget-type">§45b <i>ALT</i></span>
            <span class="budget-value">{{
              data.first_patient.budget_calculations.budget_45b_old.value
            }}</span>
            <i
              class="pi pi-exclamation-triangle"
              *ngIf="
                data.first_patient.budget_calculations.budget_45b_old.tooltip
              "
              tooltipPosition="left"
              [pTooltip]="
                data.first_patient.budget_calculations.budget_45b_old.tooltip
              "
            ></i>
          </div>
          <div class="budget-item">
            <span class="budget-type">§45b <i>LAUFEND</i></span>
            <span class="budget-value">{{
              data.first_patient.budget_calculations.budget_45b_current.value
            }}</span>
            <i
              class="pi pi-exclamation-triangle"
              tooltipPosition="left"
              *ngIf="
                data.first_patient.budget_calculations.budget_45b_current
                  .tooltip
              "
              [pTooltip]="
                data.first_patient.budget_calculations.budget_45b_current
                  .tooltip
              "
            ></i>
          </div>
          <div class="budget-item">
            <span class="budget-type">§39</span>
            <span class="budget-value">{{
              data.first_patient.budget_calculations.budget_39.value
            }}</span>
            <i
              class="pi pi-exclamation-triangle"
              *ngIf="data.first_patient.budget_calculations.budget_39.tooltip"
              tooltipPosition="left"
              [pTooltip]="
                data.first_patient.budget_calculations.budget_39.tooltip
              "
            ></i>
            <span
              class="content-alert-message"
              *ngIf="data.first_patient.budget_calculations.budget_39.request"
            >
              Antrag stellen!
            </span>
          </div>
          <div class="budget-item">
            <span class="budget-type">§39 plus</span>
            <span class="budget-value">{{
              data.first_patient.budget_calculations.budget_42.value
            }}</span>
            <i
              class="pi pi-exclamation-triangle"
              *ngIf="data.first_patient.budget_calculations.budget_42.tooltip"
              tooltipPosition="left"
              [pTooltip]="
                data.first_patient.budget_calculations.budget_42.tooltip
              "
            ></i>
            <span
              class="content-alert-message"
              *ngIf="data.first_patient.budget_calculations.budget_42.request"
            >
              Antrag stellen!
            </span>
          </div>
          <span
            class="budget-information-comment"
            *ngIf="data.first_patient.budget_information"
          >
            {{ data.first_patient.budget_information }}
          </span>
        </div>
      </div>
      <div
        class="budget-container budget-container-2"
        *ngIf="data.patient_type === 'B'"
      >
        <h3>
          Person 2
          <i
            (click)="toggleBudgetOpenSecond()"
            class="pi"
            [class.pi-chevron-up]="budgetsOpen.second"
            [class.pi-chevron-down]="!budgetsOpen.second"
          ></i>
        </h3>

        <span
          class="content-alert-message with-margin"
          *ngIf="data.second_patient.other_service_provider === 'Ja'"
        >
          Bitte Budget prüfen wegen alten Dienstleistern!
        </span>

        <!-- TODO: auch auf detailseite anzeigen -->
        <span
          class="content-alert-message with-margin"
          *ngIf="data.second_patient.has_degree_of_care_old"
        >
          Bitte alles individuell prüfen!
        </span>

        <div class="budget-items" *ngIf="budgetsOpen.second">
          <div class="budget-item">
            <span class="budget-type">Selbstzahler</span>
            <span class="budget-value">{{
              data.second_patient.insured_via.includes("Selbstzahler")
                ? "ja"
                : "nein"
            }}</span>
          </div>
          <div class="budget-item">
            <span class="budget-type">SGB V</span>
            <span class="budget-value">{{
              data.second_patient.insured_via.includes("SGB V") ? "ja" : "nein"
            }}</span>
          </div>
          <div class="budget-item">
            <span class="budget-type">Unfallkasse</span>
            <span class="budget-value">{{
              data.second_patient.insured_via.includes("UV") ? "ja" : "nein"
            }}</span>
          </div>
          <div class="budget-item">
            <span class="budget-type">Kostenträger</span>
            <span class="budget-value">{{
              data.second_patient.insured_via.includes("Kostenträger")
                ? "ja"
                : "nein"
            }}</span>
          </div>
          <div class="budget-item">
            <span class="budget-type">§37</span>
            <span class="budget-value">{{
              data.second_patient.budget_calculations.budget_37.value
            }}</span>
            <i
              class="pi pi-exclamation-triangle"
              *ngIf="data.second_patient.budget_calculations.budget_37.tooltip"
              tooltipPosition="left"
              [pTooltip]="
                data.second_patient.budget_calculations.budget_37.tooltip
              "
            ></i>
          </div>
          <div class="budget-item">
            <span class="budget-type">§45a</span>
            <span class="budget-value">{{
              data.second_patient.budget_calculations.budget_45a.value
            }}</span>
            <i
              class="pi pi-exclamation-triangle"
              *ngIf="data.second_patient.budget_calculations.budget_45a.tooltip"
              tooltipPosition="left"
              [pTooltip]="
                data.second_patient.budget_calculations.budget_45a.tooltip
              "
            ></i>
          </div>
          <div class="budget-item">
            <span class="budget-type">§45b <i>ALT</i></span>
            <span class="budget-value">{{
              data.second_patient.budget_calculations.budget_45b_old.value
            }}</span>
            <i
              class="pi pi-exclamation-triangle"
              *ngIf="
                data.second_patient.budget_calculations.budget_45b_old.tooltip
              "
              tooltipPosition="left"
              [pTooltip]="
                data.second_patient.budget_calculations.budget_45b_old.tooltip
              "
            ></i>
          </div>
          <div class="budget-item">
            <span class="budget-type">§45b <i>LAUFEND</i></span>
            <span class="budget-value">{{
              data.second_patient.budget_calculations.budget_45b_current.value
            }}</span>
            <i
              class="pi pi-exclamation-triangle"
              *ngIf="
                data.second_patient.budget_calculations.budget_45b_current
                  .tooltip
              "
              tooltipPosition="left"
              [pTooltip]="
                data.second_patient.budget_calculations.budget_45b_current
                  .tooltip
              "
            ></i>
          </div>
          <div class="budget-item">
            <span class="budget-type">§39</span>
            <span class="budget-value">{{
              data.second_patient.budget_calculations.budget_39.value
            }}</span>
            <i
              class="pi pi-exclamation-triangle"
              *ngIf="data.second_patient.budget_calculations.budget_39.tooltip"
              tooltipPosition="left"
              [pTooltip]="
                data.second_patient.budget_calculations.budget_39.tooltip
              "
            ></i>
            <span
              class="content-alert-message"
              *ngIf="data.second_patient.budget_calculations.budget_39.request"
            >
              Antrag stellen!
            </span>
          </div>
          <div class="budget-item">
            <span class="budget-type">§39 plus</span>
            <span class="budget-value">{{
              data.second_patient.budget_calculations.budget_42.value
            }}</span>
            <i
              class="pi pi-exclamation-triangle"
              *ngIf="data.second_patient.budget_calculations.budget_42.tooltip"
              [pTooltip]="
                data.second_patient.budget_calculations.budget_42.tooltip
              "
            ></i>
            <span
              class="content-alert-message"
              *ngIf="data.second_patient.budget_calculations.budget_42.request"
            >
              Antrag stellen!
            </span>
          </div>
          <span
            class="budget-information-comment"
            *ngIf="data.second_patient.budget_information"
          >
            {{ data.second_patient.budget_information }}
          </span>
        </div>
      </div>
    </div>
  </div>
</form>
