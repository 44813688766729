import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../environments/environment'
import { AccountingReturnModel } from '../models/accounting/accounting-return.model'
import { AccountingReturnHistoryModel } from '../models/accounting/accounting-return-history.model'

@Injectable({
  providedIn: 'root',
})
export class CustomerRequestService {
  constructor(private http: HttpClient) {}

  public loadCreated(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.api}/customer-requests/created`)
  }
}
